.card-grid {
    margin-top: 20px;

    &__grid-layout {
        .content-card {
            width: 100%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__pagination {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 20px 0 ;
    }

    &__pagination-label {
        font-family: $font-family-secondary;
        color: $color__gray--dark;
        text-transform: uppercase;
        padding-left: 10px;
    }

    &__pagination-circles {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-grow: 1;

        .swiper-pagination-bullet {
            border: 1px solid $color__gray--dark;
            height: 14px;
            width: 14px;
            background-color: transparent;
            transition: border-color .2s ease-out,
            background-color .2s ease-out;
            opacity: 1;

            &-active {
                background-color: $color-brand-green;
                border-color: $color-brand-green;
            }
        }

        

    }
    @include bp-min($bp-mobile) {
        &__pagination {
            display: none;
        }
        &__grid-layout {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .content-card {
                width: calc(50% - 16px);
                margin-left: 0;
                margin-right: 0;
                max-width: none;
            }
        }
    }

    @include bp-min($bp-tablet-sm) {
        margin-top: 30px;
    
        .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: auto;
            height: auto;
            transform: none;
        }
    
        .content-card {
            flex-shrink: unset;
            width: calc(32.33% - 16px);
            height: auto;
        }
    }

    @include bp-min($bp-tablet) {
        &__grid-layout {
            &:after {
                content: '';
                display: block;
                width: calc(33.3333% - 11px);
            }
        
            .content-card {
                width: calc(33.3333% - 11px);
                // min-height: 604px;
            }
        }

        &__grid-two-layout {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .content-card-wide {
                width: calc(50% - 16px);

                &--x2 {
                    width: 100%;
                }
            }
        }
    }
}