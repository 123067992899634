.page-header {
	@extend %bg-cover;

	padding: px-to-rem(24px) 0;
	position: relative;
	margin-top: $desktop-header;


	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(48, 72, 91, 0.5);
		z-index: 1;
	}

	.page-wrapper  {
		position: relative;
		z-index: 5;
	}

	@include bp-max($bp-hamburger) {
		margin-top: $mobile-header;
	}
}

.page-header-article {
	margin: px-to-rem(45px) 0;
	position: relative;

	@include bp-max($bp-tablet-sm) {
		margin: px-to-rem(30px) 0;
	}
}


.rte-header {
	font-family: "brandon-grotesque",sans-serif;
	font-weight: 500;
	text-transform: none;
    text-rendering: optimizeLegibility;
    letter-spacing: .05rem;
    -webkit-font-smoothing: antialiased;
    margin: 0;
	font-size: 1.5rem;
  	line-height: 1.1;
	color: #666;
}
.page-header-title {
	@include font-secondary;

	font-size: px-to-rem(70px);
	line-height: 1.1;
	color: $color-brand-white-abs;
	text-align: center;
	display: table;
	min-height: px-to-rem(300px);
	padding: px-to-rem(25px, $font-size-base-new) 0 0;
	width: 100%;
	text-transform: uppercase;

	@include bp-max($bp-tablet-sm) {
		min-height: px-to-rem(180px);
		font-size: px-to-rem(70px);
	}

	@include bp-max($bp-mobile) {
		font-size: px-to-rem(50px);
	}
}

.page-header-label {
	display: table-cell;
	vertical-align: middle;
}

.page-header-title--article {
	font-size: px-to-rem(70px);

	line-height: 1.3;
	font-weight: $font-weight-medium;
	min-height: px-to-rem(300px);

	@include bp-max($bp-tablet-sm) {
		font-size: px-to-rem(30px);
	}

	@include bp-max($bp-mobile) {
		font-size: px-to-rem(24px);
	}

	// &:before,
	// &:after {
	// 	@include horizontal-align;

	// 	position: absolute;
	// 	content: '';
	// 	display: block;
	// 	border-top: 2px solid $color-brand-white-abs;
	// 	top: 0;
	// 	width: 60%;
	// }

	// &:after {
	// 	bottom: 0;
	// 	top: auto;
	// }
}
