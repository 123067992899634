//==============================================================================
// Accessibility Utitlity Classes
//
// Use to hide / show HTML elements used for accessibility
// Example (HTML):
// The following link would be hidden on screen, but accessible to screen readers
// <a href="#start-of-content" tabindex="1" class="accessibility-aid js-skip-to-content">Skip to content</a>
// The link, when clicked, would jump to the location of the following HTML node
// <div id="start-of-content" class="accessibility-aid"></div>
// The skip-to element is simply a placeholder, and would never received focus,
// so would always remain hidden and occupy no space in the document.
//==============================================================================

// Add this CSS to your existing styles
//   TIP: Use this class for any elements you want to hide from screen,
//   but want to be accessible to screen readers
.accessibility-aid {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// Show the 'Skip to content' link when keyboard tabbing
// focuses the 'Skip to content' link
.accessibility-aid:focus {
  top: 0;
  z-index: 1;
  width: auto;
  height: auto;
  padding: 0 10px;
  clip: auto;
  font-weight: bold;
  line-height: 49px; /* you may want to change this value */
  color: #333; /* you may want to change this value */
  text-decoration: none;
  background: #f5f5f5; /* you may want to change this value */
}

.collapsible {

  [aria-hidden="true"] {
    display: none;
  }

  [aria-hidden="false"] {
    display: block;
  }

  [class^="trigger-"] {
    margin-left: 4px;
  }

  [aria-expanded="false"] {

    .trigger-collapsed {
      display: inline-block
    }

    .trigger-expanded {
      display: none;
    }
  }

  [aria-expanded="true"] {
    .trigger-collapsed {
      display: none;
    }

    trigger-expanded {
      display: inline-block;
    }
  }
}