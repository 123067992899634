.tabs-nav {
  $tabs-border: 2px;
  $nav-label-padding: px-to-rem(15px);

  position: relative;

  @include bp-max($bp-tablet-sm) {
    margin-bottom: $spacing-unit-block;
    margin-left: -$site-padding-tablet;
    margin-right: -$site-padding-tablet;
  }

  &__list {
    border-bottom: $tabs-border solid $color-border-alt;
    margin-bottom: $spacing-unit-block;
    padding-bottom: $spacing-unit/2;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include bp-max($bp-tablet-sm) {
      display: block;
      margin-bottom: 0;
      overflow: hidden;
      max-height: 0;
      transition: max-height $animation-locations-timing $animationEase,
                  visibility .3s linear $animation-locations-timing;
      visibility: hidden;

      position: absolute;
      top: 100%;
      margin-top: -4px;
      z-index: 10;

      background-color: $color-brand-white-abs;
      width: 100%;
      padding: 0 $site-padding-tablet 0;
      border: 0 none;

      .list__item {
        display: block;

        &:last-child{
          padding-bottom: px-to-rem(10px);
        }
      }
    }
  }

  &.is-expanded {
    .tabs-nav__list{

      @include bp-max($bp-tablet-sm) {
        max-height: px-to-rem(400px);
        visibility: visible;
        transition: max-height .5s $animationEase,
                    visibility .3s linear 0;
      }
    }
  }

  &__link {
    @include font-secondary;

    font-size: px-to-rem(20px);
    line-height: 1.1;
    text-transform: uppercase;
    color: $color-text-dark;

    @include bp-max($bp-tablet-sm) {
      color: $color-brand-blue;
      font-size: px-to-rem(18px);
      font-family: $font-family-accent;
      padding: $spacing-unit/2 0;
      display: block;

      &.is-active-tab{
        display: none;
      }
    }

    &.is-active-tab{
      color: $color-links;
    }
  }
  &__label {


    display: none;
    text-align: left;
    width: 100%;
    padding: $nav-label-padding ;
    padding-right: px-to-rem(40px);
    position: relative;
    background-color: rgba(255, 255, 255, .4);
    position: relative;
    z-index: 15;
    border: 0 none;

    &:hover {
      background-color: $color-brand-white-abs;
      color: $color-links;
    }


    @include bi-icon-after($bi-arrow-bottom) {
      position: absolute;
      font-size: px-to-rem(25px);
      right: $site-padding-tablet;
      top: 50%;
      transform: translateY(-50%);
      transition: transform .4s;
      transform-origin: 50% 30%;

    }

    @include bp-max($bp-tablet-sm) {
      display: block;


      padding-left: $site-padding-tablet;
      padding-right: $site-padding-tablet;

    }
  }

  &.is-expanded {
    .tabs-nav__label {
      border-bottom: 1px solid $color-brand-white-abs;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: $color-brand-white-abs;

      &:after {
        transform: rotateX(180deg);
      }
    }
  }

  &__design-line {
    position: absolute;
    left: 0;
    bottom: 0;
    height: $tabs-border;
    background-color: $color-links;
    transition: width .45s $animationEase,
                left .45s $animationEase;

    @include bp-max($bp-tablet-sm) {
      display: none;
    }
  }
}
