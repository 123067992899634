//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//	Font Size REMS
//	Base: 18px
//  Quattrocento:400,700
//  Oxygen:400,700
//  Brandon-grotesque: 300, 400, 500
//
///////////////////////////////////////////////////////////////////////////////

$font-size-base: 100%; //15 pixels
$font-size-base-px: 18px;
$font-size-base-new: 15px;

$font-family-primary: 'Quattrocento',
serif;
$font-family-accent: 'Oxygen',
sans-serif;
$font-family-titles: "brandon-grotesque",
sans-serif;
$font-family-secondary: "brandon-grotesque",
sans-serif;
$font-family-serif-new: 'Amiri',
serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;


// BASE LINE HEIGHT
$base-line-height: 1.4;


//Can't use shorthand font declaration since IE9 breaks once you declare font size in REM
html {
  font-size: 100%;
  font-size: $font-size-base;
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: $base-line-height;

  @include bp-max($bp-tablet-sm) {
    font-size: 87.5%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1-size,
.h2-size,
.h3-size,
.h4-size,
.h5-size,
.section-header {
  font-family: $font-family-titles;
  font-weight: $font-weight-medium;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.1;
}

h2,
.section-header {
  font-size: px-to-rem(32px);
  line-height: 1.1;
  margin-bottom: px-to-rem(8px);
  text-transform: uppercase;
  color: $color-text-dark;

  @include bp-max($bp-mobile) {
    font-size: px-to-rem(24px);
  }
}

.section-header {
  margin-bottom: $spacing-unit;
}

.header--emphasis {
  font-size: px-to-rem(44px);
  line-height: 1.1;
  font-family: $font-family-titles;
  font-weight: $font-weight-light;
  margin-bottom: $spacing-unit;
  text-transform: uppercase;
  color: $color-brand-green;

  letter-spacing: .05rem strong {
    font-weight: $font-weight-medium;
  }

  @include bp-max($bp-tablet-sm) {
    font-size: px-to-rem(40px);
  }
}

.article-title-size {
  font-size: 1.667rem; //30px;
  line-height: 1.3;
  font-weight: $font-weight-light;

  @include bp-max-height($bp-narrow) {
    font-size: 1.45rem;
    line-height: 1.2;
  }

  @include bp-max($bp-tablet-sm) {
    font-size: 1.45rem;
  }

  @include bp-max($bp-mobile) {
    font-size: 1.2rem;
  }
}

.callout-size {
  font-size: 2.2222rem; //40px;
  line-height: 1.15;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;

  @include bp-max($bp-tablet-sm) {
    font-size: 1.75rem;
  }

  @include bp-max($bp-mobile) {
    font-size: 1.2rem;
  }
}

%small-text {
  font-size: px-to-rem(14px);
  line-height: 1.1;
}

/*---------- Font Primary richtext copy  ----------*/

@mixin font-primary {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
}

@mixin font-primary-bold {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
}

/*---------- Font ACCENTS  ----------*/

@mixin font-accent {
  font-family: $font-family-accent;
  font-weight: $font-weight-regular;
}

@mixin font-accent-italic {
  font-family: $font-family-accent;
  font-weight: $font-weight-regular;
  font-style: italic;
}

@mixin font-accent-bold {
  font-family: $font-family-accent;
  font-weight: $font-weight-bold;
}

/*----------  Font Secondary  ----------*/
@mixin font-secondary {
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
}

@mixin font-secondary-light {
  font-family: $font-family-secondary;
  font-weight: $font-weight-light;
}

@mixin font-secondary-medium {
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
}

@mixin font-secondary-bold {
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
}

// Careers Landing

@font-face {
  font-family: 'Big-Caslon-Roman';
  src: url('/assets/site/Main/public/fonts/Big-Caslon-Roman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gloss_And_Bloom';
  src: url('/assets/site/Main/public/fonts/Gloss_And_Bloom.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$font-family-careers-serif: 'Big-Caslon-Roman',
serif;
$font-family-handwritten: 'Gloss_And_Bloom',
sans-serif;

@mixin careers-h1 {
  font-family: $font-family-titles;
  font-weight: $font-weight-bold;
  font-size: px-to-rem(78px);
  text-transform: uppercase;
}

@mixin careers-h2 {
  font-family: $font-family-careers-serif;
  font-weight: $font-weight-medium;
  font-size: px-to-rem(36px);
}

@mixin careers-h3 {
  font-family: $font-family-titles;
  font-weight: $font-weight-bold;
  font-size: px-to-rem(38px);
  text-transform: uppercase;
}

@mixin careers-subtitle {
  font-family: $font-family-titles;
  font-weight: $font-weight-regular;
  font-size: px-to-rem(24px);
}

@mixin careers-business-card {
  font-family: $font-family-titles;
  font-weight: $font-weight-bold;
  font-size: px-to-rem(20px);
  text-transform: uppercase;
}

@mixin careers-infostat {
  font-family: $font-family-handwritten;
  line-height: 1;
  font-size: px-to-rem(100px);
}