.content-card-wide {
    position: relative;
    margin-bottom: 32px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        transform: translate(6px, 6px);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        background-color: $color-content-royal-hl;
    }

    &::before {
        left: 0;
        height: 6px;
    }

    &::after {
        top: 0;
        width: 6px;
    }
    &--no-img {
        background-color: $color-content-royal;
    }

    &--no-img & {
        &__text-wrapper {
            border: none;
            color: $color-brand-white-abs;
            background-color: transparent;
        }

        &__eyebrow {
            margin-bottom: 0;
        }

        &__title,
        &__related-professional {
            color: $color-brand-white-abs;
        }
    }

    &__hidden-link {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        z-index: 2;
        display: block;
        border-bottom: 3px solid $color-content-royal;
    }

    &__image {
        width: 100%;
        padding-bottom: 226px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        position: relative;
    }

    &__text-wrapper {
        background-color: $color-brand-white-abs;
        border: 1px solid $color__gray--medium;
        border-width: 0 1px;
        padding: 32px;
        font-family: $font-family-secondary;
        color: $color__gray--dark;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__eyebrow {
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &__title {
        font-weight: $font-weight-medium;
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 12px;
        color: $color__gray--dark;
        display: inline-block;
    }

    &__abstract {
        font-weight: $font-weight-regular;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    &__related-professionals {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 14px;
        position: relative;
        z-index: 3;
    }

    &__related-professional {
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        font-weight: 500;
        color: $color__gray--dark;
        position: relative;
        pointer-events: all;

        &:not(:last-of-type)::after {
            content: ',';
            margin-right: 3px;
        }
    }

    &__date {
        font-weight: $font-weight-medium;
        font-size: 16px;
        line-height: 1;
    }

    &__meta {
        &:not(:last-of-type)::after {
            content: ' | ';
            display: inline-block;
            padding-left: 0.25em;
        }
    }

    @include bp-min($bp-tablet-sm) {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        min-height: px-to-rem(290px);

        &__image {
            width: 240px;
            padding: 0;
            flex-shrink: 0;
        }

        &__text-wrapper {
            flex-grow: 1;
            border-left: 0 none;
            border-top-width: 1px;
        }

        &:hover {
            &::before,
            &::after {
                opacity: 1;
            }
        }
    }
}