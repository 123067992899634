.people-infographic-container {
    border-bottom: 2px solid $color-border-alt;
    border-top: 2px solid $color-border-alt;
    display: flex;
    margin-top: 3.1rem;
    justify-content: center;
    margin-bottom: 5rem;
    padding-left: 0;
    align-items: center;
    @media (min-width: px-to-rem(768px)) {
        align-items: center;
        flex-direction: row;
        min-height: px-to-rem(333px);
        justify-content: center;
        max-width: 100%;
    }
}

.people-infographic-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    align-items: flex-end;
    @media (min-width: px-to-rem(768px)) {
        flex-direction: row;
        justify-content: space-between;        
    }

    @include bp-max($bp-mobile) {
        align-items: center;
    }

    li {
        align-items: center;
        display: flex;
        /*flex-basis: 9rem;*/
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        width: 8rem;
        img { 
            margin-bottom: px-to-rem(25px);
            max-height:px-to-rem(250px);
        }
        @media (min-width: px-to-rem(768px)) {
            flex-basis: px-to-rem(250px);
            font-size: 1rem;
        }
    }
}

.people-infographic-label {
    font-family: $font-family-primary;
    font-size: 0.8rem;
    max-width: 100% !important;
    @media(min-width: px-to-rem(768px)) {
        font-size: px-to-rem(24px);
    }
}

.people-infographic-count {
    color: $color-text-green;
    font-family: $font-family-secondary;
    font-size: 4.1rem;
    font-weight: $font-weight-light;
    @media(min-width: px-to-rem(768px)) {
        font-size: 6.1rem;
        font-size: px-to-rem(90px);
    }
}