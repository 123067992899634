.header-rich-text {
    margin-bottom: $spacing-unit;
}

.title-border {
    margin-top: $spacing-unit;
    padding-top: $spacing-unit;
    border-top: 1px solid $color-border;
}

.border-bottom {
  margin-bottom: $spacing-unit;
  border-bottom: 1px solid $color-border;
}

.rich-text {
    font-size: px-to-rem(24px);
    line-height: 1.5;

    ul {
       color: $color-text-bullets;
       padding-left: px-to-rem(40px);
       font-size: px-to-rem(22px);
    }

    li {
        $bullet-size: px-to-rem(8px);

        color: $color-text-gray-light;
        margin-bottom: $spacing-unit/4;


        &:before {
            @include size( $bullet-size );

            content: '';
            position: absolute;
            display: inline-block;
            background-color: $color-text-bullets;
            margin-left: -1rem;
            margin-top: .7rem;
        }
    }
}

.img-block,
figure {
    margin: $spacing-unit 0;
    padding: $spacing-unit 0;
    border: 2px solid $color-border;
    border-width: 2px 0;
    display: table;


    > img,
    figcaption,
    .img-block-caption {
        display: table-cell;
        vertical-align: bottom;

        @include bp-max($bp-mobile) {
            display: block;
            vertical-align: baseline;
        }
    }

     figcaption,
    .img-block-caption {
        font-size: px-to-rem(18px);
        line-height: 1.3;
        width: 30%;
        padding-left: 2rem;

        strong {
            @include font-secondary-bold;

            display: block;
            color: $color-text-dark;
            margin-bottom: px-to-rem(5px);
            text-transform: uppercase;

             @include bp-max($bp-mobile) {
                width: auto;
                padding-left: 0;
                margin-top: px-to-rem(15px);
            }
        }

        @include bp-max($bp-mobile) {
            width: auto;
            padding-left: 0;

        }
    }
}
