.applied-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include font-secondary-medium();
    padding: px-to-rem(24px) 0;

    &__pill {
        font-size: px-to-rem(16px);
        padding: px-to-rem(10px) px-to-rem(24px);
        border: 1px solid $color-brand-black;
        border-radius: 45px;
        color: $color-brand-black;
        margin-bottom: px-to-rem(20px);

        &:not(:last-child) {
            margin-right: px-to-rem(24px)
        }

        &--clear {
            border: 0 none;
            color: $color-brand-green;
            width: 100%;
            margin-top: px-to-rem(10px);
        }

        svg {
            vertical-align: middle;
            width: 16px;
            height: 17px;
        }
    }
    

    @include bp-min($bp-tablet) {
        padding-top: px-to-rem(32px);
        border-top: 1px solid $color__gray--medium;

        &__pill {
            font-size: 1rem;
            padding: px-to-rem(10px) px-to-rem(24px);

            &--clear {
                width: auto;
                margin-top: 0;
            }
        }
    }
}