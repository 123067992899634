﻿.scrollspy {
    position: relative;
}

.scrollspy-sections {
    margin-left: 100px;//fix later
}

.scrollspy-nav-offset {
    @include bp-min($bp-scrollspy) {
        margin-left: 100px; //fix later
    }
}

@include bp-max($bp-scrollspy) {

    .scrollspy {
        position: relative;
    }

    .scrollspy-sections {
        margin-left: 0;
    }

    .mobile-nav-offset {
        margin-top: 120px;
    }
}

@include bp-max($bp-scrollspy) {
    .scrollspy {
        padding-top: 10px; //fix later
        margin-bottom: 10px; //fix later
    }
}
