// Mixins
// --------------------------

@mixin bt-icon() {
  display: inline-block;
  font: normal normal normal #{$bt-font-size-base}/1 "Black Tie"; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  vertical-align: (-100%*2/14);
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}

@mixin bt-icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin bt-icon-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}

//applied for font brands only
@mixin bm-icon-before($icon-type) {

  &:before {
    content: $icon-type;
    display: inline-block;
    speak: none;
    font: normal normal normal #{$bt-font-size-base}/1 "Font Awesome Brands"; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    vertical-align: (-100%*2/14);
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox

    @content;
  }
}
//applied for font brands only
@mixin bm-icon-after($icon-type) {

  &:after {
    content: $icon-type;
    display: inline-block;
    speak: none;
    font: normal normal normal #{$bt-font-size-base}/1 "Font Awesome Brands"; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    vertical-align: (-100%*2/14);
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox


    @content;
  }
}


