.careers {
    $careers-section-spacing: px-to-rem(80px);
    $box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, .15);

    &__component {
        margin-bottom: $careers-section-spacing;

        label {
            display: block;
            font-weight: bold;
        }

        .accordion-list {
            max-width: 786px;
            margin: 0 auto;

            .question-text {
                font-family: $font-family-titles;
            }

            .question-answer ul li:before {
                background-color: $color__green;
            }
        }

        &--carousel {
            @include bp-max($bp-mobile-portrait) {
                .careers__carousel-pagination {
                    bottom: px-to-rem(40px) !important;
                }

                .careers__carousel-dot {
                    height: 12px;
                    width: 12px;
                }

                .careers__carousel-arrows {
                    top: 50%;
                    transform: translateY(-50%);

                    .careers__carousel-arrow {
                        padding: 0;

                        &::after {
                            font-size: px-to-rem(32px);
                        }
                    }
                }
            }
        }
    }

    &__h1 {
        @include careers-h1;
        color: $color__green;
        padding-top: px-to-rem(35px);

        @include bp-max($bp-mobile-portrait) {
            font-size: 3.5rem;
        }
    }

    &__h2 {
        @include careers-h2;
        color: $color__gray--dark;
    }

    &__h3 {
        @include careers-h3;
        color: $color__green;
    }

    &__center-title {
        text-align: center;
        margin: 0 auto 2rem;
    }

    &__subtitle {
        @include careers-subtitle;
        color: $color-brand-black;
        padding-bottom: px-to-rem(16px);

        strong {
            position: relative;
            font-weight: 400;
            background-image: url('/assets/site/Main/public/images/careers/circle.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 14px 0;
        }
    }

    &__cta {
        background-color: $color__green--light;
        padding: px-to-rem(10px) px-to-rem(16px);
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        color: $color-brand-black !important;
        text-transform: uppercase;
        display: inline-block;
        font-size: px-to-rem(16px);
        margin-top: px-to-rem(35px);
        transform: translate(0, 0);
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

        &:hover {
            color: $color-brand-black;
            transform: translate(2px, -2px);
            box-shadow: $box-shadow;
        }
    }

    &__full-wrapper {
        width: 100%;
        background-color: $color-brand-white-abs;
        z-index: 0;
    }

    &__inner-wrapper {
        justify-content: space-between;
        padding-top: 5rem;
        margin-top: px-to-rem(-125px);

        @include bp-max($bp-mobile-portrait) {
            padding-top: 5.5rem;
        }
    }

    // Hero Input



    &__card-input-outside.scroll-appear--visible {
        transition: transform 1s cubic-bezier(.645, .045, .355, 1), opacity 0.5s cubic-bezier(.645, .045, .355, 1) !important;
        transition-delay: 1s !important;
    }

    &__card-input-outer-wrapper {

        @include bp-max(769px) {
            padding: 0 5rem;
        }

        @include bp-max($bp-mobile-portrait) {
            padding: .75rem;
        }
    }

    &__card-input-wrapper {
        position: relative;

        span {
            position: absolute;
            width: 1px;
            height: 80%;
            background-color: $color__gray--dark;
            left: 250px;
            top: 10%;
            animation-name: blink;
            animation-duration: 800ms;
            animation-iteration-count: infinite;
            opacity: 1;
        }

        input:focus+span {
            display: none;
        }

        @keyframes blink {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    }

    // Override opinionated global input styles
    &__card-input {
        background-color: transparent !important;
        padding: 0 !important;
        border: none !important;
        font-family: 'Big-Caslon-Roman', serif !important;
        font-size: 36px !important;
        color: $color__gray--dark !important;
        line-height: 1 !important;
        caret-color: $color__gray--dark;
        margin-top: px-to-rem(4px);

        @include bp-max($bp-mobile-portrait) {
            margin-top: 1rem;
        }

        &::placeholder {
            color: $color__gray--dark !important;
            font-size: 36px !important;
            overflow: hidden;
            white-space: nowrap;
            animation: typing 3.5s steps(40, end);

            @keyframes typing {
                from {
                    width: 0
                }

                to {
                    width: 100%
                }
            }
        }
    }

    &__card-input-underline {
        background-image: url('/assets/site/Main/public/images/careers/underline.png');
        width: 230px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: px-to-rem(-25px);
        bottom: px-to-rem(-32px);
        z-index: -1;
    }

    // Scroll Hint

    &__scroll-hint-wrapper {
        margin-top: px-to-rem(30px);
        display: inline-block;

        &:hover {
            .careers__scroll-hint {
                color: $color__gray--dark;
            }

            .careers__scroll-hint-arrow {
                animation: hoverMe .3s infinite cubic-bezier(0.645, 0.045, 0.355, 1) alternate;

                @keyframes hoverMe {
                    100% {
                        transform: translate3d(0, 10px, 0);
                    }
                }
            }
        }
    }

    &__scroll-hint-wrapper.scroll-appear--visible {
        transition: transform 1s cubic-bezier(.645, .045, .355, 1), opacity 1s cubic-bezier(.645, .045, .355, 1) !important;
        transition-delay: 1.25s !important;
    }

    &__scroll-hint {
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        font-size: px-to-rem(16px);
        text-transform: uppercase;
        color: $color-brand-black;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &__scroll-hint-arrow {
        @include bi-icon-after($bi-arrow-bottom) {
            display: block;
            margin: 0 auto;
            color: $color__green--light;
            font-size: px-to-rem(30px);
        }
    }

    // Business Card
    &__business-card {
        @include bp-max($bp-mobile-portrait) {
            width: 100%;
            height: 18rem;
        }
    }

    &__business-card-lower-fields {
        display: flex;
        justify-content: flex-end;
    }

    &__business-card-website {
        text-align: right;
    }

    &__display-wrapper {
        margin-top: 0.5rem;

        div {
            text-align: center;
            margin: 0 auto;
        }
    }

    &__name-field {
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        font-size: px-to-rem(60px);
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        text-shadow: 1px 2px 3px #00bd58, 0 0 0 black, -1px 1px 0 #717070, 1px 1px 0 #4e4e4e;
        margin-top: px-to-rem(66px);
    }

    &__label {
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        margin-top: 1rem;
        text-shadow: 1px 2px 3px #00bd58, 0 0 0 black, -1px 1px 0 #717070, 1px 1px 0 #4e4e4e;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

        &--visible {
            height: auto;
            visibility: visible;
            opacity: 1;
        }

    }

    // Call to Action

    &__cta-column {
        @include bp-min($bp-tablet-sm) {
            &--left {
                padding-left: 0;
                padding-right: 32px;
            }

            &--right {
                padding-right: 0;
                padding-left: 32px;
            }
        }
    }

    &__cta-wrapper {
        align-items: center;
        z-index: 1;
        position: relative;

        @include bp-max($bp-mobile-portrait) {
            display: block;
        }

        &--dark-theme {
            background-color: $color__gray--dark;
            padding: px-to-rem(60px);
            box-shadow: $box-shadow;
            width: 100%;

            @include bp-max(769px) {
                margin-top: 3rem;
            }

            @include bp-max($bp-mobile-portrait) {
                margin-top: 0;
            }

            .careers__h3 {
                color: $color__green--light;
            }

            .rich-text {
                color: $color-brand-white-abs;
            }

            .careers__subtitle {
                color: $color-brand-white-abs;
            }
        }
    }

    &__cta-image-wrapper {
        position: relative;
        display: inline-block;
        line-height: 0;
    }

    &__cta-image {
        box-shadow: $box-shadow;
        padding: 0;

        @include bp-max(769px) {
            max-width: 300px;
        }

        @include bp-max($bp-mobile-portrait) {
            max-width: 100%;
        }
    }

    &__word-highlight {
        content: url('/assets/site/Main/public/images/careers/circle.png');
        position: absolute;
        top: 0;
        left: 0;
    }

    // Video Modal

    &__video-btn {
        padding: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 0;
        background-color: rgba(0, 0, 0, .5);
        transition: background-color .3s ease-in-out;

        &:hover {
            background-color: rgba(0, 0, 0, .7);
        }
    }

    &__video-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        // hahaha i hate this
        z-index: 501;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &--open {
            visibility: visible;
            opacity: 1;
        }
    }

    &__modal-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &__modal-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 3rem;
    }

    &__video-embed {
        display: block;
        margin: auto;
        text-align: center;
    }

    &__modal-close {
        color: $color-brand-white-abs;
        z-index: 12;
        font-size: 18px;
        margin-left: 13.5%;
        margin-bottom: 1rem;
    }

    &__responsive-frame {
        position: relative;
        padding-bottom: 40%;
        height: 0;

        iframe,
        object {
            position: absolute;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    &__freeze {
        overflow: hidden;
    }

    // Carousel
    &__carousel {
        z-index: 11;

        &-arrows {
            z-index: 11;
        }

        &--swiper-container {

            @include bp-max($bp-mobile-portrait) {
                overflow: visible !important;
                margin-bottom: px-to-rem(700px) !important;
            }
        }

        &-quote {
            @include bp-max($bp-mobile-portrait) {
                position: relative;
                margin-top: $spacing-unit-block !important;
                width: 90%;
            }
        }
    }

    &__carousel-slide {
        width: 100%;
        height: 90vh !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding-top: px-to-rem(125px);

        @include bp-max(769px) {
            height: 70vh !important;
        }

        @include bp-max($bp-mobile-portrait) {
            height: 90vh !important;
        }

        @include bp-max-height(650px) {
            height: 110vh !important;
        }
    }

    &__carousel-light-wrapper {
        background-color: $color__gray--light;
        width: 40%;
        padding: 3.45rem;
        float: right;

        @include bp-max(769px) {
            width: 60%;
        }

        @include bp-max($bp-mobile-portrait) {
            padding: 2.25rem;
            width: 100%;
        }
    }

    &__carousel-arrows {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include bp-max($bp-mobile-portrait) {
            bottom: 0;
            top: unset;
        }
    }

    &__carousel-arrow {

        &--prev {
            @include bi-icon-after($bi-arrow-right) {
                display: block;
                margin: 0 auto;
                color: $color__green--light;
                font-size: px-to-rem(60px);
            }

            transform: rotate(180deg);
        }

        &--next {
            @include bi-icon-after($bi-arrow-right) {
                display: block;
                margin: 0 auto;
                color: $color__green--light;
                font-size: px-to-rem(60px);
            }
        }
    }

    &__carousel-slide--background-image {

        @include bp-max($bp-mobile-portrait) {
            height: px-to-rem(220px) !important;
        }
    }

    &__carousel-dot {
        border: 2px solid $color__green--light;
        display: inline-block;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 0;
        margin-right: 1rem;
        background-color: transparent;

        &:last-of-type {
            margin-right: 0;
        }

        &--active {
            background-color: $color__green--light;
        }
    }

    // Quote

    &__quotes-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        &.more-than-four {
            justify-content: space-around;

            .careers__quote-wrapper--display {
                width: 34%;
            }
        }
    }

    &__quote-wrapper {

        &--display {
            margin-top: 2rem;
            padding-right: 3rem;
            padding-bottom: 0;
            width: 33%;
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;

            &:nth-child(-n+3) {
                margin-top: 0;
            }

            &:nth-child(3n) {
                padding-right: 0.75rem;
            }
        }

        @include bp-max(769px) {
            width: 100%;
        }
    }

    &__quote-headline {
        color: $color-brand-black;
        margin-bottom: 1rem;
    }

    &__quote {
        position: relative;
        z-index: 0;

        &::before {
            content: url('/assets/site/Main/public/images/careers/quote-mark.svg');
            position: absolute;
            top: px-to-rem(-20px);
            left: px-to-rem(-20px);
            z-index: -1;
        }

        &::after {
            content: url('/assets/site/Main/public/images/careers/quote-mark.svg');
            transform: rotate(180deg);
            position: absolute;
            bottom: px-to-rem(-20px);
            right: px-to-rem(-8px);
            z-index: -1;
        }

        &--display {
            font-family: $font-family-careers-serif;
            font-size: px-to-rem(26px);
            color: $color-brand-black;
        }
    }

    &__quote-attribution {
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        color: $color-brand-black;
        font-size: 1rem;
        line-height: 1.4;
        text-transform: uppercase;

        &--line1 {
            color: $color-brand-black;
        }

        &--line2 {
            color: $color__gray--dark;
        }
    }

    &__quote-supporting-text {
        font-family: $font-family-careers-serif;
        font-weight: $font-weight-medium;
        font-size: px-to-rem(24px);
        line-height: 1.3;
        margin-top: 1rem;
    }

    // Highlight Card
    &__highlight-image {
        width: 100%;
        height: px-to-rem(334px);
        background-size: cover;
        background-repeat: no-repeat;

        @include bp-max(769px) {
            margin-bottom: 4rem;
        }

        @include bp-max($bp-mobile-portrait) {
            margin-bottom: 3rem;
        }
    }

    &__highlight-wrapper {
        margin-right: px-to-rem(25px);
        box-shadow: $box-shadow;

        @include bp-max(769px) {
            width: 65%;
            margin: 0 auto;
        }

        @include bp-max($bp-mobile-portrait) {
            width: 100%;
        }
    }

    &__highlight-text-wrapper {
        background-color: $color__gray--dark;
        padding: px-to-rem(35px);
        margin-top: -3rem;
        margin-right: px-to-rem(-20px);
        float: right;
        width: 80%;
        box-shadow: $box-shadow;

        @include bp-max(769px) {
            margin-top: -9rem;
        }

        @include bp-max($bp-mobile-portrait) {
            margin-top: -8rem;
            margin-right: 0;
        }
    }

    &__highlight-title {
        color: $color__green--light;
        text-align: right;
        font-size: 2rem;
    }

    &__highlight-link {
        color: $color-brand-white-abs !important;
        display: block;
        text-align: right;
        font-family: $font-family-titles;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

        &:hover {
            color: $color__green--light;
        }
    }

    // Infostats

    &__infostat-wrapper {
        align-items: center;
    }

    &__infostat-columns-wrapper {
        @include bp-max(769px) {
            display: flex;
            margin-top: 2rem;
        }

        @include bp-max($bp-mobile-portrait) {
            display: block;
            margin-top: 0;
        }
    }

    &__infostat-inner-wrapper {
        margin-left: 12%;
    }

    &__infostat {
        text-align: center;

        @include bp-max($bp-mobile-portrait) {
            margin-bottom: 3rem;
        }
    }

    &__infostat-number {
        @include careers-infostat;
        color: $color__green;
    }

    &__infostat-label {
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        font-size: px-to-rem(22px);
        color: $color-brand-black;
    }

    //Tabs

    &__tabs-nav {
        margin-top: px-to-rem(45px);

        @include bp-max(769px) {
            display: flex;
            overflow-x: scroll;
            flex-wrap: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }
        }
    }

    &__tabs-nav-item {
        display: block;
        margin-left: 0;
        font-size: px-to-rem(20px);
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $color__gray--dark;
        text-align: left;
        position: relative;

        @include bp-max(769px) {
            font-size: px-to-rem(20px);
            white-space: nowrap;
            display: none;
        }

        &--active {
            color: $color-brand-black;

            &::before {
                content: '';
                background-color: $color__green--light;
                height: px-to-rem(26px);
                padding: 2px;
                position: absolute;
                left: 3px;
                top: 6px;

                @include bp-max(769px) {
                    top: 8px;
                }
            }
        }
    }

    &__tabs {
        padding: 0;
    }

    &__tab {
        position: relative;

        @include bp-max($bp-mobile-portrait) {
            height: 100%;
            padding: 1rem;
        }

        @include bp-max($bp-tablet-sm) {
            display: block !important;
        }
    }

    &__tab-background {
        background-size: cover;
        background-position: 100% 50%;
        box-shadow: $box-shadow;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: px-to-rem(560px);

        @include bp-max($bp-tablet-sm) {
            height: 100%;
        }
    }

    &__tabs-wrapper {
        position: relative;
        margin-bottom: 10rem !important;

        @include bp-max($bp-mobile-portrait) {
            margin-bottom: 0 !important;
        }
    }

    &__tab-text-wrapper {
        width: 50%;
        background-color: $color-brand-white-abs;
        padding: px-to-rem(50px);
        margin-left: px-to-rem(-25px);
        box-shadow: $box-shadow;
        position: relative;

        @include bp-max($bp-desktop) {
            width: 65%;
        }

        @include bp-max($bp-tablet-sm) {
            width: 100%;
            padding: 2rem;
            margin-left: 0;
        }

        .rich-text {
            font-size: px-to-rem(22px);
            padding-bottom: 1rem;

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    // Instagram Feed

    &__instagram-feed {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__instagram-tile {
        width: 25%;
        height: 350px;

        @include bp-max($bp-tablet) {
            width: 50%;
        }

        @include bp-max($bp-mobile-portrait) {
            width: 100%;
        }
    }

    &__instagram-photo {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }

    // Photo Slider Carousel
    &__photo-slider {
        .swiper-slide {
            background-size: cover;
            background-position: center;
            min-height: 400px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        &.swiper-container {
            position: static !important;
        }

        $arrow-diameter: 55px;

        .careers__carousel-arrows {
            transform: translateY(-50%);
            width: calc(100% + 40px);
            left: -22px;
            top: 50%;

            &--slider {
                width: calc(100% - 4px);
                left: 6px;

                @include bp-max(420px) {
                    width: calc(100% - 15px);
                    left: 8px;
                }
            }
        }

        p {
            text-align: center;
            color: white;
            font-family: "brandon-grotesque", sans-serif;
            font-weight: 400;
            font-size: 18px;
            padding: 10px 0;
            background-color: rgba(0, 0, 0, 0.6);
            width: 100%;
        }

        .careers__carousel-arrow--prev,
        .careers__carousel-arrow--next {
            background-color: $color__gray--dark;
            border-radius: 50%;
            height: $arrow-diameter;
            width: $arrow-diameter;

            &:after {
                top: 2px;
                position: relative;
                font-size: 2.2rem;
            }
        }
    }
}

.careers__photo-slider {
    .swiper-pagination {
        bottom: -50px !important;
    }
}

#app {
    background-image: url('/assets/site/Main/public/images/careers/background-texture.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
    overflow-y: hidden;

    @include bp-max(769px) {
        background-size: contain;
        background-repeat: repeat-y;
    }
}

.career__text.rich-text {
    max-width: 786px;
    margin: 0 auto;
}

.swiper-wrapper {
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}