.searchbox {
	position: relative;
}

.searchbox__input {
	width: 100%;
	padding: .5rem 1rem;

    .generic & {
        background-color: #FFF;
        padding: .5rem 3.5rem;
    }
}

.btn-search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    margin-left: .8rem;

    i.bi {
        font-size: 125%;
        color: $color-blue-waikawa-gray;
    }
}

.searchbox__button,
.searchbox__btn {
	position: absolute;
	right: 0;
	padding: 0 1.5rem;
	top: 0;
	height: 100%;
	font-size: 1.2rem;
}

.searchbox__input--nonmobile {
    display: none;
}

.searchbox--landing, .searchbox--people, .searchbox--subscribe, .searchbox--probono {
    margin-bottom: $spacing-unit;

    .searchbox__input {
        appearance: none;
        background-color: $color-brand-white-abs;
        padding: 1rem 1rem 1rem 4rem;
        border-width: 2px;
        line-height: inherit;
    }

    .searchbox__button {
        left: 1.25rem;
        right: auto;
        color: $color-links;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            color: $color-text-base;
        }
    }
}

.searchbox--people {
    margin-bottom: 1.75rem;

    .searchbox__input {
        font-size: 1rem;
    }

    @media (min-width: px-to-rem(600px)) {
        margin-bottom: 2.75rem;

        .searchbox__input {
            font-size: 1rem;
        }

        .searchbox__input--nonmobile {
            display: block;
        }

        .searchbox__input--mobile {
            display: none;
        }
    }

		@media (max-width: px-to-rem(500px)){
			margin-bottom: 2.75rem;
			
			.searchbox__input--nonmobile {
					display: none;
			}

			.searchbox__input--mobile {
					display: block;
			}
		}
}
