
/// searchbox slide
.cube__searchbox {
	@include vertical-align;

	width: 60%;
	margin: 0 auto;

	@include bp-max($bp-mobile) {
    	width: 95%;
  	}

	.searchbox__input {
		background-color: $color-opaque-white;
		padding: .9rem 1.5rem .9rem 5rem;
		text-align: center;
		box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.4);

		@include placeholder() {
			color: $color-text-input;
		}

		@include bp-max($bp-mobile) {
			padding-left: 3rem;
			font-size: 1rem;
		}
	}

	.searchbox__btn {
		color: $color-brand-green;
		right: auto;
		left: 0;
		font-size: 1.3rem;

		@include bp-max($bp-mobile) {
			font-size: 1.1rem;
			padding: 0 1.2rem;
		}
	}
}

.cube__slide-footer {
	@include horizontal-align;

	position: absolute;
	bottom: 0;
	right: $site-padding;
	font-family: $font-family-accent;
	font-size: .9rem;
	line-height: 1.1;
	width: 100%;
	height: 10%;

	@include bp-max($bp-tablet) {
		right: $site-padding-tablet;
	}

	@include bp-max($bp-mobile) {
		@include horizontal-align;

		position: absolute;
		right: auto;
		width: 80%;
		padding: 0;
		text-align: center;
		bottom: 2%;
		height: auto;
		line-height: 1.2;

	}

	.cube__slide-footer-wrap {
		@include vertical-align;

		margin-left: 15%;
		text-align: right;
		color: $color-brand-white;

		@include bp-min($bp-desktop-large) {
			margin-left: 5%;
		}

		@include bp-max($bp-mobile) {
			margin-left: 0;
			text-align: center;
			height: auto;
			position: static;
			top: auto;
			transform: translateY(0);
		}
	}

	.copyright {
		float: left;
		width: 30%;
		text-align: left;
		margin-bottom: 0;
		text-transform: uppercase;
	}

	@include bp-max($bp-desktop) {

		.copyright,
		.footer-nav {
			font-size: px-to-rem(14px);
		}

	}

	@include bp-max($bp-hamburger) {
		.cube__slide-footer-wrap {
			margin-left: 0;
		}

		.copyright,
		.footer-nav {
			font-size: px-to-rem(15px);
		}

	}

	@include bp-max($bp-mobile) {


		.cube__slide-footer-wrap {
			text-align: center;
		}

		.copyright {
			float: none;
			text-align: center;
			width: 100%;
		}

		.footer-options {
			margin-left: 0;

			.list-horizontal {
				display: block;
				margin-top: $spacing-unit/2;
			}
		}


	}
}
