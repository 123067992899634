//$bp-desktop - 2 columns
//$bp-tablet-sm 1 column

.faq {
    $faq-content-padding: 4vw;
    $total-faq-content-padding: $faq-content-padding*2;

    .faq__header {
        font-weight: 500;
        color: $color-brand-green;
    }

    .faq-question-set {
        
        display: inline-block;
        margin-bottom: 2%;
        vertical-align: top;
        width: 46%;
        margin-right: 2%;
        font-size: 1rem;
        line-height: $base-line-height;

        @include bp-max($bp-tablet-sm) {
            display: block;
            width: 100%;
            max-width: px-to-rem(600px);
            margin: 0 auto 2%;
            vertical-align: top;
        }

        @include bp-max($bp-mobile) {
            max-width: px-to-rem(370px);
        }

        &:nth-of-type(2n) {

            .faq-answer {
                margin-left: -104%;
            }

            .pointer {
                right: 25%;
            }

            @include bp-max($bp-tablet-sm) {
                .faq-answer {
                    margin-left: 0;
                }

                .pointer {
                     left: 50%;
                     transform: translateX(-50%);
                }
            }
        }
    }

    .faq__content {
        position: relative;
        margin-right: -2%;
        font-size: 0;
        line-height: 0;
    }

    .faq-question-wrapper {
        background-color: #FFF;
        cursor: pointer;
        position:relative;
        
        width: 100%;
        padding-bottom: 100%;


        &:hover {
            

            .faq-question {
                color: $color-brand-darkblue;

                &:before,
                &:after {
                    border-color: $color-brand-darkblue;
                }
            }
        }
        
    }

    .faq-question {
        @include transition(color);
        
        position: absolute;
        text-align: center;
        font-family: $font-family-accent;
        font-size: px-to-rem(24px);
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color-blue-waikawa-gray;


        //lines
        &:before,
        &:after {
            content: '';
            margin: auto;
            display: block;
            width: 40%;
            border: 1px solid #b5b5b5;

            @include transition(border-color);
        }

        .question {
           
            padding: 1.5rem 1rem;
        }
    }

    .faq-answer {
        display: none;
        position: relative;
        float: left;
        width:204%;
        background-color: #506e85;
        color: #FFF;
        padding: 3rem 8rem 1rem 8rem;
        margin: 4% 0;
        line-height: $base-line-height;
        text-align: left;

        

        .pointer {
            width: 0;
            display: inline-block;
            position: absolute;
            top: -14px;
            border-bottom: solid 15px #506e85;
            border-right: solid 15px transparent;
            border-left: solid 15px transparent;

            @include bp-max($bp-tablet-sm) {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @include bp-max($bp-tablet-sm) {
            float: none;
            width: 100%;
            padding: 3rem 2rem 1rem 2rem;
        }
    }

    .faq-answer-question {
        font-family: $font-family-accent;
        font-weight: $font-weight-bold;
        font-size: px-to-rem(26px);
        margin-bottom: 1rem;
    }

    .faq-answer-answer {
        font-family: $font-family-primary;
        font-size: px-to-rem(24px);
    }

    .close-btn-section {
        text-align: center;
        cursor: pointer;
        margin-top: 3rem;

        .close-label {
            font-family: $font-family-titles;
            text-transform: uppercase;
            margin-left: .5rem;
        }
    }
}