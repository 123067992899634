// Callout slide
.cube__callout {
	@include vertical-align;

	margin: 0 auto;
	text-align: center;

    .haslink{
        background: $color-opaque-teal;
     span{
        color:white;
            }
      em{
        color:white;
            }

      &:hover {
          background:none;
		}
      &:hover span{
          color: $color-brand-white;
            }
      &:hover em{
                color: $color-brand-lightgreen;
	            font-style: normal;
	            display: block;
            }
    }
   
}

.cube__callout-headline {
	min-width: 60%;
	display: inline-block;
	border: 2px solid $color-brand-white;
	padding:  2rem;
	text-align: center;
	color: $color-brand-white;
	white-space: nowrap;

	@include font-secondary-medium;

	@include bp-max($bp-mobile) {
		padding: 1.2rem;
	}

	@include bp-max($bp-mobile-portrait) {
		padding: 5%;
		white-space: normal;
	}

}

.cube__callout-headline--emphasis {
	color: $color-brand-lightgreen;
	font-style: normal;
	display: block;

}


.cube__service {
	@extend %bg-cover;

	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 50;
}

.js-cube-service-clone {
	z-index: 40;
	opacity: 1;
}

.fadeout-service {

	.cube__service {
		opacity: 0;
		transition: opacity .7s ease-out;
	}

	.js-cube-service-clone {
		opacity: 1;
	}
}

.cube__slide-service-footer {
	@include horizontal-align;
	position: absolute;
	bottom: 0;
	height: 10%;
	padding-top: 1.5%;
	z-index: $zindex-cube-arrows;
	color: $color-brand-white;

	text-align: center;

	@include bp-max-height($bp-narrow) {
		padding-top: 0;
	}

	@include bp-max($bp-mobile) {
		height: 8%;
	}

	@include bp-max($bp-mobile-portrait) {
		height: 10%;
		width: 60vw;
	}
	
	.cube__slide-refresh {
		position: static;
		transform: translate(0,0);
		left: auto;
		display: block;

		margin:0 auto px-to-rem(40px);

		@include bp-max($bp-mobile) {
			margin-bottom: px-to-rem(20px);
		}
	}

	.client-login,
	.social-icon__link {
		color: $color-brand-white-abs;
	}

	.client-login {
		font-family: $font-family-accent;
		font-size: .9rem;
		line-height: 1.1;
		text-transform: uppercase;
	}

	.social-icons {
		display: inline-block;
	}

	.social-icon__link {
		font-size: px-to-rem(14px);
		margin-left: 1rem;
	}
}