/* ====== MEDIA OBJECT ====== */
/* URL: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/

*/
.media {
  @extend %clearfix;
}

.media__img {
  float: left;
  margin-right: $spacing-unit;
  display: block;

  > img {
    display: block;
  }
}

.media__body {
  overflow: hidden;
}

/* ====== FLAG OBJECT ====== */
/* URL: http://csswizardry.com/2013/05/the-flag-object/ */

.flag{
  display:table;
  width:auto;
}

.flag__image, .flag__body{
  display:table-cell;
  vertical-align:middle;
  
  .flag--top &{
    vertical-align:top;
  }
  
  .flag--bottom &{
    vertical-align:bottom;
  }
}

.flag__image{
  padding-right: $spacing-unit;;
  
  > img{
    display:block;
  }
  
  .flag--rev &{
    padding-right:0;
    padding-left:10px;
  }
}

.flag__body{
  width:100%;
}