//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//  You should use the Breakpoint Aliases (below) when referencing media query
//  breakpoints with your custom CSS
//
//	Breakpoint Values
//	http://www.paintcodeapp.com/news/iphone-6-screens-demystified
///////////////////////////////////////////////////////////////////////////////



$bp-desktop-large:				1250px;
$bp-desktop:				    1080px;

$bp-tablet:						900px; //
$bp-tablet-sm: 					768px;
$bp-mobile: 					668px;
$bp-mobile-portrait: 			420px;
$bp-mobile-portrait-sm:		    375px;


//element specific breakpoints
$bp-hamburger:                  980px;
$bp-scrollspy:                  1025px; 

@mixin bp-min($device-width) {
  @media screen and (min-width: $device-width) {
    @content
  }
}

@mixin bp-max($device-width) {
  @media screen and (max-width: $device-width - 1) {
    @content
  }
}

$bp-narrow:               820px;
$bp-landscape: 					  745px;

@mixin bp-max-height($device-height) {
  @media screen and (max-height: $device-height) {
    @content
  }
}


@mixin bp-max-orientation() {
  @media screen and (max-width: $bp-mobile) and (max-height: $bp-mobile-portrait) {
    @content
  }
}