.people-search-result {
    border-top: 2px solid $color-border-alt;
    color: #000;
    display: flex;
    flex-direction: column;
    font-family: $font-family-accent;
    letter-spacing: 0.05rem;
    margin: auto;
    margin-top: 0;
    padding-bottom: 2.8rem;
    padding-left: 1rem;
    padding-top: 2.8rem;
    @media (min-width: px-to-rem(600px)) {
        padding-left: 6rem;
    }
    @media (min-width: px-to-rem(850px)) {
        flex-direction: row;
        padding-left: 0.75rem;
    }
    &:last-of-type {
        border-bottom: 2px solid $color-border-alt;
    }
    ul {
        padding-left: 0;
    }
}

.people-search-result-photo {
    flex-shrink: 0;
    margin-bottom: 1.7rem;
    @media (min-width: px-to-rem(850px)) {
        margin-right: 3.8rem;
        margin-bottom: 0;
    }
    img {
        height: 8rem;
        width: 14rem;
        @media (min-width: px-to-rem(850px)) {
            height: 12.458rem;
            width: 23.0265rem;
        }
    }
}

.people-search-result-name {
    font-size: 1.3rem;
    line-height: 1;
    margin-bottom: 0.75rem;
    @media (min-width: px-to-rem(850px)) {
        font-size: px-to-rem(30px);
        margin-bottom: 1.3rem;
    }
}

.people-search-result-tel {
    &::before {
        content: "\f095";
    }
    a {
        color: #000;
    }
}

.people-search-result-location {
    &::before {
        content: "\f124";
    }
}

.people-search-result-email,
.people-search-result-tel,
.people-search-result-location {
    margin-bottom: 0.6rem;
}

.people-search-result-email::before,
.people-search-result-tel::before,
.people-search-result-location::before {
    color: #555;
    display: inline-block;
    font-family: "Font Awesome";
    margin-right: 2rem;
    @media (min-width: px-to-rem(450px)) {
        margin-right: 1.3rem;
    }
}

.people-search-result-email {
    margin-bottom: 0;
    &::before {
        content: "\f003";
    }
}

.people-result-details {
    font-size: 1rem;
    @media (min-width: px-to-rem(450px)) {
        font-size: px-to-rem(22px);
    }
}

.people-search-result-title {
    font-size: 1.1rem;
    margin-bottom: 1.75rem;
    @media (min-width: px-to-rem(850px)) {
        font-size: px-to-rem(26px);
        margin-bottom: 1.25rem;
    }
}
