.careers-intro {
    position: relative;

    .page-header-title {
        padding-top: 6.5rem;
        padding-bottom: 1rem;
        margin: 0;
        min-height: inherit;

        @include bp-max($bp-tablet-sm) {
            padding-top: 1rem;
        }

        .page-header-label {
            display: block;
        }
    }

    .extras {
        margin: auto;
        margin-bottom: 1rem;

        @include bp-min($bp-tablet-sm+1) {
            bottom: 15%;
        }

        .apply-btn {
            width: 13rem;
            display: block;
            margin: auto;
        }

        .divider {
            border: 0;
            background-color: #8a969b;
            width: 5rem;
            height: 2px;
            margin-top: 3rem;
            margin-bottom: 2rem;

            @include bp-max($bp-tablet-sm) {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
        }

        .social-icons {
            text-align:center;

            .list__item {
                display:inline-block;
                position: relative;
                width: 2.4rem;
                height: 2.4rem;
                margin: 0 .5rem;
                border-radius: 50%;
                background-color: #788189;

                i {
                    color: white;
                    height: 1rem;
                    width: 1rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}