@mixin clearfix {

    &:after {
        content: ""; // 1
        display: table; // 2
        width: 100%; // 3
        clear: both;
    }
}

@mixin borderBox {
    box-sizing: border-box;
}

@mixin display($display: block) {
    display: $display;

    @if ($display == inline-block and $legacy_support_for_ie) {
        *display: inline;
        zoom: 1;
    }
}

/// Helper mixin to size elements
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin transition($property: all, $timing: $base-animation-timing) {
    transition: $property $timing $animationEase;
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin reset-vertical-align {
    position: static;
    top: auto;
    transform: translateY(0);
}

@mixin horizontal-align {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@mixin absolute-center-align {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

//Mixin for placeholder text
@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}


@mixin gradient($start-color: #555, $end-color: #333, $deg: 45deg, $spread: 100%) {
  background-repeat: repeat-x;
  background-image: linear-gradient($deg, $start-color, $end-color $spread); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin vertical-rule-after($color: #eee, $margin-right: px-to-rem(8px), $margin-left: px-to-rem(8px) ) {
    &:after {
        content: '|';
        color: $color;
        display: inline-block;
        margin-right: $margin-right;
        margin-left: $margin-left;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
}