.contacts {
	&__header {
		margin-bottom: $spacing-unit;
	}

    .intro-description {
        padding-bottom: $spacing-unit-block;
        margin-bottom: $spacing-unit-block;
        border-bottom: 1px solid #d7d7d7;
    }

	&__list {
		@include container;
		
	}

	&__list-item {
		@include gallery(2 of 6);
  		margin-bottom: gutter(6);
		//margin-bottom: $spacing-unit-block;

		// &:nth-child(3n) {
		// 	@include last;
		// }

		@include bp-max($bp-tablet) {

			@include gallery(3 of 6);

		}


		@include bp-max($bp-mobile-portrait) {
			@include gallery(6 of 6);

			float: none;
		}
	}

	&__view-more {
		margin: 0 auto;
		display: block;
	}
	.contacts__view-more {
        max-width: 133px;
	}

	@include bp-max(769px) {
    	.contacts__list{
			.contacts__list-item{
				.contact-card__image{
					max-width: 100%;
					margin-right: 0;
					img{
						height: 100%;
						width: 100%;
					}
				}
			}
		}
  }  
}

