.casestudy-card {
    position: relative;
    padding-left: 120px;
    padding-top: 50px;
    display: block;
    color: $color-brand-white-abs;
    @include font-secondary;
    max-width: 390px;
    min-height: 160px;

    &__image {
        width: 105px;
        height: 160px;
        background: no-repeat top left;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
    }

    &__meta {
        font-size: px-to-rem(18px);
        margin-bottom: 0.5em;
        letter-spacing: 0.2em;
    }

    &__headline {
        @include font-secondary-medium;

        color: $color-content-citrus;
        font-size: 24px;
        line-height: 1.1;
        font-weight: 500;
    }

    &__abstract {
        font-size: px-to-rem(22px);
        margin-top: 0.75em;
    }

    @include bp-min($bp-tablet-sm) {
        width: 455px;
        height: 660px;
        padding: px-to-rem(60px) px-to-rem(50px);
        max-width: none;
        z-index: 1;
        transition: color 0.25s ease-out,
            opacity 0.2s ease-out;

        & .swiper-container {
            overflow: visible;
        }

        &:hover {
            color: $color-brand-white-abs;
        }

        .casestudy-module__cards--hovered &{
            color: rgba(255,255,255,0);
            opacity: 0.5;
        }

        .casestudy-module__cards &{
            &:hover {
                color: rgba(255, 255, 255, 1);
                opacity: 1;
            
                &__image {
                    &:after {
                        background-color: rgba(0, 0, 0, 0);
                    }
                }
            }
        }

        &__image {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: auto;
            height: auto;
            z-index: -1;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                @include transition(background-color);
            }
        }

        &__headline {
            font-size: px-to-rem(36px);
            color: inherit
        }
    }
    @include bp-min($bp-desktop) {
        &__headline {
            font-size: px-to-rem(44px);
        }
        &__abstract {
            font-size: px-to-rem(28px);
        }

    }
}