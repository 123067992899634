.latest-insights {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;

    & &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /*min-height: 334px;*/
        margin-right: 16px;
        margin-bottom: 16px;
        height: auto;

        &:hover {

            &::before,
            &::after {
                opacity: 1;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
            transform: translate(6px, 6px);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            // background-color: $color-content-blue;
        }

        &::before {
            left: 0;
            height: 6px;
        }

        &::after {
            top: 0;
            width: 6px;
        }

        &--one-third {
            width: calc(33.33% - 16px);
        }

        &--two-third {
            width: calc(66.66% - 16px);
        }
        &--news {
            .latest-insights__text-wrapper {
                border-bottom: 3px solid $color-content-green;
            }

            &::before,
            &::after {
                background-color: $color-content-green-hl;
            }
        }
    
        &--event {
            .latest-insights__text-wrapper {
                border-bottom: 3px solid $color-content-royal;
            }
            &::before,
            &::after {
                background-color: $color-content-royal-hl;
            }
        }
    
        &--case-study {
            .latest-insights__text-wrapper {
                border-bottom: 3px solid $color-content-citrus;
            }

            &::before,
            &::after {
                background-color: $color-content-citrus-hl;
            }
        }
    
        &--insight {
            .latest-insights__text-wrapper {
                border-bottom: 3px solid $color-content-blue;
            }
            &::before,
            &::after {
                background-color: $color-content-blue-hl;
            }
        }
    }

    &__hidden-link {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &__text-wrapper {
        background-color: $color-brand-white-abs;
        border: 1px solid $color__gray--medium;
        border-bottom: 3px solid $color-content-blue;
        padding: 32px;
        font-family: $font-family-secondary;
        color: $color__gray--dark;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__eyebrow {
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &__title {
        font-weight: $font-weight-medium;
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 12px;
        color: $color__gray--dark;
        display: inline-block;
    }

    &__abstract {
        font-weight: $font-weight-regular;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    &__date {
        font-weight: $font-weight-medium;
        font-size: 16px;
        line-height: 1;
    }

    @include bp-max($bp-tablet-sm) {
        flex-wrap: nowrap;
        & &__card {
            width: 100%;
            margin-right: 0;
        }
    }
}