.section-wrapper {
    padding: 60px 0;

    &__container {
        padding-left: $site-padding-tablet;
        padding-right: $site-padding-tablet;
    }

    &__page-main {
        padding-top: $desktop-header;
    }

    &--white {
        background-color: $color-brand-white-abs;
    }

    &--off-white {
        background-color: #FAFAFA;
    }

    &--gray-light {
        background-color: $color__gray--light;
    }

    &--black {
        background-color: $color-brand-black;

        .section-wrapper__title,
        .section-wrapper__text {
            color: $color-brand-white-abs;
        }
    }

    &--green {
        background-color: $color__green;

        .section-wrapper__title,
        .section-wrapper__text {
            color: $color-brand-white-abs;
        }
    }
    &--pattern {
        background-image: url('/assets/site/Main/public/images/careers/background-texture.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__container {
        max-width: calc(1160px + #{($site-padding-tablet * 2)});
        margin: 0 auto;
    }

    &__title {
        font-family: $font-family-serif-new;
        font-size: 64px;
        line-height: 1;
        color: $color__gray--dark;
        text-transform: unset;
        letter-spacing: 0;

        &--h2 {
            font-size: 38px;
            color: $color__gray--dark;
        }

        &--dark {
            color: $color-brand-black;
        }
    }
    &__text {
        font-family: $font-family-secondary;
        color: $color__gray--dark;
        font-weight: $font-weight-regular;
        font-size: 20px;
        line-height: 24px;
        max-width: 805px;
        margin-top: 24px;
        margin-bottom: 1.65rem;
    }

    @include bp-max($bp-hamburger) {
        &__page-main {
            padding-top: $mobile-header;
        }
    }

    @include bp-max($bp-tablet-sm) {
        &__container {
            padding-left: $site-padding;
            padding-right: $site-padding;
        }

        &__title {
            font-size: 32px;
        }

        &__text {
            font-size: 20px;
            line-height: 22px;
        }
    }
}