.list-focus-areas {
  $arrow-padding: px-to-rem(20px);
  columns: 2 auto;

  @include bp-max($bp-tablet-sm) {
    columns: 1;
  }


  .list__item {
    margin-bottom: px-to-rem(4px);
    font-size: px-to-rem(22px);
    line-height: 1.4;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid; 

    @include font-accent-bold;
  }

  .list__link {
    display: block;
    background-color: $color-brand-white-abs;
    padding: px-to-rem(20px) px-to-rem(45px) px-to-rem(20px) px-to-rem(30px);
    position: relative;
    //border: 2px solid $color-border;

    @include bi-icon-after($bi-arrow-right) {
      @include vertical-align;

      color: $color-list-arrow;
      position: absolute;
      right: $arrow-padding;
      
    }

    &:hover {
      color: $color-brand-light;
      background-color: $color-links;
      
      &:after {
        color: $color-brand-light;
      }
    }
  }
}