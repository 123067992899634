.subscribe-cta {
	margin: $spacing-unit-block 0;
}

a.subscribe-cta__button {
	display: inline-block;
}
.has-blog-link {
    width: 70%;
}
.subscribe-cta__copy {
	margin-bottom: $spacing-unit;
    @include bp-max(850px) {
        width:100% !important;
    }
}
.subscribe-cta__copy.blog-link {
    float:left;
}
.subscribe-cta__button {
    margin: 0 auto;
    display: block;
}

.subscribe-cta__button.blog-link {
    width:28%;
    float:right;
    min-width:200px;
    margin-bottom: 19px;
    @include bp-max(850px) {
        width:100% !important;
    }
    
    .button {
        float:right;
        @include bp-max(850px) {
            float:none;
            margin: 0px auto;
            display: block;
        }
    }
}

