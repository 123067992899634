.people-search-results {
    & > ol {
        padding-left: 0;
    }
    ol, ul {
        margin-bottom: 0;
        list-style-type: none;
    }
}

.people-sort-container {
    ol {
        margin-bottom: 3rem;
    }
}