.featured-grid {
    padding-bottom: px-to-rem(24px);

    &__item {
        &+& {
            margin-top: 1rem;
        }
        &--news {
            .featured-grid__card-background {
                background-color: $color-content-green;
            }
        }

        &--case-study {
            .featured-grid__card-background {
                background-color: $color-content-citrus;
            }
        }

        &--event {
            .featured-grid__card-background {
                background-color: $color-content-royal;
            }
        }

        &--insight {
            .featured-grid__card-background {
                background-color: $color-content-blue;
            }
        }
    }

    &__card {
        position: relative;
        padding: px-to-rem(48px) px-to-rem(24px);
        color: $color-brand-white-abs;
        @include font-secondary-medium;
        display: block;
        overflow: hidden;

        &:hover {
            color: $color-brand-white-abs;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
            @include transition(background-color);
            z-index: 1;
        }

        &-tag,
        &-title,
        &-date {
            position: relative;
            z-index: 3;
        }
    }
    &__card-background {
        background: $color-forest-green no-repeat top left;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform 1.5s ease-out;
    }

    &__item--highlight &{
        &__card-title {
            font-size: px-to-rem(34px);
        }
    }

    &__card-tag {
        font-size: px-to-rem(16px);
        margin-bottom: px-to-rem(24px);
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    &__card-title {
        font-size: px-to-rem(36px);
        line-height: 1.1;
        font-family: $font-family-serif-new;
    }

    &__card-date {
        margin-top: px-to-rem(24px);
    }

    &__card-meta {
        &:not(:last-child) {
            &:after {
                content: ', ';
                display: inline-block;
                margin-right: 0.25em;
            }
        }
    }
    
    @include bp-min($bp-tablet-sm) {
        &__item--highlight & {
            &__card {
                padding: px-to-rem(72px) px-to-rem(48px);
            }
            &__card-title {
                font-size: px-to-rem(64px);
            }
        }
        &__card-title {
            font-size: px-to-rem(38px);
        }
        &__item {
            &+& {
                margin-top: px-to-rem(32px);
            }
        }
        &__card {
            &:hover {
                &:before {
                    background-color: rgba(0, 0, 0, 0.35);
                }
            }
        }
        &__card:hover &{
            &__card-background {
                transform: scale(1.1);
            }
        }
    }

    @include bp-min($bp-tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__item {
            width: calc(50% - #{px-to-rem(16px)});

            &+& {
                margin-top: 0;
            }

            &:first-child:nth-last-child(2) {
                width: calc(50% - #{px-to-rem(16px)});

                .featured-grid__card {
                    padding: px-to-rem(48px) px-to-rem(24px);
                }

                .featured-grid__card-title {
                    font-size: px-to-rem(38px);
                }

            }
            &:first-child:nth-last-child(2)~& {
                width: calc(50% - #{px-to-rem(16px)});
            }
        }
        &__item--highlight {
            width: 100%;
        }
        &__card {
            margin-bottom: px-to-rem(32px);
        }
    }
}