.homepage-cta {
    display: inline-block;
    background-color: $color__green;
    color: $color-brand-white-abs;
    padding: 32px;
    position: relative;
    float: right;
    transform: translateY(-22px);
    z-index: 1;
    @include transition(background-color);

    &__label {
        font-family: $font-family-serif-new;
        font-size: 36px;
        line-height: 1;

        &::after {
            content: '';
            display: block;
            height: 4px;
            background: $color__green--light;
            width: 100%;
            @include transition(background-color);
        }
    }

    &:hover {
        color: $color-brand-white-abs;
    }
    &:hover &{
        &__label {
            &:after {
                background-color: $color-brand-white-abs;
            }
        }
    }

    @include bp-max($bp-tablet-sm) {
        float: none;
        margin: 28px 16px 0;
        display: flex;
        justify-content: center;
        transform: none;

        &__label {
            font-size: 24px;
            display: inline-block;
        }
    }
}