.careers-segment-landing {
    .general-content-section {

      
        & + .general-content-section {
            border-top: 1px solid $color-border-alt;
            padding-top: $spacing-unit+$base-line-height/2;
            margin-top: $spacing-unit;
        }
        &__video-container{
            display: flex;
            justify-content: space-around;  
            margin-top: 40px;
        }
        &__video-item{
            width: 100%;
            padding: 5px;
            margin-bottom: 20px;
        }

        @include bp-max($bp-tablet-sm) {
            &.column-layout {
                display: flex;
                flex-direction: column;

                .rich-text {
                    width: 100% !important;
                    float: none !important;
                    padding: 0;
                }

                .text-column {
                    order: 1;
                    padding: 0 0 $spacing-unit-block 0 !important;
                }

                .video-embed-column {
                    order: 2;
                }
            }
            .general-content-section{
                &__video-container{                
                    flex-wrap: wrap;
                }
                &__video-item{
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }
        .video-embed-column {
            width: 40%;
            float: left;
        }

        .text-column {
            width: 60%;
            float: left;
            padding: 0 $spacing-unit+$base-line-height/2 0 0;
        }

        &.text-to-right {
            .text-column {
                padding-left: $spacing-unit+$base-line-height/2;
            }
        }
    }

    .banner__button {
        margin-bottom: $spacing-unit;
    }

    // Careers segments-specific styles

    a {
        color: $color__green;

        &:hover {
            color: $color__gray--dark;
        }
    }

    h3 {
        @include careers-subtitle;
        color: $color-brand-black;
        padding-bottom: px-to-rem(16px);
        
        strong {
            position: relative;
            font-weight: 400;
            background-image: url('/assets/site/Main/public/images/careers/circle.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 14px 2px;
        }
    }

    .button--ghost-reverse {
        $box-shadow: -5px 25px 25px 0 rgba(0,0,0,.15);

        background-color: $color__green--light;
        padding: 1rem;
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        color: $color-brand-black;
        text-transform: uppercase;
        display: inline-block;
        font-size: 1rem;
        margin-top: px-to-rem(35px);
        transform: translate(0, 0);
        line-height: 1.4;
        box-shadow: none;
        border: none;
        border-radius: 0;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

        &:hover {
            color: $color-brand-black;
            transform: translate(2px, -2px);
            box-shadow: $box-shadow;
        }
    }

    .rich-text li:before {
        background-color: $color__green--light;
    }

    .header-text {
        color: $color-brand-green;
        font-size: px-to-rem(38px);
        text-transform:  uppercase;
        font-family: $font-family-titles;
        font-weight: bold;
        margin-bottom: px-to-rem(20px);
    }

    h2 {
        color: $color__gray--dark;
    }

    .question {
        &:hover {
            .question-text {
                color: $color__gray--dark;
            }
        }
    }

    .question-text {
        color: $color__green;
        font-family: $font-family-titles;
        transition: color .3s;
    }

    .button-expand-all {
        color: $color__green;

        &:hover {
            color: $color__gray--dark;
        }
    }
}

.careers-segment-landing.page-main {
    background-image: url('/assets/site/Main/public/images/careers/background-texture.png');
}
