.content-card {
    width: 31.33%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 32px;
    margin-bottom: 32px;

    &:nth-child(3n) {
        margin-right: 0;
    }

    &:hover {

        &::before,
        &::after {
            opacity: 1;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        transform: translate(6px, 6px);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &::before {
        left: 0;
        height: 6px;
    }

    &::after {
        top: 0;
        width: 6px;
    }

    &--no-img {

        &.content-card--news {
            background-color: $color-content-green;
        }

        &.content-card--case-study {
            background-color: $color-content-citrus;
        }

        &.content-card--event {
            background-color: $color-content-royal;
        }

        &.content-card--insight {
            background-color: $color-content-blue;
        }

        .content-card__text-wrapper {
            border: none;
            color: $color-brand-white-abs;
            background-color: transparent;
        }

        // .content-card__eyebrow {
        //     margin-bottom: 0;
        // }

        .content-card__title,
        .content-card__related-professional {
            color: $color-brand-white-abs;
        }
    }

    &--news {
        .content-card__text-wrapper {
            border-bottom: 3px solid $color-content-green;
        }

        &::before,
        &::after {
            background-color: $color-content-green-hl;
        }
    }

    &--event {
        .content-card__text-wrapper {
            border-bottom: 3px solid $color-content-royal;
        }

        &::before,
        &::after {
            background-color: $color-content-royal-hl;
        }
    }

    &--case-study {
        .content-card__text-wrapper {
            border-bottom: 3px solid $color-content-citrus;
        }

        &::before,
        &::after {
            background-color: $color-content-citrus-hl;
        }
    }

    &--insight {
        .content-card__text-wrapper {
            border-bottom: 3px solid $color-content-blue;
        }

        &::before,
        &::after {
            background-color: $color-content-blue-hl;
        }
    }

    &__hidden-link {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        z-index: 2;
    }

    &__image {
        width: 100%;
        padding-bottom: 226px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        position: relative;
    }

    &__text-wrapper {
        background-color: $color-brand-white-abs;
        border-left: 1px solid $color__gray--medium;
        border-right: 1px solid $color__gray--medium;
        padding: 32px;
        font-family: $font-family-secondary;
        color: $color__gray--dark;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__eyebrow {
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    &__title {
        font-weight: $font-weight-medium;
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 12px;
        color: $color__gray--dark;
        display: inline-block;
    }

    &__abstract {
        font-weight: $font-weight-regular;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    &__related-professionals {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 14px;
        position: relative;
        z-index: 3;
    }

    &__related-professional {
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        font-weight: 500;
        color: $color__gray--dark;
        position: relative;
        pointer-events: all;

        &:not(:last-of-type)::after {
            content: ',';
            margin-right: 3px;
        }
    }

    &__date {
        font-weight: $font-weight-medium;
        font-size: 16px;
        line-height: 1;
    }
}