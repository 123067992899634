.admin-listing {
    $side-padding: 10px;

    clear: both; // In case there are floats in the rich text before this component

    &__items {
        padding-left: 0;
    }

    &__item {
        padding: 26px $side-padding;

        &:not(:first-child) {
            border-top: 1px solid $color-gray-light-gray;
        }

        &--single {
            width: 100%;
            border-top: 1px solid $color-gray-light-gray;

            &:last-of-type {
                border-bottom: 1px solid $color-gray-light-gray;
            }
        }
    }

    &__photo-container {
        width: 100%;
        margin-bottom: 20px;
    }

    &__photo {
        display: block;
        width: 100%;
    }

    &__title {
        @include font-secondary-medium;
        font-size: 18px;
        line-height: 1.1;
        text-transform: uppercase;
        color: $color-gray-thunder;
        margin-bottom: 1.5rem;
    }

    &__name {
        @include font-secondary-medium;
        font-size: 21px;
        line-height: 1.1;
        color: $color-gray-dim-gray;
        padding-bottom: 1.5rem;
    }

    &__link {
        @include font-secondary-medium;
        line-height: 1.1;

        &--phone,
        &--email {
            font-size: 18px;
            display: block;
            margin-top: 0.5rem;

            &::before {
                color: #555;
                display: inline-block;
                font-family: "Font Awesome";
                padding-right: 1rem;
            }
        }

        &--phone {
            &::before {
                content: "\f095";
            }
        }

        &--email {
            &::before {
                content: "\f003";
            }
        }
        
        &--name {
            font-size: 21px;
        }
    }

    &__description {
        margin-top: 0.6rem;
    }

    &__content {
        width: 100%;
    }

    @media (min-width: 550px) {
        $item-width: 50%;

        &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &:before,
            &:after {
                content: '';
                width: $item-width;
                order: 1;
            }
        }

        &__item {
            width: $item-width;
        }

        &__item--single {
            display: flex;
            justify-content: space-between;
            width: 100%;

            // .admin-listing {
            //     &__photo-container,
            //     &__content {
            //         width: calc(50% - #{$side-padding});
            //     }
            // }

            .admin-listing__description {
                margin-top: 0;
            }

            .admin-listing__content {
                padding-left: 3rem;
            }

            .admin__photo-container {
                margin-bottom: 0;
            }
        }

        &__title {
            font-size: 20px;
        }
    }

    @media (min-width: #{$bp-tablet-sm}) {
        $item-width: 33.33%;

        &__items {
            &:after,
            &:before {
                width: $item-width;
            }
        }

        &__item {
            width: 33.33%;

            &--single {
                width: 100%;

                .admin-listing__content {
                    padding-left: 3rem;
                }
            }
        }

        &__title {
            font-size: 18px;
        }

        &__name {
            font-size: 23px;
        }

        &__link {
            &--name {
                font-size: 23px;
            }
        }
    }
}
