.cube {
	position: relative;
	height: 93%;
	width:100%;
	z-index: $zindex-cube;
	//Animation Styles
	perspective: 5000px;
	perspective-origin:0 50%;
	overflow: hidden;

	@include bp-max($bp-tablet) {
		perspective: 2000px;
		height: 87%;
	}

	@include bp-max($bp-mobile) {
		perspective: 2000px;
		height: 85%;
	}
}

.cube__slide {
	@extend %bg-cover;

	background-color: $color-brand-blue;
	position: absolute;
	height: 100%;
	width:100%;
	padding: $site-header-height 0;
	z-index: $zindex-panel-next;
	opacity: 0;
	visibility: hidden;

	//Animation Styles
	backface-visibility: hidden;
	//transform-origin: 100% 50%;

	@include bp-max($bp-tablet) {
  		padding: 15% 0;
  	}

  	@include bp-max-orientation() {
  		padding: 8% 0;
  	}

	&.is-slide-current {
		opacity: 1;
		visibility: visible;
	}

	.is-animating & {
		//transition: transform 1s ease;
	}

	&.is-cube-slide-out {
		z-index: $zindex-panel-current;
		transform-origin: 100% 50%;


		opacity: 1;
		visibility: visible;

		.is-animating-left & {
			animation: slideCurrent 1s ease forwards
		}

		.is-animating-right & {
			animation: slideCurrent-reverse 1s ease  forwards
		}
	}

	&.is-cube-slide-in {
		left: 100%;
		transform-origin: 0% 100%;
		z-index: $zindex-panel-next;

		opacity: 1;
		visibility: visible;

		.is-animating-left & {
			animation: slideNext 1s ease forwards
		}

		.is-animating-right & {
			animation: slideNext-reverse 1s ease forwards
		}
	}
}

@keyframes slideCurrent {

  0% {
    transform: translateX(0%) rotateY(0deg) translateZ(0);
  }

  100% {
    transform: translateX(-100%) rotateY(-90deg) translateZ(0);
  }

}

@keyframes slideNext {

  0% {
    transform: translateX(0) translateZ(0) rotateY(75deg);
  }

  100% {
    transform: translateX(-100%) translateZ(0) rotateY(0deg);
  }

}

@keyframes slideCurrent-reverse {

  0% {
  	transform: translateX(-100%) rotateY(-90deg) translateZ(0);
  }

  100% {
    transform: translateX(0%) rotateY(0deg) translateZ(0);
  }
}

@keyframes slideNext-reverse {

  0% {
  	transform: translateX(-100%) rotateY(0deg) translateZ(0);
  }

  100% {
    transform: translateX(0) rotateY(70deg) translateZ(0);
  }
}

.cube__slide-content {
	height: 100%;
	position: relative;
}

.cube__wrapper  {
	height: 0;
}

.cube__arrow {
	@include vertical-align;

	position: absolute;
	z-index: $zindex-cube-arrows;
	color: $color-brand-white;
	transition: transform 300ms ease-out;
	font-size: 1.7rem;

	&:hover {
		transform: translate( 10px, -50%);

		@include bp-max($bp-mobile) {
			transform: translate( 10px, 0);
		}
	}

	&.cube__arrow--left {

		&:hover {
			transform: translate( -10px, -50%);

			@include bp-max($bp-mobile) {
				transform: translate( -10px, 0);
			}
		}
	}

	@include bp-max($bp-mobile) {
		top: auto;
		bottom: 2.5%;
		transform: translate(0);
		font-size: 1.4rem;
	}
}

.js-cube-arrow-right {
	margin-left: -20px;
	float: right;
}

.util-text-align-right {
	float: right;
}

.cube__slide-refresh {
	@include horizontal-align;
	position: absolute;
	bottom: 0;
	height: 10%;
	z-index: $zindex-cube-arrows;
	color: $color-brand-white;

	&:hover {
		opacity: 0.8;

		@include bp-max($bp-mobile) {
			opacity: 1;
		}
	}

	.bi {
		font-size: 2.4em;
		transition: transform .6s linear;

		@include bp-max($bp-mobile) {
			font-size: 2em;
		}

		&:before {
			transform: rotate(-90deg);
		}
	}

	@include bp-max($bp-mobile) {
		height: auto;
		bottom: 3%;

	}
}
