.scroll-appear {
    opacity: 0;
  
    &--left {
      transform: translateX(-10%);
    }
  
    &--right {
      transform: translateX(10%);
    }
  
    &--top {
      transform: translateY(-150%);
    }
    
    &--default {
      transform: translateY(20%);
    }
  
    &--visible {
        opacity: 1;
        transform: translate(0, 0);
        transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
      &--default {
          transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1); 
      }
    }
  
  
  }