.featured-crosslinks {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 24px;

    .careers__cta {
        border-radius: 4px;

        &:hover {
            transform: none;
            box-shadow: none;
            background-color: $color__green;
            color: $color-brand-white-abs !important;
        }
    }

    &__bar {
        position: absolute;
        top: 10px;
        left: 0;
        height: 8px;
        width: 180px;
        background-color: $color__green--light;
    }

    &__left-col {
        margin-right: 50px;
        
        &:last-child {
            width: 50%;
            margin-right: calc(28% + 50px);
        }
    }

    &__left-col,
    &__right-col {
        width: 39%;
    }

    &__card {
        margin-top: 70px;
    }

    &__left-col & {
        &__card {
            &:first-child {
                margin-top:150px;
            }
        }
    }

    &__main-text,
    &__text {
        font-family: $font-family-secondary;
        font-size: 24px;
        line-height: 26px;
        font-weight: $font-weight-medium;
        color: $color__gray--dark;
    }

    &__image {
        width: 100%;
        padding-bottom: 235px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__title {
        color: $color__green;
        font-family: $font-family-secondary;
        font-weight: $font-weight-bold;
        font-size: 32px;
        margin: 28px 0 18px;
    }
    &__main-text,
    &__text {
        strong {
            position: relative;
            background-image: url('/assets/site/Main/public/images/careers/circle.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 14px 0;
            font-weight: inherit;
        }
    }

    @include bp-max($bp-tablet-sm) {
        display: block;
        margin-top: 18px;

        &__bar {
            position: static;
        }

        &__left-col {
            margin-right: 0;
            margin-top: 26px;

            .featured-crosslinks__card {
                margin-top: 70px;

                &:first-child {
                    margin-top: 70px;
                }
            }

            &:last-child {
                width: 100%;
                margin-right: 0;
            }
        }

        &__right-col {
            .featured-crosslinks__card {
                margin-top: 32px;
            }
        }

        &__left-col,
        &__right-col {
            width: 100%;
        }

        &__title {
            font-weight: $font-weight-medium;
            font-size: 24px;
            margin: 18px 0 8px;
        }

        &__main-text,
        &__text {
            font-size: 20px;
        }

        .careers__cta {
            margin-top: 0;
            width: 100%;
            text-align: center;
        }
    }
}