//TabArea
.pdf-tabcontentlist{
  margin-top: 10px;
  margin-left: 20px;
}

.pdf-list__item{
  margin-top: 25px;
}

.pdf-list ul, menu, dir{
  margin: 10px 0;
}

.publications{
    .pdf-list {
        color:$pdf-print-blue;
        line-height:1.5em;
    } 

    .list-articles__date{
        text-transform:uppercase;
        font-size:1.1em; 
    }

    .list-articles__link{
        display:block;
    }

    .pdf-tabcontentlist {
        padding-bottom:2em;
        border-bottom: 2px lightgray solid;
        
        .list-articles__date {
            font-size:1em;
        }

        &:last-child{
            border-bottom:none;
        }
     }
}