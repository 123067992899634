.dropdown-redesign {
    position: relative;
    display: inline-flex;
    width: 100%;

    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        right: px-to-rem(16px);
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
    &__select {
        @include font-secondary();
        width: 100%;
        padding: px-to-rem(10px) px-to-rem(16px);
        padding-right: px-to-rem(50px);
        font-size: px-to-rem(18px);
        line-height: 1.4;
        color: $color-brand-black;
        border: 0 none;
        border-radius: 0;
        border-bottom: 1px solid $color-brand-black;
        background-color: $color__gray--light;
        appearance: none;
    }
    
    @include bp-min($bp-tablet-sm) {
        max-width: px-to-rem(180px);
    }

    @include bp-min($bp-tablet) {
    }
}