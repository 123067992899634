.dropdown-label-icon {
    font-size: 1.7rem;
    position: absolute;
    right: 6%;
    top: 22%;
}

.dropdown-label-text {
    font-size: 1.1rem;
    text-align: center;
    text-transform: uppercase;
}

.dropdown-container {
    border: 2px solid $color-brand-white;
    border-radius: 4px;
    font-family: $font-family-accent;
    font-weight: $font-weight-light;
    height: px-to-rem(60px);
    position: relative;
    label {
        align-items: center;
        background-color: $color-brand-white-abs;
        color: $color-text-input;
        display: flex;
        font-size: px-to-rem(20px);
        height: 100%;
        justify-content: center;
        line-height: 100%;
        padding: 0.5rem;
    }
    select {
        appearance: none;
        background-color: transparent;
        border-width: 0;
        cursor: pointer;
        height: 100%;
        left: 0;
        padding-right: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
        -webkit-appearance:none;
        option {
            color: $color-text-input;
        }
        &:-moz-focusring {
            color: transparent;
        }
        &::-ms-expand {
            display: none;
        }
        &::-ms-value {
            background: none;
        }
    }
}

.people-additional-search-container {
    margin-left: 2px;
    margin-right: 2px;
    .dropdown-label-text {
        font-size: 1rem;
        @media (min-width: px-to-rem(675px)) {
            font-size: 1.1rem;
        }
    }
}

.people-dropdown-container-one,
.topicpage-dropdowns-container-one {
    display: inline-block;
    width: 100%;
    .dropdown-container {
        
    }
}

.people-dropdown-container-two,
.topicpage-dropdowns-container-two {
    display: flex;
    .dropdown-container {
        flex-basis: 49.5%;
        &:nth-of-type(odd) {
            margin-right: 1%;
        }
    }
}
.people-dropdown-container-three {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: px-to-rem(800px)) {
        flex-wrap: nowrap;
    }
    .dropdown-container {
        flex-basis: 49.5%;

        -ms-flex-preferred-size: 48.5%;
        &:nth-of-type(odd) {
            margin-right: 1%;
        }
        @media (min-width: px-to-rem(800px)) {
            flex-basis: 32.5%;
            &:nth-of-type(odd) {
                margin-right: 0;
            }
            &:nth-of-type(-n+2) {
                margin-right: 1.25%;
            }
        }
    }
}

.people-dropdowns-container,
.people-dropdown-container,
.topicpage-dropdowns-container,
.topicpage-dropdown-container {
    .dropdown-container {
        margin-bottom: 0.7rem;
        @media (min-width: px-to-rem(800px)) {
            margin-bottom: 1.4rem;
        }
    }
    .dropdown-label-icon {
        font-size: 1rem;
        right: 2%;
        top: 0;
        @media (min-width: px-to-rem(800px)) {
            font-size: 1.7rem;
            right: 6%;
            top: 22%;
        }
    }
    .dropdown-label-text {
        font-size: 0.9rem;
        text-align: center;
        text-transform: uppercase;
        @media (min-width: px-to-rem(600px)) {
            font-size: 1.1rem;
        }
    }
}
