/* Black Tie uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

//we are only using font brands
// .#{$bt-css-prefix}-bars:before { content: $bt-var-bars; }
// .#{$bt-css-prefix}-envelope:before { content: $bt-var-envelope; }
// .#{$bt-css-prefix}-search:before { content: $bt-var-search; }
// .#{$bt-css-prefix}-search-plus:before { content: $bt-var-search-plus; }
// .#{$bt-css-prefix}-search-minus:before { content: $bt-var-search-minus; }
// .#{$bt-css-prefix}-phone:before { content: $bt-var-phone; }
// .#{$bt-css-prefix}-comment:before { content: $bt-var-comment; }
// .#{$bt-css-prefix}-commenting:before { content: $bt-var-commenting; }
// .#{$bt-css-prefix}-comments:before { content: $bt-var-comments; }
// .#{$bt-css-prefix}-rss:before { content: $bt-var-rss; }
// .#{$bt-css-prefix}-times:before { content: $bt-var-times; }
// .#{$bt-css-prefix}-times-circle:before { content: $bt-var-times-circle; }
// .#{$bt-css-prefix}-clock:before { content: $bt-var-clock; }
// .#{$bt-css-prefix}-star:before { content: $bt-var-star; }
// .#{$bt-css-prefix}-star-half:before { content: $bt-var-star-half; }
// .#{$bt-css-prefix}-check:before { content: $bt-var-check; }
// .#{$bt-css-prefix}-check-circle:before { content: $bt-var-check-circle; }
// .#{$bt-css-prefix}-check-square:before { content: $bt-var-check-square; }
// .#{$bt-css-prefix}-th:before { content: $bt-var-th; }
// .#{$bt-css-prefix}-th-large:before { content: $bt-var-th-large; }
// .#{$bt-css-prefix}-heart:before { content: $bt-var-heart; }
// .#{$bt-css-prefix}-heart-half:before { content: $bt-var-heart-half; }
// .#{$bt-css-prefix}-calendar:before { content: $bt-var-calendar; }
// .#{$bt-css-prefix}-shopping-cart:before { content: $bt-var-shopping-cart; }
// .#{$bt-css-prefix}-plus:before { content: $bt-var-plus; }
// .#{$bt-css-prefix}-plus-circle:before { content: $bt-var-plus-circle; }
// .#{$bt-css-prefix}-plus-square:before { content: $bt-var-plus-square; }
// .#{$bt-css-prefix}-pen:before { content: $bt-var-pen; }
// .#{$bt-css-prefix}-minus:before { content: $bt-var-minus; }
// .#{$bt-css-prefix}-minus-circle:before { content: $bt-var-minus-circle; }
// .#{$bt-css-prefix}-minus-square:before { content: $bt-var-minus-square; }
// .#{$bt-css-prefix}-pencil:before { content: $bt-var-pencil; }
// .#{$bt-css-prefix}-edit:before { content: $bt-var-edit; }
// .#{$bt-css-prefix}-thumbs-up:before { content: $bt-var-thumbs-up; }
// .#{$bt-css-prefix}-thumbs-down:before { content: $bt-var-thumbs-down; }
// .#{$bt-css-prefix}-gear:before { content: $bt-var-gear; }
// .#{$bt-css-prefix}-trash:before { content: $bt-var-trash; }
// .#{$bt-css-prefix}-file:before { content: $bt-var-file; }
// .#{$bt-css-prefix}-info-circle:before { content: $bt-var-info-circle; }
// .#{$bt-css-prefix}-label:before { content: $bt-var-label; }
// .#{$bt-css-prefix}-rocket:before { content: $bt-var-rocket; }
// .#{$bt-css-prefix}-book:before { content: $bt-var-book; }
// .#{$bt-css-prefix}-book-open:before { content: $bt-var-book-open; }
// .#{$bt-css-prefix}-notebook:before { content: $bt-var-notebook; }
// .#{$bt-css-prefix}-camera:before { content: $bt-var-camera; }
// .#{$bt-css-prefix}-folder:before { content: $bt-var-folder; }
// .#{$bt-css-prefix}-quote-left:before { content: $bt-var-quote-left; }
// .#{$bt-css-prefix}-quote-right:before { content: $bt-var-quote-right; }
// .#{$bt-css-prefix}-eye:before { content: $bt-var-eye; }
// .#{$bt-css-prefix}-lock:before { content: $bt-var-lock; }
// .#{$bt-css-prefix}-lock-open:before { content: $bt-var-lock-open; }
// .#{$bt-css-prefix}-gift:before { content: $bt-var-gift; }
// .#{$bt-css-prefix}-spinner-clock:before { content: $bt-var-spinner-clock; }
// .#{$bt-css-prefix}-spinner:before { content: $bt-var-spinner; }
// .#{$bt-css-prefix}-wrench:before { content: $bt-var-wrench; }
// .#{$bt-css-prefix}-cloud:before { content: $bt-var-cloud; }
// .#{$bt-css-prefix}-cloud-upload:before { content: $bt-var-cloud-upload; }
// .#{$bt-css-prefix}-cloud-download:before { content: $bt-var-cloud-download; }
// .#{$bt-css-prefix}-sync:before { content: $bt-var-sync; }
// .#{$bt-css-prefix}-question-circle:before { content: $bt-var-question-circle; }
// .#{$bt-css-prefix}-share:before { content: $bt-var-share; }
// .#{$bt-css-prefix}-briefcase:before { content: $bt-var-briefcase; }
// .#{$bt-css-prefix}-money:before { content: $bt-var-money; }
// .#{$bt-css-prefix}-megaphone:before { content: $bt-var-megaphone; }
// .#{$bt-css-prefix}-sign-in:before { content: $bt-var-sign-in; }
// .#{$bt-css-prefix}-sign-out:before { content: $bt-var-sign-out; }
// .#{$bt-css-prefix}-film:before { content: $bt-var-film; }
// .#{$bt-css-prefix}-trophy:before { content: $bt-var-trophy; }
// .#{$bt-css-prefix}-code:before { content: $bt-var-code; }
// .#{$bt-css-prefix}-light-bulb:before { content: $bt-var-light-bulb; }
// .#{$bt-css-prefix}-print:before { content: $bt-var-print; }
// .#{$bt-css-prefix}-fax:before { content: $bt-var-fax; }
// .#{$bt-css-prefix}-video:before { content: $bt-var-video; }
// .#{$bt-css-prefix}-signal:before { content: $bt-var-signal; }
// .#{$bt-css-prefix}-sitemap:before { content: $bt-var-sitemap; }
// .#{$bt-css-prefix}-upload:before { content: $bt-var-upload; }
// .#{$bt-css-prefix}-download:before { content: $bt-var-download; }
// .#{$bt-css-prefix}-key:before { content: $bt-var-key; }
// .#{$bt-css-prefix}-mug:before { content: $bt-var-mug; }
// .#{$bt-css-prefix}-bookmark:before { content: $bt-var-bookmark; }
// .#{$bt-css-prefix}-flag:before { content: $bt-var-flag; }
// .#{$bt-css-prefix}-external-link:before { content: $bt-var-external-link; }
// .#{$bt-css-prefix}-smile:before { content: $bt-var-smile; }
// .#{$bt-css-prefix}-frown:before { content: $bt-var-frown; }
// .#{$bt-css-prefix}-meh:before { content: $bt-var-meh; }
// .#{$bt-css-prefix}-magic:before { content: $bt-var-magic; }
// .#{$bt-css-prefix}-bolt:before { content: $bt-var-bolt; }
// .#{$bt-css-prefix}-exclamation-triangle:before { content: $bt-var-exclamation-triangle; }
// .#{$bt-css-prefix}-exclamation-circle:before { content: $bt-var-exclamation-circle; }
// .#{$bt-css-prefix}-flask:before { content: $bt-var-flask; }
// .#{$bt-css-prefix}-music:before { content: $bt-var-music; }
// .#{$bt-css-prefix}-push-pin:before { content: $bt-var-push-pin; }
// .#{$bt-css-prefix}-shield:before { content: $bt-var-shield; }
// .#{$bt-css-prefix}-sort:before { content: $bt-var-sort; }
// .#{$bt-css-prefix}-reply:before { content: $bt-var-reply; }
// .#{$bt-css-prefix}-forward:before { content: $bt-var-forward; }
// .#{$bt-css-prefix}-reply-all:before { content: $bt-var-reply-all; }
// .#{$bt-css-prefix}-forward-all:before { content: $bt-var-forward-all; }
// .#{$bt-css-prefix}-bell:before { content: $bt-var-bell; }
// .#{$bt-css-prefix}-bell-off:before { content: $bt-var-bell-off; }
// .#{$bt-css-prefix}-ban:before { content: $bt-var-ban; }
// .#{$bt-css-prefix}-database:before { content: $bt-var-database; }
// .#{$bt-css-prefix}-hard-drive:before { content: $bt-var-hard-drive; }
// .#{$bt-css-prefix}-merge:before { content: $bt-var-merge; }
// .#{$bt-css-prefix}-fork:before { content: $bt-var-fork; }
// .#{$bt-css-prefix}-wifi:before { content: $bt-var-wifi; }
// .#{$bt-css-prefix}-paper-plane:before { content: $bt-var-paper-plane; }
// .#{$bt-css-prefix}-inbox:before { content: $bt-var-inbox; }
// .#{$bt-css-prefix}-fire:before { content: $bt-var-fire; }
// .#{$bt-css-prefix}-play:before { content: $bt-var-play; }
// .#{$bt-css-prefix}-pause:before { content: $bt-var-pause; }
// .#{$bt-css-prefix}-stop:before { content: $bt-var-stop; }
// .#{$bt-css-prefix}-play-circle:before { content: $bt-var-play-circle; }
// .#{$bt-css-prefix}-next:before { content: $bt-var-next; }
// .#{$bt-css-prefix}-previous:before { content: $bt-var-previous; }
// .#{$bt-css-prefix}-repeat:before { content: $bt-var-repeat; }
// .#{$bt-css-prefix}-fast-forward:before { content: $bt-var-fast-forward; }
// .#{$bt-css-prefix}-fast-reverse:before { content: $bt-var-fast-reverse; }
// .#{$bt-css-prefix}-volume:before { content: $bt-var-volume; }
// .#{$bt-css-prefix}-volume-off:before { content: $bt-var-volume-off; }
// .#{$bt-css-prefix}-volume-up:before { content: $bt-var-volume-up; }
// .#{$bt-css-prefix}-volume-down:before { content: $bt-var-volume-down; }
// .#{$bt-css-prefix}-maximize:before { content: $bt-var-maximize; }
// .#{$bt-css-prefix}-minimize:before { content: $bt-var-minimize; }
// .#{$bt-css-prefix}-closed-captions:before { content: $bt-var-closed-captions; }
// .#{$bt-css-prefix}-shuffle:before { content: $bt-var-shuffle; }
// .#{$bt-css-prefix}-triangle:before { content: $bt-var-triangle; }
// .#{$bt-css-prefix}-square:before { content: $bt-var-square; }
// .#{$bt-css-prefix}-circle:before { content: $bt-var-circle; }
// .#{$bt-css-prefix}-hexagon:before { content: $bt-var-hexagon; }
// .#{$bt-css-prefix}-octagon:before { content: $bt-var-octagon; }
// .#{$bt-css-prefix}-angle-up:before { content: $bt-var-angle-up; }
// .#{$bt-css-prefix}-angle-down:before { content: $bt-var-angle-down; }
// .#{$bt-css-prefix}-angle-left:before { content: $bt-var-angle-left; }
// .#{$bt-css-prefix}-angle-right:before { content: $bt-var-angle-right; }
// .#{$bt-css-prefix}-angles-up:before { content: $bt-var-angles-up; }
// .#{$bt-css-prefix}-angles-down:before { content: $bt-var-angles-down; }
// .#{$bt-css-prefix}-angles-left:before { content: $bt-var-angles-left; }
// .#{$bt-css-prefix}-angles-right:before { content: $bt-var-angles-right; }
// .#{$bt-css-prefix}-arrow-up:before { content: $bt-var-arrow-up; }
// .#{$bt-css-prefix}-arrow-down:before { content: $bt-var-arrow-down; }
// .#{$bt-css-prefix}-arrow-left:before { content: $bt-var-arrow-left; }
// .#{$bt-css-prefix}-arrow-right:before { content: $bt-var-arrow-right; }
// .#{$bt-css-prefix}-bar-chart:before { content: $bt-var-bar-chart; }
// .#{$bt-css-prefix}-pie-chart:before { content: $bt-var-pie-chart; }
// .#{$bt-css-prefix}-circle-arrow-up:before { content: $bt-var-circle-arrow-up; }
// .#{$bt-css-prefix}-circle-arrow-down:before { content: $bt-var-circle-arrow-down; }
// .#{$bt-css-prefix}-circle-arrow-left:before { content: $bt-var-circle-arrow-left; }
// .#{$bt-css-prefix}-circle-arrow-right:before { content: $bt-var-circle-arrow-right; }
// .#{$bt-css-prefix}-caret-up:before { content: $bt-var-caret-up; }
// .#{$bt-css-prefix}-caret-down:before { content: $bt-var-caret-down; }
// .#{$bt-css-prefix}-caret-left:before { content: $bt-var-caret-left; }
// .#{$bt-css-prefix}-caret-right:before { content: $bt-var-caret-right; }
// .#{$bt-css-prefix}-long-arrow-up:before { content: $bt-var-long-arrow-up; }
// .#{$bt-css-prefix}-long-arrow-down:before { content: $bt-var-long-arrow-down; }
// .#{$bt-css-prefix}-long-arrow-left:before { content: $bt-var-long-arrow-left; }
// .#{$bt-css-prefix}-long-arrow-right:before { content: $bt-var-long-arrow-right; }
// .#{$bt-css-prefix}-Bold:before { content: $bt-var-Bold; }
// .#{$bt-css-prefix}-italic:before { content: $bt-var-italic; }
// .#{$bt-css-prefix}-underline:before { content: $bt-var-underline; }
// .#{$bt-css-prefix}-link:before { content: $bt-var-link; }
// .#{$bt-css-prefix}-paper-clip:before { content: $bt-var-paper-clip; }
// .#{$bt-css-prefix}-align-left:before { content: $bt-var-align-left; }
// .#{$bt-css-prefix}-align-center:before { content: $bt-var-align-center; }
// .#{$bt-css-prefix}-align-right:before { content: $bt-var-align-right; }
// .#{$bt-css-prefix}-align-justify:before { content: $bt-var-align-justify; }
// .#{$bt-css-prefix}-cut:before { content: $bt-var-cut; }
// .#{$bt-css-prefix}-copy:before { content: $bt-var-copy; }
// .#{$bt-css-prefix}-paste:before { content: $bt-var-paste; }
// .#{$bt-css-prefix}-photo:before { content: $bt-var-photo; }
// .#{$bt-css-prefix}-table:before { content: $bt-var-table; }
// .#{$bt-css-prefix}-ulist:before { content: $bt-var-ulist; }
// .#{$bt-css-prefix}-olist:before { content: $bt-var-olist; }
// .#{$bt-css-prefix}-indent:before { content: $bt-var-indent; }
// .#{$bt-css-prefix}-outdent:before { content: $bt-var-outdent; }
// .#{$bt-css-prefix}-undo:before { content: $bt-var-undo; }
// .#{$bt-css-prefix}-redo:before { content: $bt-var-redo; }
// .#{$bt-css-prefix}-sup:before { content: $bt-var-sup; }
// .#{$bt-css-prefix}-sub:before { content: $bt-var-sub; }
// .#{$bt-css-prefix}-text-size:before { content: $bt-var-text-size; }
// .#{$bt-css-prefix}-text-color:before { content: $bt-var-text-color; }
// .#{$bt-css-prefix}-remove-formatting:before { content: $bt-var-remove-formatting; }
// .#{$bt-css-prefix}-blockquote:before { content: $bt-var-blockquote; }
// .#{$bt-css-prefix}-globe:before { content: $bt-var-globe; }
// .#{$bt-css-prefix}-map:before { content: $bt-var-map; }
// .#{$bt-css-prefix}-map-arrow:before { content: $bt-var-map-arrow; }
// .#{$bt-css-prefix}-map-marker:before { content: $bt-var-map-marker; }
// .#{$bt-css-prefix}-map-pin:before { content: $bt-var-map-pin; }
// .#{$bt-css-prefix}-home:before { content: $bt-var-home; }
// .#{$bt-css-prefix}-building:before { content: $bt-var-building; }
// .#{$bt-css-prefix}-industry:before { content: $bt-var-industry; }
// .#{$bt-css-prefix}-desktop:before { content: $bt-var-desktop; }
// .#{$bt-css-prefix}-laptop:before { content: $bt-var-laptop; }
// .#{$bt-css-prefix}-tablet:before { content: $bt-var-tablet; }
// .#{$bt-css-prefix}-mobile:before { content: $bt-var-mobile; }
// .#{$bt-css-prefix}-tv:before { content: $bt-var-tv; }
// .#{$bt-css-prefix}-radio-checked:before { content: $bt-var-radio-checked; }
// .#{$bt-css-prefix}-radio-unchecked:before { content: $bt-var-radio-unchecked; }
// .#{$bt-css-prefix}-checkbox-checked:before { content: $bt-var-checkbox-checked; }
// .#{$bt-css-prefix}-checkbox-unchecked:before { content: $bt-var-checkbox-unchecked; }
// .#{$bt-css-prefix}-checkbox-intermediate:before { content: $bt-var-checkbox-intermediate; }
// .#{$bt-css-prefix}-user:before { content: $bt-var-user; }
// .#{$bt-css-prefix}-user-male:before { content: $bt-var-user-male; }
// .#{$bt-css-prefix}-user-female:before { content: $bt-var-user-female; }
// .#{$bt-css-prefix}-crown:before { content: $bt-var-crown; }
// .#{$bt-css-prefix}-credit-card:before { content: $bt-var-credit-card; }
// .#{$bt-css-prefix}-strikethrough:before { content: $bt-var-strikethrough; }
// .#{$bt-css-prefix}-eject:before { content: $bt-var-eject; }
// .#{$bt-css-prefix}-ellipsis-h:before { content: $bt-var-ellipsis-h; }
// .#{$bt-css-prefix}-ellipsis-v:before { content: $bt-var-ellipsis-v; }

.#{$brands-css-prefix}-facebook:before { content: $fab-var-facebook; }
.#{$brands-css-prefix}-facebook-alt:before { content: $fab-var-facebook-alt; }
.#{$brands-css-prefix}-twitter:before { content: $fab-var-twitter; }
.#{$brands-css-prefix}-linkedin:before { content: $fab-var-linkedin; }
.#{$brands-css-prefix}-linkedin-alt:before { content: $fab-var-linkedin-alt; }
.#{$brands-css-prefix}-instagram:before { content: $fab-var-instagram; }
.#{$brands-css-prefix}-github:before { content: $fab-var-github; }
.#{$brands-css-prefix}-github-alt:before { content: $fab-var-github-alt; }
.#{$brands-css-prefix}-googleplus:before { content: $fab-var-googleplus; }
.#{$brands-css-prefix}-googleplus-alt:before { content: $fab-var-googleplus-alt; }
.#{$brands-css-prefix}-pinterest:before { content: $fab-var-pinterest; }
.#{$brands-css-prefix}-pinterest-alt:before { content: $fab-var-pinterest-alt; }
.#{$brands-css-prefix}-tumblr:before { content: $fab-var-tumblr; }
.#{$brands-css-prefix}-tumblr-alt:before { content: $fab-var-tumblr-alt; }
.#{$brands-css-prefix}-bitcoin:before { content: $fab-var-bitcoin; }
.#{$brands-css-prefix}-bitcoin-alt:before { content: $fab-var-bitcoin-alt; }
.#{$brands-css-prefix}-dropbox:before { content: $fab-var-dropbox; }
.#{$brands-css-prefix}-stackexchange:before { content: $fab-var-stackexchange; }
.#{$brands-css-prefix}-stackoverflow:before { content: $fab-var-stackoverflow; }
.#{$brands-css-prefix}-flickr:before { content: $fab-var-flickr; }
.#{$brands-css-prefix}-flickr-alt:before { content: $fab-var-flickr-alt; }
.#{$brands-css-prefix}-bitbucket:before { content: $fab-var-bitbucket; }
.#{$brands-css-prefix}-html5:before { content: $fab-var-html5; }
.#{$brands-css-prefix}-css3:before { content: $fab-var-css3; }
.#{$brands-css-prefix}-apple:before { content: $fab-var-apple; }
.#{$brands-css-prefix}-windows:before { content: $fab-var-windows; }
.#{$brands-css-prefix}-android:before { content: $fab-var-android; }
.#{$brands-css-prefix}-linux:before { content: $fab-var-linux; }
.#{$brands-css-prefix}-dribbble:before { content: $fab-var-dribbble; }
.#{$brands-css-prefix}-youtube:before { content: $fab-var-youtube; }
.#{$brands-css-prefix}-skype:before { content: $fab-var-skype; }
.#{$brands-css-prefix}-foursquare:before { content: $fab-var-foursquare; }
.#{$brands-css-prefix}-trello:before { content: $fab-var-trello; }
.#{$brands-css-prefix}-maxcdn:before { content: $fab-var-maxcdn; }
.#{$brands-css-prefix}-gittip:before,
.#{$brands-css-prefix}-gratipay:before { content: $fab-var-gratipay; }
.#{$brands-css-prefix}-vimeo:before { content: $fab-var-vimeo; }
.#{$brands-css-prefix}-vimeo-alt:before { content: $fab-var-vimeo-alt; }
.#{$brands-css-prefix}-slack:before { content: $fab-var-slack; }
.#{$brands-css-prefix}-wordpress:before { content: $fab-var-wordpress; }
.#{$brands-css-prefix}-wordpress-alt:before { content: $fab-var-wordpress-alt; }
.#{$brands-css-prefix}-openid:before { content: $fab-var-openid; }
.#{$brands-css-prefix}-yahoo:before { content: $fab-var-yahoo; }
.#{$brands-css-prefix}-yahoo-alt:before { content: $fab-var-yahoo-alt; }
.#{$brands-css-prefix}-reddit:before { content: $fab-var-reddit; }
.#{$brands-css-prefix}-google:before { content: $fab-var-google; }
.#{$brands-css-prefix}-google-alt:before { content: $fab-var-google-alt; }
.#{$brands-css-prefix}-stumbleupon:before { content: $fab-var-stumbleupon; }
.#{$brands-css-prefix}-stumbleupon-alt:before { content: $fab-var-stumbleupon-alt; }
.#{$brands-css-prefix}-delicious:before { content: $fab-var-delicious; }
.#{$brands-css-prefix}-digg:before { content: $fab-var-digg; }
.#{$brands-css-prefix}-piedpiper:before { content: $fab-var-piedpiper; }
.#{$brands-css-prefix}-piedpiper-alt:before { content: $fab-var-piedpiper-alt; }
.#{$brands-css-prefix}-drupal:before { content: $fab-var-drupal; }
.#{$brands-css-prefix}-joomla:before { content: $fab-var-joomla; }
.#{$brands-css-prefix}-behance:before { content: $fab-var-behance; }
.#{$brands-css-prefix}-steam:before { content: $fab-var-steam; }
.#{$brands-css-prefix}-steam-alt:before { content: $fab-var-steam-alt; }
.#{$brands-css-prefix}-spotify:before { content: $fab-var-spotify; }
.#{$brands-css-prefix}-deviantart:before { content: $fab-var-deviantart; }
.#{$brands-css-prefix}-soundcloud:before { content: $fab-var-soundcloud; }
.#{$brands-css-prefix}-vine:before { content: $fab-var-vine; }
.#{$brands-css-prefix}-codepen:before { content: $fab-var-codepen; }
.#{$brands-css-prefix}-jsfiddle:before { content: $fab-var-jsfiddle; }
.#{$brands-css-prefix}-rebel:before { content: $fab-var-rebel; }
.#{$brands-css-prefix}-empire:before { content: $fab-var-empire; }
.#{$brands-css-prefix}-git:before { content: $fab-var-git; }
.#{$brands-css-prefix}-hackernews:before { content: $fab-var-hackernews; }
.#{$brands-css-prefix}-hackernews-alt:before { content: $fab-var-hackernews-alt; }
.#{$brands-css-prefix}-slideshare:before { content: $fab-var-slideshare; }
.#{$brands-css-prefix}-twitch:before { content: $fab-var-twitch; }
.#{$brands-css-prefix}-yelp:before { content: $fab-var-yelp; }
.#{$brands-css-prefix}-paypal:before { content: $fab-var-paypal; }
.#{$brands-css-prefix}-google-wallet:before { content: $fab-var-google-wallet; }
.#{$brands-css-prefix}-angellist:before { content: $fab-var-angellist; }
.#{$brands-css-prefix}-cc-visa:before { content: $fab-var-cc-visa; }
.#{$brands-css-prefix}-cc-mastercard:before { content: $fab-var-cc-mastercard; }
.#{$brands-css-prefix}-cc-discover:before { content: $fab-var-cc-discover; }
.#{$brands-css-prefix}-cc-amex:before { content: $fab-var-cc-amex; }
.#{$brands-css-prefix}-cc-paypal:before { content: $fab-var-cc-paypal; }
.#{$brands-css-prefix}-cc-stripe:before { content: $fab-var-cc-stripe; }
.#{$brands-css-prefix}-lastfm:before { content: $fab-var-lastfm; }
.#{$brands-css-prefix}-whatsapp:before { content: $fab-var-whatsapp; }
.#{$brands-css-prefix}-medium:before { content: $fab-var-medium; }
.#{$brands-css-prefix}-meanpath:before { content: $fab-var-meanpath; }
.#{$brands-css-prefix}-meanpath-alt:before { content: $fab-var-meanpath-alt; }
.#{$brands-css-prefix}-pagelines:before { content: $fab-var-pagelines; }
.#{$brands-css-prefix}-ioxhost:before { content: $fab-var-ioxhost; }
.#{$brands-css-prefix}-buysellads:before { content: $fab-var-buysellads; }
.#{$brands-css-prefix}-buysellads-alt:before { content: $fab-var-buysellads-alt; }
.#{$brands-css-prefix}-connectdevelop:before { content: $fab-var-connectdevelop; }
.#{$brands-css-prefix}-dashcube:before { content: $fab-var-dashcube; }
.#{$brands-css-prefix}-forumbee:before { content: $fab-var-forumbee; }
.#{$brands-css-prefix}-leanpub:before { content: $fab-var-leanpub; }
.#{$brands-css-prefix}-sellsy:before { content: $fab-var-sellsy; }
.#{$brands-css-prefix}-shirtsinbulk:before { content: $fab-var-shirtsinbulk; }
.#{$brands-css-prefix}-simplybuilt:before { content: $fab-var-simplybuilt; }
.#{$brands-css-prefix}-skyatlas:before { content: $fab-var-skyatlas; }
.#{$brands-css-prefix}-viacoin:before { content: $fab-var-viacoin; }
.#{$brands-css-prefix}-codiepie:before { content: $fab-var-codiepie; }
.#{$brands-css-prefix}-queue:before { content: $fab-var-queue; }
.#{$brands-css-prefix}-queue-alt:before { content: $fab-var-queue-alt; }
.#{$brands-css-prefix}-fonticons:before { content: $fab-var-fonticons; }
.#{$brands-css-prefix}-fonticons-alt:before { content: $fab-var-fonticons-alt; }
.#{$brands-css-prefix}-blacktie:before { content: $fab-var-blacktie; }
.#{$brands-css-prefix}-blacktie-alt:before { content: $fab-var-blacktie-alt; }
.#{$brands-css-prefix}-xing:before { content: $fab-var-xing; }
.#{$brands-css-prefix}-vk:before { content: $fab-var-vk; }
.#{$brands-css-prefix}-weibo:before { content: $fab-var-weibo; }
.#{$brands-css-prefix}-renren:before { content: $fab-var-renren; }
.#{$brands-css-prefix}-tencent-weibo:before { content: $fab-var-tencent-weibo; }
.#{$brands-css-prefix}-qq:before { content: $fab-var-qq; }
.#{$brands-css-prefix}-wechat:before,
.#{$brands-css-prefix}-weixin:before { content: $fab-var-weixin; }
