.credentials-slide-down {
	background-color: $color-gray-lynch;
	text-align: center;
	color: $color-brand-white-abs;
	padding: px-to-rem(16px);
    display: none;

    .close-credentials-summary-btn {
        display: block;
        margin: auto;

        .custom-icon {
            color: #FFF;
        }
    }

	.title {
		$font-size: 24px;

		font-size: px-to-rem(24px);
		text-transform: uppercase;
		cursor: pointer;

		@include font-secondary-medium;


		@include bi-icon-after($bi-arrow-bottom) {
			margin-left: .5em;
			display: inline-block;
			transition: transform .4s;
			transform: rotateX(0deg);
		}

		&.show-slide-down {
			&:after {
				transform: rotateX(180deg);
			}
		}
	}

	.grid-container {
		position: relative;
		margin-top: px-to-rem(50px);

		@include container(1000px);

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			height: 70%;
			width: 1px;
			left: 50%;
			top: 50%;
			transform: translateY(-50%);
			background-color: $color-brand-white-abs;
		}
	}

	.credential-category {
		$spacing: 10%;

		text-align: right;
		padding-right: $spacing;

		@include span(6);

		&:last-of-type {
			text-align: left;
			padding-left: $spacing;

			@include last();
		}
	}

	.alignment-container {
		display: inline-block;
	}

	.category-label {
		$font-size: 26px;

		text-align: left;
		font-size: px-to-rem($font-size);
		margin-bottom: $spacing-unit-small;

		@include font-accent-bold;
	}

	.credential-items {
		list-style-type: disc;
		text-align: left;
		padding-left: .5em;
		margin-left: 1em;
	}

	.credential-item {
		$font-size: 23px;

		font-size: px-to-rem($font-size);
		margin-top: 1em;

		@include font-accent;

		&:first-of-type {
			margin-top: 0;
		}
	}

	.button-call-to-action {
		$font-size: 20px;

		clear: both;
		margin-top: 3em;
		margin-bottom: 1em;

		&:hover {
			color: $color-brand-green;
		}
	}

	@include bp-max(600px) {
		.grid-container {
			&:after {
				content: none;
			}
		}

		.credential-category {
			text-align: left;
			padding: 0;

			@include span(100%);

			&:last-of-type {
				padding: 0;
				margin-top: 1em;
			}
		}
	}
}