.actionModal--is-ready {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0,0,0,0);
	transition: opacity background-color;

	&[aria-hidden="true"] {
		visibility: hidden;
		opacity: 0;
	}

	&[aria-hidden="false"] {
		visibility: visible;
		opacity: 1;
		background-color: $color-opaque-overlay;
	}

	.actionModal-modal {
	    position: absolute;
	    left: 50%;
	    top: 50%;
	    height: 95%;
	    width: 95%;
	    padding: 1rem;
	    transform: translateX(-50%) translateY(-50%);
		background-color: $color-opaque-white-modal;	
	}
}

.actionModal-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 24px;
	height: 24px;
}