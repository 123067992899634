.custom-icon{
	display: inline-block;
	
}

[class^="custom-icon-"] {
	display: inline-block;
	position: relative;
}

$close-icon-size: 30px;

.custom-icon-close {
	display: block;
	margin: 0 auto;
	width: $close-icon-size;
	height: $close-icon-size;
	margin-bottom: 0.2rem;

	&:after,
	&:before {
		@include vertical-align;

		content: '';
		width: $close-icon-size;
		height: 2px;
		background-color: $color-brand-white;
		position: absolute;
		left: 0;
		transform: rotate(0);
		transition: transform 500ms $animationEase 500ms;
	}

	.is-overlay-active & {
		&:after {
			transform: rotate(45deg);
		}
		&:before {
			transform: rotate(-45deg);
		}
	}

	.is-overlay-active-fadeout & {
		&:after,
		&:before  {
			transform: rotate(0deg);
			transition: transform 500ms $animationEase;
		}
	}
}