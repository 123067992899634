// Icon Sizes
// -------------------------

/* makes the font 25% smaller relative to the icon container */
.#{$bt-css-prefix}-sm {
  font-size: .7em;
  vertical-align: baseline;
}
/* makes the font 33% larger relative to the icon container */
.#{$bt-css-prefix}-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
}
.#{$bt-css-prefix}-2x { font-size: 2em; }
.#{$bt-css-prefix}-3x { font-size: 3em; }
.#{$bt-css-prefix}-4x { font-size: 4em; }
.#{$bt-css-prefix}-5x { font-size: 5em; }

.#{$bt-css-prefix}-lg,
.#{$bt-css-prefix}-2x,
.#{$bt-css-prefix}-3x,
.#{$bt-css-prefix}-4x,
.#{$bt-css-prefix}-5x {
  vertical-align: -30%;
}
