.careers-segment-detail {
    .general-content-section {

      
        & + .general-content-section {
            border-top: 1px solid $color-border-alt;
            padding-top: $spacing-unit+$base-line-height/2;
            margin-top: $spacing-unit;
        }
        &__video-container{
            display: flex;
            justify-content: space-around;
            margin-top: 40px;
        }
        &__video-item{
            width: 100%;
            padding: 5px;
            margin-bottom: 20px;
        }


        @include bp-max($bp-tablet-sm) {
            &.column-layout {
                display: flex;
                flex-direction: column;

                .rich-text {
                    width: 100% !important;
                    float: none !important;
                    padding: 0;
                }

                .text-column {
                    order: 1;
                    padding: 0 0 $spacing-unit-block 0 !important;
                }

                .video-embed-column {
                    order: 2;
                }
            }
            .general-content-section{
                &__video-container{                
                    flex-wrap: wrap;
                }
                &__video-item{
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }

        .video-embed-column {
            width: 40%;
            float: left;
        }

        .text-column {
            width: 60%;
            float: left;
            padding: 0 $spacing-unit+$base-line-height/2 0 0;
        }

        &.text-to-right {
            .text-column {
                padding-left: $spacing-unit+$base-line-height/2;
            }
        }
    }

    .banner__button {
        margin-bottom: $spacing-unit;
    }
}
