.featured-hero {
    overflow: hidden;
    transition: height 1s  $animationEase;

    &__wrapper {
        &#{&} {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    
    @include bp-min($bp-tablet-sm) {
        &__wrapper {
            &#{&} {
                padding-top: px-to-rem(48px);
                padding-bottom: px-to-rem(48px);
            }
        }
    }

    @include bp-min($bp-tablet) {
        &__content {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &__main {
            flex-grow: 1;
        }

        &__sidebar {
            width: 288px;
            flex-shrink: 0;
            margin-left: px-to-rem(32px);
        }

        &__wrapper {
            &#{&} {
                padding-bottom: px-to-rem(16px);
            }
        }
    }
}