.location-listing {
	@include clearfix;

	@include bp-min($bp-tablet-sm) {
		margin: 0 -2.5%; // offset padding on .location items
	}

	.location {

		@include bp-min($bp-mobile) {
			float: left;
			width: 50%;
			padding: 0 2.5%;
		}

		@include bp-min($bp-tablet-sm) {
			width: 50%;
			padding: 0 2.5%;
		}

		&:nth-child(2n+1) {
			@include bp-min($bp-mobile-portrait) {
				padding-left: 0;
			}

			@include bp-min($bp-tablet-sm) {
				padding-left: 2.5%;
			}
		}

		&:nth-child(2n) {
			@include bp-min($bp-mobile-portrait) {
				padding-right: 0;
			}

			@include bp-min($bp-tablet-sm) {
				padding-right: 2.5%;
			}
		}

		&:nth-child(3n+1) {
			@include bp-min($bp-tablet-sm) {
				/*clear: left;*/
			}
		}
	}
}

[data-modal] {
	opacity: 1;
	visibility: visible;
	transition: {
		property: opacity;
		duration: .35s;
		timing-function: $animationEase;
		delay: .5s;
	};
	z-index: 1;
}

// disclaimer modal
[data-visible="false"] {
	visibility: hidden;
	opacity: 0;
}

#overlay {
	position: fixed; /* Sit on top of the page content */
	display: none; /* Hidden by default */
	width: 100%; /* Full width (cover the whole page) */
	height: 100%; /* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.25); /* Black background with opacity */
	z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
	cursor: pointer; /* Add a pointer on hover */
}

.boost-disclaimer-modal {
	position: fixed;
	width: 600px;
	left: 50%;
	top: 50%;
	margin-left: -300px;
	padding: $spacing-unit;
	transform: translateY(-50%);
	background-color: white;
	color: $color-text-gray;
	z-index: 100000;

	@include bp-max($bp-mobile) {
		width: 100vw;
		left: 0;
		margin-left: 0;

		.modal__message.rich-text{
			font-size: 1rem;
		}
	}

	.modal__close {
        color: $color-gray-thunder;
		display: inline-block;
        font-size: 18px;
		position: absolute;
		top: 0;
		right: 0;
    }

	.modal__title {
		margin-top: 0.75rem;
		color: $color-gray-thunder;
		margin-bottom: $spacing-unit;
	}

	.modal__message {
		font-weight: $font-weight-light;
		margin-bottom: $spacing-unit;
	}
	
}

.email-disclaimer-modal {
	position: fixed;
	width: 600px;
	// height: 300px;
	left: 50%;
	top: 50%;
	margin-left: -300px;
	padding: $spacing-unit;
	transform: translateY(-50%);
	//background-color: map-get($colors, lightblue);
	//color: map-get($colors, white);

	background-color: $color-brand-white;
	color: $color-text-gray;
	z-index: 100000;

	@include bp-max($bp-mobile) {
		width: 100vw;
		left: 0;
		margin-left: 0;

		.modal__message.rich-text{
			font-size: 1rem;
		}
		.modal__button-group .modal__button {			
			width: calc(50% - 7px);
			padding: .4rem 1rem;			
		}
	}

	.modal__title {
		//color: map-get($colors, white);
		color: $color-gray-thunder;
		margin-bottom: $spacing-unit;
	}

	.modal__message {
		font-weight: $font-weight-light;
		margin-bottom: $spacing-unit;
	}

.modal__button-group {
	text-align: center;

	.modal__button {
		display: inline-block;
	}

	.modal__button + .modal__button {
		margin-left: 1rem;
	}
}

	button {
		text-transform: uppercase;
		letter-spacing: 1px;
		text-rendering: optimizeLegibility;

		&:focus {
			outline: 1px solid yellow;
		}
	}

	[data-action="agree"] {
		background-color: map-get($colors, white);
	}

	[data-action="cancel"] {
		color: map-get($colors, white);
	}
}
