.page-launch {
	$border-style: 2px solid $color-gray-gainsboro;

	border-top: $border-style;
	border-bottom: $border-style;
	display: table;
	width: 100%;
	padding: 1em 0;

	.prompt {
		font-size: px-to-rem(26px);
		color: $color-gray-dim-gray;
		display: table-cell;
		width: 100%;
		vertical-align: middle;

		@include font-accent;
	}

	.button-launch {
		display: table-cell;
		vertical-align: middle;
		white-space: nowrap;
		font-size: px-to-rem(20px);
		padding-left: px-to-rem(20px);
		padding-right: px-to-rem(20px);
	}
}