.list-related {
    $arrow-padding: px-to-rem(20px);

    .service-listing-container & {
        @extend %clearfix;
    }

    .list__item {
        margin-bottom: px-to-rem(4px);
        font-size: px-to-rem(22px);
        line-height: 1.4;
        @include font-accent-bold;
    }

    .list__item--services {
        position: relative;
        height: 100%;
        min-height: 150px;
        display: flex;
        align-items: center;
        @extend %bg-cover;
        @include font-accent();
        font-size: 1.25rem;
        //overlay
        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $color-opaque-gray-overlay;
            z-index: 3;
            opacity: 1;
            transition: opacity .4s ease-in;
        }

        &:hover, &.expanded {
            background-color: transparent;

            &:before {
                opacity: 0.6;
            }
        }

        .list__link {
            background: transparent;
            padding: 3rem 6rem 3rem 3rem;
            position: relative;
            color: $color-brand-white-abs;
            z-index: 5;
            text-transform: uppercase;
            font-size: 1.25rem;

            &:hover {
                background-color: transparent;
            }

            &:after {
                content: none;
            }
        }
    }

    .list__item--services-inner {
        .list__link {
            padding: px-to-rem(15px) 0 px-to-rem(15px) 0;
            font-size: 1.25rem;
            @include font-accent();
            color: $color-brand-blue;

            &:hover {
                color: $color-blue-dodger-blue;
            }
        }
    }

    .list__link {
        display: block;
        background-color: $color-brand-white-abs;
        padding: px-to-rem(20px) px-to-rem(45px) px-to-rem(20px) px-to-rem(30px);
        position: relative;

        @include bi-icon-after($bi-arrow-right) {
            @include vertical-align;
            color: $color-list-arrow;
            position: absolute;
            right: $arrow-padding;
        }

        &:hover {
            color: $color-brand-light;
            background-color: $color-links;

            &:after {
                color: $color-brand-light;
            }
        }
    }

    .list__link--services {
        background: transparent;
        color: $color-gray-dim-gray;

        &:hover {
            background-color: transparent;
        }

        &:after {
            content: none;
        }
    }
}
