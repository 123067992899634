.stats-module {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // align-items: flex-end;
    margin-top: 64px;
    &.three-stats {
        .stats-module__wrapper {
            width: calc(33.3333% - 24px);
        }
    }
    &__wrapper {
        width: calc(50% - 24px);
        height: 100%;
        max-width: 520px;
        margin-bottom: 50px;
        
    }

    &__description {
        font-family: $font-family-secondary;
        font-size: 32px;
        line-height: 1.15;
        font-weight: $font-weight-medium;
        color: $color-brand-black;
        margin-top: 8px;
    }

    .counter {
        color: $color__green;
        font-family: $font-family-secondary;
        font-weight: $font-weight-regular;
        font-size: 70px;
        line-height: 1;
        letter-spacing: 8px;
    }
    .counter-symbol {
        color: $color__green;
        font-family: $font-family-secondary;
        font-weight: $font-weight-regular;
        font-size: 70px;
        line-height: 1;
        letter-spacing: 8px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
    }
    &__icon {
        max-height: 120px;
    }

    &--small-container {
        .section-wrapper__container {
            max-width: calc(828.889px + 3rem);
        }
    }

    @include bp-max($bp-tablet-sm) {
        display: block;

        &__wrapper {
            width: 100%;
            max-width: 280px;
            margin: 0 auto 32px;
            text-align: center;
        }

        &__icon {
            display: block;
            margin: 0 auto;
        }

        &__description {
            text-align: center;
            font-size: 20px;
            line-height: 20px;
            margin-top: 16px;
        }

        .counter {
            text-align: center;
        }
        &.three-stats {
            .stats-module__wrapper {
                width: calc(100% - 24px);
            }
        }
    }
}