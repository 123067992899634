// List Icons
// -------------------------

.#{$bt-css-prefix}-ul {
  padding-left: 0;
  margin-left: $bt-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{$bt-css-prefix}-li {
  position: absolute;
  left: -$bt-li-width;
  width: $bt-li-width;
  top: (2em / 14);
  text-align: center;
  &.#{$bt-css-prefix}-lg {
    left: -$bt-li-width + (2em / 14);
  }
}
