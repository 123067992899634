// Base Class Definition
// -------------------------

.bts, .btb, .btr, .btl, .fab {
  display: inline-block;
  font: normal normal normal #{$bt-font-size-base}/1 "Black Tie"; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  vertical-align: (-100%*2/14);
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}

.bts  { font-weight: 900; }
.btb  { font-weight: 700; }
.btl  { font-weight: 200; }
.fab { font-family: "Font Awesome Brands"; }
