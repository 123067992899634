﻿@charset "utf-8";
$max-width: 75em; // 1200px / 16px

$a-tags: 'a, a:link, a::visited';
$a-tags-hover: 'a:hover, a:active';

a {
    // color: map-get($colors, blue);
    color: $color-links;
    text-decoration: none;
    transition: $base-animation;

    &:before,
    &:after {
        transition: $base-animation;
    }

    &:hover, &:active, &:focus {
        outline: 0;
    }

}

a:hover {
    color: mix(map-get($colors, lightblue), map-get($colors, dark), 95%);
}


* {
    @include borderBox;

    &:before,
    &:after{
        @include borderBox;
    }
}

body,html {
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-text-rich-text;
}

body {
    &:before {
        content: '';
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(map-get($colors, dark), 1);
        z-index: -1;
        opacity: 0;
        transform: translateZ(0);
        transition: opacity .35s;
    }
    &[data-modal="true"] { 
        &:before {
            opacity: .8;
            z-index: 0;
        }
    }

    &:not(#homepage){
        max-width: 130rem;
        margin: 0 auto;
    }
}

p {
    margin-bottom: $spacing-unit;
    margin-top: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

img {
    max-width: 100%;
}

address {
    font-style: normal;
    margin-bottom: $spacing-unit;
}

time {
    display: block;
}

.main-content {
    padding: 1em;
    overflow: hidden;
}

.rich-text blockquote,
.blockquote {
    border-left: px-to-rem(20px) solid $color-gray-gainsboro;
    margin:$spacing-unit-block 0;
    padding-left: px-to-rem(60px);
    font-size: px-to-rem(32px);
    font-style: italic;
    line-height: 1.2;
    position: relative;
    color: $color-text-dark;
    line-height: 1.4;

    @include font-accent;

    &:before {
        content:'”';
        position: absolute;
        left: 1.5em;
    }

    &:after {
         content: '”';
    }

    > p {
        margin-bottom: 0;
    }

    cite {
        font-size: px-to-rem(24px);
        font-style: normal;
        display: block;
        margin-top: $spacing-unit/2;
    }
}

.rich-text {
    ol {
        li{
           &:before{
            content: none;
           } 
        }
    }
}

.people-quote-section {

    blockquote {
        margin-bottom: 0;
    }

    .quote-source {
        border-left: px-to-rem(20px) solid $color-gray-gainsboro;
        padding-left: px-to-rem(60px);
        padding-top: px-to-rem(20px);
    }
}


.title-border{
    margin-top: $spacing-unit;
}

ul {
    list-style: none;
    margin: 0 0 $spacing-unit;
}

.show-mobile{
    display: none !important;
}
@include bp-max($bp-mobile) {
    .show-mobile{
        display: inline-block !important;
    }
    .show-desktop{
        display: none !important;
    }
}

[v-cloak] {
    display: none !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
