.careers__hero {
    width: 100%;
    background-size: cover;
    position: relative;
    $box-shadow: -5px 25px 25px 0 rgba(0,0,0,.15);
    margin-bottom: px-to-rem(125px);
    margin-top: 125px;

    @include bp-max($bp-tablet) {        
        margin-top: 86px;
    }

    @include bp-max($bp-tablet-sm) {
        margin-bottom: 6rem;        
    }

    .careers__business-card {
        width: 100%;
        height: 100%;
        box-shadow: $box-shadow;
        padding: 20px;
        padding-left: 25px;
        position: relative;
        cursor: pointer;

        &:hover {
            .careers__business-card-name {
                text-decoration: underline;
            }
        }

        &-wrapper {
            position: absolute;
            bottom: -40px;
            z-index: 10;
            background-image: url('/assets/site/Main/public/images/careers/whiteTexture.jpg');
            background-size: cover;
            max-width: 500px;
            width: 40%;
            right: 20px;
            height: auto;
            top: inherit;
            left: inherit;
        }

        &-name {
            position: relative;
            margin-top: px-to-rem(40px);
            height: 47px;
            display: block;
            text-align: left;
            color: $color-brand-green;
            font-size: px-to-rem(38px);
            text-transform: uppercase;
            font-family: $font-family-titles;
            font-weight: bold;

            a {
                color: $color-brand-green;
            }

            .name-hidden {
                // opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                color: transparent;
                // height: 100%;
            }

            .name-input {
                background-color: transparent;
                color: $color-brand-green;
                font-size: px-to-rem(38px);
                text-transform: uppercase;
                font-family: $font-family-titles;
                font-weight: bold;
                border: none;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                width: 100%;
                overflow: visible;

                &::placeholder {
                    color: $color-brand-green;
                }

                &:focus {
                    // display: none;
                    color: transparent;
                    text-shadow: 0 0 0 $color-brand-green;

                    & ~ .name-hidden .name-blinker {
                        display: inline-block;
                        position: absolute;
                        width: 1px;
                        height: px-to-rem(70px);
                        background-color: $color-brand-green;
                        animation-name: blink;
                        animation-duration: 800ms;
                        animation-iteration-count: infinite;
                        opacity: 1;
                        margin-left: 1px;
                        transform: translateY(-15%);
                    }
                }
            }
        }

        &-address {
            position: relative;
            margin-top: px-to-rem(40px);
            display: block;
            text-align: right;
            font-family: $font-family-titles;
            font-size: px-to-rem(18px);
            color: black;

            address {
                margin-bottom: 0;
            }

            a {
                span {
                    color: $color-brand-green;
                    margin-right: 2px;
                }

                margin-left: 10px;
                color: black;

                &.careers__business-card-website {
                    color: $color-brand-green;
                }
            }
        }
    }

    .careers__carousel-slide {
        max-height: 620px;
    }

    .careers__carousel-pagination {
        display: none;
    }

    .careers__carousel-arrows {
        display: none;
    }

    .swiper-container {
        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(48,72,91,0) 50%, rgba(48,72,91,0.7) 100%);
            position: absolute;
            z-index: 3;
        }
    }

    &-names {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30%;
        z-index: 3;
        padding-right: 12px;
        text-align: right;
        overflow: visible;

        &--title {
            font-family: $font-family-handwritten;
            font-size: px-to-rem(42px);
            color: white;
        }

        &--container {
            margin-bottom: 3px;
        }

        &--item {
            font-size: px-to-rem(46px);
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font-family-titles;
            background-color: $color-brand-green;
            padding: 0 10px;
            color: rgba(255,255,255,.5);
            position: relative;
            cursor: pointer;
            transition: color 0.2s ease-in-out;

            &:after {
                content: '';
                display: block;
                width: 0;
                max-width: 0;
                background-color: $color__green--light;
                height: px-to-rem(7px);
                position: absolute;
                left: calc(100% - 8px);
                top: calc(50% - 3px);
                transition: max-width 0.2s ease-in-out;
            }

            &:hover {
                color: white;
            }

            &.active {
                color: white;

                &:after {
                    width: px-to-rem(90px);
                    max-width: px-to-rem(90px);
                }
            }

            &.you-active {
                color: white;

                &:after {
                    width: 56rem;
                    max-width: 56rem;
                    height: 0;
                    background-color: transparent;
                    border-top: 6px dashed $color__green--light;
                }
            }
        }

        span {
            display: inline-block;
        }
    }

    @include bp-max($bp-desktop-large) {
        .careers__hero-names--item.you-active:after {
            width: 30rem;
            max-width: 30rem;
        }
    }

    @include bp-max($bp-tablet) {
        .careers__hero-names {
            &--title {
                font-size: px-to-rem(32px);
            }

            &--item {
                font-size: px-to-rem(46px);
            }
        }

        .careers__business-card-name {
            font-size: 1.8rem;
            line-height: 1.1;

            .name-input {
                font-size: 1.8rem;
                line-height: 1.6;
            }
        }
    }

    @include bp-max($bp-mobile) {
        height: auto;
        margin-bottom: px-to-rem(30px);

        .careers__carousel-slide {
            max-height: 400px;
        }

        .careers__carousel-pagination {
            display: block;
            position: absolute;
            bottom: 20px !important;
        }

        .careers__carousel-arrows {
            display: none;
        }

        .careers__hero-names {
            display: block;
            top: calc(100% - 100px);
            left: 10px;
            width: 40%;

            &--title {
                display: block;
            }

            &--container {
                display: inline-block;
            }

            &--item {
                display: none;

                &.active,
                &.you-active {
                    display: block;
                }

                &:after {
                    display: none;
                }
            }
        }

        .careers__business-card {
            &-wrapper {
                position: relative;
                width: 90%;
                top: -14px;
                right: 0px;
                height: auto;
                margin: 0 auto;
            }
        }
    }
}

@keyframes blink {
    from {  opacity: 1; }
    to { opacity: 0; }
}