.awards-module {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .app-dei & {
        align-items: flex-start;
    }
    

    &__award {
        height: 100%;
        width: calc(50% - 16px);
        display: flex;
        margin-bottom: 24px;
    }

    &__image {
        height: 152px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 216px;
        margin-right: 24px;
    }

    &__content-wrapper {
        width: 50%;

        > img {
            height: 35px;
        }
    }

    &__stat {
        color: $color__green;
        font-family: $font-family-secondary;
        font-weight: $font-weight-regular;
        font-size: 32px;
        line-height: 1;
    }

    &__title {
        font-family: $font-family-secondary;
        font-weight: $font-weight-medium;
        color: $color-brand-black;
        font-size: 24px;
        line-height: 25px;
        margin: 6px 0;
    }

    &__subtitle {
        font-family: $font-family-secondary;
        font-weight: $font-weight-medium;
        font-size: 20px;
        color: $color-brand-black;
    }

    &__link {
        font-family: $font-family-secondary;
        font-weight: $font-weight-regular;
        color: $color__green;
        font-size: 20px;
    }

    @include bp-max($bp-tablet-sm) {
        display: block;
        padding-top: 24px;

        &__award {
            width: 100%;
            margin-bottom: 24px;
        }

        &__image {
            height: 87px;
            width: 50%;
        }

        &__content-wrapper {
            width: 80%;
            margin-left: 12px;
        }

        &__title {
            font-size: 20px;
            line-height: 20px;
        }

        &__link {
            font-size: 16px;
        }
    }
}