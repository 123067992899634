$font-size-details: 20px;

.location {
	margin-bottom: $spacing-unit-block;

	img[data-src] {
		opacity: 0;
	}
}

.location-link--complex {
	display: block;
	margin-bottom: $spacing-unit-small;

	img{
		height: 136px;
		width: 372px;
	}
}

.location-name {
    font-family: "brandon-grotesque",sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .05rem;
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: $spacing-unit-small;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}


.location-image {
	display: block;
	margin-bottom: $spacing-unit;
	opacity: 1;
	transition: opacity 0.25s ease-in;
}

.location-image--thumb {
	min-height: 180px;
	max-height: 180px;
	height: auto;
	width: 100%;
}

.location-address{
		color: $color-opaque-dark-gray;
		margin: 10px 0px;
		.location-address__line{
			margin-bottom: 0;
		}
		display: block; 
}

.location__city {

	&:after {
		content: ', ';
		display: inline-block;
	}
}

.location__professional{
	margin-top: 20px;

	.professional{
		margin-top:10px;

		.professional__title,
		.professional__phone,
		.professional__email{
			margin-bottom: 0px;
			display: block;
		}
	}

}

.location-phone__number,
.location-email {
	$icon-spacing: 1.5em;

	font-size: px-to-rem($font-size-details);
	color: $color-blue-regent-blue;
	margin-left: $icon-spacing;
	position: relative;
	@include font-accent;

	@include bi-icon-before($bi-interface-direction) {
		position: absolute;
		left: $icon-spacing * -1;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1em;
	}
}


.location-phone__number {
	color: $color-opaque-dark-gray;

	&:before {
		content: $bi-com-phone;
		transform: rotate(-270deg);
		transform-origin: 70% 30%;
	}
}

.location-email {
	display: inline-block;
	color: $color-blue-dodger-blue;

	&:before {
		content: $bi-com-email;
	}

	&:after{
		content: '|';
		color: $color-opaque-dark-gray;
		display: inline-block;
		margin-right:  px-to-rem(8px);
		padding-left:  px-to-rem(8px);
	}
}
