.people-additionalSearch__title {

    button {
        color: $color-blue-waikawa-gray;
        display: block;
        font-family: $font-family-secondary;
        font-size: px-to-rem(15px);
        font-weight: $font-weight-medium;
        margin-bottom: 1.55rem;
        margin-left: auto;
        padding-left: 0;
        padding-right: 0;
        text-transform: uppercase;
        @media (min-width: px-to-rem(600px)) {
            font-size: px-to-rem(20px);
        }
        &:hover {
            color: mix(map-get($colors, lightblue), map-get($colors, dark), 95%);
        }

        @include bp-max($bp-mobile) {
            margin-left: 0;
        }
    }
}

.people-additional-search-btn {
    color: $color-blue-waikawa-gray;
    display: block;
    font-family: $font-family-secondary;
    font-size: px-to-rem(15px);
    font-weight: $font-weight-medium;
    margin-bottom: 1.55rem;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    text-transform: uppercase;
    @media (min-width: px-to-rem(600px)) {
        font-size: px-to-rem(20px);
    }
    &:hover {
        color: mix(map-get($colors, lightblue), map-get($colors, dark), 95%);
    }
}

.additional-search-open {
    display: none;
}
