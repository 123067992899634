.checkbox-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.checkbox-list--stacked {
	@include clearfix;

	.checkbox-list__item {
		float: left;
		width: 25%;

		&:nth-child(4n+1) {
			clear: left;
		}

		@include bp-max($bp-mobile) {
        	width: 100%;
			float: none;
    	}
	}
}

.checkbox-list__item {
	padding: 0 1rem 0.5rem 1rem;
}

.checkbox-label {
	@include clearfix;
	display: inline-block;
}

.checkbox-label__input,
.checkbox-label__text {
    display: inline-block;
    float: left;
    line-height: 1.2;
    cursor: pointer;
}

.checkbox-label__input {
	width: 1rem;
	margin-left: -1rem; // pull back left equal to left padding on __item
	margin-top: 1px;
}

.checkbox-label__text {
	padding-left: 4px;
}

