$grid-item-gutter: 1rem !default;

.l-grid {
	@include clearfix;
}

.l-grid__item {
	width: 100%;
}

.l-grid__item--centered {
	text-align: center;
}

.l-grid__item1\/3\@lg {

	@include bp-min($bp-mobile) {
		float: left;
		width: 33.3333%;
		padding-right: $grid-item-gutter;
	}

	&:nth-child(3n) {
		padding-right: 0;
	}
}