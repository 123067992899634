.site-brand {
	float: left;
	height: 100%;
	z-index: 5;

	@include bp-max($bp-hamburger) {
		position: fixed;
		width: 100%;
		float: none;
		top: 0;
		left: 0;
		right: 0;
		height: 7%;
		background-color: $color-opaque-white;
		z-index: $zindex-header + 5;
	}

	@include bp-max($bp-mobile) {
		height: px-to-rem($site-header-height-mobile);
	}

	.site-header-wrapper {
		display: table;
		height: 100%;

		@include bp-max($bp-hamburger) {
			display: block;
			@include vertical-align;
			@include clearfix;

			height: auto;
			padding: 0 $site-padding;
		}

		@include bp-max($bp-tablet) {
			padding: 0 $site-padding-tablet;
		}

		@include bp-max($bp-mobile) {
			padding: 0 $site-padding-mobile;
		}

		.site-brand__logo--interior {
			vertical-align: middle;
		}
	}
}

.site-brand__logo {
	display: block;
	height: 50px;

	@include bp-max($bp-scrollspy) {
		height: 40px;
	}
}

//dont show homepage logo on interiors


.site-brand__title {
	/* @include vertical-align; */
	position: relative;
	margin: 0;
	display: table-cell;
	vertical-align: middle;
	width: px-to-rem($site-header-width);
	z-index: 10;

	@include bp-max($bp-hamburger) {
		@include reset-vertical-align;

		float: left;
		position: static;
		top: auto;
	}


	@include bp-max($bp-mobile) {
		width: px-to-rem($site-logo-width-mobile);
	}
}