.subheader-nav {
    @include clearfix;
    
    background-color: $color-brand-green;
    font-family: $font-family-secondary;
    font-size: px-to-rem(18px);
    line-height: 1.3;
    margin: 0 auto;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    padding-bottom:1rem;


    @include bp-max($bp-tablet) {
        padding-top: 1rem;
    }

    &.expanded {
        padding-bottom: 0;
    }

    .active-page-mobile-title {
        @include bp-min($bp-tablet) {
          display: none;
        }

        text-align: center;
        cursor: pointer;

        @include bi-icon-after($bi-arrow-bottom) {
          margin-left: .5em;
          display: inline-block;
          transition: transform .4s;
          transform: rotateX(0deg);
        }

        &.show-slide-down {
            + .subnav-list {
                padding-bottom: 0;
            }

            &:after {
              transform: rotateX(180deg);
            }
        }
    }

    .active-page {
        .nav-inner-text {

            &:before  {
                height: 1px;
                opacity: 1;
                transform: translateY(0px);    
            }

            &:after {
                width: 0;
                height: 0;
                content:'';
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $color-brand-green;
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);

                @include bp-max($bp-mobile) {
                    display: none;
                }
            }
        }
    }

    .subnav-list {
        @include font-secondary-light;

        padding-left: px-to-rem(10px);
        padding-right: px-to-rem(10px);
        margin: auto;

        @include bp-max($bp-tablet) {
          display: none;
          padding: px-to-rem(30px) 0 0;
          margin: 0;
        }

        .list__item {
            
            @include bp-max($bp-tablet) {
                display: block;
                float: none;
                text-align: center;
                padding: px-to-rem(10px) 0;
            }

            + .list__item {
                &:before {
                    content: '|';
                    padding-right: .5rem;
                    padding-left: .3rem;
                    display: inline-block;
                    vertical-align: text-top;
                    font-weight: $font-weight-light;
                    line-height: 1;

                    @include bp-max($bp-tablet) {
                        content: '';
                        padding: 0;
                    }
                }
            }

            &:last-child {
                @include bp-max($bp-tablet) {
                    padding-bottom: px-to-rem(20px);
                }
            }
        }
    }

    .nav-inner-text {
        display: inline-block;
        position: relative;
        padding-top: 1rem;
        color: $color-brand-white-abs;
        cursor: pointer;
        z-index: 5;
        
        @include bp-max($bp-tablet) {
            padding-top: 0;
        }

        &:hover {
            position: relative;

            &:before  {
                height: 1px;
                opacity: 1;
                transform: translateY(0px);    
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-brand-white-abs;
            opacity: 0;
            transition: height 0.3s, opacity 0.3s, transform 0.3s;
            transform: translateY(-10px);
        }
    }
}