.homepage-banner {
    background-color: $color__green;
    color: $color__gray--light;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 24px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    &__msg-wrapper {
        font-family: $font-family-secondary;
    }

    &__message {
        font-size: 12px;
        line-height: 1.1;
        margin-right: 16px;
        display: inline;
    }

    &__link {
        font-size: 12px;
        line-height: 13px;
        font-weight: 700;
        text-decoration: underline;
        color: $color__gray--light !important;
        display:inline-block;
    }

    &__btn-close {
        padding: 0;
        height: 19px;
    }

    @include bp-max($bp-tablet-sm) {

        &__msg-wrapper {
            display: block;
        }
    }
}