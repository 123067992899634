.subscriptions { }

.subscriptions-heading { 
	text-transform: uppercase;
	color: $color-brand-green;
}

.subscriptions-message {
	margin-bottom: 1rem;
}

.subscriberInfo {
	@include clearfix;
}

.subscriptionSection {
	@include clearfix;
}

.subscriptionSection-heading {
	text-transform: uppercase;
	color: $color-gray-thunder;
}