.related-items {

}

.related-items__no-results {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 7px;
  font-family: "Quattrocento", serif;
  padding: 5px;
  margin: 5px;
  border: 1px solid rgb(255, 204, 51);
  background-color: rgb(255, 244, 194);
}