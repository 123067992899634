.site-full-nav {
	text-align: right;
	height: 100%;

	@include bp-max($bp-hamburger) {
		position: fixed;
		background-color: $color-opaque-dark-gray;
		opacity: 0;
		visibility: hidden;
		height: 0;
		width: 100%;
		overflow: hidden;
		text-align: center;
		z-index: $zindex-header;
		transition: height .5s $animationEase,
			opacity .5s $animationEase .5s,
			visibility .5s $animationEase .5s;
	}

	@include bp-max-orientation() {
		overflow-y: auto;
	}


	.is-active-mobile-menu &,
	#homepage.is-active-mobile-menu & {

		height: 100vh;
		visibility: visible;
		opacity: 1;
		transition: height .5s $animationEase;
	}

	.site-header-wrapper {
		@include vertical-align;
		position: relative;

		.is-active-mobile-menu & {
			opacity: 1;
			transition: opacity .3s $animationEase .2s;
		}
	}


	//search icon
	.button--icon {
		font-size: px-to-rem(16px);
		position: absolute;
		top: 0;
		right: 0;
		color: $color-brand-white;

		&:hover {
			color: $color-brand-black;
		}


		@include bp-max($bp-hamburger) {
			right: auto;
			left: $site-padding-tablet;
			top: 10vh;
			margin-top: px-to-rem(30px);
			transform: translate(0);
			width: 90%;
			font-size: px-to-rem(20px);
			color: $color-brand-white;
			text-align: left;

			&:before {
				content: 'Search';
				font-family: $font-family-accent;
				font-weight: $font-weight-light;
				display: inline-block;
				position: absolute;
				left: 40px;
				-webkit-font-smoothing: antialiased;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 120%;
				width: 100%;
				border-bottom: 1px solid $color-brand-white;
			}
		}

		@include bp-max($bp-mobile) {
			top: $site-header-height-mobile;
			margin-top: px-to-rem(10px);
		}

		@include bp-max-orientation() {
			margin-top: 0;
		}
	}
}