.topic-detail{
  .page-body{
    .rich-text{
      margin-bottom: 44px;
    }
  }

  &__tag-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }

  &__select-tag {
    padding-right: 1rem;
    text-transform: none;
    margin-top: 0.5rem;
  }

  &__tags {
    cursor: pointer;
    background-color: #f5f5f5;
    color: $color-brand-green;
    padding: 6px 15px;
    border-radius: 15px;
    font-weight: 900;
    margin-right: 0.7rem;
    margin-bottom: 0.7rem;
    font-size: 1rem;
    outline: none;
    align-self: center;
    transition: color 0.25s ease-in, background-color 0.25s ease-in;

    &-section {
      flex: 1 0 70%;
    }

    & + .topic-detail__tags {
      margin-left: 0;
    }

    &:hover,
    &--active {
      background-color: $color-brand-green;
      color: $color-brand-white;
    }
  }

  &__clear {
    cursor: pointer;
    text-decoration: underline;
    float: right;
    font-size: 1.3rem;
    font-weight: 400;
    align-self: flex-end;
  }

  @include bp-max($bp-tablet-sm) {
    &__tags {

      &-section {
        flex: 1 1 auto;
      }
    }

    &__clear {
      width: 100%;
      text-align: end;
    }
  }
}
