#homepage {
	background-color: $color-brand-white-abs;

	#app {
		background-image: none;
	}

	.site-header {
		&--with-banner {
			// padding-top: 51px;

			@include bp-max($bp-tablet-sm) {
				// padding-top: 86px;
			}
		}
	}

	.site-overlay {
		>.site-layout-wrapper {
			position: relative;
			max-width: $site-max-width;
			margin: 0 auto;
			padding: 0 $site-padding;
		}
	}

	.site-header-wrapper {
		display: block;
		height: auto;
		max-width: $site-max-width;
		margin: 0 auto;
		padding: 0 $site-padding;

		@include bp-max($bp-tablet) {
			padding: 0 $site-padding-tablet;
			width: 100%;
		}

		@include bp-max($bp-mobile) {
			padding: 0 $site-padding-mobile;
		}
	}

	//shows white version of logo

	.site-brand__title {
		@include reset-vertical-align;

		@include bp-max($bp-hamburger) {
			position: static;
			height: auto;
			bottom: auto;
		}
	}

	.site-brand__logo-link {
		@include vertical-align;

		display: inline-block;
		transform: translateY(50%);

		@include bp-max($bp-hamburger) {
			@include reset-vertical-align;
		}
	}

	/*==================================
	=            Site Navigation
	==================================*/

	.site-full-nav {

		.button--icon {
			right: $site-padding;
			color: $color-brand-white;
		}
	}

	.site-footer {
		z-index: 100;
		position: relative;
		width: 100%;
		min-height: 15%;
	}


	.site-nav__link,
	.subnav__link {
		color: $color-brand-white;

		&:hover {
			color: $color-brand-white;
		}
	}
}