// Variables
// --------------------------

$bt-font-path:        "/assets/site/Main/public/fonts" !default;
$bt-font-size-base:   14px !default;
$bt-css-prefix:       bt !default;
$brands-css-prefix:   fab !default;
$bt-version:          "1.0.0" !default;
$bt-border-color:     #eee !default;
$bt-inverse:          #fff !default;
$bt-li-width:         (30em / 14) !default;

/**
 *
 * only Font brans is being used, scroll down for variables
 *
 */

// $bt-var-bars: "\f000";
// $bt-var-envelope: "\f001";
// $bt-var-search: "\f002";
// $bt-var-search-plus: "\f003";
// $bt-var-search-minus: "\f004";
// $bt-var-phone: "\f005";
// $bt-var-comment: "\f007";
// $bt-var-commenting: "\f008";
// $bt-var-comments: "\f009";
// $bt-var-rss: "\f00a";
// $bt-var-times: "\f00c";
// $bt-var-times-circle: "\f00d";
// $bt-var-clock: "\f00e";
// $bt-var-star: "\f010";
// $bt-var-star-half: "\f011";
// $bt-var-check: "\f012";
// $bt-var-check-circle: "\f013";
// $bt-var-check-square: "\f014";
// $bt-var-th: "\f015";
// $bt-var-th-large: "\f016";
// $bt-var-heart: "\f017";
// $bt-var-heart-half: "\f018";
// $bt-var-calendar: "\f019";
// $bt-var-shopping-cart: "\f01a";
// $bt-var-plus: "\f01b";
// $bt-var-plus-circle: "\f01c";
// $bt-var-plus-square: "\f01d";
// $bt-var-pen: "\f01e";
// $bt-var-minus: "\f021";
// $bt-var-minus-circle: "\f022";
// $bt-var-minus-square: "\f023";
// $bt-var-pencil: "\f024";
// $bt-var-edit: "\f025";
// $bt-var-thumbs-up: "\f026";
// $bt-var-thumbs-down: "\f027";
// $bt-var-gear: "\f028";
// $bt-var-trash: "\f029";
// $bt-var-file: "\f02a";
// $bt-var-info-circle: "\f02b";
// $bt-var-label: "\f02c";
// $bt-var-rocket: "\f02d";
// $bt-var-book: "\f02e";
// $bt-var-book-open: "\f02f";
// $bt-var-notebook: "\f030";
// $bt-var-camera: "\f031";
// $bt-var-folder: "\f032";
// $bt-var-quote-left: "\f036";
// $bt-var-quote-right: "\f037";
// $bt-var-eye: "\f038";
// $bt-var-lock: "\f039";
// $bt-var-lock-open: "\f03a";
// $bt-var-gift: "\f03b";
// $bt-var-spinner-clock: "\f03c";
// $bt-var-spinner: "\f03d";
// $bt-var-wrench: "\f03e";
// $bt-var-cloud: "\f040";
// $bt-var-cloud-upload: "\f041";
// $bt-var-cloud-download: "\f042";
// $bt-var-sync: "\f043";
// $bt-var-question-circle: "\f044";
// $bt-var-share: "\f045";
// $bt-var-briefcase: "\f046";
// $bt-var-money: "\f047";
// $bt-var-megaphone: "\f048";
// $bt-var-sign-in: "\f049";
// $bt-var-sign-out: "\f04a";
// $bt-var-film: "\f04b";
// $bt-var-trophy: "\f04c";
// $bt-var-code: "\f04d";
// $bt-var-light-bulb: "\f04e";
// $bt-var-print: "\f050";
// $bt-var-fax: "\f051";
// $bt-var-video: "\f052";
// $bt-var-signal: "\f053";
// $bt-var-sitemap: "\f054";
// $bt-var-upload: "\f055";
// $bt-var-download: "\f056";
// $bt-var-key: "\f057";
// $bt-var-mug: "\f058";
// $bt-var-bookmark: "\f059";
// $bt-var-flag: "\f05a";
// $bt-var-external-link: "\f05b";
// $bt-var-smile: "\f05c";
// $bt-var-frown: "\f05d";
// $bt-var-meh: "\f05e";
// $bt-var-magic: "\f060";
// $bt-var-bolt: "\f061";
// $bt-var-exclamation-triangle: "\f062";
// $bt-var-exclamation-circle: "\f063";
// $bt-var-flask: "\f064";
// $bt-var-music: "\f065";
// $bt-var-push-pin: "\f066";
// $bt-var-shield: "\f067";
// $bt-var-sort: "\f068";
// $bt-var-reply: "\f069";
// $bt-var-forward: "\f06a";
// $bt-var-reply-all: "\f06b";
// $bt-var-forward-all: "\f06c";
// $bt-var-bell: "\f06d";
// $bt-var-bell-off: "\f06e";
// $bt-var-ban: "\f070";
// $bt-var-database: "\f071";
// $bt-var-hard-drive: "\f072";
// $bt-var-merge: "\f073";
// $bt-var-fork: "\f074";
// $bt-var-wifi: "\f075";
// $bt-var-paper-plane: "\f076";
// $bt-var-inbox: "\f077";
// $bt-var-fire: "\f078";
// $bt-var-play: "\f079";
// $bt-var-pause: "\f07a";
// $bt-var-stop: "\f08b";
// $bt-var-play-circle: "\f07b";
// $bt-var-next: "\f07c";
// $bt-var-previous: "\f07d";
// $bt-var-repeat: "\f07e";
// $bt-var-fast-forward: "\f080";
// $bt-var-fast-reverse: "\f081";
// $bt-var-volume: "\f082";
// $bt-var-volume-off: "\f083";
// $bt-var-volume-up: "\f084";
// $bt-var-volume-down: "\f085";
// $bt-var-maximize: "\f086";
// $bt-var-minimize: "\f087";
// $bt-var-closed-captions: "\f088";
// $bt-var-shuffle: "\f089";
// $bt-var-triangle: "\f08a";
// $bt-var-square: "\f08b";
// $bt-var-circle: "\f08c";
// $bt-var-hexagon: "\f08d";
// $bt-var-octagon: "\f08e";
// $bt-var-angle-up: "\f090";
// $bt-var-angle-down: "\f091";
// $bt-var-angle-left: "\f092";
// $bt-var-angle-right: "\f093";
// $bt-var-angles-up: "\f094";
// $bt-var-angles-down: "\f095";
// $bt-var-angles-left: "\f096";
// $bt-var-angles-right: "\f097";
// $bt-var-arrow-up: "\f098";
// $bt-var-arrow-down: "\f099";
// $bt-var-arrow-left: "\f09a";
// $bt-var-arrow-right: "\f09b";
// $bt-var-bar-chart: "\f09c";
// $bt-var-pie-chart: "\f09d";
// $bt-var-circle-arrow-up: "\f0a0";
// $bt-var-circle-arrow-down: "\f0a1";
// $bt-var-circle-arrow-left: "\f0a2";
// $bt-var-circle-arrow-right: "\f0a3";
// $bt-var-caret-up: "\f0a4";
// $bt-var-caret-down: "\f0a5";
// $bt-var-caret-left: "\f0a6";
// $bt-var-caret-right: "\f0a7";
// $bt-var-long-arrow-up: "\f0a8";
// $bt-var-long-arrow-down: "\f0a9";
// $bt-var-long-arrow-left: "\f0aa";
// $bt-var-long-arrow-right: "\f0ab";
// $bt-var-Bold: "\f0ac";
// $bt-var-italic: "\f0ad";
// $bt-var-underline: "\f0ae";
// $bt-var-link: "\f0b0";
// $bt-var-paper-clip: "\f0b1";
// $bt-var-align-left: "\f0b2";
// $bt-var-align-center: "\f0b3";
// $bt-var-align-right: "\f0b4";
// $bt-var-align-justify: "\f0b5";
// $bt-var-cut: "\f0b6";
// $bt-var-copy: "\f0b7";
// $bt-var-paste: "\f0b8";
// $bt-var-photo: "\f0b9";
// $bt-var-table: "\f0ba";
// $bt-var-ulist: "\f0bb";
// $bt-var-olist: "\f0bc";
// $bt-var-indent: "\f0bd";
// $bt-var-outdent: "\f0be";
// $bt-var-undo: "\f0c0";
// $bt-var-redo: "\f0c1";
// $bt-var-sup: "\f0c2";
// $bt-var-sub: "\f0c3";
// $bt-var-text-size: "\f0c4";
// $bt-var-text-color: "\f0c5";
// $bt-var-remove-formatting: "\f0c6";
// $bt-var-blockquote: "\f036";
// $bt-var-globe: "\f0c7";
// $bt-var-map: "\f0c8";
// $bt-var-map-arrow: "\f0c9";
// $bt-var-map-marker: "\f0ca";
// $bt-var-map-pin: "\f0cb";
// $bt-var-home: "\f0cc";
// $bt-var-building: "\f0cd";
// $bt-var-industry: "\f0ce";
// $bt-var-desktop: "\f0d0";
// $bt-var-laptop: "\f0d1";
// $bt-var-tablet: "\f0d2";
// $bt-var-mobile: "\f0d3";
// $bt-var-tv: "\f0d4";
// $bt-var-radio-checked: "\f0d5";
// $bt-var-radio-unchecked: "\f08c";
// $bt-var-checkbox-checked: "\f014";
// $bt-var-checkbox-unchecked: "\f08b";
// $bt-var-checkbox-intermediate: "\f023";
// $bt-var-user: "\f0d6";
// $bt-var-user-male: "\f0d6";
// $bt-var-user-female: "\f0d7";
// $bt-var-crown: "\f0d8";
// $bt-var-credit-card: "\f0d9";
// $bt-var-strikethrough: "\f0da";
// $bt-var-eject: "\f0db";
// $bt-var-ellipsis-h: "\f0dc";
// $bt-var-ellipsis-v: "\f0dd";

$fab-var-facebook: "\f000";
$fab-var-facebook-alt: "\f001";
$fab-var-twitter: "\f002";
$fab-var-linkedin: "\f003";
$fab-var-linkedin-alt: "\f004";
$fab-var-instagram: "\f005";
$fab-var-github: "\f006";
$fab-var-github-alt: "\f007";
$fab-var-googleplus: "\f008";
$fab-var-googleplus-alt: "\f009";
$fab-var-pinterest: "\f00a";
$fab-var-pinterest-alt: "\f00b";
$fab-var-tumblr: "\f00c";
$fab-var-tumblr-alt: "\f00d";
$fab-var-bitcoin: "\f010";
$fab-var-bitcoin-alt: "\f011";
$fab-var-dropbox: "\f012";
$fab-var-stackexchange: "\f013";
$fab-var-stackoverflow: "\f014";
$fab-var-flickr: "\f015";
$fab-var-flickr-alt: "\f016";
$fab-var-bitbucket: "\f017";
$fab-var-html5: "\f018";
$fab-var-css3: "\f019";
$fab-var-apple: "\f01a";
$fab-var-windows: "\f01b";
$fab-var-android: "\f01c";
$fab-var-linux: "\f01d";
$fab-var-dribbble: "\f01e";
$fab-var-youtube: "\f021";
$fab-var-skype: "\f022";
$fab-var-foursquare: "\f023";
$fab-var-trello: "\f024";
$fab-var-maxcdn: "\f025";
$fab-var-gratipay: "\f026";
$fab-var-vimeo: "\f027";
$fab-var-vimeo-alt: "\f028";
$fab-var-slack: "\f029";
$fab-var-wordpress: "\f02a";
$fab-var-wordpress-alt: "\f02b";
$fab-var-openid: "\f02c";
$fab-var-yahoo: "\f02d";
$fab-var-yahoo-alt: "\f02e";
$fab-var-reddit: "\f02f";
$fab-var-google: "\f030";
$fab-var-google-alt: "\f031";
$fab-var-stumbleupon: "\f032";
$fab-var-stumbleupon-alt: "\f033";
$fab-var-delicious: "\f034";
$fab-var-digg: "\f035";
$fab-var-piedpiper: "\f036";
$fab-var-piedpiper-alt: "\f037";
$fab-var-drupal: "\f038";
$fab-var-joomla: "\f039";
$fab-var-behance: "\f03a";
$fab-var-steam: "\f03b";
$fab-var-steam-alt: "\f03c";
$fab-var-spotify: "\f03d";
$fab-var-deviantart: "\f03e";
$fab-var-soundcloud: "\f040";
$fab-var-vine: "\f041";
$fab-var-codepen: "\f042";
$fab-var-jsfiddle: "\f043";
$fab-var-rebel: "\f044";
$fab-var-empire: "\f045";
$fab-var-git: "\f046";
$fab-var-hackernews: "\f047";
$fab-var-hackernews-alt: "\f048";
$fab-var-slideshare: "\f049";
$fab-var-twitch: "\f04a";
$fab-var-yelp: "\f04b";
$fab-var-paypal: "\f04c";
$fab-var-google-wallet: "\f04d";
$fab-var-angellist: "\f04e";
$fab-var-cc-visa: "\f050";
$fab-var-cc-mastercard: "\f051";
$fab-var-cc-discover: "\f052";
$fab-var-cc-amex: "\f053";
$fab-var-cc-paypal: "\f054";
$fab-var-cc-stripe: "\f055";
$fab-var-lastfm: "\f056";
$fab-var-whatsapp: "\f057";
$fab-var-medium: "\f058";
$fab-var-meanpath: "\f059";
$fab-var-meanpath-alt: "\f05a";
$fab-var-pagelines: "\f05b";
$fab-var-ioxhost: "\f060";
$fab-var-buysellads: "\f061";
$fab-var-buysellads-alt: "\f062";
$fab-var-connectdevelop: "\f063";
$fab-var-dashcube: "\f064";
$fab-var-forumbee: "\f065";
$fab-var-leanpub: "\f066";
$fab-var-sellsy: "\f067";
$fab-var-shirtsinbulk: "\f068";
$fab-var-simplybuilt: "\f069";
$fab-var-skyatlas: "\f06a";
$fab-var-viacoin: "\f06b";
$fab-var-codiepie: "\f06c";
$fab-var-queue: "\f06d";
$fab-var-queue-alt: "\f06e";
$fab-var-fonticons: "\f070";
$fab-var-fonticons-alt: "\f071";
$fab-var-blacktie: "\f072";
$fab-var-blacktie-alt: "\f073";
$fab-var-xing: "\f090";
$fab-var-vk: "\f091";
$fab-var-weibo: "\f092";
$fab-var-renren: "\f093";
$fab-var-tencent-weibo: "\f094";
$fab-var-qq: "\f095";
$fab-var-weixin: "\f096";

