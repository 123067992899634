.cta-bar {
	background-color: $color-gray-thunder;
    font-family: $font-family-secondary;
    font-size: px-to-rem(18px);
    line-height: 1.3;
    padding: px-to-rem(18px) 0;
    margin: 0 auto;
    color: $color-brand-white;
    text-transform: uppercase;
    text-align: center;
}

.cta-bar__list{
	@include font-secondary-light;

    padding-left: px-to-rem(10px);
    padding-right: px-to-rem(10px);
    display: inline-block;
}

.cta-bar__link {
	color: $color-brand-white;
	display: inline-block;
    cursor: pointer;
    position: relative;

    &:hover {
        position: relative;
        color: $color-brand-white;

        &:after  {
            height: 1px;
            opacity: 1;
            transform: translateY(0px);    
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background: $color-brand-white;
        opacity: 0;
        transition: height 0.3s, opacity 0.3s, transform 0.3s;
        transform: translateY(-10px);
    }
}

