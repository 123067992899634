@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
}
@media screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
  .container.is-fluid {
    margin-left: 32px;
    margin-right: 32px;
    max-width: none;
  }
}
@media screen and (max-width: 1215px) {
  .container.is-widescreen {
    max-width: 1152px;
  }
}
@media screen and (max-width: 1407px) {
  .container.is-fullhd {
    max-width: 1344px;
  }
}
@media screen and (min-width: 1216px) {
  .container {
    max-width: 1152px;
  }
}
@media screen and (min-width: 1408px) {
  .container {
    max-width: 1344px;
  }
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}
.columns.is-mobile > .column.is-narrow {
  flex: none;
}
.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}
.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}
.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}
.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}
.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}
.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}
.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}
.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}
.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}
.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}
.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}
.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}
.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}
.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}
.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}
.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}
.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}
.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}
.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}
.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}
.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}
.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.3333333333%;
}
.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.3333333333%;
}
.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6666666667%;
}
.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6666666667%;
}
.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}
.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}
.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333333333%;
}
.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333333333%;
}
.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6666666667%;
}
.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6666666667%;
}
.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}
.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}
.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333333333%;
}
.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333333333%;
}
.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6666666667%;
}
.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6666666667%;
}
.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}
.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}
.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333333333%;
}
.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333333333%;
}
.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6666666667%;
}
.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6666666667%;
}
.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}
.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}
@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    flex: none;
  }
  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-mobile {
    margin-left: 50%;
  }
  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }
  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-mobile {
    margin-left: 0%;
  }
  .column.is-1-mobile {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-mobile {
    margin-left: 8.3333333333%;
  }
  .column.is-2-mobile {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-mobile {
    margin-left: 16.6666666667%;
  }
  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-mobile {
    margin-left: 25%;
  }
  .column.is-4-mobile {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-mobile {
    margin-left: 33.3333333333%;
  }
  .column.is-5-mobile {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-mobile {
    margin-left: 41.6666666667%;
  }
  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-mobile {
    margin-left: 50%;
  }
  .column.is-7-mobile {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-mobile {
    margin-left: 58.3333333333%;
  }
  .column.is-8-mobile {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-mobile {
    margin-left: 66.6666666667%;
  }
  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-mobile {
    margin-left: 75%;
  }
  .column.is-10-mobile {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-mobile {
    margin-left: 83.3333333333%;
  }
  .column.is-11-mobile {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-mobile {
    margin-left: 91.6666666667%;
  }
  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}
@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }
  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }
  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }
  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }
  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }
  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.3333333333%;
  }
  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6666666667%;
  }
  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }
  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333333333%;
  }
  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6666666667%;
  }
  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }
  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333333333%;
  }
  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6666666667%;
  }
  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }
  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333333333%;
  }
  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6666666667%;
  }
  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    flex: none;
  }
  .column.is-full-touch {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-touch {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-touch {
    margin-left: 50%;
  }
  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }
  .column.is-0-touch {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-touch {
    margin-left: 0%;
  }
  .column.is-1-touch {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-touch {
    margin-left: 8.3333333333%;
  }
  .column.is-2-touch {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-touch {
    margin-left: 16.6666666667%;
  }
  .column.is-3-touch {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-touch {
    margin-left: 25%;
  }
  .column.is-4-touch {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-touch {
    margin-left: 33.3333333333%;
  }
  .column.is-5-touch {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-touch {
    margin-left: 41.6666666667%;
  }
  .column.is-6-touch {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-touch {
    margin-left: 50%;
  }
  .column.is-7-touch {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-touch {
    margin-left: 58.3333333333%;
  }
  .column.is-8-touch {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-touch {
    margin-left: 66.6666666667%;
  }
  .column.is-9-touch {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-touch {
    margin-left: 75%;
  }
  .column.is-10-touch {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-touch {
    margin-left: 83.3333333333%;
  }
  .column.is-11-touch {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-touch {
    margin-left: 91.6666666667%;
  }
  .column.is-12-touch {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    flex: none;
  }
  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-desktop {
    margin-left: 50%;
  }
  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }
  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-desktop {
    margin-left: 0%;
  }
  .column.is-1-desktop {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-desktop {
    margin-left: 8.3333333333%;
  }
  .column.is-2-desktop {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-desktop {
    margin-left: 16.6666666667%;
  }
  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-desktop {
    margin-left: 25%;
  }
  .column.is-4-desktop {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-desktop {
    margin-left: 33.3333333333%;
  }
  .column.is-5-desktop {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-desktop {
    margin-left: 41.6666666667%;
  }
  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-desktop {
    margin-left: 50%;
  }
  .column.is-7-desktop {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-desktop {
    margin-left: 58.3333333333%;
  }
  .column.is-8-desktop {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-desktop {
    margin-left: 66.6666666667%;
  }
  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-desktop {
    margin-left: 75%;
  }
  .column.is-10-desktop {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-desktop {
    margin-left: 83.3333333333%;
  }
  .column.is-11-desktop {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-desktop {
    margin-left: 91.6666666667%;
  }
  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    flex: none;
  }
  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }
  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }
  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }
  .column.is-1-widescreen {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-widescreen {
    margin-left: 8.3333333333%;
  }
  .column.is-2-widescreen {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-widescreen {
    margin-left: 16.6666666667%;
  }
  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }
  .column.is-4-widescreen {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-widescreen {
    margin-left: 33.3333333333%;
  }
  .column.is-5-widescreen {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-widescreen {
    margin-left: 41.6666666667%;
  }
  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }
  .column.is-7-widescreen {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-widescreen {
    margin-left: 58.3333333333%;
  }
  .column.is-8-widescreen {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-widescreen {
    margin-left: 66.6666666667%;
  }
  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }
  .column.is-10-widescreen {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-widescreen {
    margin-left: 83.3333333333%;
  }
  .column.is-11-widescreen {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-widescreen {
    margin-left: 91.6666666667%;
  }
  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    flex: none;
  }
  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }
  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }
  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }
  .column.is-1-fullhd {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-fullhd {
    margin-left: 8.3333333333%;
  }
  .column.is-2-fullhd {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-fullhd {
    margin-left: 16.6666666667%;
  }
  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }
  .column.is-4-fullhd {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-fullhd {
    margin-left: 33.3333333333%;
  }
  .column.is-5-fullhd {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-fullhd {
    margin-left: 41.6666666667%;
  }
  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }
  .column.is-7-fullhd {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-fullhd {
    margin-left: 58.3333333333%;
  }
  .column.is-8-fullhd {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-fullhd {
    margin-left: 66.6666666667%;
  }
  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }
  .column.is-10-fullhd {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-fullhd {
    margin-left: 83.3333333333%;
  }
  .column.is-11-fullhd {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-fullhd {
    margin-left: 91.6666666667%;
  }
  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}
.columns:last-child {
  margin-bottom: -0.75rem;
}
.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}
.columns.is-centered {
  justify-content: center;
}
.columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}
.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}
.columns.is-gapless:last-child {
  margin-bottom: 0;
}
.columns.is-mobile {
  display: flex;
}
.columns.is-multiline {
  flex-wrap: wrap;
}
.columns.is-vcentered {
  align-items: center;
}
@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}
.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}
.columns.is-variable.is-0 {
  --columnGap: 0rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}
.columns.is-variable.is-1 {
  --columnGap: 0.25rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: 0.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: 0.25rem;
  }
}
.columns.is-variable.is-2 {
  --columnGap: 0.5rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: 0.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: 0.5rem;
  }
}
.columns.is-variable.is-3 {
  --columnGap: 0.75rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: 0.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: 0.75rem;
  }
}
.columns.is-variable.is-4 {
  --columnGap: 1rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}
.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}
.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}
.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}
.columns.is-variable.is-8 {
  --columnGap: 2rem;
}
@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}
@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}
@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

/*!
 *  The Black Tie Font is commercial software. Please do not distribute.
 */
/**
 *
 * only Font brans is being used, scroll down for variables
 *
 */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: "Font Awesome Brands";
  src: url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.eot?v=1.0.0");
  src: url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.woff2?v=1.0.0") format("woff2"), url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.woff?v=1.0.0") format("woff"), url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.ttf?v=1.0.0") format("truetype"), url("/assets/site/Main/public/fonts/brands/FontAwesomeBrands-Regular-webfont.svg?v=1.0.0#font_awesome_brandsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Font Awesome";
  src: url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.eot?v=1.0.0");
  src: url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.eot?#iefix&v=1.0.0") format("embedded-opentype"), url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.woff2?v=1.0.0") format("woff2"), url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.woff?v=1.0.0") format("woff"), url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.ttf?v=1.0.0") format("truetype"), url("/assets/site/Main/public/fonts/regular/fontawesome-webfont.svg?v=1.0.0#font_awesome_regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.bts, .btb, .btr, .btl, .fab {
  display: inline-block;
  font: normal normal normal 14px/1 "Black Tie";
  font-size: inherit;
  vertical-align: -14.2857142857%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.bts {
  font-weight: 900;
}

.btb {
  font-weight: 700;
}

.btl {
  font-weight: 200;
}

.fab {
  font-family: "Font Awesome Brands";
}

/* makes the font 25% smaller relative to the icon container */
.bt-sm {
  font-size: 0.7em;
  vertical-align: baseline;
}

/* makes the font 33% larger relative to the icon container */
.bt-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
}

.bt-2x {
  font-size: 2em;
}

.bt-3x {
  font-size: 3em;
}

.bt-4x {
  font-size: 4em;
}

.bt-5x {
  font-size: 5em;
}

.bt-lg,
.bt-2x,
.bt-3x,
.bt-4x,
.bt-5x {
  vertical-align: -30%;
}

.bt-fw {
  width: 1.2857142857em;
  text-align: center;
}

.bt-ul {
  padding-left: 0;
  margin-left: 2.1428571429em;
  list-style-type: none;
}
.bt-ul > li {
  position: relative;
}

.bt-li {
  position: absolute;
  left: -2.1428571429em;
  width: 2.1428571429em;
  top: 0.1428571429em;
  text-align: center;
}
.bt-li.bt-lg {
  left: -2em;
}

.bt-border {
  padding: 0.2em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.bts.pull-left, .btb.pull-left, .btr.pull-left, .btl.pull-left, .fab.pull-left {
  margin-right: 0.3em;
}
.bts.pull-right, .btb.pull-right, .btr.pull-right, .btl.pull-right, .fab.pull-right {
  margin-left: 0.3em;
}

.bt-spin {
  -webkit-animation: bt-spin 2s infinite linear;
  animation: bt-spin 2s infinite linear;
}

.bt-pulse {
  -webkit-animation: bt-spin 1s infinite steps(8);
  animation: bt-spin 1s infinite steps(8);
}

@-webkit-keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.bt-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bt-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.bt-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.bt-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.bt-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .bt-rotate-90,
:root .bt-rotate-180,
:root .bt-rotate-270,
:root .bt-flip-horizontal,
:root .bt-flip-vertical {
  filter: none;
}

.bt-stack {
  position: relative;
  display: inline-block;
  width: 1.2857142857em;
  height: 1em;
  line-height: 1em;
  vertical-align: baseline;
}

.bt-stack-sm {
  position: absolute;
  top: 0;
  left: 0;
  line-height: inherit;
  font-size: 0.5em;
}

.bt-stack-1x, .bt-stack-sm {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.bt-inverse {
  color: #fff;
}

/* Black Tie uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fab-facebook:before {
  content: "";
}

.fab-facebook-alt:before {
  content: "";
}

.fab-twitter:before {
  content: "";
}

.fab-linkedin:before {
  content: "";
}

.fab-linkedin-alt:before {
  content: "";
}

.fab-instagram:before {
  content: "";
}

.fab-github:before {
  content: "";
}

.fab-github-alt:before {
  content: "";
}

.fab-googleplus:before {
  content: "";
}

.fab-googleplus-alt:before {
  content: "";
}

.fab-pinterest:before {
  content: "";
}

.fab-pinterest-alt:before {
  content: "";
}

.fab-tumblr:before {
  content: "";
}

.fab-tumblr-alt:before {
  content: "";
}

.fab-bitcoin:before {
  content: "";
}

.fab-bitcoin-alt:before {
  content: "";
}

.fab-dropbox:before {
  content: "";
}

.fab-stackexchange:before {
  content: "";
}

.fab-stackoverflow:before {
  content: "";
}

.fab-flickr:before {
  content: "";
}

.fab-flickr-alt:before {
  content: "";
}

.fab-bitbucket:before {
  content: "";
}

.fab-html5:before {
  content: "";
}

.fab-css3:before {
  content: "";
}

.fab-apple:before {
  content: "";
}

.fab-windows:before {
  content: "";
}

.fab-android:before {
  content: "";
}

.fab-linux:before {
  content: "";
}

.fab-dribbble:before {
  content: "";
}

.fab-youtube:before {
  content: "";
}

.fab-skype:before {
  content: "";
}

.fab-foursquare:before {
  content: "";
}

.fab-trello:before {
  content: "";
}

.fab-maxcdn:before {
  content: "";
}

.fab-gittip:before,
.fab-gratipay:before {
  content: "";
}

.fab-vimeo:before {
  content: "";
}

.fab-vimeo-alt:before {
  content: "";
}

.fab-slack:before {
  content: "";
}

.fab-wordpress:before {
  content: "";
}

.fab-wordpress-alt:before {
  content: "";
}

.fab-openid:before {
  content: "";
}

.fab-yahoo:before {
  content: "";
}

.fab-yahoo-alt:before {
  content: "";
}

.fab-reddit:before {
  content: "";
}

.fab-google:before {
  content: "";
}

.fab-google-alt:before {
  content: "";
}

.fab-stumbleupon:before {
  content: "";
}

.fab-stumbleupon-alt:before {
  content: "";
}

.fab-delicious:before {
  content: "";
}

.fab-digg:before {
  content: "";
}

.fab-piedpiper:before {
  content: "";
}

.fab-piedpiper-alt:before {
  content: "";
}

.fab-drupal:before {
  content: "";
}

.fab-joomla:before {
  content: "";
}

.fab-behance:before {
  content: "";
}

.fab-steam:before {
  content: "";
}

.fab-steam-alt:before {
  content: "";
}

.fab-spotify:before {
  content: "";
}

.fab-deviantart:before {
  content: "";
}

.fab-soundcloud:before {
  content: "";
}

.fab-vine:before {
  content: "";
}

.fab-codepen:before {
  content: "";
}

.fab-jsfiddle:before {
  content: "";
}

.fab-rebel:before {
  content: "";
}

.fab-empire:before {
  content: "";
}

.fab-git:before {
  content: "";
}

.fab-hackernews:before {
  content: "";
}

.fab-hackernews-alt:before {
  content: "";
}

.fab-slideshare:before {
  content: "";
}

.fab-twitch:before {
  content: "";
}

.fab-yelp:before {
  content: "";
}

.fab-paypal:before {
  content: "";
}

.fab-google-wallet:before {
  content: "";
}

.fab-angellist:before {
  content: "";
}

.fab-cc-visa:before {
  content: "";
}

.fab-cc-mastercard:before {
  content: "";
}

.fab-cc-discover:before {
  content: "";
}

.fab-cc-amex:before {
  content: "";
}

.fab-cc-paypal:before {
  content: "";
}

.fab-cc-stripe:before {
  content: "";
}

.fab-lastfm:before {
  content: "";
}

.fab-whatsapp:before {
  content: "";
}

.fab-medium:before {
  content: "";
}

.fab-meanpath:before {
  content: "";
}

.fab-meanpath-alt:before {
  content: "";
}

.fab-pagelines:before {
  content: "";
}

.fab-ioxhost:before {
  content: "";
}

.fab-buysellads:before {
  content: "";
}

.fab-buysellads-alt:before {
  content: "";
}

.fab-connectdevelop:before {
  content: "";
}

.fab-dashcube:before {
  content: "";
}

.fab-forumbee:before {
  content: "";
}

.fab-leanpub:before {
  content: "";
}

.fab-sellsy:before {
  content: "";
}

.fab-shirtsinbulk:before {
  content: "";
}

.fab-simplybuilt:before {
  content: "";
}

.fab-skyatlas:before {
  content: "";
}

.fab-viacoin:before {
  content: "";
}

.fab-codiepie:before {
  content: "";
}

.fab-queue:before {
  content: "";
}

.fab-queue-alt:before {
  content: "";
}

.fab-fonticons:before {
  content: "";
}

.fab-fonticons-alt:before {
  content: "";
}

.fab-blacktie:before {
  content: "";
}

.fab-blacktie-alt:before {
  content: "";
}

.fab-xing:before {
  content: "";
}

.fab-vk:before {
  content: "";
}

.fab-weibo:before {
  content: "";
}

.fab-renren:before {
  content: "";
}

.fab-tencent-weibo:before {
  content: "";
}

.fab-qq:before {
  content: "";
}

.fab-wechat:before,
.fab-weixin:before {
  content: "";
}

/**

  TODO:
  - separate mixing
  - Separate icon classses

 */
@font-face {
  font-family: "budicon";
  src: url("/assets/site/Main/public/fonts/budicon.eot");
  src: url("/assets/site/Main/public/fonts/budicon.eot?#iefix") format("embedded-opentype"), url("/assets/site/Main/public/fonts/budicon.woff") format("woff"), url("/assets/site/Main/public/fonts/budicon.ttf") format("truetype"), url("/assets/site/Main/public/fonts/budicon.svg#budicon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.bi {
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
}
.bi:before {
  display: inline-block;
}

.bi-animal-fish:before {
  content: "";
}

/* '' */
.bi-animal-fox:before {
  content: "";
}

/* '' */
.bi-animal-frog:before {
  content: "";
}

/* '' */
.bi-animal-goose:before {
  content: "";
}

/* '' */
.bi-animal-husky:before {
  content: "";
}

/* '' */
.bi-animal-lion:before {
  content: "";
}

/* '' */
.bi-animal-monkey:before {
  content: "";
}

/* '' */
.bi-animal-monkey-a:before {
  content: "";
}

/* '' */
.bi-animal-mouse:before {
  content: "";
}

/* '' */
.bi-animal-pig:before {
  content: "";
}

/* '' */
.bi-animal-pig-a:before {
  content: "";
}

/* '' */
.bi-animal-sheep:before {
  content: "";
}

/* '' */
.bi-animal-shrimp:before {
  content: "";
}

/* '' */
.bi-animal-wolf:before {
  content: "";
}

/* '' */
.bi-animal-bull:before {
  content: "";
}

/* '' */
.bi-animal-cat:before {
  content: "";
}

/* '' */
.bi-animal-chicken:before {
  content: "";
}

/* '' */
.bi-animal-cow:before {
  content: "";
}

/* '' */
.bi-animal-cow-a:before {
  content: "";
}

/* '' */
.bi-animal-dog:before {
  content: "";
}

/* '' */
.bi-com-bubble-dot-b:before {
  content: "";
}

/* '' */
.bi-com-bubble-line:before {
  content: "";
}

/* '' */
.bi-com-bubble-line-a:before {
  content: "";
}

/* '' */
.bi-com-bubble-line-b:before {
  content: "";
}

/* '' */
.bi-com-chat:before {
  content: "";
}

/* '' */
.bi-com-check-box:before {
  content: "";
}

/* '' */
.bi-com-email:before {
  content: "";
}

/* '' */
.bi-com-email-cross:before {
  content: "";
}

/* '' */
.bi-com-email-minus:before {
  content: "";
}

/* '' */
.bi-com-email-o:before {
  content: "";
}

/* '' */
.bi-com-email-plus:before {
  content: "";
}

/* '' */
.bi-com-email-tick:before {
  content: "";
}

/* '' */
.bi-com-envelope:before {
  content: "";
}

/* '' */
.bi-com-feather-pen:before {
  content: "";
}

/* '' */
.bi-com-fountain-pen:before {
  content: "";
}

/* '' */
.bi-com-group-bubble:before {
  content: "";
}

/* '' */
.bi-com-group-bubble-a:before {
  content: "";
}

/* '' */
.bi-com-group-bubble-b:before {
  content: "";
}

/* '' */
.bi-com-group-bubble-c:before {
  content: "";
}

/* '' */
.bi-com-group-bubble-d:before {
  content: "";
}

/* '' */
.bi-com-help:before {
  content: "";
}

/* '' */
.bi-com-help-a:before {
  content: "";
}

/* '' */
.bi-com-megaphone:before {
  content: "";
}

/* '' */
.bi-com-megaphone-a:before {
  content: "";
}

/* '' */
.bi-com-mic:before {
  content: "";
}

/* '' */
.bi-com-mic-mute:before {
  content: "";
}

/* '' */
.bi-com-paperplane:before {
  content: "";
}

/* '' */
.bi-com-phone:before {
  content: "";
}

/* '' */
.bi-com-phone-a:before {
  content: "";
}

/* '' */
.bi-com-pictures:before {
  content: "";
}

/* '' */
.bi-com-voicemail:before {
  content: "";
}

/* '' */
.bi-com-bell:before {
  content: "";
}

/* '' */
.bi-com-bell-a:before {
  content: "";
}

/* '' */
.bi-com-broadcast:before {
  content: "";
}

/* '' */
.bi-com-broadcast-a:before {
  content: "";
}

/* '' */
.bi-com-bubble:before {
  content: "";
}

/* '' */
.bi-com-bubble-a:before {
  content: "";
}

/* '' */
.bi-com-bubble-b:before {
  content: "";
}

/* '' */
.bi-com-bubble-dot:before {
  content: "";
}

/* '' */
.bi-com-bubble-dot-a:before {
  content: "";
}

/* '' */
.bi-doc-analytic-blank:before {
  content: "";
}

/* '' */
.bi-doc-analytic-line:before {
  content: "";
}

/* '' */
.bi-doc-analytic-line-a:before {
  content: "";
}

/* '' */
.bi-doc-article:before {
  content: "";
}

/* '' */
.bi-doc-article-a:before {
  content: "";
}

/* '' */
.bi-doc-article-b:before {
  content: "";
}

/* '' */
.bi-doc-attachment:before {
  content: "";
}

/* '' */
.bi-doc-attachment-a:before {
  content: "";
}

/* '' */
.bi-doc-binder-blank:before {
  content: "";
}

/* '' */
.bi-doc-binder-four:before {
  content: "";
}

/* '' */
.bi-doc-binder-one:before {
  content: "";
}

/* '' */
.bi-doc-binder-three:before {
  content: "";
}

/* '' */
.bi-doc-binder-two:before {
  content: "";
}

/* '' */
.bi-doc-binder-zero:before {
  content: "";
}

/* '' */
.bi-doc-book:before {
  content: "";
}

/* '' */
.bi-doc-book-a:before {
  content: "";
}

/* '' */
.bi-doc-book-b:before {
  content: "";
}

/* '' */
.bi-doc-book-c:before {
  content: "";
}

/* '' */
.bi-doc-book-d:before {
  content: "";
}

/* '' */
.bi-doc-bookmark:before {
  content: "";
}

/* '' */
.bi-doc-book-mark:before {
  content: "";
}

/* '' */
.bi-doc-box:before {
  content: "";
}

/* '' */
.bi-doc-briefcase:before {
  content: "";
}

/* '' */
.bi-doc-briefcase-a:before {
  content: "";
}

/* '' */
.bi-doc-briefcase-b:before {
  content: "";
}

/* '' */
.bi-doc-briefcase-c:before {
  content: "";
}

/* '' */
.bi-doc-briefcase-d:before {
  content: "";
}

/* '' */
.bi-doc-compose:before {
  content: "";
}

/* '' */
.bi-doc-compose-a:before {
  content: "";
}

/* '' */
.bi-doc-documents:before {
  content: "";
}

/* '' */
.bi-doc-documents-minus:before {
  content: "";
}

/* '' */
.bi-doc-documents-plus:before {
  content: "";
}

/* '' */
.bi-doc-download:before {
  content: "";
}

/* '' */
.bi-doc-drawer:before {
  content: "";
}

/* '' */
.bi-doc-drawer-a:before {
  content: "";
}

/* '' */
.bi-doc-drawer-b:before {
  content: "";
}

/* '' */
.bi-doc-drawer-blank:before {
  content: "";
}

/* '' */
.bi-doc-drawer-download:before {
  content: "";
}

/* '' */
.bi-doc-drawer-line:before {
  content: "";
}

/* '' */
.bi-doc-drawer-upload:before {
  content: "";
}

/* '' */
.bi-doc-envelope:before {
  content: "";
}

/* '' */
.bi-doc-file-blank:before {
  content: "";
}

/* '' */
.bi-doc-file-blank-a:before {
  content: "";
}

/* '' */
.bi-doc-file-cross:before {
  content: "";
}

/* '' */
.bi-doc-file-cross-a:before {
  content: "";
}

/* '' */
.bi-doc-file-line:before {
  content: "";
}

/* '' */
.bi-doc-file-line-a:before {
  content: "";
}

/* '' */
.bi-doc-file-minus:before {
  content: "";
}

/* '' */
.bi-doc-file-minus-a:before {
  content: "";
}

/* '' */
.bi-doc-file-plus:before {
  content: "";
}

/* '' */
.bi-doc-file-plus-a:before {
  content: "";
}

/* '' */
.bi-doc-file-stack:before {
  content: "";
}

/* '' */
.bi-doc-file-tick:before {
  content: "";
}

/* '' */
.bi-doc-file-tick-a:before {
  content: "";
}

/* '' */
.bi-doc-folder:before {
  content: "";
}

/* '' */
.bi-doc-folder-a:before {
  content: "";
}

/* '' */
.bi-doc-folder-check-a:before {
  content: "";
}

/* '' */
.bi-doc-folder-cross:before {
  content: "";
}

/* '' */
.bi-doc-folder-cross-a:before {
  content: "";
}

/* '' */
.bi-doc-folder-minus:before {
  content: "";
}

/* '' */
.bi-doc-folder-minus-a:before {
  content: "";
}

/* '' */
.bi-doc-folder-plus:before {
  content: "";
}

/* '' */
.bi-doc-folder-plus-a:before {
  content: "";
}

/* '' */
.bi-doc-folder-s:before {
  content: "";
}

/* '' */
.bi-doc-folder-sync:before {
  content: "";
}

/* '' */
.bi-doc-folder-tick:before {
  content: "";
}

/* '' */
.bi-doc-list:before {
  content: "";
}

/* '' */
.bi-doc-news:before {
  content: "";
}

/* '' */
.bi-doc-newspaper:before {
  content: "";
}

/* '' */
.bi-doc-newspaper-a:before {
  content: "";
}

/* '' */
.bi-doc-newspaper-b:before {
  content: "";
}

/* '' */
.bi-doc-notebook:before {
  content: "";
}

/* '' */
.bi-doc-notebook-a:before {
  content: "";
}

/* '' */
.bi-doc-note-pen:before {
  content: "";
}

/* '' */
.bi-doc-paper-line:before {
  content: "";
}

/* '' */
.bi-doc-papers:before {
  content: "";
}

/* '' */
.bi-doc-paper-stack:before {
  content: "";
}

/* '' */
.bi-doc-photo-stack:before {
  content: "";
}

/* '' */
.bi-doc-pie:before {
  content: "";
}

/* '' */
.bi-doc-pie-a:before {
  content: "";
}

/* '' */
.bi-doc-pie-b:before {
  content: "";
}

/* '' */
.bi-doc-pin:before {
  content: "";
}

/* '' */
.bi-doc-pin-a:before {
  content: "";
}

/* '' */
.bi-doc-print:before {
  content: "";
}

/* '' */
.bi-doc-print-a:before {
  content: "";
}

/* '' */
.bi-doc-profile:before {
  content: "";
}

/* '' */
.bi-doc-profile-a:before {
  content: "";
}

/* '' */
.bi-doc-upload:before {
  content: "";
}

/* '' */
.bi-doc-wboard-line:before {
  content: "";
}

/* '' */
.bi-doc-wboard-tick:before {
  content: "";
}

/* '' */
.bi-media-glass:before {
  content: "";
}

/* '' */
.bi-media-glass-a:before {
  content: "";
}

/* '' */
.bi-media-image:before {
  content: "";
}

/* '' */
.bi-media-image-a:before {
  content: "";
}

/* '' */
.bi-media-image-b:before {
  content: "";
}

/* '' */
.bi-media-image-c:before {
  content: "";
}

/* '' */
.bi-media-image-d:before {
  content: "";
}

/* '' */
.bi-media-negative:before {
  content: "";
}

/* '' */
.bi-media-negative-a:before {
  content: "";
}

/* '' */
.bi-media-video:before {
  content: "";
}

/* '' */
.bi-media-video-a:before {
  content: "";
}

/* '' */
.bi-media-video-clip:before {
  content: "";
}

/* '' */
.bi-media-video-r:before {
  content: "";
}

/* '' */
.bi-business-tie:before {
  content: "";
}

/* '' */
.bi-business-tie-a:before {
  content: "";
}

/* '' */
.bi-business-tie-b:before {
  content: "";
}

/* '' */
.bi-doc-analytic-bar:before {
  content: "";
}

/* '' */
.bi-ecommerce-basket:before {
  content: "";
}

/* '' */
.bi-ecommerce-bill:before {
  content: "";
}

/* '' */
.bi-ecommerce-bill-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-bill-b:before {
  content: "";
}

/* '' */
.bi-ecommerce-bowtie:before {
  content: "";
}

/* '' */
.bi-ecommerce-cash:before {
  content: "";
}

/* '' */
.bi-ecommerce-cash-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-coins:before {
  content: "";
}

/* '' */
.bi-ecommerce-coins-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-creditcard:before {
  content: "";
}

/* '' */
.bi-ecommerce-creditcard-b:before {
  content: "";
}

/* '' */
.bi-ecommerce-creditcard-c:before {
  content: "";
}

/* '' */
.bi-ecommerce-creditcard-d:before {
  content: "";
}

/* '' */
.bi-ecommerce-diamon:before {
  content: "";
}

/* '' */
.bi-ecommerce-digital-download:before {
  content: "";
}

/* '' */
.bi-ecommerce-digital-upload:before {
  content: "";
}

/* '' */
.bi-ecommerce-dollar:before {
  content: "";
}

/* '' */
.bi-ecommerce-euro:before {
  content: "";
}

/* '' */
.bi-ecommerce-gift:before {
  content: "";
}

/* '' */
.bi-ecommerce-hanger:before {
  content: "";
}

/* '' */
.bi-ecommerce-jeans:before {
  content: "";
}

/* '' */
.bi-ecommerce-lipstick:before {
  content: "";
}

/* '' */
.bi-ecommerce-market:before {
  content: "";
}

/* '' */
.bi-ecommerce-mastercard:before {
  content: "";
}

/* '' */
.bi-ecommerce-pack:before {
  content: "";
}

/* '' */
.bi-ecommerce-pack-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-pounds:before {
  content: "";
}

/* '' */
.bi-ecommerce-ring:before {
  content: "";
}

/* '' */
.bi-ecommerce-safebox:before {
  content: "";
}

/* '' */
.bi-ecommerce-shirt:before {
  content: "";
}

/* '' */
.bi-ecommerce-shirt-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-shoes:before {
  content: "";
}

/* '' */
.bi-ecommerce-shop:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-a-download:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-a-fill:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-c:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-d:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-download:before {
  content: "";
}

/* '' */
.bi-ecommerce-shopcart-fill:before {
  content: "";
}

/* '' */
.bi-ecommerce-short:before {
  content: "";
}

/* '' */
.bi-ecommerce-suitcase:before {
  content: "";
}

/* '' */
.bi-ecommerce-suitcase-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-a-s:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-b:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-b-s:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-c:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-cross:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-c-s:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-minus:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-plus:before {
  content: "";
}

/* '' */
.bi-ecommerce-tag-tick:before {
  content: "";
}

/* '' */
.bi-ecommerce-tracking:before {
  content: "";
}

/* '' */
.bi-ecommerce-tshirt:before {
  content: "";
}

/* '' */
.bi-ecommerce-wallet:before {
  content: "";
}

/* '' */
.bi-ecommerce-wallet-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-woman-bag:before {
  content: "";
}

/* '' */
.bi-ecommerce-yen:before {
  content: "";
}

/* '' */
.bi-ecommerce-backpack:before {
  content: "";
}

/* '' */
.bi-ecommerce-backpack-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-bag:before {
  content: "";
}

/* '' */
.bi-ecommerce-bag-a:before {
  content: "";
}

/* '' */
.bi-ecommerce-bag-b:before {
  content: "";
}

/* '' */
.bi-editorial-pencil-s:before {
  content: "";
}

/* '' */
.bi-editorial-pen-s:before {
  content: "";
}

/* '' */
.bi-editorial-redo:before {
  content: "";
}

/* '' */
.bi-editorial-right-align:before {
  content: "";
}

/* '' */
.bi-editorial-trash:before {
  content: "";
}

/* '' */
.bi-editorial-trash-a:before {
  content: "";
}

/* '' */
.bi-editorial-trash-a-l:before {
  content: "";
}

/* '' */
.bi-editorial-trash-l:before {
  content: "";
}

/* '' */
.bi-editorial-undo:before {
  content: "";
}

/* '' */
.bi-editorial-write:before {
  content: "";
}

/* '' */
.bi-editorial-write-s:before {
  content: "";
}

/* '' */
.bi-editorial-ascending:before {
  content: "";
}

/* '' */
.bi-editorial-bookmark:before {
  content: "";
}

/* '' */
.bi-editorial-bookmark-a:before {
  content: "";
}

/* '' */
.bi-editorial-brush:before {
  content: "";
}

/* '' */
.bi-editorial-center-align:before {
  content: "";
}

/* '' */
.bi-editorial-compose:before {
  content: "";
}

/* '' */
.bi-editorial-descending:before {
  content: "";
}

/* '' */
.bi-editorial-left-align:before {
  content: "";
}

/* '' */
.bi-editorial-pen:before {
  content: "";
}

/* '' */
.bi-editorial-pencil:before {
  content: "";
}

/* '' */
.bi-editorial-pencil-a:before {
  content: "";
}

/* '' */
.bi-editorial-pencil-a-s:before {
  content: "";
}

/* '' */
.bi-building-drawer-a:before {
  content: "";
}

/* '' */
.bi-building-house:before {
  content: "";
}

/* '' */
.bi-building-house-a:before {
  content: "";
}

/* '' */
.bi-building-museum:before {
  content: "";
}

/* '' */
.bi-building-office:before {
  content: "";
}

/* '' */
.bi-building-office-a:before {
  content: "";
}

/* '' */
.bi-building-office-b:before {
  content: "";
}

/* '' */
.bi-building-sofa:before {
  content: "";
}

/* '' */
.bi-building-sofa-a:before {
  content: "";
}

/* '' */
.bi-building-sofa-b:before {
  content: "";
}

/* '' */
.bi-building-sofa-twin:before {
  content: "";
}

/* '' */
.bi-building-sofa-twin-a:before {
  content: "";
}

/* '' */
.bi-building-table-lamp:before {
  content: "";
}

/* '' */
.bi-building-table-lamp-a:before {
  content: "";
}

/* '' */
.bi-building-tower:before {
  content: "";
}

/* '' */
.bi-building-tree:before {
  content: "";
}

/* '' */
.bi-environment-flower:before {
  content: "";
}

/* '' */
.bi-environment-flower-a:before {
  content: "";
}

/* '' */
.bi-environment-flower-b:before {
  content: "";
}

/* '' */
.bi-environment-leaf:before {
  content: "";
}

/* '' */
.bi-environment-leaf-a:before {
  content: "";
}

/* '' */
.bi-environment-mountain:before {
  content: "";
}

/* '' */
.bi-environment-mountain-a:before {
  content: "";
}

/* '' */
.bi-environment-no-smoke:before {
  content: "";
}

/* '' */
.bi-environment-plant:before {
  content: "";
}

/* '' */
.bi-environment-sign:before {
  content: "";
}

/* '' */
.bi-environment-smoke:before {
  content: "";
}

/* '' */
.bi-environment-tree:before {
  content: "";
}

/* '' */
.bi-building-apartment:before {
  content: "";
}

/* '' */
.bi-building-bed:before {
  content: "";
}

/* '' */
.bi-building-bulb:before {
  content: "";
}

/* '' */
.bi-building-cabinet:before {
  content: "";
}

/* '' */
.bi-building-desk:before {
  content: "";
}

/* '' */
.bi-building-desk-a:before {
  content: "";
}

/* '' */
.bi-building-desk-b:before {
  content: "";
}

/* '' */
.bi-building-desk-c:before {
  content: "";
}

/* '' */
.bi-building-door:before {
  content: "";
}

/* '' */
.bi-building-drawer:before {
  content: "";
}

/* '' */
.bi-beverage-cocktail-a:before {
  content: "";
}

/* '' */
.bi-beverage-coffee:before {
  content: "";
}

/* '' */
.bi-beverage-coffee-a:before {
  content: "";
}

/* '' */
.bi-beverage-coffee-b:before {
  content: "";
}

/* '' */
.bi-beverage-coffee-cup:before {
  content: "";
}

/* '' */
.bi-beverage-coffee-cup-a:before {
  content: "";
}

/* '' */
.bi-beverage-cokctail:before {
  content: "";
}

/* '' */
.bi-beverage-cup-straw:before {
  content: "";
}

/* '' */
.bi-beverage-empty-glass:before {
  content: "";
}

/* '' */
.bi-beverage-milk:before {
  content: "";
}

/* '' */
.bi-beverage-milk-a:before {
  content: "";
}

/* '' */
.bi-beverage-tea:before {
  content: "";
}

/* '' */
.bi-beverage-tea-a:before {
  content: "";
}

/* '' */
.bi-beverage-tea-cup:before {
  content: "";
}

/* '' */
.bi-beverage-tea-cup-a:before {
  content: "";
}

/* '' */
.bi-beverage-water:before {
  content: "";
}

/* '' */
.bi-beverage-water-a:before {
  content: "";
}

/* '' */
.bi-beverage-water-glass:before {
  content: "";
}

/* '' */
.bi-beverage-wine:before {
  content: "";
}

/* '' */
.bi-food-apple:before {
  content: "";
}

/* '' */
.bi-food-bowl:before {
  content: "";
}

/* '' */
.bi-food-cherry:before {
  content: "";
}

/* '' */
.bi-food-cherry-a:before {
  content: "";
}

/* '' */
.bi-food-chinese-food:before {
  content: "";
}

/* '' */
.bi-food-chinese-food-a:before {
  content: "";
}

/* '' */
.bi-food-chinese-food-b:before {
  content: "";
}

/* '' */
.bi-food-drumstick:before {
  content: "";
}

/* '' */
.bi-food-egg:before {
  content: "";
}

/* '' */
.bi-food-grape:before {
  content: "";
}

/* '' */
.bi-food-hamburger:before {
  content: "";
}

/* '' */
.bi-food-hamburger-a:before {
  content: "";
}

/* '' */
.bi-food-ice-cream:before {
  content: "";
}

/* '' */
.bi-food-ice-cream-a:before {
  content: "";
}

/* '' */
.bi-food-ice-cream-b:before {
  content: "";
}

/* '' */
.bi-food-ice-cream-c:before {
  content: "";
}

/* '' */
.bi-food-melon:before {
  content: "";
}

/* '' */
.bi-food-noodle:before {
  content: "";
}

/* '' */
.bi-food-noodle-a:before {
  content: "";
}

/* '' */
.bi-food-onigiri:before {
  content: "";
}

/* '' */
.bi-food-onigiri-a:before {
  content: "";
}

/* '' */
.bi-food-white-bread:before {
  content: "";
}

/* '' */
.bi-kitchen-fork-knife:before {
  content: "";
}

/* '' */
.bi-kitchen-fork-knife-a:before {
  content: "";
}

/* '' */
.bi-kitchen-fridge:before {
  content: "";
}

/* '' */
.bi-kitchen-grill:before {
  content: "";
}

/* '' */
.bi-kitchen-heat:before {
  content: "";
}

/* '' */
.bi-kitchen-heat-a:before {
  content: "";
}

/* '' */
.bi-kitchen-hood:before {
  content: "";
}

/* '' */
.bi-kitchen-hood-a:before {
  content: "";
}

/* '' */
.bi-kitchen-jam:before {
  content: "";
}

/* '' */
.bi-kitchen-ketchup:before {
  content: "";
}

/* '' */
.bi-kitchen-leaf:before {
  content: "";
}

/* '' */
.bi-kitchen-microwave:before {
  content: "";
}

/* '' */
.bi-kitchen-pepper:before {
  content: "";
}

/* '' */
.bi-kitchen-plate:before {
  content: "";
}

/* '' */
.bi-kitchen-recipe-book:before {
  content: "";
}

/* '' */
.bi-kitchen-salt:before {
  content: "";
}

/* '' */
.bi-kitchen-spatula:before {
  content: "";
}

/* '' */
.bi-kitchen-spoon-fork:before {
  content: "";
}

/* '' */
.bi-kitchen-spoon-fork-a:before {
  content: "";
}

/* '' */
.bi-kitchen-tissue:before {
  content: "";
}

/* '' */
.bi-kitchen-water:before {
  content: "";
}

/* '' */
.bi-beverage-alt-glass:before {
  content: "";
}

/* '' */
.bi-beverage-alt-glass-a:before {
  content: "";
}

/* '' */
.bi-beverage-alt-glass-b:before {
  content: "";
}

/* '' */
.bi-beverage-alt-glass-c:before {
  content: "";
}

/* '' */
.bi-beverage-beer:before {
  content: "";
}

/* '' */
.bi-beverage-chinese-tea:before {
  content: "";
}

/* '' */
.bi-beverage-chinese-tea-a:before {
  content: "";
}

/* '' */
.bi-beverage-chinese-tea-a-s:before {
  content: "";
}

/* '' */
.bi-beverage-chinese-tea-s:before {
  content: "";
}

/* '' */
.bi-setting-switch:before {
  content: "";
}

/* '' */
.bi-setting-switch-a:before {
  content: "";
}

/* '' */
.bi-setting-wrench:before {
  content: "";
}

/* '' */
.bi-setting-wrench-a:before {
  content: "";
}

/* '' */
.bi-setting-airplane:before {
  content: "";
}

/* '' */
.bi-setting-component:before {
  content: "";
}

/* '' */
.bi-setting-eq:before {
  content: "";
}

/* '' */
.bi-setting-eq-a:before {
  content: "";
}

/* '' */
.bi-setting-gear:before {
  content: "";
}

/* '' */
.bi-setting-gear-a:before {
  content: "";
}

/* '' */
.bi-setting-gear-b:before {
  content: "";
}

/* '' */
.bi-setting-hotspot:before {
  content: "";
}

/* '' */
.bi-setting-notification:before {
  content: "";
}

/* '' */
.bi-sport-tape:before {
  content: "";
}

/* '' */
.bi-sport-tennisball:before {
  content: "";
}

/* '' */
.bi-sport-time:before {
  content: "";
}

/* '' */
.bi-sport-trophy:before {
  content: "";
}

/* '' */
.bi-sport-trophy-a:before {
  content: "";
}

/* '' */
.bi-sport-tv:before {
  content: "";
}

/* '' */
.bi-sport-tv-a:before {
  content: "";
}

/* '' */
.bi-medicine-heart:before {
  content: "";
}

/* '' */
.bi-medicine-lab:before {
  content: "";
}

/* '' */
.bi-medicine-lab-a:before {
  content: "";
}

/* '' */
.bi-sport-alt-badge:before {
  content: "";
}

/* '' */
.bi-sport-alt-badge-a:before {
  content: "";
}

/* '' */
.bi-sport-badge:before {
  content: "";
}

/* '' */
.bi-sport-badge-a:before {
  content: "";
}

/* '' */
.bi-sport-badge-b:before {
  content: "";
}

/* '' */
.bi-sport-badge-c:before {
  content: "";
}

/* '' */
.bi-sport-badge-one:before {
  content: "";
}

/* '' */
.bi-sport-badge-one-a:before {
  content: "";
}

/* '' */
.bi-sport-baseball:before {
  content: "";
}

/* '' */
.bi-sport-basketball:before {
  content: "";
}

/* '' */
.bi-sport-basketball-a:before {
  content: "";
}

/* '' */
.bi-sport-bottle:before {
  content: "";
}

/* '' */
.bi-sport-cards:before {
  content: "";
}

/* '' */
.bi-sport-dumbell:before {
  content: "";
}

/* '' */
.bi-sport-dumbell-a:before {
  content: "";
}

/* '' */
.bi-sport-flag:before {
  content: "";
}

/* '' */
.bi-sport-flag-a:before {
  content: "";
}

/* '' */
.bi-sport-football:before {
  content: "";
}

/* '' */
.bi-sport-medic:before {
  content: "";
}

/* '' */
.bi-sport-mic:before {
  content: "";
}

/* '' */
.bi-sport-puzzle:before {
  content: "";
}

/* '' */
.bi-sport-stadium:before {
  content: "";
}

/* '' */
.bi-sport-stadium-a:before {
  content: "";
}

/* '' */
.bi-time-alarm:before {
  content: "";
}

/* '' */
.bi-time-alt-wall-clock:before {
  content: "";
}

/* '' */
.bi-time-calendar:before {
  content: "";
}

/* '' */
.bi-time-calendar-a:before {
  content: "";
}

/* '' */
.bi-time-calendar-b:before {
  content: "";
}

/* '' */
.bi-time-clock:before {
  content: "";
}

/* '' */
.bi-time-clock-a:before {
  content: "";
}

/* '' */
.bi-time-clock-b:before {
  content: "";
}

/* '' */
.bi-time-clock-c:before {
  content: "";
}

/* '' */
.bi-time-hour-glass:before {
  content: "";
}

/* '' */
.bi-time-speed-meter:before {
  content: "";
}

/* '' */
.bi-time-stopwatch:before {
  content: "";
}

/* '' */
.bi-time-stopwatch-a:before {
  content: "";
}

/* '' */
.bi-time-stopwatch-b:before {
  content: "";
}

/* '' */
.bi-time-timer:before {
  content: "";
}

/* '' */
.bi-time-wall-clock:before {
  content: "";
}

/* '' */
.bi-time-watches:before {
  content: "";
}

/* '' */
.bi-time-watches-a:before {
  content: "";
}

/* '' */
.bi-time-watches-b:before {
  content: "";
}

/* '' */
.bi-location-alt-pin:before {
  content: "";
}

/* '' */
.bi-location-avenue:before {
  content: "";
}

/* '' */
.bi-location-compass:before {
  content: "";
}

/* '' */
.bi-location-map:before {
  content: "";
}

/* '' */
.bi-location-pin:before {
  content: "";
}

/* '' */
.bi-location-pin-blank:before {
  content: "";
}

/* '' */
.bi-location-pin-check:before {
  content: "";
}

/* '' */
.bi-location-pin-map:before {
  content: "";
}

/* '' */
.bi-location-pin-map-a:before {
  content: "";
}

/* '' */
.bi-location-pin-minus:before {
  content: "";
}

/* '' */
.bi-location-pin-plus:before {
  content: "";
}

/* '' */
.bi-location-pin-regular:before {
  content: "";
}

/* '' */
.bi-location-sign-street:before {
  content: "";
}

/* '' */
.bi-tool-console:before {
  content: "";
}

/* '' */
.bi-tool-console-a:before {
  content: "";
}

/* '' */
.bi-tool-console-old:before {
  content: "";
}

/* '' */
.bi-tool-disk:before {
  content: "";
}

/* '' */
.bi-tool-disk-a:before {
  content: "";
}

/* '' */
.bi-tool-flashlight:before {
  content: "";
}

/* '' */
.bi-tool-flashlight-a:before {
  content: "";
}

/* '' */
.bi-tool-flashlight-a-s:before {
  content: "";
}

/* '' */
.bi-tool-flashlight-s:before {
  content: "";
}

/* '' */
.bi-tool-gameboy:before {
  content: "";
}

/* '' */
.bi-tool-hammer:before {
  content: "";
}

/* '' */
.bi-tool-headphone:before {
  content: "";
}

/* '' */
.bi-tool-headphone-a:before {
  content: "";
}

/* '' */
.bi-tool-laptop:before {
  content: "";
}

/* '' */
.bi-tool-magic-wand:before {
  content: "";
}

/* '' */
.bi-tool-magic-wand-a:before {
  content: "";
}

/* '' */
.bi-tool-magnet:before {
  content: "";
}

/* '' */
.bi-tool-magnifier:before {
  content: "";
}

/* '' */
.bi-tool-medical-tape:before {
  content: "";
}

/* '' */
.bi-tool-mobile:before {
  content: "";
}

/* '' */
.bi-tool-mobile-l:before {
  content: "";
}

/* '' */
.bi-tool-monitor:before {
  content: "";
}

/* '' */
.bi-tool-monitor-a:before {
  content: "";
}

/* '' */
.bi-tool-mouse:before {
  content: "";
}

/* '' */
.bi-tool-net:before {
  content: "";
}

/* '' */
.bi-tool-paint-roler:before {
  content: "";
}

/* '' */
.bi-tool-projector:before {
  content: "";
}

/* '' */
.bi-tool-radio:before {
  content: "";
}

/* '' */
.bi-tool-ruler:before {
  content: "";
}

/* '' */
.bi-tool-scissor:before {
  content: "";
}

/* '' */
.bi-tool-tablet:before {
  content: "";
}

/* '' */
.bi-tool-tablet-l:before {
  content: "";
}

/* '' */
.bi-tool-television:before {
  content: "";
}

/* '' */
.bi-tool-toothbrush:before {
  content: "";
}

/* '' */
.bi-tool-umbrella:before {
  content: "";
}

/* '' */
.bi-tool-video:before {
  content: "";
}

/* '' */
.bi-tool-alt-camera:before {
  content: "";
}

/* '' */
.bi-tool-alt-camera-a:before {
  content: "";
}

/* '' */
.bi-tool-android:before {
  content: "";
}

/* '' */
.bi-tool-android-l:before {
  content: "";
}

/* '' */
.bi-tool-binoculars:before {
  content: "";
}

/* '' */
.bi-tool-brush:before {
  content: "";
}

/* '' */
.bi-tool-brush-a:before {
  content: "";
}

/* '' */
.bi-tool-calculator:before {
  content: "";
}

/* '' */
.bi-tool-camera:before {
  content: "";
}

/* '' */
.bi-tool-camera-a:before {
  content: "";
}

/* '' */
.bi-tool-camera-b:before {
  content: "";
}

/* '' */
.bi-tool-compass:before {
  content: "";
}

/* '' */
.bi-transport-car-b:before {
  content: "";
}

/* '' */
.bi-transport-car-c:before {
  content: "";
}

/* '' */
.bi-transport-ship:before {
  content: "";
}

/* '' */
.bi-transport-train:before {
  content: "";
}

/* '' */
.bi-transport-truck:before {
  content: "";
}

/* '' */
.bi-transport-truck-a:before {
  content: "";
}

/* '' */
.bi-transport-alt-bus:before {
  content: "";
}

/* '' */
.bi-transport-bicycle:before {
  content: "";
}

/* '' */
.bi-transport-bicycle-a:before {
  content: "";
}

/* '' */
.bi-transport-boat:before {
  content: "";
}

/* '' */
.bi-transport-bus:before {
  content: "";
}

/* '' */
.bi-transport-bus-a:before {
  content: "";
}

/* '' */
.bi-transport-car:before {
  content: "";
}

/* '' */
.bi-transport-car-a:before {
  content: "";
}

/* '' */
.bi-user-male:before {
  content: "";
}

/* '' */
.bi-user-male-check:before {
  content: "";
}

/* '' */
.bi-user-male-cross:before {
  content: "";
}

/* '' */
.bi-user-male-minus:before {
  content: "";
}

/* '' */
.bi-user-male-plus:before {
  content: "";
}

/* '' */
.bi-user-male-sign:before {
  content: "";
}

/* '' */
.bi-user-password:before {
  content: "";
}

/* '' */
.bi-user-password-u:before {
  content: "";
}

/* '' */
.bi-user-password-u-a:before {
  content: "";
}

/* '' */
.bi-user-single:before {
  content: "";
}

/* '' */
.bi-user-single-a:before {
  content: "";
}

/* '' */
.bi-user-single-a-check:before {
  content: "";
}

/* '' */
.bi-user-single-a-cross:before {
  content: "";
}

/* '' */
.bi-user-single-a-group:before {
  content: "";
}

/* '' */
.bi-user-single-a-list:before {
  content: "";
}

/* '' */
.bi-user-single-a-minus:before {
  content: "";
}

/* '' */
.bi-user-single-a-plus:before {
  content: "";
}

/* '' */
.bi-user-single-check:before {
  content: "";
}

/* '' */
.bi-user-single-cross:before {
  content: "";
}

/* '' */
.bi-user-single-minus:before {
  content: "";
}

/* '' */
.bi-user-single-plus:before {
  content: "";
}

/* '' */
.bi-user-single-round:before {
  content: "";
}

/* '' */
.bi-user-alt:before {
  content: "";
}

/* '' */
.bi-user-alt-check:before {
  content: "";
}

/* '' */
.bi-user-alt-cross:before {
  content: "";
}

/* '' */
.bi-user-alt-group:before {
  content: "";
}

/* '' */
.bi-user-alt-list:before {
  content: "";
}

/* '' */
.bi-user-alt-minus:before {
  content: "";
}

/* '' */
.bi-user-alt-plus:before {
  content: "";
}

/* '' */
.bi-user-contact-book:before {
  content: "";
}

/* '' */
.bi-user-female:before {
  content: "";
}

/* '' */
.bi-user-female-cross:before {
  content: "";
}

/* '' */
.bi-user-female-group:before {
  content: "";
}

/* '' */
.bi-user-female-list:before {
  content: "";
}

/* '' */
.bi-user-female-minus:before {
  content: "";
}

/* '' */
.bi-user-female-plus:before {
  content: "";
}

/* '' */
.bi-user-female-sign:before {
  content: "";
}

/* '' */
.bi-user-female-tick:before {
  content: "";
}

/* '' */
.bi-user-key:before {
  content: "";
}

/* '' */
.bi-user-lock:before {
  content: "";
}

/* '' */
.bi-user-lock-u:before {
  content: "";
}

/* '' */
.bi-user-lock-u-a:before {
  content: "";
}

/* '' */
.bi-weather-sunset-a:before {
  content: "";
}

/* '' */
.bi-weather-wind:before {
  content: "";
}

/* '' */
.bi-weather-wind-pressure:before {
  content: "";
}

/* '' */
.bi-weather-wind-pressure-a:before {
  content: "";
}

/* '' */
.bi-weather-cloud:before {
  content: "";
}

/* '' */
.bi-weather-cloud-a:before {
  content: "";
}

/* '' */
.bi-weather-cloud-rainy:before {
  content: "";
}

/* '' */
.bi-weather-cloud-rainy-a:before {
  content: "";
}

/* '' */
.bi-weather-cloud-snow:before {
  content: "";
}

/* '' */
.bi-weather-cloud-snow-a:before {
  content: "";
}

/* '' */
.bi-weather-cloud-stormy:before {
  content: "";
}

/* '' */
.bi-weather-cloud-stormy-a:before {
  content: "";
}

/* '' */
.bi-weather-cloud-sun:before {
  content: "";
}

/* '' */
.bi-weather-humid:before {
  content: "";
}

/* '' */
.bi-weather-moon:before {
  content: "";
}

/* '' */
.bi-weather-moon-a:before {
  content: "";
}

/* '' */
.bi-weather-moon-b:before {
  content: "";
}

/* '' */
.bi-weather-moon-r:before {
  content: "";
}

/* '' */
.bi-weather-sun-a:before {
  content: "";
}

/* '' */
.bi-weather-sun-b:before {
  content: "";
}

/* '' */
.bi-weather-sun-r:before {
  content: "";
}

/* '' */
.bi-weather-sunrise:before {
  content: "";
}

/* '' */
.bi-web-bug:before {
  content: "";
}

/* '' */
.bi-web-code:before {
  content: "";
}

/* '' */
.bi-web-database:before {
  content: "";
}

/* '' */
.bi-web-download:before {
  content: "";
}

/* '' */
.bi-web-graph:before {
  content: "";
}

/* '' */
.bi-web-inspect:before {
  content: "";
}

/* '' */
.bi-web-internet:before {
  content: "";
}

/* '' */
.bi-web-internet-a:before {
  content: "";
}

/* '' */
.bi-web-logout:before {
  content: "";
}

/* '' */
.bi-web-menu-collapse-down:before {
  content: "";
}

/* '' */
.bi-web-menu-collapse-left:before {
  content: "";
}

/* '' */
.bi-web-menu-collapse-right:before {
  content: "";
}

/* '' */
.bi-web-menu-collapse-up:before {
  content: "";
}

/* '' */
.bi-web-reading-list:before {
  content: "";
}

/* '' */
.bi-web-report:before {
  content: "";
}

/* '' */
.bi-web-report-a:before {
  content: "";
}

/* '' */
.bi-web-rss:before {
  content: "";
}

/* '' */
.bi-web-share:before {
  content: "";
}

/* '' */
.bi-web-share-a:before {
  content: "";
}

/* '' */
.bi-web-share-b:before {
  content: "";
}

/* '' */
.bi-web-statistic:before {
  content: "";
}

/* '' */
.bi-web-traffic:before {
  content: "";
}

/* '' */
.bi-web-traffic-a:before {
  content: "";
}

/* '' */
.bi-web-traffic-b:before {
  content: "";
}

/* '' */
.bi-web-traffic-c:before {
  content: "";
}

/* '' */
.bi-web-upload:before {
  content: "";
}

/* '' */
.bi-web-url:before {
  content: "";
}

/* '' */
.bi-web-url-a:before {
  content: "";
}

/* '' */
.bi-web-url-b:before {
  content: "";
}

/* '' */
.bi-web-warning:before {
  content: "";
}

/* '' */
.bi-web-webcam:before {
  content: "";
}

/* '' */
.bi-web-broken-link:before {
  content: "";
}

/* '' */
.bi-web-browser:before {
  content: "";
}

/* '' */
.bi-web-browser-a:before {
  content: "";
}

/* '' */
.bi-web-browser-b:before {
  content: "";
}

/* '' */
.bi-web-browser-cross:before {
  content: "";
}

/* '' */
.bi-web-browser-minus:before {
  content: "";
}

/* '' */
.bi-web-browser-plus:before {
  content: "";
}

/* '' */
.bi-web-browser-tab:before {
  content: "";
}

/* '' */
.bi-web-browser-tab-cross:before {
  content: "";
}

/* '' */
.bi-web-browser-tab-minus:before {
  content: "";
}

/* '' */
.bi-web-browser-tab-plus:before {
  content: "";
}

/* '' */
.bi-web-browser-tab-tick:before {
  content: "";
}

/* '' */
.bi-web-browser-tick:before {
  content: "";
}

/* '' */
.bi-web-browser-window:before {
  content: "";
}

/* '' */
.bi-logo-vimeo:before {
  content: "";
}

/* '' */
.bi-logo-windows:before {
  content: "";
}

/* '' */
.bi-logo-zerply:before {
  content: "";
}

/* '' */
.bi-logo-amazon:before {
  content: "";
}

/* '' */
.bi-logo-dribbble:before {
  content: "";
}

/* '' */
.bi-logo-dropbox:before {
  content: "";
}

/* '' */
.bi-logo-evernote:before {
  content: "";
}

/* '' */
.bi-logo-facebook:before {
  content: "";
}

/* '' */
.bi-logo-grooveshark:before {
  content: "";
}

/* '' */
.bi-logo-instagram:before {
  content: "";
}

/* '' */
.bi-logo-linkedin:before {
  content: "";
}

/* '' */
.bi-logo-musio:before {
  content: "";
}

/* '' */
.bi-logo-path:before {
  content: "";
}

/* '' */
.bi-logo-paypal:before {
  content: "";
}

/* '' */
.bi-logo-picasa:before {
  content: "";
}

/* '' */
.bi-logo-pinterest:before {
  content: "";
}

/* '' */
.bi-logo-rdio:before {
  content: "";
}

/* '' */
.bi-logo-squarespace:before {
  content: "";
}

/* '' */
.bi-logo-squareup:before {
  content: "";
}

/* '' */
.bi-logo-twitter:before {
  content: "";
}

/* '' */
.bi-misc-one-click:before {
  content: "";
}

/* '' */
.bi-misc-pointer:before {
  content: "";
}

/* '' */
.bi-misc-pointer-swipe-l:before {
  content: "";
}

/* '' */
.bi-misc-pointer-swipe-r:before {
  content: "";
}

/* '' */
.bi-misc-puzzle:before {
  content: "";
}

/* '' */
.bi-misc-wifi:before {
  content: "";
}

/* '' */
.bi-misc-crown:before {
  content: "";
}

/* '' */
.bi-misc-cube:before {
  content: "";
}

/* '' */
.bi-misc-dice-five:before {
  content: "";
}

/* '' */
.bi-misc-dice-four:before {
  content: "";
}

/* '' */
.bi-misc-dice-one:before {
  content: "";
}

/* '' */
.bi-misc-dice-six:before {
  content: "";
}

/* '' */
.bi-misc-dice-three:before {
  content: "";
}

/* '' */
.bi-misc-dice-two:before {
  content: "";
}

/* '' */
.bi-misc-double-click:before {
  content: "";
}

/* '' */
.bi-misc-female-gender:before {
  content: "";
}

/* '' */
.bi-misc-globe:before {
  content: "";
}

/* '' */
.bi-misc-male-gender:before {
  content: "";
}

/* '' */
.bi-misc-mood-happy:before {
  content: "";
}

/* '' */
.bi-misc-mood-sad:before {
  content: "";
}

/* '' */
.bi-music-mic:before {
  content: "";
}

/* '' */
.bi-music-mic-a:before {
  content: "";
}

/* '' */
.bi-music-next:before {
  content: "";
}

/* '' */
.bi-music-next-l:before {
  content: "";
}

/* '' */
.bi-music-next-l-a:before {
  content: "";
}

/* '' */
.bi-music-pause:before {
  content: "";
}

/* '' */
.bi-music-pause-a:before {
  content: "";
}

/* '' */
.bi-music-pause-b:before {
  content: "";
}

/* '' */
.bi-music-play:before {
  content: "";
}

/* '' */
.bi-music-playlist:before {
  content: "";
}

/* '' */
.bi-music-previous:before {
  content: "";
}

/* '' */
.bi-music-previous-l:before {
  content: "";
}

/* '' */
.bi-music-previous-l-a:before {
  content: "";
}

/* '' */
.bi-music-radio:before {
  content: "";
}

/* '' */
.bi-music-record:before {
  content: "";
}

/* '' */
.bi-music-record-a:before {
  content: "";
}

/* '' */
.bi-music-repeat:before {
  content: "";
}

/* '' */
.bi-music-repeat-a:before {
  content: "";
}

/* '' */
.bi-music-repeat-one:before {
  content: "";
}

/* '' */
.bi-music-repeat-one-a:before {
  content: "";
}

/* '' */
.bi-music-shuffle:before {
  content: "";
}

/* '' */
.bi-music-shuffle-a:before {
  content: "";
}

/* '' */
.bi-music-song-note:before {
  content: "";
}

/* '' */
.bi-music-song-note-a:before {
  content: "";
}

/* '' */
.bi-music-speaker:before {
  content: "";
}

/* '' */
.bi-music-speaker-a:before {
  content: "";
}

/* '' */
.bi-music-stop:before {
  content: "";
}

/* '' */
.bi-music-stop-a:before {
  content: "";
}

/* '' */
.bi-volume-high:before {
  content: "";
}

/* '' */
.bi-volume-high-a:before {
  content: "";
}

/* '' */
.bi-volume-low:before {
  content: "";
}

/* '' */
.bi-volume-low-a:before {
  content: "";
}

/* '' */
.bi-volume-medium:before {
  content: "";
}

/* '' */
.bi-volume-medium-a:before {
  content: "";
}

/* '' */
.bi-volume-mute:before {
  content: "";
}

/* '' */
.bi-volume-mute-a:before {
  content: "";
}

/* '' */
.bi-music-album:before {
  content: "";
}

/* '' */
.bi-music-album-a:before {
  content: "";
}

/* '' */
.bi-music-album-b:before {
  content: "";
}

/* '' */
.bi-music-album-c:before {
  content: "";
}

/* '' */
.bi-music-album-cd:before {
  content: "";
}

/* '' */
.bi-music-cd:before {
  content: "";
}

/* '' */
.bi-music-eject:before {
  content: "";
}

/* '' */
.bi-music-eq:before {
  content: "";
}

/* '' */
.bi-music-eq-a:before {
  content: "";
}

/* '' */
.bi-music-group-note:before {
  content: "";
}

/* '' */
.bi-music-group-note-a:before {
  content: "";
}

/* '' */
.bi-music-headphone:before {
  content: "";
}

/* '' */
.bi-music-headphone-a:before {
  content: "";
}

/* '' */
.bi-music-list:before {
  content: "";
}

/* '' */
.bi-interface-backspace:before {
  content: "";
}

/* '' */
.bi-interface-backspace-a:before {
  content: "";
}

/* '' */
.bi-interface-bell:before {
  content: "";
}

/* '' */
.bi-interface-bell-a:before {
  content: "";
}

/* '' */
.bi-interface-block:before {
  content: "";
}

/* '' */
.bi-interface-bottom:before {
  content: "";
}

/* '' */
.bi-interface-bottom-k:before {
  content: "";
}

/* '' */
.bi-interface-bottom-r:before {
  content: "";
}

/* '' */
.bi-interface-box-bottom:before {
  content: "";
}

/* '' */
.bi-interface-box-bottom-a:before {
  content: "";
}

/* '' */
.bi-interface-box-cross:before {
  content: "";
}

/* '' */
.bi-interface-box-left:before {
  content: "";
}

/* '' */
.bi-interface-box-left-a:before {
  content: "";
}

/* '' */
.bi-interface-box-minus:before {
  content: "";
}

/* '' */
.bi-interface-box-plus:before {
  content: "";
}

/* '' */
.bi-interface-box-right:before {
  content: "";
}

/* '' */
.bi-interface-box-right-a:before {
  content: "";
}

/* '' */
.bi-interface-box-tick:before {
  content: "";
}

/* '' */
.bi-interface-box-top:before {
  content: "";
}

/* '' */
.bi-interface-box-top-a:before {
  content: "";
}

/* '' */
.bi-interface-bulleye:before {
  content: "";
}

/* '' */
.bi-interface-calendar:before {
  content: "";
}

/* '' */
.bi-interface-circle:before {
  content: "";
}

/* '' */
.bi-interface-circle-cross:before {
  content: "";
}

/* '' */
.bi-interface-circle-minus:before {
  content: "";
}

/* '' */
.bi-interface-circle-plus:before {
  content: "";
}

/* '' */
.bi-interface-circle-tick:before {
  content: "";
}

/* '' */
.bi-interface-circle-tick-a:before {
  content: "";
}

/* '' */
.bi-interface-clock:before {
  content: "";
}

/* '' */
.bi-interface-clock-a:before {
  content: "";
}

/* '' */
.bi-interface-clock-b:before {
  content: "";
}

/* '' */
.bi-interface-cloud-download:before {
  content: "";
}

/* '' */
.bi-interface-cloud-upload:before {
  content: "";
}

/* '' */
.bi-interface-cmd:before {
  content: "";
}

/* '' */
.bi-interface-crop:before {
  content: "";
}

/* '' */
.bi-interface-cross:before {
  content: "";
}

/* '' */
.bi-interface-dashboard:before {
  content: "";
}

/* '' */
.bi-interface-direction:before {
  content: "";
}

/* '' */
.bi-interface-downloading:before {
  content: "";
}

/* '' */
.bi-interface-enlarge:before {
  content: "";
}

/* '' */
.bi-interface-enlarge-a:before {
  content: "";
}

/* '' */
.bi-interface-enlarge-c:before {
  content: "";
}

/* '' */
.bi-interface-expand:before {
  content: "";
}

/* '' */
.bi-interface-forward:before {
  content: "";
}

/* '' */
.bi-interface-fullscreen:before {
  content: "";
}

/* '' */
.bi-interface-fullscreen-a:before {
  content: "";
}

/* '' */
.bi-interface-fullscreen-wide:before {
  content: "";
}

/* '' */
.bi-interface-hamburger:before {
  content: "";
}

/* '' */
.bi-interface-heart:before {
  content: "";
}

/* '' */
.bi-interface-help:before {
  content: "";
}

/* '' */
.bi-interface-help-a:before {
  content: "";
}

/* '' */
.bi-interface-home:before {
  content: "";
}

/* '' */
.bi-interface-home-a:before {
  content: "";
}

/* '' */
.bi-interface-horizontal:before {
  content: "";
}

/* '' */
.bi-interface-horizontal-a:before {
  content: "";
}

/* '' */
.bi-interface-in-link:before {
  content: "";
}

/* '' */
.bi-interface-left:before {
  content: "";
}

/* '' */
.bi-interface-left-b-k:before {
  content: "";
}

/* '' */
.bi-interface-left-k:before {
  content: "";
}

/* '' */
.bi-interface-left-r:before {
  content: "";
}

/* '' */
.bi-interface-left-t-k:before {
  content: "";
}

/* '' */
.bi-interface-list-view:before {
  content: "";
}

/* '' */
.bi-interface-login:before {
  content: "";
}

/* '' */
.bi-interface-logout-a:before {
  content: "";
}

/* '' */
.bi-interface-minimize:before {
  content: "";
}

/* '' */
.bi-interface-minus:before {
  content: "";
}

/* '' */
.bi-interface-more:before {
  content: "";
}

/* '' */
.bi-interface-number:before {
  content: "";
}

/* '' */
.bi-interface-out-link:before {
  content: "";
}

/* '' */
.bi-interface-pixel:before {
  content: "";
}

/* '' */
.bi-interface-places:before {
  content: "";
}

/* '' */
.bi-interface-places-a:before {
  content: "";
}

/* '' */
.bi-interface-plus:before {
  content: "";
}

/* '' */
.bi-interface-popup:before {
  content: "";
}

/* '' */
.bi-interface-power:before {
  content: "";
}

/* '' */
.bi-interface-refresh:before {
  content: "";
}

/* '' */
.bi-interface-repeating:before {
  content: "";
}

/* '' */
.bi-interface-reply:before {
  content: "";
}

/* '' */
.bi-interface-resize-full:before {
  content: "";
}

/* '' */
.bi-interface-resize-full-a:before {
  content: "";
}

/* '' */
.bi-interface-resize-normal:before {
  content: "";
}

/* '' */
.bi-interface-reverse:before {
  content: "";
}

/* '' */
.bi-interface-right:before {
  content: "";
}

/* '' */
.bi-interface-right-k:before {
  content: "";
}

/* '' */
.bi-interface-right-r:before {
  content: "";
}

/* '' */
.bi-interface-rigth-b-k:before {
  content: "";
}

/* '' */
.bi-interface-rigth-t-k:before {
  content: "";
}

/* '' */
.bi-interface-search:before {
  content: "";
}

/* '' */
.bi-interface-search-cross:before {
  content: "";
}

/* '' */
.bi-interface-search-minus:before {
  content: "";
}

/* '' */
.bi-interface-search-plus:before {
  content: "";
}

/* '' */
.bi-interface-search-tick:before {
  content: "";
}

/* '' */
.bi-interface-sidebar-hamburger:before {
  content: "";
}

/* '' */
.bi-interface-star:before {
  content: "";
}

/* '' */
.bi-interface-star-a:before {
  content: "";
}

/* '' */
.bi-interface-target:before {
  content: "";
}

/* '' */
.bi-interface-thumbnail:before {
  content: "";
}

/* '' */
.bi-interface-tick:before {
  content: "";
}

/* '' */
.bi-interface-top:before {
  content: "";
}

/* '' */
.bi-interface-top-k:before {
  content: "";
}

/* '' */
.bi-interface-top-r:before {
  content: "";
}

/* '' */
.bi-interface-undo:before {
  content: "";
}

/* '' */
.bi-interface-uploading:before {
  content: "";
}

/* '' */
.bi-interface-vertical:before {
  content: "";
}

/* '' */
.bi-interface-vertical-a:before {
  content: "";
}

/* '' */
.bi-interface-view:before {
  content: "";
}

/* '' */
.bi-interface-warning:before {
  content: "";
}

/* '' */
.bi-interface-window:before {
  content: "";
}

/* '' */
.bi-layout-footer:before {
  content: "";
}

/* '' */
.bi-layout-grid:before {
  content: "";
}

/* '' */
.bi-layout-half:before {
  content: "";
}

/* '' */
.bi-layout-header:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-l:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-l-a:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-l-half:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-l-half-a:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-r:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-r-a:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-r-half:before {
  content: "";
}

/* '' */
.bi-layout-sidebar-r-half-a:before {
  content: "";
}

/* '' */
.bi-layout-third-h:before {
  content: "";
}

/* '' */
.bi-layout-third-v:before {
  content: "";
}

/* '' */
.bi-layout-wireframe:before {
  content: "";
}

/* '' */
.bi-layout-wireframe-a:before {
  content: "";
}

/* '' */
.bi-interface-alt-cross:before {
  content: "";
}

/* '' */
.bi-interface-alt-fullscreen:before {
  content: "";
}

/* '' */
.bi-interface-alt-minus:before {
  content: "";
}

/* '' */
.bi-interface-alt-plus:before {
  content: "";
}

/* '' */
.bi-interface-alt-tick:before {
  content: "";
}

/* '' */
.bi-interface-alt-widescreen:before {
  content: "";
}

/* '' */
.bi-interface-arrow-all:before {
  content: "";
}

/* '' */
.bi-interface-arrow-bottom:before {
  content: "";
}

/* '' */
.bi-interface-arrow-bottom-circle:before {
  content: "";
}

/* '' */
.bi-interface-arrow-left:before {
  content: "";
}

/* '' */
.bi-interface-arrow-left-b:before {
  content: "";
}

/* '' */
.bi-interface-arrow-left-circle:before {
  content: "";
}

/* '' */
.bi-interface-arrow-left-t:before {
  content: "";
}

/* '' */
.bi-interface-arrow-right:before {
  content: "";
}

/* '' */
.bi-interface-arrow-right-b:before {
  content: "";
}

/* '' */
.bi-interface-arrow-right-circle:before {
  content: "";
}

/* '' */
.bi-interface-arrow-right-t:before {
  content: "";
}

/* '' */
.bi-interface-arrow-top:before {
  content: "";
}

/* '' */
.bi-interface-arrow-top-circle:before {
  content: "";
}

/* '' */
.bi-animal-dog-a:before {
  content: "";
}

/* '' */
.bi-interface-horizontal-a-1:before {
  content: "";
}

/* '' */
.bi-interface-vertical-a-1:before {
  content: "";
}

/* '' */
.bi-interface-diag:before {
  content: "";
}

/* '' */
.bi-interface-diag-a:before {
  content: "";
}

/* '' */
.list-authors__level {
  text-transform: uppercase;
}

/**
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    contenteditable attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that are clearfixed.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
* 3. Use width: 100% instead of clear: both on the :after pseudo-element
*    The clear: both on the after pseuod-element is known to causes issues
*    when used as clearfix technique on elements within a containing element
*    that which uses margin offset to position the containing element
*    relative to a floated sibling element
*
*    Reference:
*    http://nicolasgallagher.com/micro-clearfix-hack/#comment-43304
*    http://nicolasgallagher.com/micro-clearfix-hack/#comment-77581
*
*/
.media, .service-listing-container .list-related, .grid-row {
  /**
   * For IE 6/7 only
   * Include this rule to trigger hasLayout and contain floats.
   */
  *zoom: 1;
}
.media:after, .service-listing-container .list-related:after, .grid-row:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.hero-profile .hero-image, .cube__slide, .trending-topics .trending-topics__bg, .page-header, .cube__service, .list-related .list__item--services {
  background: transparent no-repeat center center;
  background-size: cover;
}

.list-horizontal, .list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  font-size: 100%;
  font-family: "Quattrocento", serif;
  font-weight: 400;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  html {
    font-size: 87.5%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1-size,
.h2-size,
.h3-size,
.h4-size,
.h5-size,
.section-header {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.1;
}

h2,
.section-header {
  font-size: 1.7777777778rem;
  line-height: 1.1;
  margin-bottom: 0.4444444444rem;
  text-transform: uppercase;
  color: #464645;
}
@media screen and (max-width: 667px) {
  h2,
.section-header {
    font-size: 1.3333333333rem;
  }
}

.section-header {
  margin-bottom: 1.65rem;
}

.header--emphasis {
  font-size: 2.4444444444rem;
  line-height: 1.1;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
  margin-bottom: 1.65rem;
  text-transform: uppercase;
  color: #009a45;
  letter-spacing: 0.05rem strong;
  letter-spacing-font-weight: 500;
}
@media screen and (max-width: 767px) {
  .header--emphasis {
    font-size: 2.2222222222rem;
  }
}

.article-title-size, .cube__insights-article {
  font-size: 1.667rem;
  line-height: 1.3;
  font-weight: 300;
}
@media screen and (max-height: 820px) {
  .article-title-size, .cube__insights-article {
    font-size: 1.45rem;
    line-height: 1.2;
  }
}
@media screen and (max-width: 767px) {
  .article-title-size, .cube__insights-article {
    font-size: 1.45rem;
  }
}
@media screen and (max-width: 667px) {
  .article-title-size, .cube__insights-article {
    font-size: 1.2rem;
  }
}

.callout-size {
  font-size: 2.2222rem;
  line-height: 1.15;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}
@media screen and (max-width: 767px) {
  .callout-size {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 667px) {
  .callout-size {
    font-size: 1.2rem;
  }
}

.subnav__link {
  font-size: 0.7777777778rem;
  line-height: 1.1;
}

/*---------- Font Primary richtext copy  ----------*/
/*---------- Font ACCENTS  ----------*/
/*----------  Font Secondary  ----------*/
@font-face {
  font-family: "Big-Caslon-Roman";
  src: url("/assets/site/Main/public/fonts/Big-Caslon-Roman.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gloss_And_Bloom";
  src: url("/assets/site/Main/public/fonts/Gloss_And_Bloom.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
a {
  color: #5b7e99;
  text-decoration: none;
  transition: color 0.25s ease-in, border-color 0.25s ease-in, opacity 0.25s ease-in, background-color 0.25s ease-in;
}
a:before, a:after {
  transition: color 0.25s ease-in, border-color 0.25s ease-in, opacity 0.25s ease-in, background-color 0.25s ease-in;
}
a:hover, a:active, a:focus {
  outline: 0;
}

a:hover {
  color: #578fdb;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #1e1e1e;
  z-index: -1;
  opacity: 0;
  transform: translateZ(0);
  transition: opacity 0.35s;
}
body[data-modal=true]:before {
  opacity: 0.8;
  z-index: 0;
}
body:not(#homepage) {
  max-width: 130rem;
  margin: 0 auto;
}

p {
  margin-bottom: 1.65rem;
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

address {
  font-style: normal;
  margin-bottom: 1.65rem;
}

time {
  display: block;
}

.main-content {
  padding: 1em;
  overflow: hidden;
}

.rich-text blockquote,
.blockquote {
  border-left: 1.1111111111rem solid #e1e1e1;
  margin: 3.3rem 0;
  padding-left: 3.3333333333rem;
  font-size: 1.7777777778rem;
  font-style: italic;
  line-height: 1.2;
  position: relative;
  color: #464645;
  line-height: 1.4;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.rich-text blockquote:before,
.blockquote:before {
  content: "”";
  position: absolute;
  left: 1.5em;
}
.rich-text blockquote:after,
.blockquote:after {
  content: "”";
}
.rich-text blockquote > p,
.blockquote > p {
  margin-bottom: 0;
}
.rich-text blockquote cite,
.blockquote cite {
  font-size: 1.3333333333rem;
  font-style: normal;
  display: block;
  margin-top: 0.825rem;
}

.rich-text ol li:before {
  content: none;
}

.people-quote-section blockquote {
  margin-bottom: 0;
}
.people-quote-section .quote-source {
  border-left: 1.1111111111rem solid #e1e1e1;
  padding-left: 3.3333333333rem;
  padding-top: 1.1111111111rem;
}

.title-border {
  margin-top: 1.65rem;
}

ul {
  list-style: none;
  margin: 0 0 1.65rem;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 667px) {
  .show-mobile {
    display: inline-block !important;
  }

  .show-desktop {
    display: none !important;
  }
}
[v-cloak] {
  display: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.grid-row-inline-block {
  overflow: hidden;
  font-size: 0;
  line-height: 0;
}
.grid-row-inline-block > [class*=grid-col] {
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
  vertical-align: top;
}

.grid-row-reversed > [class*=grid-col] {
  float: right;
}

[class*=grid-col] {
  float: left;
}

.grid-col-33 {
  width: 33.33333%;
}

.grid-col-50 {
  width: 50%;
}

.grid-col-20 {
  width: 20%;
}

.grid-col-25 {
  width: 25%;
}

.grid-col-40 {
  width: 40%;
}

.grid-col-35 {
  width: 35%;
}

.grid-col-60 {
  width: 60%;
}

.grid-col-sidebar {
  width: 28%;
  line-height: 1.4;
  float: right;
}
@media screen and (max-width: 899px) {
  .grid-col-sidebar {
    width: 30%;
  }
}

.grid-col-content {
  width: 66%;
}

.l-grid:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.l-grid__item {
  width: 100%;
}

.l-grid__item--centered {
  text-align: center;
}

@media screen and (min-width: 668px) {
  .l-grid__item1\/3\@lg {
    float: left;
    width: 33.3333%;
    padding-right: 1rem;
  }
}
.l-grid__item1\/3\@lg:nth-child(3n) {
  padding-right: 0;
}

.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
}

.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-text-align-right {
  text-align: right;
}

.util-text-align-center {
  text-align: center;
}

.clearfix {
  clear: both;
}

[v-cloak] {
  display: none;
}

.site-layout-wrapper, .bio-detail .scrollspy .page-wrapper {
  max-width: 77.7777777778rem;
  margin: 0 auto;
  padding: 0 1.9rem;
  height: 100%;
}
@media screen and (max-width: 899px) {
  .site-layout-wrapper, .bio-detail .scrollspy .page-wrapper {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 667px) {
  .site-layout-wrapper, .bio-detail .scrollspy .page-wrapper {
    padding: 0 1.2rem;
  }
}

.page-wrapper {
  max-width: 55.5555555556rem;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.9rem;
  padding-left: 1.9rem;
}
@media screen and (max-width: 899px) {
  .page-wrapper {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 667px) {
  .page-wrapper {
    padding: 0 1.2rem;
  }
}

.page-wrapper--centered {
  text-align: center;
}

.page-main {
  display: block;
  margin-top: 125px;
}
.page-main:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}
@media screen and (max-width: 979px) {
  .page-main {
    margin-top: 86px;
  }
}
@media screen and (max-width: 667px) {
  .page-main .rich-text iframe {
    width: 100%;
  }
}

.page-body {
  margin-top: 4.125rem;
  margin-bottom: 4.125rem;
}

.page-content {
  overflow: hidden;
}
.page-content.case-study {
  background-color: #fff;
}

.page-content-shade {
  background-color: #f6f6f6;
  overflow: hidden;
}

.page-module + .page-module {
  margin-top: 3.3rem;
}

.accessibility-aid {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.accessibility-aid:focus {
  top: 0;
  z-index: 1;
  width: auto;
  height: auto;
  padding: 0 10px;
  clip: auto;
  font-weight: bold;
  line-height: 49px;
  /* you may want to change this value */
  color: #333;
  /* you may want to change this value */
  text-decoration: none;
  background: #f5f5f5;
  /* you may want to change this value */
}

.collapsible [aria-hidden=true] {
  display: none;
}
.collapsible [aria-hidden=false] {
  display: block;
}
.collapsible [class^=trigger-] {
  margin-left: 4px;
}
.collapsible [aria-expanded=false] .trigger-collapsed {
  display: inline-block;
}
.collapsible [aria-expanded=false] .trigger-expanded {
  display: none;
}
.collapsible [aria-expanded=true] .trigger-collapsed {
  display: none;
}
.collapsible [aria-expanded=true] trigger-expanded {
  display: inline-block;
}

button,
.button {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  padding: 0.4rem 1rem;
  border: 1px solid transparent;
  font-size: 0.7rem;
  font-family: "Oxygen", sans-serif;
  font-weight: 300;
  transition: color 0.25s ease-in, border-color 0.25s ease-in, opacity 0.25s ease-in, background-color 0.25s ease-in;
  border-radius: 4px;
  outline: 0;
}
button + button,
button + .button,
.button + button,
.button + .button {
  margin-left: 1rem;
}

.button--ghost, .button--regent {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  border: 2px solid #5a7d99;
  color: #5a7d99;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.5555555556rem 2.2222222222rem;
  text-transform: uppercase;
}
.button--ghost:hover, .button--regent:hover {
  color: #fff;
  background-color: #5a7d99;
}
@media screen and (max-width: 767px) {
  .button--ghost, .button--regent {
    padding-top: 0.7777777778rem;
    padding-bottom: 0.7777777778rem;
  }
}

.button--ghost-reverse {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.5555555556rem 2.2222222222rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #5a7d99;
  border: 2px solid #5a7d99;
}
.button--ghost-reverse:hover {
  background-color: #fff;
  color: #5a7d99;
}
.button--ghost-reverse.emailDisclaimer {
  color: black;
}
.button--ghost-reverse.emailDisclaimerCancel {
  background-color: #5a7d99;
  color: white;
}

.button--regent {
  border: 2px solid #FFF;
  color: #FFF;
}
.button--regent:hover {
  border-color: #5a7d99;
}

.button--white {
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  border: 2px solid #fff;
  color: #fff;
  font-size: 1rem;
  line-height: 1.1;
  padding: 0.5555555556rem 2.2222222222rem;
  text-transform: uppercase;
}
.button--white:hover {
  color: #464645;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .button--white {
    padding-top: 0.7777777778rem;
    padding-bottom: 0.7777777778rem;
  }
}

.button--black {
  color: #000000;
  border: 1px solid black;
}
.button--black:hover {
  color: #fff;
  background-color: #000000;
}

.button--card-grid {
  font-family: "brandon-grotesque", sans-serif;
  padding: 12px 32px;
  margin: 0 auto;
  display: table;
  text-align: center;
}
.button--card-grid .bi {
  padding-left: 0.5em;
}

.button--icon {
  border: 0 none;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}

.button--full {
  width: 100%;
}

.button--large {
  width: 100%;
  font-size: 1rem;
  text-align: center;
}

.button--green {
  background-color: rgba(10, 154, 69, 0.75);
  color: #fff;
}
.button--green:hover {
  background-color: #009a45;
  color: #fff;
}

.button--load-more {
  display: block;
  margin: 0 auto;
}
.button--load-more + .rich-text {
  margin-top: 3.3rem;
}

.button--accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background-color: #fff;
  color: #009a45;
  text-align: center;
  padding: 0;
  z-index: 10;
  width: 35px;
  height: 35px;
}
.button--accordion-toggle:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  transition: transform 0.4s;
  transform-origin: 50% 50%;
  font-size: 0.85rem;
  font-weight: 700;
}
.button--accordion-toggle.expanded:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
}

.services-accordion .button-expand-all {
  color: #627278;
  font-weight: 700;
  float: right;
  text-transform: uppercase;
  font-size: 0.9rem;
}
@media screen and (max-width: 767px) {
  .services-accordion .button-expand-all {
    display: none;
  }
}
.services-accordion .button-expand-all:after {
  margin-left: 0.8333333333rem;
}
.services-accordion .button-expand-all:hover {
  color: #578fdb;
}
.services-accordion .button-expand-all.expand:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  color: inherit;
}
.services-accordion .button-expand-all.collapse {
  margin-left: 0;
}
.services-accordion .button-expand-all.collapse:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  color: inherit;
}

.list-horizontal .list__item {
  display: inline;
}
.list-horizontal .list__link {
  display: inline-block;
}

.list-vertical {
  padding: 0;
}
.list-vertical .list__item:after {
  content: none;
}

.list-linked-white .list__link {
  color: #e1e1e1;
  display: inline-block;
}

.list-related .list__item {
  margin-bottom: 0.2222222222rem;
  font-size: 1.2222222222rem;
  line-height: 1.4;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
}
.list-related .list__item--services {
  position: relative;
  height: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}
.list-related .list__item--services:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(98, 114, 120, 0.8);
  z-index: 3;
  opacity: 1;
  transition: opacity 0.4s ease-in;
}
.list-related .list__item--services:hover, .list-related .list__item--services.expanded {
  background-color: transparent;
}
.list-related .list__item--services:hover:before, .list-related .list__item--services.expanded:before {
  opacity: 0.6;
}
.list-related .list__item--services .list__link {
  background: transparent;
  padding: 3rem 6rem 3rem 3rem;
  position: relative;
  color: #fff;
  z-index: 5;
  text-transform: uppercase;
  font-size: 1.25rem;
}
.list-related .list__item--services .list__link:hover {
  background-color: transparent;
}
.list-related .list__item--services .list__link:after {
  content: none;
}
.list-related .list__item--services-inner .list__link {
  padding: 0.8333333333rem 0 0.8333333333rem 0;
  font-size: 1.25rem;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  color: #5a7d99;
}
.list-related .list__item--services-inner .list__link:hover {
  color: #578fdb;
}
.list-related .list__link {
  display: block;
  background-color: #fff;
  padding: 1.1111111111rem 2.5rem 1.1111111111rem 1.6666666667rem;
  position: relative;
}
.list-related .list__link:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #93bed3;
  position: absolute;
  right: 1.1111111111rem;
}
.list-related .list__link:hover {
  color: #f6f6f6;
  background-color: #5b7e99;
}
.list-related .list__link:hover:after {
  color: #f6f6f6;
}
.list-related .list__link--services {
  background: transparent;
  color: #666666;
}
.list-related .list__link--services:hover {
  background-color: transparent;
}
.list-related .list__link--services:after {
  content: none;
}

.list-authors .media__img {
  max-width: 45%;
  padding-bottom: 20px;
}
@media screen and (max-width: 667px) {
  .list-authors .media__img {
    max-width: none;
    float: none;
    margin-bottom: 0.5rem;
    padding-bottom: 0px;
  }
}
.list-authors__name {
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  font-size: 1.3333333333rem;
}
.list-authors__level {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: #464646;
  margin-bottom: 0.5rem;
}
.list-authors .media__bodyspace {
  overflow: hidden;
  margin-bottom: 1.3rem;
}
.list-authors .list-authors-result-details {
  font-size: 1rem;
}
@media (min-width: 25rem) {
  .list-authors .list-authors-result-details {
    font-size: 1.2222222222rem;
  }
}
.list-authors .list-authors-result-tel::before {
  content: "";
}
.list-authors .list-authors-result-tel a {
  color: #000;
}
.list-authors .list-authors-result-email,
.list-authors .list-authors-result-tel,
.list-authors .list-authors-result-location {
  margin-bottom: 0.6rem;
}
.list-authors .list-authors-result-email::before,
.list-authors .list-authors-result-tel::before,
.list-authors .list-authors-result-location::before {
  color: #555;
  display: inline-block;
  font-family: "Font Awesome";
  margin-right: 2rem;
}
@media (min-width: 25rem) {
  .list-authors .list-authors-result-email::before,
.list-authors .list-authors-result-tel::before,
.list-authors .list-authors-result-location::before {
    margin-right: 1.3rem;
  }
}
.list-authors .list-authors-result-email {
  margin-bottom: 0;
}
.list-authors .list-authors-result-email::before {
  content: "";
}
.list-authors .list-authors-result-title {
  font-size: 1.1rem;
  margin-bottom: 1.75rem;
}
@media (min-width: 47.2222222222rem) {
  .list-authors .list-authors-result-title {
    font-size: 1.4444444444rem;
    margin-bottom: 0.25rem;
  }
}

.list-focus-areas {
  columns: 2 auto;
}
@media screen and (max-width: 767px) {
  .list-focus-areas {
    columns: 1;
  }
}
.list-focus-areas .list__item {
  margin-bottom: 0.2222222222rem;
  font-size: 1.2222222222rem;
  line-height: 1.4;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
}
.list-focus-areas .list__link {
  display: block;
  background-color: #fff;
  padding: 1.1111111111rem 2.5rem 1.1111111111rem 1.6666666667rem;
  position: relative;
}
.list-focus-areas .list__link:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #93bed3;
  position: absolute;
  right: 1.1111111111rem;
}
.list-focus-areas .list__link:hover {
  color: #f6f6f6;
  background-color: #5b7e99;
}
.list-focus-areas .list__link:hover:after {
  color: #f6f6f6;
}

.list-articles,
.list-articles-nojs {
  margin-bottom: 3.3rem;
}
.list-articles .list__item,
.list-articles-nojs .list__item {
  margin-bottom: 2.2222222222rem;
  padding-bottom: 2.2222222222rem;
  border-bottom: 1px solid #d7d7d7;
}
.list-articles__link,
.list-articles-nojs__link {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  font-size: 1.3333333333rem;
  line-height: 1.4;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.list-articles__date,
.list-articles-nojs__date {
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  color: #464645;
  margin-bottom: 0.5rem;
}
.list-articles__abstract,
.list-articles-nojs__abstract {
  font-size: 1.2222222222rem;
  line-height: 1.4;
}
.list-articles__btn,
.list-articles-nojs__btn {
  display: block;
  margin: 0 auto;
}
.list-articles__type,
.list-articles-nojs__type {
  margin-bottom: 20px;
  margin-top: -7px;
}
.list-articles__tags,
.list-articles-nojs__tags {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  color: #009a45;
  font-weight: 500;
  margin-top: 0.5rem;
}

fieldset {
  border: 0 none;
  padding: 0;
}

input[type=text],
input[type=password],
input[type=email],
textarea,
select {
  display: inline-block;
  background-color: #e1e1e1;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-family: "Oxygen", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: #898989;
}
input[type=text]:active, input[type=text]:focus,
input[type=password]:active,
input[type=password]:focus,
input[type=email]:active,
input[type=email]:focus,
textarea:active,
textarea:focus,
select:active,
select:focus {
  outline: 0;
}
input[type=text]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #898989;
}
input[type=text]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=email]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  color: #898989;
}
input[type=text]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=email]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  color: #898989;
}
input[type=text]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #898989;
}

/* ====== MEDIA OBJECT ====== */
/* URL: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/

*/
.media__img {
  float: left;
  margin-right: 1.65rem;
  display: block;
}
.media__img > img {
  display: block;
}

.media__body {
  overflow: hidden;
}

/* ====== FLAG OBJECT ====== */
/* URL: http://csswizardry.com/2013/05/the-flag-object/ */
.flag {
  display: table;
  width: auto;
}

.flag__image, .flag__body {
  display: table-cell;
  vertical-align: middle;
}
.flag--top .flag__image, .flag--top .flag__body {
  vertical-align: top;
}
.flag--bottom .flag__image, .flag--bottom .flag__body {
  vertical-align: bottom;
}

.flag__image {
  padding-right: 1.65rem;
}
.flag__image > img {
  display: block;
}
.flag--rev .flag__image {
  padding-right: 0;
  padding-left: 10px;
}

.flag__body {
  width: 100%;
}

.site-nav {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  margin-right: 50px;
  padding-left: 7.6666666667rem;
  white-space: nowrap;
  text-transform: uppercase;
  transform: translateZ(0);
}
@media screen and (max-width: 1079px) {
  .site-nav {
    font-size: 0.8888888889rem;
  }
}
@media screen and (max-width: 979px) {
  .site-nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0;
    white-space: normal;
    padding-left: 0;
  }
}
@media screen and (max-width: 667px) {
  .site-nav {
    transform: translateY(-40%);
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .site-nav {
    top: auto;
    transform: translateY(0);
    margin-top: 4.4444444444rem;
  }
}
@media screen and (max-width: 419px) {
  .site-nav {
    top: 40%;
  }
}

.site-nav__list {
  position: relative;
  display: inline;
}
@media screen and (max-width: 979px) {
  .site-nav__list {
    display: block;
  }
  .site-nav__list > .list__item {
    display: block;
    position: relative;
  }
}
.site-nav__list .list__item + .list__item {
  margin-left: 3%;
}
@media screen and (max-width: 979px) {
  .site-nav__list .list__item + .list__item {
    margin-left: 0;
  }
}
@media screen and (min-width: 420px) {
  .site-nav__list .list__item:last-child .subnav {
    text-align: right;
  }
  .site-nav__list .list__item:nth-last-of-type(2) .subnav {
    text-align: right;
  }
}
@media screen and (max-width: 419px) {
  .site-nav__list .list__item .subnav {
    text-align: center;
  }
}

@media screen and (max-width: 419px) {
  .is-visible-subnav {
    margin-bottom: 0 !important;
  }
}
.subnav {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in, visibility 0.2s ease-in 0.4s;
}
@media screen and (max-width: 419px) {
  .subnav {
    position: relative;
    display: none;
    margin-left: -6%;
  }
  .subnav .is-visible-subnav + .subnav {
    display: none;
  }
  .subnav .list__item {
    display: block;
    padding: 1% 0;
    font-size: 1.1em;
  }
}
.is-visible-subnav + .subnav {
  transition: opacity 0.4s ease-in, visibility 0.4s ease-in;
  opacity: 1;
  display: block;
  visibility: visible;
  margin-bottom: 4%;
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .is-visible-subnav + .subnav {
    position: relative;
  }
}
.subnav .list__item + .list__item {
  margin-left: 4%;
}
@media screen and (max-width: 979px) {
  .subnav {
    margin-left: 0;
    padding: 1.65rem 1.65rem 0;
  }
}
@media screen and (max-width: 979px) {
  .subnav {
    padding-top: 0.2777777778rem;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .subnav {
    padding-bottom: 0.8333333333rem;
  }
}

.site-nav__link,
.subnav__link {
  line-height: 1;
  display: inline-block;
  color: #e1e1e1;
}
.site-nav__link:hover,
.subnav__link:hover {
  color: black;
}
@media screen and (max-width: 979px) {
  .site-nav__link,
.subnav__link {
    color: #e1e1e1;
  }
}

.site-nav__link {
  position: relative;
}
.site-nav__link.active-nav {
  font-weight: 500;
}
.site-nav__link.active-nav:after {
  font-weight: 600 !important;
}
.subnav-active .site-nav__link {
  opacity: 0.5;
}
.site-nav__link.subnav__dropdown:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  margin-left: 0.5rem;
  font-size: 0.8333333333rem;
  transition: transform 0.4s;
}
.site-nav__link.subnav__dropdown.is-visible-subnav {
  opacity: 1;
}
.site-nav__link.subnav__dropdown.is-visible-subnav:after {
  transform: rotateX(180deg);
}
@media screen and (max-width: 979px) {
  .site-nav__link {
    font-size: 1.1111111111rem;
    display: block;
    margin-bottom: 10%;
    margin-left: 0;
  }
}
@media screen and (max-width: 667px) {
  .site-nav__link {
    font-size: 1.3rem;
    margin-bottom: 5%;
    display: block;
  }
}
@media screen and (max-width: 419px) {
  .site-nav__link {
    font-size: 1.2rem;
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .site-nav__link {
    margin-bottom: 15px;
  }
}

.searchbox {
  position: relative;
}

.searchbox__input {
  width: 100%;
  padding: 0.5rem 1rem;
}
.generic .searchbox__input {
  background-color: #FFF;
  padding: 0.5rem 3.5rem;
}

.btn-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  margin-left: 0.8rem;
}
.btn-search-icon i.bi {
  font-size: 125%;
  color: #5b7e99;
}

.searchbox__button,
.searchbox__btn {
  position: absolute;
  right: 0;
  padding: 0 1.5rem;
  top: 0;
  height: 100%;
  font-size: 1.2rem;
}

.searchbox__input--nonmobile {
  display: none;
}

.searchbox--landing, .searchbox--people, .searchbox--subscribe, .searchbox--probono {
  margin-bottom: 1.65rem;
}
.searchbox--landing .searchbox__input, .searchbox--people .searchbox__input, .searchbox--subscribe .searchbox__input, .searchbox--probono .searchbox__input {
  appearance: none;
  background-color: #fff;
  padding: 1rem 1rem 1rem 4rem;
  border-width: 2px;
  line-height: inherit;
}
.searchbox--landing .searchbox__button, .searchbox--people .searchbox__button, .searchbox--subscribe .searchbox__button, .searchbox--probono .searchbox__button {
  left: 1.25rem;
  right: auto;
  color: #5b7e99;
  padding-left: 0;
  padding-right: 0;
}
.searchbox--landing .searchbox__button:hover, .searchbox--people .searchbox__button:hover, .searchbox--subscribe .searchbox__button:hover, .searchbox--probono .searchbox__button:hover {
  color: #464645;
}

.searchbox--people {
  margin-bottom: 1.75rem;
}
.searchbox--people .searchbox__input {
  font-size: 1rem;
}
@media (min-width: 33.3333333333rem) {
  .searchbox--people {
    margin-bottom: 2.75rem;
  }
  .searchbox--people .searchbox__input {
    font-size: 1rem;
  }
  .searchbox--people .searchbox__input--nonmobile {
    display: block;
  }
  .searchbox--people .searchbox__input--mobile {
    display: none;
  }
}
@media (max-width: 27.7777777778rem) {
  .searchbox--people {
    margin-bottom: 2.75rem;
  }
  .searchbox--people .searchbox__input--nonmobile {
    display: none;
  }
  .searchbox--people .searchbox__input--mobile {
    display: block;
  }
}

.custom-icon {
  display: inline-block;
}

[class^=custom-icon-] {
  display: inline-block;
  position: relative;
}

.custom-icon-close {
  display: block;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  margin-bottom: 0.2rem;
}
.custom-icon-close:after, .custom-icon-close:before {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 30px;
  height: 2px;
  background-color: #e1e1e1;
  position: absolute;
  left: 0;
  transform: rotate(0);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 500ms;
}
.is-overlay-active .custom-icon-close:after {
  transform: rotate(45deg);
}
.is-overlay-active .custom-icon-close:before {
  transform: rotate(-45deg);
}
.is-overlay-active-fadeout .custom-icon-close:after, .is-overlay-active-fadeout .custom-icon-close:before {
  transform: rotate(0deg);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.mobile-toggle {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.mobile-toggle__label {
  color: #585858;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -1.5%;
  font-family: "brandon-grotesque", sans-serif;
  display: inline-block;
  margin-left: 8px;
}
.mobile-toggle__label--dark {
  color: #fff;
}

.loading-bar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #455363;
  transition: bottom 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.5s ease 0.5s;
  bottom: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 150;
}
.is-loading-data .loading-bar {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: bottom 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.loading-bar__progress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 3px;
  padding: 1px 0;
  background-color: #303a45;
}

.loading-bar__percentage {
  height: 1px;
  width: 5%;
  display: block;
  background-color: #adccbf;
  transition: width 1s linear 1.2s;
}
.is-loading-data .loading-bar__percentage {
  width: 100%;
}

.related-info {
  font-family: "Oxygen", sans-serif;
  font-weight: 300;
}
.related-info + .related-info {
  margin-top: 1.65rem;
}

.related-info__title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.related-info__title:after {
  content: ":";
}

.cube__callout {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
}
.cube__callout .haslink {
  background: rgba(0, 128, 128, 0.25);
}
.cube__callout .haslink span {
  color: white;
}
.cube__callout .haslink em {
  color: white;
}
.cube__callout .haslink:hover {
  background: none;
}
.cube__callout .haslink:hover span {
  color: #e1e1e1;
}
.cube__callout .haslink:hover em {
  color: #adccbf;
  font-style: normal;
  display: block;
}

.cube__callout-headline {
  min-width: 60%;
  display: inline-block;
  border: 2px solid #e1e1e1;
  padding: 2rem;
  text-align: center;
  color: #e1e1e1;
  white-space: nowrap;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 667px) {
  .cube__callout-headline {
    padding: 1.2rem;
  }
}
@media screen and (max-width: 419px) {
  .cube__callout-headline {
    padding: 5%;
    white-space: normal;
  }
}

.cube__callout-headline--emphasis {
  color: #adccbf;
  font-style: normal;
  display: block;
}

.cube__service {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 50;
}

.js-cube-service-clone {
  z-index: 40;
  opacity: 1;
}

.fadeout-service .cube__service {
  opacity: 0;
  transition: opacity 0.7s ease-out;
}
.fadeout-service .js-cube-service-clone {
  opacity: 1;
}

.cube__slide-service-footer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  height: 10%;
  padding-top: 1.5%;
  z-index: 150;
  color: #e1e1e1;
  text-align: center;
}
@media screen and (max-height: 820px) {
  .cube__slide-service-footer {
    padding-top: 0;
  }
}
@media screen and (max-width: 667px) {
  .cube__slide-service-footer {
    height: 8%;
  }
}
@media screen and (max-width: 419px) {
  .cube__slide-service-footer {
    height: 10%;
    width: 60vw;
  }
}
.cube__slide-service-footer .cube__slide-refresh {
  position: static;
  transform: translate(0, 0);
  left: auto;
  display: block;
  margin: 0 auto 2.2222222222rem;
}
@media screen and (max-width: 667px) {
  .cube__slide-service-footer .cube__slide-refresh {
    margin-bottom: 1.1111111111rem;
  }
}
.cube__slide-service-footer .client-login,
.cube__slide-service-footer .social-icon__link {
  color: #fff;
}
.cube__slide-service-footer .client-login {
  font-family: "Oxygen", sans-serif;
  font-size: 0.9rem;
  line-height: 1.1;
  text-transform: uppercase;
}
.cube__slide-service-footer .social-icons {
  display: inline-block;
}
.cube__slide-service-footer .social-icon__link {
  font-size: 0.7777777778rem;
  margin-left: 1rem;
}

.cube__insights {
  height: 100%;
  margin: 0 30px 0 45px;
}
@media screen and (max-width: 667px) {
  .cube__insights {
    font-size: 95%;
    margin: 0;
    overflow: scroll;
  }
}

@keyframes scaleDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scaleUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cube__insights-block {
  height: 50%;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}
.cube__insights-block:not(.cube__insights-block--testimonial):after {
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  content: "";
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: #009a45;
}
@media screen and (max-width: 667px) {
  .cube__insights-block:not(.cube__insights-block--testimonial):after {
    display: none;
  }
}
.insights-stacked .cube__insights-block {
  height: 25%;
  width: 100%;
  padding: 5px 0 0;
}
.fade-out-slides .cube__insights-block {
  animation: scaleDown 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-in-slides .cube__insights-block {
  animation: scaleUp 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
@media screen and (max-width: 767px) {
  .cube__insights-block {
    height: 25%;
    width: 100%;
    padding: 5px 0 0;
  }
}
.cube__insights-block:hover .cube__insights-article {
  background-color: #fff;
}
.cube__insights-block.mobile__highlight {
  display: none;
}
@media screen and (max-width: 667px) {
  .cube__insights-block.mobile__highlight {
    display: inline-block;
  }
}
.cube__insights-block.nonmobile__highlight {
  display: inline;
}
@media screen and (max-width: 667px) {
  .cube__insights-block.nonmobile__highlight {
    display: none;
  }
}

.cube__insights-article {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1.5rem solid transparent;
  border-top: 1rem solid transparent;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 1.29;
  transition: color 0.25s ease-in, border-color 0.25s ease-in, opacity 0.25s ease-in, background-color 0.25s ease-in;
}
@media screen and (max-width: 667px) {
  .cube__insights-article {
    padding: 5%;
    border: 0 none;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .cube__insights-article {
    padding: 2%;
  }
}
.cube__insights-article .article-date {
  color: #707070;
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1rem;
}

.cube__insights-featured-figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: left center;
}
@media screen and (max-width: 667px) {
  .cube__insights-featured-figure {
    width: 100%;
  }
}

.cube__insights-featured-img {
  margin-top: -5%;
  max-width: 125%;
}
@media screen and (max-width: 667px) {
  .cube__insights-featured-img {
    margin-right: 0;
    height: 220px;
    width: 100%;
    max-height: 199%;
  }
}

.cube__insights-testimonial-img {
  float: right;
  max-width: none;
  height: 100%;
  margin-right: -20%;
}
.insights-stacked .cube__insights-testimonial-img {
  margin-right: 0;
}
@media screen and (max-width: 667px) {
  .cube__insights-testimonial-img {
    margin-right: 0;
    height: 220px;
    width: 100%;
  }
}

.cube__insights-testimonial-figure {
  float: right;
  height: 100%;
  margin-left: 2%;
  width: 50%;
  text-align: right;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 667px) {
  .cube__insights-testimonial-figure {
    width: 100%;
  }
}

.cube__insights-testimonial {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  margin: 0;
  background-color: rgba(90, 125, 153, 0.85);
  padding: 5%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #e1e1e1;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
@media screen and (max-height: 820px) {
  .cube__insights-testimonial {
    font-size: 1.3rem;
    line-height: 1.2;
  }
}
@media screen and (max-width: 767px) {
  .cube__insights-testimonial {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 667px) {
  .cube__insights-testimonial {
    font-size: 1.1rem;
    line-height: 1.2;
  }
}
.cube__insights-testimonial > p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes modalScaleUpDefault {
  0% {
    right: 100%;
    left: 1.9rem;
    height: 0;
    opacity: 0;
  }
  100% {
    right: 1.9rem;
    left: 1.9rem;
    opacity: 1;
    height: 100%;
  }
}
@keyframes modalScaleDownDefault {
  0% {
    right: 1.9rem;
    left: 1.9rem;
    opacity: 1;
    height: 100%;
  }
  100% {
    right: 100%;
    left: 1.9rem;
    height: 0;
    opacity: 0;
  }
}
@keyframes modalScaleUpRight {
  0% {
    right: 1.9rem;
    left: 80%;
    height: 0;
    opacity: 0;
  }
  100% {
    right: 1.9rem;
    left: 1.9rem;
    opacity: 1;
    height: 100%;
  }
}
@keyframes modalScaleDownRight {
  0% {
    right: 1.9rem;
    left: 1.9rem;
    opacity: 1;
    height: 100%;
  }
  100% {
    right: 1.9rem;
    left: 80%;
    height: 0;
    opacity: 0;
  }
}
@keyframes modalScaleUpBottom {
  0% {
    right: 100%;
    left: 1.9rem;
    height: 0;
    opacity: 0;
  }
  100% {
    right: 1.9rem;
    left: 1.9rem;
    bottom: 0;
    opacity: 1;
    height: 100%;
  }
}
@keyframes modalScaleDownBottom {
  0% {
    right: 1.9rem;
    left: 1.9rem;
    opacity: 1;
    height: 100%;
  }
  100% {
    right: 100%;
    left: 1.9rem;
    height: 0;
    opacity: 0;
  }
}
@media screen and (max-width: 899px) {
  @keyframes modalScaleUpDefault {
    0% {
      right: 100%;
      left: 1.5rem;
      height: 0;
      opacity: 0;
    }
    100% {
      right: 1.5rem;
      left: 1.5rem;
      opacity: 1;
      height: 100%;
    }
  }
  @keyframes modalScaleDownDefault {
    0% {
      right: 1.5rem;
      left: 1.5rem;
      opacity: 1;
      height: 100%;
    }
    100% {
      right: 100%;
      left: 1.5rem;
      height: 0;
      opacity: 0;
    }
  }
  @keyframes modalScaleUpRight {
    0% {
      right: 1.5rem;
      left: 80%;
      height: 0;
      opacity: 0;
    }
    100% {
      right: 1.5rem;
      left: 1.5rem;
      opacity: 1;
      height: 100%;
    }
  }
  @keyframes modalScaleDownRight {
    0% {
      right: 1.5rem;
      left: 1.5rem;
      opacity: 1;
      height: 100%;
    }
    100% {
      right: 1.5rem;
      left: 80%;
      height: 0;
      opacity: 0;
    }
  }
  @keyframes modalScaleUpBottom {
    0% {
      right: 100%;
      left: 1.5rem;
      height: 0;
      opacity: 0;
    }
    100% {
      right: 1.5rem;
      left: 1.5rem;
      bottom: 0;
      opacity: 1;
      height: 100%;
    }
  }
  @keyframes modalScaleDownBottom {
    0% {
      right: 1.5rem;
      left: 1.5rem;
      opacity: 1;
      height: 100%;
    }
    100% {
      right: 100%;
      left: 1.5rem;
      height: 0;
      opacity: 0;
    }
  }
}
.cube__insights-modal {
  background-color: rgba(255, 255, 255, 0.96);
  position: absolute;
  height: 0;
  margin: 0 30px 0 45px;
  padding: 4.5%;
  overflow-y: auto;
  color: #5a7d99;
  opacity: 0;
}
.fade-in-modal .cube__insights-modal {
  top: 0;
  animation: modalScaleUpDefault 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-out-modal .cube__insights-modal {
  animation: modalScaleDownDefault 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-in-modal--right .cube__insights-modal {
  animation: modalScaleUpRight 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-out-modal.fade-in-modal--right .cube__insights-modal {
  animation: modalScaleDownRight 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-in-modal--bottom .cube__insights-modal {
  top: auto;
  bottom: 0;
  animation: modalScaleUpBottom 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
.fade-out-modal.fade-in-modal--bottom .cube__insights-modal {
  animation: modalScaleDownBottom 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
}
@media screen and (max-width: 899px) {
  .cube__insights-modal {
    right: 1.5rem;
    left: 1.5rem;
  }
}
@media screen and (max-width: 667px) {
  .cube__insights-modal {
    right: 1.2rem;
    left: 1.2rem;
  }
}
.cube__insights-modal .article__title {
  font-size: 1.444rem;
}
.cube__insights-modal .article__body {
  font-size: 1.1rem;
  line-height: 1.5;
}
.cube__insights-modal .related-info .list__link {
  color: #7d7d7d;
}

.cube__insights-modal-content {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s;
}
.fade-in-modal .cube__insights-modal-content {
  opacity: 1;
}
.fade-out-modal .cube__insights-modal-content {
  opacity: 0;
  transition-delay: 0;
}
.cube__insights-modal-content .grid-col-sidebar {
  width: 28%;
}
@media screen and (max-width: 899px) {
  .cube__insights-modal-content .grid-col-sidebar {
    width: 30%;
  }
}

.cube__insights-modal-abstract {
  width: 72%;
}
@media screen and (max-width: 899px) {
  .cube__insights-modal-abstract {
    width: 68%;
  }
}
.cube__insights-modal-abstract .article__body {
  border-right: 1px solid #5a7d99;
  padding-right: 5%;
  margin-right: 5%;
}
.cube__insights-modal-abstract .article__readmore {
  width: 90%;
}

.cube__insights-modal-close {
  position: absolute;
  right: 1rem;
  top: 1em;
  font-size: 1.1rem;
}

.cube__searchbox {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 667px) {
  .cube__searchbox {
    width: 95%;
  }
}
.cube__searchbox .searchbox__input {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.9rem 1.5rem 0.9rem 5rem;
  text-align: center;
  box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.4);
}
.cube__searchbox .searchbox__input::-webkit-input-placeholder {
  color: #898989;
}
.cube__searchbox .searchbox__input::-moz-placeholder {
  color: #898989;
}
.cube__searchbox .searchbox__input:-moz-placeholder {
  color: #898989;
}
.cube__searchbox .searchbox__input:-ms-input-placeholder {
  color: #898989;
}
@media screen and (max-width: 667px) {
  .cube__searchbox .searchbox__input {
    padding-left: 3rem;
    font-size: 1rem;
  }
}
.cube__searchbox .searchbox__btn {
  color: #009a45;
  right: auto;
  left: 0;
  font-size: 1.3rem;
}
@media screen and (max-width: 667px) {
  .cube__searchbox .searchbox__btn {
    font-size: 1.1rem;
    padding: 0 1.2rem;
  }
}

.cube__slide-footer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  right: 1.9rem;
  font-family: "Oxygen", sans-serif;
  font-size: 0.9rem;
  line-height: 1.1;
  width: 100%;
  height: 10%;
}
@media screen and (max-width: 899px) {
  .cube__slide-footer {
    right: 1.5rem;
  }
}
@media screen and (max-width: 667px) {
  .cube__slide-footer {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    right: auto;
    width: 80%;
    padding: 0;
    text-align: center;
    bottom: 2%;
    height: auto;
    line-height: 1.2;
  }
}
.cube__slide-footer .cube__slide-footer-wrap {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 15%;
  text-align: right;
  color: #e1e1e1;
}
@media screen and (min-width: 1250px) {
  .cube__slide-footer .cube__slide-footer-wrap {
    margin-left: 5%;
  }
}
@media screen and (max-width: 667px) {
  .cube__slide-footer .cube__slide-footer-wrap {
    margin-left: 0;
    text-align: center;
    height: auto;
    position: static;
    top: auto;
    transform: translateY(0);
  }
}
.cube__slide-footer .copyright {
  float: left;
  width: 30%;
  text-align: left;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media screen and (max-width: 1079px) {
  .cube__slide-footer .copyright,
.cube__slide-footer .footer-nav {
    font-size: 0.7777777778rem;
  }
}
@media screen and (max-width: 979px) {
  .cube__slide-footer .cube__slide-footer-wrap {
    margin-left: 0;
  }
  .cube__slide-footer .copyright,
.cube__slide-footer .footer-nav {
    font-size: 0.8333333333rem;
  }
}
@media screen and (max-width: 667px) {
  .cube__slide-footer .cube__slide-footer-wrap {
    text-align: center;
  }
  .cube__slide-footer .copyright {
    float: none;
    text-align: center;
    width: 100%;
  }
  .cube__slide-footer .footer-options {
    margin-left: 0;
  }
  .cube__slide-footer .footer-options .list-horizontal {
    display: block;
    margin-top: 0.825rem;
  }
}

.breadcrumb-nav {
  margin-top: 1.65rem;
  height: auto;
}
@media screen and (max-width: 899px) {
  .breadcrumb-nav {
    display: none;
  }
}
.breadcrumb-nav + .page-body {
  margin-top: 1.65rem;
}

.breadcrumb-nav-links {
  font-size: 0.8rem;
  color: #5b7e99;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
}
.breadcrumb-nav-links .breadcrumb-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.breadcrumb-nav-links .breadcrumb-link {
  text-transform: uppercase;
  display: inline;
  color: inherit;
  padding-right: 1em;
  margin-right: 1em;
  border-right: 1px solid #D2D2D2;
  line-height: 1;
}
.breadcrumb-nav-links .breadcrumb-link a:hover {
  opacity: 0.7;
}
.breadcrumb-nav-links .breadcrumb-link:last-of-type {
  color: #7d7d7d;
  font-weight: 500;
  border-right: none;
}

.credentials-slide-down {
  background-color: #71848b;
  text-align: center;
  color: #fff;
  padding: 0.8888888889rem;
  display: none;
}
.credentials-slide-down .close-credentials-summary-btn {
  display: block;
  margin: auto;
}
.credentials-slide-down .close-credentials-summary-btn .custom-icon {
  color: #FFF;
}
.credentials-slide-down .title {
  font-size: 1.3333333333rem;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
.credentials-slide-down .title:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  margin-left: 0.5em;
  display: inline-block;
  transition: transform 0.4s;
  transform: rotateX(0deg);
}
.credentials-slide-down .title.show-slide-down:after {
  transform: rotateX(180deg);
}
.credentials-slide-down .grid-container {
  position: relative;
  margin-top: 2.7777777778rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.credentials-slide-down .grid-container:after {
  content: " ";
  display: block;
  clear: both;
}
.credentials-slide-down .grid-container:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 70%;
  width: 1px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.credentials-slide-down .credential-category {
  text-align: right;
  padding-right: 10%;
  width: 48.9362516995%;
  float: left;
  margin-right: 2.127496601%;
}
.credentials-slide-down .credential-category:last-of-type {
  text-align: left;
  padding-left: 10%;
  float: right;
  margin-right: 0;
}
.credentials-slide-down .alignment-container {
  display: inline-block;
}
.credentials-slide-down .category-label {
  text-align: left;
  font-size: 1.4444444444rem;
  margin-bottom: 0.5rem;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
}
.credentials-slide-down .credential-items {
  list-style-type: disc;
  text-align: left;
  padding-left: 0.5em;
  margin-left: 1em;
}
.credentials-slide-down .credential-item {
  font-size: 1.2777777778rem;
  margin-top: 1em;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.credentials-slide-down .credential-item:first-of-type {
  margin-top: 0;
}
.credentials-slide-down .button-call-to-action {
  clear: both;
  margin-top: 3em;
  margin-bottom: 1em;
}
.credentials-slide-down .button-call-to-action:hover {
  color: #009a45;
}
@media screen and (max-width: 599px) {
  .credentials-slide-down .grid-container:after {
    content: none;
  }
  .credentials-slide-down .credential-category {
    text-align: left;
    padding: 0;
    width: 100%;
    float: left;
    margin-right: 2.127496601%;
  }
  .credentials-slide-down .credential-category:last-of-type {
    padding: 0;
    margin-top: 1em;
  }
}

.header-rich-text {
  margin-bottom: 1.65rem;
}

.title-border {
  margin-top: 1.65rem;
  padding-top: 1.65rem;
  border-top: 1px solid #e1e1e1;
}

.border-bottom {
  margin-bottom: 1.65rem;
  border-bottom: 1px solid #e1e1e1;
}

.rich-text {
  font-size: 1.3333333333rem;
  line-height: 1.5;
}
.rich-text ul {
  color: #5b7e99;
  padding-left: 2.2222222222rem;
  font-size: 1.2222222222rem;
}
.rich-text li {
  color: #7d7d7d;
  margin-bottom: 0.4125rem;
}
.rich-text li:before {
  width: 0.4444444444rem;
  height: 0.4444444444rem;
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #5b7e99;
  margin-left: -1rem;
  margin-top: 0.7rem;
}

.img-block,
figure {
  margin: 1.65rem 0;
  padding: 1.65rem 0;
  border: 2px solid #e1e1e1;
  border-width: 2px 0;
  display: table;
}
.img-block > img,
.img-block figcaption,
.img-block .img-block-caption,
figure > img,
figure figcaption,
figure .img-block-caption {
  display: table-cell;
  vertical-align: bottom;
}
@media screen and (max-width: 667px) {
  .img-block > img,
.img-block figcaption,
.img-block .img-block-caption,
figure > img,
figure figcaption,
figure .img-block-caption {
    display: block;
    vertical-align: baseline;
  }
}
.img-block figcaption,
.img-block .img-block-caption,
figure figcaption,
figure .img-block-caption {
  font-size: 1rem;
  line-height: 1.3;
  width: 30%;
  padding-left: 2rem;
}
.img-block figcaption strong,
.img-block .img-block-caption strong,
figure figcaption strong,
figure .img-block-caption strong {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  display: block;
  color: #464645;
  margin-bottom: 0.2777777778rem;
  text-transform: uppercase;
}
@media screen and (max-width: 667px) {
  .img-block figcaption strong,
.img-block .img-block-caption strong,
figure figcaption strong,
figure .img-block-caption strong {
    width: auto;
    padding-left: 0;
    margin-top: 0.8333333333rem;
  }
}
@media screen and (max-width: 667px) {
  .img-block figcaption,
.img-block .img-block-caption,
figure figcaption,
figure .img-block-caption {
    width: auto;
    padding-left: 0;
  }
}

.page-launch {
  border-top: 2px solid #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
  display: table;
  width: 100%;
  padding: 1em 0;
}
.page-launch .prompt {
  font-size: 1.4444444444rem;
  color: #666666;
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.page-launch .button-launch {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 1.1111111111rem;
  padding-left: 1.1111111111rem;
  padding-right: 1.1111111111rem;
}

.accordion-list {
  width: 100%;
}
.accordion-list .header {
  display: table;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
.accordion-list .header-text {
  display: table-cell;
  width: 100%;
  font-family: "Amiri", serif;
  font-size: 64px;
  color: #009a45;
  line-height: 1.1;
}
.accordion-list .button-expand-all {
  display: table-cell;
  font-size: 1.1111111111rem;
  white-space: nowrap;
  padding: 0;
  color: #009a45;
  transition: color 0.3s;
  text-transform: uppercase;
}
.accordion-list .button-expand-all:hover {
  color: #578fdb;
}
.accordion-list .questions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.accordion-list .question {
  margin-top: 0.5em;
}
.accordion-list .question-text {
  font-size: 1.4444444444rem;
  color: #009a45;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid #D2D2D2;
  cursor: pointer;
  position: relative;
  padding-right: 1em;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.accordion-list .question-text:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s;
  transform-origin: 50% 50%;
}
.accordion-list .question-text.show-answer:after {
  transform: translateY(-50%) rotateX(180deg);
}
.accordion-list .question-answer {
  font-size: 1.3333333333rem;
  line-height: 1.7777777778rem;
  color: #666666;
  padding-top: 1em;
  display: none;
}
.accordion-list .question-answer a:not(.button) {
  color: #009a45;
}
.accordion-list .question-answer a:not(.button):hover {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .accordion-list .header-text {
    font-size: 32px;
  }
}

.article-detail-body {
  font-weight: normal;
}
.article-detail-body .rich-text ul {
  padding-left: 2.2222222222rem;
}
.article-detail-body .article-head-title {
  color: #009a45;
  font-size: 2.2222222222rem;
  text-transform: uppercase;
  margin-bottom: 1.65rem;
  display: block;
  letter-spacing: 0.05rem;
}
.article-detail-body .article-head-title--citrus {
  color: #EC572F;
}
.article-detail-body ul {
  margin: 0;
  padding: 0;
}
.article-detail-body .article-detail-author::before,
.article-detail-body .article-detail-filter::before {
  color: #555;
  display: inline-block;
  font-family: "Font Awesome";
  margin-right: 2rem;
}
@media (min-width: 25rem) {
  .article-detail-body .article-detail-author::before,
.article-detail-body .article-detail-filter::before {
    margin-right: 1.3rem;
  }
}
.article-detail-body .article-head-items {
  margin-bottom: 2em;
  font-family: Oxygen, sans-serif;
  font-weight: bold;
  font-size: 1.2222222222rem;
}
.article-detail-body .article-detail-people {
  margin-bottom: 1.7777777778rem;
}
.article-detail-body .article-detail-people a::before {
  content: ",";
  margin-right: 4px;
}
.article-detail-body .article-detail-people a:first-child:before {
  content: "";
  margin-right: 0;
}
.article-detail-body .article-detail-author {
  margin-bottom: 0;
}
.article-detail-body .article-detail-author::before {
  content: "";
  margin-right: 0.8em;
}
.article-detail-body .article-detail-filter {
  margin-bottom: 0;
}
.article-detail-body .article-detail-filter::before {
  content: "";
  margin-right: 0.8em;
}

.article-detail-meta {
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  font-size: 1.3333333333rem;
}
.article-detail-meta .authors-list {
  display: block;
}
.article-detail-meta .topics,
.article-detail-meta .date {
  font-size: 1.2222222222rem;
}
.article-detail-meta .subtitle {
  font-size: 1.4444444444rem;
}
.article-detail-meta time {
  display: inline;
}

.contact-card {
  overflow: hidden;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.contact-card__image {
  display: block;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 899px) {
  .contact-card__image {
    max-width: 11.1111111111rem;
    margin-right: 5%;
  }
}
@media screen and (max-width: 419px) {
  .contact-card__image {
    max-width: 35%;
  }
}
.contact-card__image a img {
  height: 137px;
  width: 239px;
}
.case-study .contact-card__image a img {
  height: auto;
}
.contact-card__image > img {
  left: 100%;
}
@media screen and (max-width: 899px) {
  .contact-card__image > img {
    width: 190%;
    transform: translateX(-95%);
  }
}
.contact-card__name {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 1.3333333333rem;
  text-transform: uppercase;
}
.contact-card__name .last-name {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
}
.contact-card__position {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.contact-card__phone {
  color: #666666;
}
.contact-card__phone:hover {
  color: #666666;
}
.contact-card__office {
  display: block;
}
.tabs-nav {
  position: relative;
}
@media screen and (max-width: 767px) {
  .tabs-nav {
    margin-bottom: 3.3rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
.tabs-nav__list {
  border-bottom: 2px solid #d7d7d7;
  margin-bottom: 3.3rem;
  padding-bottom: 0.825rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .tabs-nav__list {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.3s linear 0.4s;
    visibility: hidden;
    position: absolute;
    top: 100%;
    margin-top: -4px;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    padding: 0 1.5rem 0;
    border: 0 none;
  }
  .tabs-nav__list .list__item {
    display: block;
  }
  .tabs-nav__list .list__item:last-child {
    padding-bottom: 0.5555555556rem;
  }
}
@media screen and (max-width: 767px) {
  .tabs-nav.is-expanded .tabs-nav__list {
    max-height: 22.2222222222rem;
    visibility: visible;
    transition: max-height 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), visibility 0.3s linear 0;
  }
}
.tabs-nav__link {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 1.1111111111rem;
  line-height: 1.1;
  text-transform: uppercase;
  color: #464645;
}
@media screen and (max-width: 767px) {
  .tabs-nav__link {
    color: #5a7d99;
    font-size: 1rem;
    font-family: "Oxygen", sans-serif;
    padding: 0.825rem 0;
    display: block;
  }
  .tabs-nav__link.is-active-tab {
    display: none;
  }
}
.tabs-nav__link.is-active-tab {
  color: #5b7e99;
}
.tabs-nav__label {
  display: none;
  text-align: left;
  width: 100%;
  padding: 0.8333333333rem;
  padding-right: 2.2222222222rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;
  z-index: 15;
  border: 0 none;
}
.tabs-nav__label:hover {
  background-color: #fff;
  color: #5b7e99;
}
.tabs-nav__label:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: absolute;
  font-size: 1.3888888889rem;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s;
  transform-origin: 50% 30%;
}
@media screen and (max-width: 767px) {
  .tabs-nav__label {
    display: block;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.tabs-nav.is-expanded .tabs-nav__label {
  border-bottom: 1px solid #fff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fff;
}
.tabs-nav.is-expanded .tabs-nav__label:after {
  transform: rotateX(180deg);
}
.tabs-nav__design-line {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #5b7e99;
  transition: width 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86), left 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (max-width: 767px) {
  .tabs-nav__design-line {
    display: none;
  }
}

.tabs-panel__body {
  overflow: hidden;
  position: relative;
}
.tabs-panel__body.is-animating-right .is-selected {
  left: -100%;
}
.tabs-panel__body.is-animating-out.is-animating-left .is-active-panel {
  position: absolute;
  left: -100%;
}
.tabs-panel__body.is-animating-out.is-animating-right .is-active-panel {
  position: absolute;
  left: 100%;
}
.tabs-panel__body.is-animating-out .is-animating-in {
  z-index: 2;
  left: 0;
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86), left 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.tabs-panel {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
  width: 100%;
  background-color: #f6f6f6;
}
.tabs-panel.is-active-panel {
  position: relative;
  z-index: 5;
  left: 0;
  transform: translateZ(0);
  transition: transform 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86), left 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.subheader-nav {
  background-color: #009a45;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  margin: 0 auto;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1rem;
}
.subheader-nav:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}
@media screen and (max-width: 899px) {
  .subheader-nav {
    padding-top: 1rem;
  }
}
.subheader-nav.expanded {
  padding-bottom: 0;
}
.subheader-nav .active-page-mobile-title {
  text-align: center;
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  .subheader-nav .active-page-mobile-title {
    display: none;
  }
}
.subheader-nav .active-page-mobile-title:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  margin-left: 0.5em;
  display: inline-block;
  transition: transform 0.4s;
  transform: rotateX(0deg);
}
.subheader-nav .active-page-mobile-title.show-slide-down + .subnav-list {
  padding-bottom: 0;
}
.subheader-nav .active-page-mobile-title.show-slide-down:after {
  transform: rotateX(180deg);
}
.subheader-nav .active-page .nav-inner-text:before {
  height: 1px;
  opacity: 1;
  transform: translateY(0px);
}
.subheader-nav .active-page .nav-inner-text:after {
  width: 0;
  height: 0;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #009a45;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 667px) {
  .subheader-nav .active-page .nav-inner-text:after {
    display: none;
  }
}
.subheader-nav .subnav-list {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
  padding-left: 0.5555555556rem;
  padding-right: 0.5555555556rem;
  margin: auto;
}
@media screen and (max-width: 899px) {
  .subheader-nav .subnav-list {
    display: none;
    padding: 1.6666666667rem 0 0;
    margin: 0;
  }
}
@media screen and (max-width: 899px) {
  .subheader-nav .subnav-list .list__item {
    display: block;
    float: none;
    text-align: center;
    padding: 0.5555555556rem 0;
  }
}
.subheader-nav .subnav-list .list__item + .list__item:before {
  content: "|";
  padding-right: 0.5rem;
  padding-left: 0.3rem;
  display: inline-block;
  vertical-align: text-top;
  font-weight: 300;
  line-height: 1;
}
@media screen and (max-width: 899px) {
  .subheader-nav .subnav-list .list__item + .list__item:before {
    content: "";
    padding: 0;
  }
}
@media screen and (max-width: 899px) {
  .subheader-nav .subnav-list .list__item:last-child {
    padding-bottom: 1.1111111111rem;
  }
}
.subheader-nav .nav-inner-text {
  display: inline-block;
  position: relative;
  padding-top: 1rem;
  color: #fff;
  cursor: pointer;
  z-index: 5;
}
@media screen and (max-width: 899px) {
  .subheader-nav .nav-inner-text {
    padding-top: 0;
  }
}
.subheader-nav .nav-inner-text:hover {
  position: relative;
}
.subheader-nav .nav-inner-text:hover:before {
  height: 1px;
  opacity: 1;
  transform: translateY(0px);
}
.subheader-nav .nav-inner-text:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transform: translateY(-10px);
}

.dropdown-label-icon {
  font-size: 1.7rem;
  position: absolute;
  right: 6%;
  top: 22%;
}

.dropdown-label-text {
  font-size: 1.1rem;
  text-align: center;
  text-transform: uppercase;
}

.dropdown-container {
  border: 2px solid #e1e1e1;
  border-radius: 4px;
  font-family: "Oxygen", sans-serif;
  font-weight: 300;
  height: 3.3333333333rem;
  position: relative;
}
.dropdown-container label {
  align-items: center;
  background-color: #fff;
  color: #898989;
  display: flex;
  font-size: 1.1111111111rem;
  height: 100%;
  justify-content: center;
  line-height: 100%;
  padding: 0.5rem;
}
.dropdown-container select {
  appearance: none;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  padding-right: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  -webkit-appearance: none;
}
.dropdown-container select option {
  color: #898989;
}
.dropdown-container select:-moz-focusring {
  color: transparent;
}
.dropdown-container select::-ms-expand {
  display: none;
}
.dropdown-container select::-ms-value {
  background: none;
}

.people-additional-search-container {
  margin-left: 2px;
  margin-right: 2px;
}
.people-additional-search-container .dropdown-label-text {
  font-size: 1rem;
}
@media (min-width: 37.5rem) {
  .people-additional-search-container .dropdown-label-text {
    font-size: 1.1rem;
  }
}

.people-dropdown-container-one,
.topicpage-dropdowns-container-one {
  display: inline-block;
  width: 100%;
}
.people-dropdown-container-two,
.topicpage-dropdowns-container-two {
  display: flex;
}
.people-dropdown-container-two .dropdown-container,
.topicpage-dropdowns-container-two .dropdown-container {
  flex-basis: 49.5%;
}
.people-dropdown-container-two .dropdown-container:nth-of-type(odd),
.topicpage-dropdowns-container-two .dropdown-container:nth-of-type(odd) {
  margin-right: 1%;
}

.people-dropdown-container-three {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 44.4444444444rem) {
  .people-dropdown-container-three {
    flex-wrap: nowrap;
  }
}
.people-dropdown-container-three .dropdown-container {
  flex-basis: 49.5%;
  -ms-flex-preferred-size: 48.5%;
}
.people-dropdown-container-three .dropdown-container:nth-of-type(odd) {
  margin-right: 1%;
}
@media (min-width: 44.4444444444rem) {
  .people-dropdown-container-three .dropdown-container {
    flex-basis: 32.5%;
  }
  .people-dropdown-container-three .dropdown-container:nth-of-type(odd) {
    margin-right: 0;
  }
  .people-dropdown-container-three .dropdown-container:nth-of-type(-n+2) {
    margin-right: 1.25%;
  }
}

.people-dropdowns-container .dropdown-container,
.people-dropdown-container .dropdown-container,
.topicpage-dropdowns-container .dropdown-container,
.topicpage-dropdown-container .dropdown-container {
  margin-bottom: 0.7rem;
}
@media (min-width: 44.4444444444rem) {
  .people-dropdowns-container .dropdown-container,
.people-dropdown-container .dropdown-container,
.topicpage-dropdowns-container .dropdown-container,
.topicpage-dropdown-container .dropdown-container {
    margin-bottom: 1.4rem;
  }
}
.people-dropdowns-container .dropdown-label-icon,
.people-dropdown-container .dropdown-label-icon,
.topicpage-dropdowns-container .dropdown-label-icon,
.topicpage-dropdown-container .dropdown-label-icon {
  font-size: 1rem;
  right: 2%;
  top: 0;
}
@media (min-width: 44.4444444444rem) {
  .people-dropdowns-container .dropdown-label-icon,
.people-dropdown-container .dropdown-label-icon,
.topicpage-dropdowns-container .dropdown-label-icon,
.topicpage-dropdown-container .dropdown-label-icon {
    font-size: 1.7rem;
    right: 6%;
    top: 22%;
  }
}
.people-dropdowns-container .dropdown-label-text,
.people-dropdown-container .dropdown-label-text,
.topicpage-dropdowns-container .dropdown-label-text,
.topicpage-dropdown-container .dropdown-label-text {
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;
}
@media (min-width: 33.3333333333rem) {
  .people-dropdowns-container .dropdown-label-text,
.people-dropdown-container .dropdown-label-text,
.topicpage-dropdowns-container .dropdown-label-text,
.topicpage-dropdown-container .dropdown-label-text {
    font-size: 1.1rem;
  }
}

.credentials {
  columns: 2 auto;
}
@media screen and (max-width: 767px) {
  .credentials {
    columns: 1;
  }
}

.credentials__category {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.credentials__category-label {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 1.3888888889rem;
  line-height: 1.4;
}

.credentials__category-content {
  position: relative;
}
.credentials__category-content.rich-text {
  margin-top: 0px;
}

.btn--icon .custom-icon-close.is-open:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btn--icon .custom-icon-close.is-open:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.people-letters-container-sort > ol {
  margin-bottom: 2.2rem;
}

.people-sort-letters-container {
  display: inline-block;
  letter-spacing: 0.05rem;
  background: white;
  background-color: #5a7d99;
  border-radius: 4px;
  padding: 1px 3px;
  border: 2px solid #5a7d99;
  cursor: pointer;
  color: white;
  font-family: Quattrocento, serif;
  margin: 0 0 0.7em 0;
}
.people-sort-letters-container button {
  color: white;
  font-size: 1.2777777778rem;
}
.people-sort-letters-container button::before {
  content: "";
  font-family: "Font Awesome";
}
.people-sort-letters-container:hover {
  color: #5a7d99;
  background-color: white;
}
.people-sort-letters-container:hover button {
  color: #d00b10;
}

.people-sort-letters-label {
  font-size: 1.1111111111rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
}

.people-sort-by-container {
  float: right;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.1111111111rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
}
.people-sort-by-container button {
  color: #5b7e99;
  font-size: inherit;
  text-transform: uppercase;
}
.people-sort-by-container button:hover {
  color: #578fdb;
}

.people-sort-by-separator {
  color: #000;
  font-size: 1.3888888889rem;
  font-weight: 300;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.people-sort-by-label {
  margin-right: 0.5rem;
}

.people-letters-container {
  display: none;
  list-style-type: none;
}
@media (min-width: 47.7777777778rem) {
  .people-letters-container {
    display: block;
  }
  .people-letters-container button {
    border-width: 0;
    padding: 0;
  }
  .people-letters-container ol {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }
  .people-letters-container ol button {
    color: #5b7e99;
    font-family: "brandon-grotesque", sans-serif;
    font-size: 1.6111111111rem;
    font-weight: 500;
    padding: 0;
    text-transform: uppercase;
  }
  .people-letters-container ol button:hover {
    color: #578fdb;
  }
  .people-letters-container ol li {
    display: inline-block;
    padding: 0;
  }
}
@media screen and (max-width: 667px) {
  .people-letters-container {
    display: block;
  }
  .people-letters-container ol {
    padding-left: 0;
    text-align: center;
  }
  .people-letters-container ol li {
    display: inline-block;
    width: 8vw;
  }
  .people-letters-container ol li button {
    font-size: 6vw;
    color: #5b7e99;
  }
}

.people-search-result {
  border-top: 2px solid #d7d7d7;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: "Oxygen", sans-serif;
  letter-spacing: 0.05rem;
  margin: auto;
  margin-top: 0;
  padding-bottom: 2.8rem;
  padding-left: 1rem;
  padding-top: 2.8rem;
}
@media (min-width: 33.3333333333rem) {
  .people-search-result {
    padding-left: 6rem;
  }
}
@media (min-width: 47.2222222222rem) {
  .people-search-result {
    flex-direction: row;
    padding-left: 0.75rem;
  }
}
.people-search-result:last-of-type {
  border-bottom: 2px solid #d7d7d7;
}
.people-search-result ul {
  padding-left: 0;
}

.people-search-result-photo {
  flex-shrink: 0;
  margin-bottom: 1.7rem;
}
@media (min-width: 47.2222222222rem) {
  .people-search-result-photo {
    margin-right: 3.8rem;
    margin-bottom: 0;
  }
}
.people-search-result-photo img {
  height: 8rem;
  width: 14rem;
}
@media (min-width: 47.2222222222rem) {
  .people-search-result-photo img {
    height: 12.458rem;
    width: 23.0265rem;
  }
}

.people-search-result-name {
  font-size: 1.3rem;
  line-height: 1;
  margin-bottom: 0.75rem;
}
@media (min-width: 47.2222222222rem) {
  .people-search-result-name {
    font-size: 1.6666666667rem;
    margin-bottom: 1.3rem;
  }
}

.people-search-result-tel::before {
  content: "";
}
.people-search-result-tel a {
  color: #000;
}

.people-search-result-location::before {
  content: "";
}

.people-search-result-email,
.people-search-result-tel,
.people-search-result-location {
  margin-bottom: 0.6rem;
}

.people-search-result-email::before,
.people-search-result-tel::before,
.people-search-result-location::before {
  color: #555;
  display: inline-block;
  font-family: "Font Awesome";
  margin-right: 2rem;
}
@media (min-width: 25rem) {
  .people-search-result-email::before,
.people-search-result-tel::before,
.people-search-result-location::before {
    margin-right: 1.3rem;
  }
}

.people-search-result-email {
  margin-bottom: 0;
}
.people-search-result-email::before {
  content: "";
}

.people-result-details {
  font-size: 1rem;
}
@media (min-width: 25rem) {
  .people-result-details {
    font-size: 1.2222222222rem;
  }
}

.people-search-result-title {
  font-size: 1.1rem;
  margin-bottom: 1.75rem;
}
@media (min-width: 47.2222222222rem) {
  .people-search-result-title {
    font-size: 1.4444444444rem;
    margin-bottom: 1.25rem;
  }
}

.jump-nav {
  background-color: #464645;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  padding: 1rem 0;
  margin: 0 auto;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
}
.jump-nav .jump-nav__label {
  display: inline-block;
}
.jump-nav.expanded {
  padding-bottom: 0;
}
.jump-nav .jump-nav-list {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
  padding-left: 0.5555555556rem;
  padding-right: 0.5555555556rem;
  display: inline-block;
  margin: auto;
}
@media screen and (max-width: 899px) {
  .jump-nav .jump-nav-list {
    display: none;
    padding: 1.6666666667rem 0 0;
    margin: 0;
  }
}
@media screen and (max-width: 899px) {
  .jump-nav .jump-nav-list .list__item {
    display: block;
    float: none;
    text-align: center;
    padding: 0.5555555556rem 0;
  }
  .jump-nav .jump-nav-list .list__item:last-child {
    padding-bottom: 1.1111111111rem;
  }
}
.jump-nav .jump-nav-list .list__item + .list__item:before {
  content: "|";
  padding-right: 0.5rem;
  padding-left: 0.3rem;
  display: inline-block;
  font-weight: 300;
}
@media screen and (max-width: 899px) {
  .jump-nav .jump-nav-list .list__item + .list__item:before {
    content: "";
    padding: 0;
  }
}
.jump-nav .jump-nav-list .list__item .nav-inner-text {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 0;
  color: #ffffff;
}
.jump-nav .jump-nav-list .list__item .nav-inner-text:hover {
  position: relative;
}
.jump-nav .jump-nav-list .list__item .nav-inner-text:hover:after {
  height: 1px;
  opacity: 1;
  transform: translateY(0px);
}
.jump-nav .jump-nav-list .list__item .nav-inner-text:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transform: translateY(-10px);
}
@media screen and (max-width: 899px) {
  .jump-nav {
    display: none;
  }
}

.subscribe-cta {
  margin: 3.3rem 0;
}

a.subscribe-cta__button {
  display: inline-block;
}

.has-blog-link {
  width: 70%;
}

.subscribe-cta__copy {
  margin-bottom: 1.65rem;
}
@media screen and (max-width: 849px) {
  .subscribe-cta__copy {
    width: 100% !important;
  }
}

.subscribe-cta__copy.blog-link {
  float: left;
}

.subscribe-cta__button {
  margin: 0 auto;
  display: block;
}

.subscribe-cta__button.blog-link {
  width: 28%;
  float: right;
  min-width: 200px;
  margin-bottom: 19px;
}
@media screen and (max-width: 849px) {
  .subscribe-cta__button.blog-link {
    width: 100% !important;
  }
}
.subscribe-cta__button.blog-link .button {
  float: right;
}
@media screen and (max-width: 849px) {
  .subscribe-cta__button.blog-link .button {
    float: none;
    margin: 0px auto;
    display: block;
  }
}

.cta-bar {
  background-color: #464645;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  padding: 1rem 0;
  margin: 0 auto;
  color: #e1e1e1;
  text-transform: uppercase;
  text-align: center;
}

.cta-bar__list {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 300;
  padding-left: 0.5555555556rem;
  padding-right: 0.5555555556rem;
  display: inline-block;
}

.cta-bar__link {
  color: #e1e1e1;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.cta-bar__link:hover {
  position: relative;
  color: #e1e1e1;
}
.cta-bar__link:hover:after {
  height: 1px;
  opacity: 1;
  transform: translateY(0px);
}
.cta-bar__link:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e1e1e1;
  opacity: 0;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transform: translateY(-10px);
}

.pdf-tabcontentlist {
  margin-top: 10px;
  margin-left: 20px;
}

.pdf-list__item {
  margin-top: 25px;
}

.pdf-list ul, menu, dir {
  margin: 10px 0;
}

.publications .pdf-list {
  color: #669dba;
  line-height: 1.5em;
}
.publications .list-articles__date {
  text-transform: uppercase;
  font-size: 1.1em;
}
.publications .list-articles__link {
  display: block;
}
.publications .pdf-tabcontentlist {
  padding-bottom: 2em;
  border-bottom: 2px lightgray solid;
}
.publications .pdf-tabcontentlist .list-articles__date {
  font-size: 1em;
}
.publications .pdf-tabcontentlist:last-child {
  border-bottom: none;
}

@page {
  margin: 25mm;
}
.pdf {
  /*Header*/
  font-family: sans-serif;
  width: 700px;
}
.pdf li {
  list-style-type: disc;
}
.pdf h1, .pdf h2, .pdf h3, .pdf h4, .pdf h5, .pdf h6,
.pdf .h1-size,
.pdf .h2-size,
.pdf .h3-size,
.pdf .h4-size,
.pdf .h5-size,
.pdf .section-header {
  letter-spacing: 0.02rem;
}
.pdf .site-full-nav {
  display: none;
}
.pdf h2 {
  color: #59819d;
}
.pdf .site-brand__logo-link img.site-brand__logo {
  display: none;
}
.pdf .site-brand__logo-link img.site-brand__logo--interior {
  margin-bottom: 20px;
  height: 50px;
  display: block;
}
.pdf .site-layout-wrapper, .pdf .bio-detail .scrollspy .page-wrapper, .bio-detail .scrollspy .pdf .page-wrapper {
  width: 100%;
  max-width: none;
  padding: 0;
  margin: 0px;
}
.pdf .question-text {
  font-weight: bold !important;
}
.pdf .question {
  padding-bottom: 12px;
}
.pdf .questions-list {
  margin-left: -1.1rem;
}
.pdf .select-matters-tab {
  padding-top: 9px;
}
.pdf .select-matters-tab p {
  margin-bottom: 0.5rem;
}
.pdf .select-matters-tab ul {
  margin-left: -1.1rem;
}
.pdf blockquote,
.pdf .blockquote {
  border-left: 0.1666666667rem solid lightgray;
  margin: 0;
  padding-left: 1.5555555556rem;
  font-size: 1.1111111111rem;
  font-style: italic;
  line-height: 1.2;
  position: relative;
  color: #669dba;
  line-height: 1.6em;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.pdf blockquote:before,
.pdf .blockquote:before {
  content: "“";
  position: absolute;
  left: 0.3em;
}
.pdf blockquote:after,
.pdf .blockquote:after {
  content: "”";
  padding-left: 0.2777777778rem;
}
.pdf blockquote > p,
.pdf .blockquote > p {
  margin-bottom: 0;
}
.pdf blockquote cite,
.pdf .blockquote cite {
  font-size: 1.3333333333rem;
  font-style: normal;
  display: block;
  margin-top: 0.825rem;
}
.pdf .pdf-list li {
  padding-bottom: 15px;
}
.pdf .people-quote-section {
  margin-top: 25px;
  margin-bottom: 25px;
}
.pdf .quote-source {
  border-left: 0.1666666667rem solid lightgray;
  padding-left: 1.5555555556rem;
  color: #669dba;
  padding-top: 0.5rem;
}

.blog-container {
  border-bottom: 2px solid #d2d2d2;
  border-top: 2px solid #d2d2d2;
  padding-top: 17px;
}

.scrollspy-nav {
  position: absolute;
  width: 18rem;
  float: left;
  transform: translate3d(0, 0, 0);
}
.scrollspy-nav.is-sticky-top {
  position: fixed;
  top: 125px;
}
.scrollspy-nav.is-sticky-bottom {
  position: absolute;
  bottom: 0;
  top: auto;
}
.scrollspy-nav .scrollspy-nav-list {
  padding: 0;
}
@media screen and (max-width: 899px) {
  .scrollspy-nav .scrollspy-nav-list {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .scrollspy-nav.scrollspy-nav.is-sticky-bottom {
    position: fixed;
    bottom: auto;
    top: 86px;
  }
}

.scrollspy-nav-link {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
  border-bottom: 1px solid #666666;
  text-transform: uppercase;
  color: #5a7d99;
  width: 95%;
  padding: 1.25rem 0 1.25rem 15px;
}
.scrollspy-nav-link:hover {
  color: black;
  border-left-color: black;
}
.is-inview .scrollspy-nav-link {
  color: #666666;
  font-weight: 700;
}

.scrollspy-nav-toggle {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  width: 100%;
  height: 3.8125rem;
  text-transform: uppercase;
  color: #5a7d99;
  padding: 1.1em 1em 0.95em;
  position: relative;
  z-index: 10;
  background-color: #fff;
  display: none;
}
.scrollspy-nav-toggle:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  font-size: 1rem;
  vertical-align: middle;
  float: right;
}

@media screen and (max-width: 899px) {
  .scrollspy-nav {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .scrollspy-nav.show-menu .scrollspy-nav-list {
    display: block;
    transform: translateY(0);
  }
  .scrollspy-nav.show-menu .scrollspy-nav-toggle:after {
    transform: rotateZ(90deg);
  }
  .scrollspy-nav.is-sticky-top {
    position: fixed;
    top: 86px;
  }
  .scrollspy-nav.is-sticky-bottom {
    position: absolute;
    bottom: 0;
    top: auto;
  }

  .scrollspy-nav-toggle {
    display: inline-block;
    text-align: left;
    font-weight: 700;
    background-color: #f6f6f6;
    padding-left: 2.25rem;
  }

  .scrollspy-nav-option {
    display: table;
    width: 100%;
  }

  .scrollspy-nav-list {
    transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #fff;
    transform: translateY(-100%);
  }

  .scrollspy-nav-link {
    margin-left: 1.25rem;
    display: table-cell;
    vertical-align: middle;
    font-size: 1em;
    border-left: 0;
    padding: 1em;
    text-align: left;
    height: 3.8125rem;
    color: #5a7d99;
    border: none;
  }
  .is-inview .scrollspy-nav-link {
    display: none;
  }
}
.scroll-appear {
  opacity: 0;
}
.scroll-appear--left {
  transform: translateX(-10%);
}
.scroll-appear--right {
  transform: translateX(10%);
}
.scroll-appear--top {
  transform: translateY(-150%);
}
.scroll-appear--default {
  transform: translateY(20%);
}
.scroll-appear--visible {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scroll-appear--visible--default {
  transition: transform 0.9s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.general-content-section--spaced {
  margin-top: 1.65rem;
}

.admin-listing {
  clear: both;
}
.admin-listing__items {
  padding-left: 0;
}
.admin-listing__item {
  padding: 26px 10px;
}
.admin-listing__item:not(:first-child) {
  border-top: 1px solid #D2D2D2;
}
.admin-listing__item--single {
  width: 100%;
  border-top: 1px solid #D2D2D2;
}
.admin-listing__item--single:last-of-type {
  border-bottom: 1px solid #D2D2D2;
}
.admin-listing__photo-container {
  width: 100%;
  margin-bottom: 20px;
}
.admin-listing__photo {
  display: block;
  width: 100%;
}
.admin-listing__title {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #464645;
  margin-bottom: 1.5rem;
}
.admin-listing__name {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.1;
  color: #666666;
  padding-bottom: 1.5rem;
}
.admin-listing__link {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  line-height: 1.1;
}
.admin-listing__link--phone, .admin-listing__link--email {
  font-size: 18px;
  display: block;
  margin-top: 0.5rem;
}
.admin-listing__link--phone::before, .admin-listing__link--email::before {
  color: #555;
  display: inline-block;
  font-family: "Font Awesome";
  padding-right: 1rem;
}
.admin-listing__link--phone::before {
  content: "";
}
.admin-listing__link--email::before {
  content: "";
}
.admin-listing__link--name {
  font-size: 21px;
}
.admin-listing__description {
  margin-top: 0.6rem;
}
.admin-listing__content {
  width: 100%;
}
@media (min-width: 550px) {
  .admin-listing__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .admin-listing__items:before, .admin-listing__items:after {
    content: "";
    width: 50%;
    order: 1;
  }
  .admin-listing__item {
    width: 50%;
  }
  .admin-listing__item--single {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .admin-listing__item--single .admin-listing__description {
    margin-top: 0;
  }
  .admin-listing__item--single .admin-listing__content {
    padding-left: 3rem;
  }
  .admin-listing__item--single .admin__photo-container {
    margin-bottom: 0;
  }
  .admin-listing__title {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .admin-listing__items:after, .admin-listing__items:before {
    width: 33.33%;
  }
  .admin-listing__item {
    width: 33.33%;
  }
  .admin-listing__item--single {
    width: 100%;
  }
  .admin-listing__item--single .admin-listing__content {
    padding-left: 3rem;
  }
  .admin-listing__title {
    font-size: 18px;
  }
  .admin-listing__name {
    font-size: 23px;
  }
  .admin-listing__link--name {
    font-size: 23px;
  }
}

.homepage-banner {
  background-color: #009B48;
  color: #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 24px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.homepage-banner__msg-wrapper {
  font-family: "brandon-grotesque", sans-serif;
}
.homepage-banner__message {
  font-size: 12px;
  line-height: 1.1;
  margin-right: 16px;
  display: inline;
}
.homepage-banner__link {
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  text-decoration: underline;
  color: #F1F1F1 !important;
  display: inline-block;
}
.homepage-banner__btn-close {
  padding: 0;
  height: 19px;
}
@media screen and (max-width: 767px) {
  .homepage-banner__msg-wrapper {
    display: block;
  }
}

.homepage-cta {
  display: inline-block;
  background-color: #009B48;
  color: #fff;
  padding: 32px;
  position: relative;
  float: right;
  transform: translateY(-22px);
  z-index: 1;
  transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.homepage-cta__label {
  font-family: "Amiri", serif;
  font-size: 36px;
  line-height: 1;
}
.homepage-cta__label::after {
  content: "";
  display: block;
  height: 4px;
  background: #9DFF78;
  width: 100%;
  transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.homepage-cta:hover {
  color: #fff;
}
.homepage-cta:hover .homepage-cta__label:after {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .homepage-cta {
    float: none;
    margin: 28px 16px 0;
    display: flex;
    justify-content: center;
    transform: none;
  }
  .homepage-cta__label {
    font-size: 24px;
    display: inline-block;
  }
}

.content-carousel {
  height: 840px;
}
.content-carousel__slide {
  background-size: cover;
  background-repeat: no-repeat;
  height: 840px !important;
  padding-top: 125px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  position: relative;
}
.content-carousel__slide video {
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  position: absolute;
  top: 50%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
}
.content-carousel__scroll-indicator {
  position: absolute;
  z-index: 1;
  right: 150px;
  bottom: 100px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.content-carousel__scroll-indicator span {
  transition: color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: -0.015em;
  font-weight: 700;
  display: inline-block;
  margin-left: 12px;
}
.content-carousel__outer-text-wrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.content-carousel__text-wrapper {
  z-index: 2;
  position: relative;
  color: #fff;
  padding: 90px;
}
.content-carousel__title {
  font-size: clamp(70px, 9vw, 120px);
  line-height: 1.05;
  font-family: "Amiri", serif;
  max-width: 1380px;
  margin-bottom: 16px;
}
.content-carousel__secondary-text {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  max-width: 620px;
}
.content-carousel a {
  font-family: "brandon-grotesque", sans-serif;
}
.content-carousel .button {
  font-family: "brandon-grotesque", sans-serif;
  margin-top: 20px;
  text-align: center;
}
.content-carousel__pagination {
  position: absolute;
  left: 24px;
  top: 18%;
  z-index: 10;
  text-align: center;
}
.content-carousel__btn {
  font-family: "brandon-grotesque", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  display: block;
  padding: 0;
  margin: 0;
}
.content-carousel__btn::before {
  content: "";
  width: 2px;
  height: 25px;
  background-color: #fff;
  display: block;
  margin: 11px 0 11px 4px;
  border-radius: 4px;
  transition: height 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.content-carousel__btn--active::before {
  height: 150px;
}
.content-carousel__auto-playcontrol {
  color: #009B48;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: -12px;
  margin-top: 1.3333333333rem;
}
.content-carousel__auto-playcontrol .bi {
  font-size: 1.1111111111rem;
}
@media screen and (max-width: 767px) {
  .content-carousel {
    height: 100%;
  }
  .content-carousel__scroll-indicator {
    display: none;
  }
  .content-carousel a {
    display: block;
  }
  .content-carousel__title {
    font-size: 50px;
    line-height: 1.1;
  }
  .content-carousel__secondary-text {
    font-size: 18px;
    line-height: 25px;
  }
  .content-carousel__slide {
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 64px) !important;
    padding-top: 0;
    padding-bottom: 120px;
  }
  .content-carousel__text-wrapper {
    padding: 20px;
  }
  .content-carousel__pagination {
    top: unset;
    bottom: 20px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 10;
  }
  .content-carousel__pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .content-carousel__btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .content-carousel__btn::before {
    height: 2px;
    width: 48px;
    margin: 0 30px;
  }
  .content-carousel__btn:first-of-type::before {
    height: 2px;
  }
  .content-carousel__btn:last-of-type::before {
    display: none;
  }
}

.card-grid {
  margin-top: 20px;
}
.card-grid__grid-layout .content-card {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.card-grid__pagination {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 20px 0;
}
.card-grid__pagination-label {
  font-family: "brandon-grotesque", sans-serif;
  color: #585858;
  text-transform: uppercase;
  padding-left: 10px;
}
.card-grid__pagination-circles {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
}
.card-grid__pagination-circles .swiper-pagination-bullet {
  border: 1px solid #585858;
  height: 14px;
  width: 14px;
  background-color: transparent;
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
  opacity: 1;
}
.card-grid__pagination-circles .swiper-pagination-bullet-active {
  background-color: #009a45;
  border-color: #009a45;
}
@media screen and (min-width: 668px) {
  .card-grid__pagination {
    display: none;
  }
  .card-grid__grid-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card-grid__grid-layout .content-card {
    width: calc(50% - 16px);
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}
@media screen and (min-width: 768px) {
  .card-grid {
    margin-top: 30px;
  }
  .card-grid .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    height: auto;
    transform: none;
  }
  .card-grid .content-card {
    flex-shrink: unset;
    width: calc(32.33% - 16px);
    height: auto;
  }
}
@media screen and (min-width: 900px) {
  .card-grid__grid-layout:after {
    content: "";
    display: block;
    width: calc(33.3333% - 11px);
  }
  .card-grid__grid-layout .content-card {
    width: calc(33.3333% - 11px);
  }
  .card-grid__grid-two-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card-grid__grid-two-layout .content-card-wide {
    width: calc(50% - 16px);
  }
  .card-grid__grid-two-layout .content-card-wide--x2 {
    width: 100%;
  }
}

.content-card {
  width: 31.33%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 32px;
  margin-bottom: 32px;
}
.content-card:nth-child(3n) {
  margin-right: 0;
}
.content-card:hover::before, .content-card:hover::after {
  opacity: 1;
}
.content-card::before, .content-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: translate(6px, 6px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.content-card::before {
  left: 0;
  height: 6px;
}
.content-card::after {
  top: 0;
  width: 6px;
}
.content-card--no-img.content-card--news {
  background-color: #009B48;
}
.content-card--no-img.content-card--case-study {
  background-color: #EC572F;
}
.content-card--no-img.content-card--event {
  background-color: #2C4497;
}
.content-card--no-img.content-card--insight {
  background-color: #3D7BCF;
}
.content-card--no-img .content-card__text-wrapper {
  border: none;
  color: #fff;
  background-color: transparent;
}
.content-card--no-img .content-card__title,
.content-card--no-img .content-card__related-professional {
  color: #fff;
}
.content-card--news .content-card__text-wrapper {
  border-bottom: 3px solid #009B48;
}
.content-card--news::before, .content-card--news::after {
  background-color: #9DFF78;
}
.content-card--event .content-card__text-wrapper {
  border-bottom: 3px solid #2C4497;
}
.content-card--event::before, .content-card--event::after {
  background-color: #4F72ED;
}
.content-card--case-study .content-card__text-wrapper {
  border-bottom: 3px solid #EC572F;
}
.content-card--case-study::before, .content-card--case-study::after {
  background-color: #FFC144;
}
.content-card--insight .content-card__text-wrapper {
  border-bottom: 3px solid #3D7BCF;
}
.content-card--insight::before, .content-card--insight::after {
  background-color: #6DC9FC;
}
.content-card__hidden-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 2;
}
.content-card__image {
  width: 100%;
  padding-bottom: 226px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
}
.content-card__text-wrapper {
  background-color: #fff;
  border-left: 1px solid #BCBCBC;
  border-right: 1px solid #BCBCBC;
  padding: 32px;
  font-family: "brandon-grotesque", sans-serif;
  color: #585858;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.content-card__eyebrow {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.content-card__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 12px;
  color: #585858;
  display: inline-block;
}
.content-card__abstract {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 12px;
}
.content-card__related-professionals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
  position: relative;
  z-index: 3;
}
.content-card__related-professional {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  font-weight: 500;
  color: #585858;
  position: relative;
  pointer-events: all;
}
.content-card__related-professional:not(:last-of-type)::after {
  content: ",";
  margin-right: 3px;
}
.content-card__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}

.content-card-wide {
  position: relative;
  margin-bottom: 32px;
}
.content-card-wide::before, .content-card-wide::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: translate(6px, 6px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #4F72ED;
}
.content-card-wide::before {
  left: 0;
  height: 6px;
}
.content-card-wide::after {
  top: 0;
  width: 6px;
}
.content-card-wide--no-img {
  background-color: #2C4497;
}
.content-card-wide--no-img .content-card-wide__text-wrapper {
  border: none;
  color: #fff;
  background-color: transparent;
}
.content-card-wide--no-img .content-card-wide__eyebrow {
  margin-bottom: 0;
}
.content-card-wide--no-img .content-card-wide__title, .content-card-wide--no-img .content-card-wide__related-professional {
  color: #fff;
}
.content-card-wide__hidden-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 2;
  display: block;
  border-bottom: 3px solid #2C4497;
}
.content-card-wide__image {
  width: 100%;
  padding-bottom: 226px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
}
.content-card-wide__text-wrapper {
  background-color: #fff;
  border: 1px solid #BCBCBC;
  border-width: 0 1px;
  padding: 32px;
  font-family: "brandon-grotesque", sans-serif;
  color: #585858;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content-card-wide__eyebrow {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.content-card-wide__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 12px;
  color: #585858;
  display: inline-block;
}
.content-card-wide__abstract {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 12px;
}
.content-card-wide__related-professionals {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
  position: relative;
  z-index: 3;
}
.content-card-wide__related-professional {
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  font-weight: 500;
  color: #585858;
  position: relative;
  pointer-events: all;
}
.content-card-wide__related-professional:not(:last-of-type)::after {
  content: ",";
  margin-right: 3px;
}
.content-card-wide__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}
.content-card-wide__meta:not(:last-of-type)::after {
  content: " | ";
  display: inline-block;
  padding-left: 0.25em;
}
@media screen and (min-width: 768px) {
  .content-card-wide {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    min-height: 16.1111111111rem;
  }
  .content-card-wide__image {
    width: 240px;
    padding: 0;
    flex-shrink: 0;
  }
  .content-card-wide__text-wrapper {
    flex-grow: 1;
    border-left: 0 none;
    border-top-width: 1px;
  }
  .content-card-wide:hover::before, .content-card-wide:hover::after {
    opacity: 1;
  }
}

.latest-insights {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
}
.latest-insights .latest-insights__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*min-height: 334px;*/
  margin-right: 16px;
  margin-bottom: 16px;
  height: auto;
}
.latest-insights .latest-insights__card:hover::before, .latest-insights .latest-insights__card:hover::after {
  opacity: 1;
}
.latest-insights .latest-insights__card::before, .latest-insights .latest-insights__card::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: translate(6px, 6px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.latest-insights .latest-insights__card::before {
  left: 0;
  height: 6px;
}
.latest-insights .latest-insights__card::after {
  top: 0;
  width: 6px;
}
.latest-insights .latest-insights__card--one-third {
  width: calc(33.33% - 16px);
}
.latest-insights .latest-insights__card--two-third {
  width: calc(66.66% - 16px);
}
.latest-insights .latest-insights__card--news .latest-insights__text-wrapper {
  border-bottom: 3px solid #009B48;
}
.latest-insights .latest-insights__card--news::before, .latest-insights .latest-insights__card--news::after {
  background-color: #9DFF78;
}
.latest-insights .latest-insights__card--event .latest-insights__text-wrapper {
  border-bottom: 3px solid #2C4497;
}
.latest-insights .latest-insights__card--event::before, .latest-insights .latest-insights__card--event::after {
  background-color: #4F72ED;
}
.latest-insights .latest-insights__card--case-study .latest-insights__text-wrapper {
  border-bottom: 3px solid #EC572F;
}
.latest-insights .latest-insights__card--case-study::before, .latest-insights .latest-insights__card--case-study::after {
  background-color: #FFC144;
}
.latest-insights .latest-insights__card--insight .latest-insights__text-wrapper {
  border-bottom: 3px solid #3D7BCF;
}
.latest-insights .latest-insights__card--insight::before, .latest-insights .latest-insights__card--insight::after {
  background-color: #6DC9FC;
}
.latest-insights__hidden-link {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 2;
}
.latest-insights__text-wrapper {
  background-color: #fff;
  border: 1px solid #BCBCBC;
  border-bottom: 3px solid #3D7BCF;
  padding: 32px;
  font-family: "brandon-grotesque", sans-serif;
  color: #585858;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.latest-insights__eyebrow {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.latest-insights__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 12px;
  color: #585858;
  display: inline-block;
}
.latest-insights__abstract {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 12px;
}
.latest-insights__date {
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .latest-insights {
    flex-wrap: nowrap;
  }
  .latest-insights .latest-insights__card {
    width: 100%;
    margin-right: 0;
  }
}

.casestudy-card {
  position: relative;
  padding-left: 120px;
  padding-top: 50px;
  display: block;
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  max-width: 390px;
  min-height: 160px;
}
.casestudy-card__image {
  width: 105px;
  height: 160px;
  background: no-repeat top left;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.casestudy-card__meta {
  font-size: 1rem;
  margin-bottom: 0.5em;
  letter-spacing: 0.2em;
}
.casestudy-card__headline {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: #EC572F;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 500;
}
.casestudy-card__abstract {
  font-size: 1.2222222222rem;
  margin-top: 0.75em;
}
@media screen and (min-width: 768px) {
  .casestudy-card {
    width: 455px;
    height: 660px;
    padding: 3.3333333333rem 2.7777777778rem;
    max-width: none;
    z-index: 1;
    transition: color 0.25s ease-out, opacity 0.2s ease-out;
  }
  .casestudy-card .swiper-container {
    overflow: visible;
  }
  .casestudy-card:hover {
    color: #fff;
  }
  .casestudy-module__cards--hovered .casestudy-card {
    color: rgba(255, 255, 255, 0);
    opacity: 0.5;
  }
  .casestudy-module__cards .casestudy-card:hover {
    color: white;
    opacity: 1;
  }
  .casestudy-module__cards .casestudy-card:hover__image:after {
    background-color: rgba(0, 0, 0, 0);
  }
  .casestudy-card__image {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
  }
  .casestudy-card__image:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .casestudy-card__headline {
    font-size: 2rem;
    color: inherit;
  }
}
@media screen and (min-width: 1080px) {
  .casestudy-card__headline {
    font-size: 2.4444444444rem;
  }
  .casestudy-card__abstract {
    font-size: 1.5555555556rem;
  }
}

.casestudy-module {
  color: #fff;
}
.casestudy-module .rich-text li {
  color: #fff;
}
.casestudy-module .section-wrapper__title {
  font-style: italic;
}
.casestudy-module__cards {
  margin-top: 50px;
}
.casestudy-module__card-item {
  counter-increment: questionNumber;
  color: #fff;
}
.casestudy-module__card-item:before {
  content: counters(questionNumber, ".", decimal-leading-zero);
  display: block;
  position: absolute;
  left: 98px;
  margin-top: -15px;
  font-size: 1.6666666667rem;
  pointer-events: none;
}
.casestudy-module__card-item + .casestudy-module__card-item {
  margin-top: 3.3333333333rem;
}
.casestudy-module__cta {
  margin-top: 1.6666666667rem;
}
.casestudy-module .swiper-wrapper {
  flex-direction: column;
}
.casestudy-module .swiper-button-prev {
  color: #009B48;
  transform: rotate(180deg);
  background-image: none;
}
.casestudy-module .swiper-button-prev:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #009B48;
  font-size: 3.3333333333rem;
}
.casestudy-module .swiper-button-next {
  color: #009B48;
  background-image: none;
}
.casestudy-module .swiper-button-next:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #009B48;
  font-size: 3.3333333333rem;
}
.casestudy-module .swiper-button-disabled,
.casestudy-module .swiper-button-disabled.swiper-button-prev,
.casestudy-module .swiper-button-disabled.swiper-button-next {
  display: none;
}
@media screen and (min-width: 768px) {
  .casestudy-module .swiper-wrapper {
    flex-direction: row;
  }
  .casestudy-module__wrapper {
    overflow: hidden;
  }
  .casestudy-module .swiper-container {
    overflow: visible;
  }
  .casestudy-module .casestudy-module__card-item {
    display: inline-flex;
    width: auto;
  }
  .casestudy-module__cards--hovered .casestudy-module__card-item:before, .casestudy-module__cards--hovered .casestudy-module__card-item:after {
    opacity: 0.25;
  }
  .casestudy-module__cards .casestudy-module__card-item:hover:before, .casestudy-module__cards .casestudy-module__card-item:hover:after {
    opacity: 1;
  }
  .casestudy-module__cards .casestudy-module__card-item:hover:before {
    color: #EC572F;
  }
  .casestudy-module__card-item {
    position: relative;
  }
  .casestudy-module__card-item + .casestudy-module__card-item {
    margin-top: 0;
  }
  .casestudy-module__card-item:nth-child(2n+1) {
    margin-top: 3.3333333333rem;
  }
  .casestudy-module__card-item:before {
    left: calc(100% - 1.5rem);
    bottom: 50px;
    font-size: 6.6666666667rem;
    line-height: 1;
    transition: opacity 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  .casestudy-module__card-item:after {
    content: "Case Study";
    font-family: "brandon-grotesque", sans-serif;
    font-weight: 500;
    transition: opacity 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    left: 100%;
    bottom: 30px;
    margin-left: 1rem;
    pointer-events: none;
  }
  .casestudy-module__cta {
    margin-top: 3.3333333333rem;
  }
}
@media screen and (min-width: 1220px) {
  .casestudy-module .swiper-container {
    margin-left: -30px;
  }
}
@media screen and (max-width: 767px) {
  .casestudy-module .swiper-button-prev,
.casestudy-module .swiper-button-next {
    display: none;
  }
}

.featured-hero {
  overflow: hidden;
  transition: height 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.featured-hero__wrapper.featured-hero__wrapper {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .featured-hero__wrapper.featured-hero__wrapper {
    padding-top: 2.6666666667rem;
    padding-bottom: 2.6666666667rem;
  }
}
@media screen and (min-width: 900px) {
  .featured-hero__content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .featured-hero__main {
    flex-grow: 1;
  }
  .featured-hero__sidebar {
    width: 288px;
    flex-shrink: 0;
    margin-left: 1.7777777778rem;
  }
  .featured-hero__wrapper.featured-hero__wrapper {
    padding-bottom: 0.8888888889rem;
  }
}

.featured-grid {
  padding-bottom: 1.3333333333rem;
}
.featured-grid__item + .featured-grid__item {
  margin-top: 1rem;
}
.featured-grid__item--news .featured-grid__card-background {
  background-color: #009B48;
}
.featured-grid__item--case-study .featured-grid__card-background {
  background-color: #EC572F;
}
.featured-grid__item--event .featured-grid__card-background {
  background-color: #2C4497;
}
.featured-grid__item--insight .featured-grid__card-background {
  background-color: #3D7BCF;
}
.featured-grid__card {
  position: relative;
  padding: 2.6666666667rem 1.3333333333rem;
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  display: block;
  overflow: hidden;
}
.featured-grid__card:hover {
  color: #fff;
}
.featured-grid__card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 1;
}
.featured-grid__card-tag, .featured-grid__card-title, .featured-grid__card-date {
  position: relative;
  z-index: 3;
}
.featured-grid__card-background {
  background: #1AA55A no-repeat top left;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 1.5s ease-out;
}
.featured-grid__item--highlight .featured-grid__card-title {
  font-size: 1.8888888889rem;
}
.featured-grid__card-tag {
  font-size: 0.8888888889rem;
  margin-bottom: 1.3333333333rem;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.featured-grid__card-title {
  font-size: 2rem;
  line-height: 1.1;
  font-family: "Amiri", serif;
}
.featured-grid__card-date {
  margin-top: 1.3333333333rem;
}
.featured-grid__card-meta:not(:last-child):after {
  content: ", ";
  display: inline-block;
  margin-right: 0.25em;
}
@media screen and (min-width: 768px) {
  .featured-grid__item--highlight .featured-grid__card {
    padding: 4rem 2.6666666667rem;
  }
  .featured-grid__item--highlight .featured-grid__card-title {
    font-size: 3.5555555556rem;
  }
  .featured-grid__card-title {
    font-size: 2.1111111111rem;
  }
  .featured-grid__item + .featured-grid__item {
    margin-top: 1.7777777778rem;
  }
  .featured-grid__card:hover:before {
    background-color: rgba(0, 0, 0, 0.35);
  }
  .featured-grid__card:hover .featured-grid__card-background {
    transform: scale(1.1);
  }
}
@media screen and (min-width: 900px) {
  .featured-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .featured-grid__item {
    width: calc(50% - 0.8888888889rem);
  }
  .featured-grid__item + .featured-grid__item {
    margin-top: 0;
  }
  .featured-grid__item:first-child:nth-last-child(2) {
    width: calc(50% - 0.8888888889rem);
  }
  .featured-grid__item:first-child:nth-last-child(2) .featured-grid__card {
    padding: 2.6666666667rem 1.3333333333rem;
  }
  .featured-grid__item:first-child:nth-last-child(2) .featured-grid__card-title {
    font-size: 2.1111111111rem;
  }
  .featured-grid__item:first-child:nth-last-child(2) ~ .featured-grid__item {
    width: calc(50% - 0.8888888889rem);
  }
  .featured-grid__item--highlight {
    width: 100%;
  }
  .featured-grid__card {
    margin-bottom: 1.7777777778rem;
  }
}

.sidebar-call {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  padding: 1.4444444444rem;
  border-top: 4px solid black;
}
.sidebar-call + .sidebar-call {
  margin-top: 1rem;
}
.sidebar-call__heading {
  font-size: 0.8888888889rem;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 1.3333333333rem;
}
.sidebar-call__title {
  font-size: 1.2222222222rem;
  line-height: 1.1;
  margin-bottom: 0.25em;
}
.sidebar-call__copy {
  line-height: 1.3;
}
.sidebar-call__link {
  display: inline-block;
  margin-top: 1.3333333333rem;
  color: #009a45;
}
@media screen and (min-width: 768px) {
  .sidebar-call + .sidebar-call {
    margin-top: 20px;
  }
  .sidebar-call__title {
    font-size: 1.4444444444rem;
  }
  .sidebar-call__copy {
    font-size: 1.2222222222rem;
  }
}

.advanced-search .dropdown-redesign + .dropdown-redesign {
  margin-top: 1rem;
}
.advanced-search__trigger {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  margin-bottom: 1.3333333333rem;
  padding-left: 0;
  padding-right: 0;
}
.advanced-search__trigger .bi {
  color: #009a45;
  margin-left: 0.2em;
}
.advanced-search__accordion {
  overflow: hidden;
  height: 0;
  transition: height 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 768px) {
  .advanced-search .dropdown-redesign + .dropdown-redesign {
    margin-top: 0;
  }
  .advanced-search .dropdown-redesign:not(:last-child) {
    margin-right: 1rem;
  }
  .advanced-search__accordion {
    padding-left: 1.7777777778rem;
  }
  .advanced-search__trigger {
    margin-left: 0;
    margin-right: 0;
  }
}
.filter-tabs {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
.filter-tabs__title {
  line-height: 1;
  margin-bottom: 1em;
}
.filter-tabs__list {
  display: flex;
  flex-wrap: nowrap;
  border-radius: 4px;
  overflow: hidden;
}
.filter-tabs__option {
  flex-grow: 1;
  display: block;
  position: relative;
}
.filter-tabs__radio {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.filter-tabs__radio:checked + .filter-tabs__radio-label {
  background-color: #009a45;
  color: #fff;
}
.filter-tabs__radio-label {
  font-size: 0.8888888889rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1F1F1;
  height: 3.4444444444rem;
  padding: 0.5555555556rem;
  transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
@media screen and (min-width: 768px) {
  .filter-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .filter-tabs__title {
    flex-shrink: 0;
    font-size: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .filter-tabs__list {
    flex-grow: 1;
  }
}
@media screen and (min-width: 900px) {
  .filter-tabs__title {
    margin-right: 1.4444444444rem;
  }
  .filter-tabs__radio-label {
    font-size: 1rem;
  }
}

.keyword-search {
  display: flex;
  align-items: center;
}
.keyword-search__input {
  flex-grow: 1;
  padding: 1rem 0;
  background: transparent;
  border: none;
  border-bottom: 2px solid #585858;
  color: #585858;
  font-weight: 500;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}
.keyword-search__input::placeholder {
  font-style: italic;
  color: #585858;
  font-weight: 500;
}
.keyword-search__button {
  flex-shrink: 0;
  color: #fff;
  background-color: #009B48;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}
.keyword-search__button .bi.bi-interface-search {
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .keyword-search__input {
    font-size: 1rem;
  }
}

.search-landing__panel-col {
  margin-bottom: 1.3333333333rem;
}
.search-landing .advanced-search {
  margin-top: 1.7777777778rem;
}
.search-landing__count {
  font-family: "Amiri", serif;
  color: black;
  font-size: 1rem;
  margin-bottom: 1.7777777778rem;
}
.search-landing__components-list .section-wrapper:first-child {
  padding-top: 0;
}
@media screen and (min-width: 768px) {
  .search-landing__count {
    font-size: 1.2222222222rem;
    margin: 1.7777777778rem 0 2.2222222222rem;
  }
  .search-landing .section-wrapper__title {
    margin-bottom: 3.5555555556rem;
  }
  .search-landing__panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .search-landing__panel-col {
    width: calc(50% - 2rem);
  }
  .search-landing .advanced-search,
.search-landing .applied-filters {
    width: 100%;
  }
}

.dropdown-redesign {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.dropdown-redesign svg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0.8888888889rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.dropdown-redesign__select {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  width: 100%;
  padding: 0.5555555556rem 0.8888888889rem;
  padding-right: 2.7777777778rem;
  font-size: 1rem;
  line-height: 1.4;
  color: black;
  border: 0 none;
  border-radius: 0;
  border-bottom: 1px solid black;
  background-color: #F1F1F1;
  appearance: none;
}
@media screen and (min-width: 768px) {
  .dropdown-redesign {
    max-width: 10rem;
  }
}
.applied-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  padding: 1.3333333333rem 0;
}
.applied-filters__pill {
  font-size: 0.8888888889rem;
  padding: 0.5555555556rem 1.3333333333rem;
  border: 1px solid black;
  border-radius: 45px;
  color: black;
  margin-bottom: 1.1111111111rem;
}
.applied-filters__pill:not(:last-child) {
  margin-right: 1.3333333333rem;
}
.applied-filters__pill--clear {
  border: 0 none;
  color: #009a45;
  width: 100%;
  margin-top: 0.5555555556rem;
}
.applied-filters__pill svg {
  vertical-align: middle;
  width: 16px;
  height: 17px;
}
@media screen and (min-width: 900px) {
  .applied-filters {
    padding-top: 1.7777777778rem;
    border-top: 1px solid #BCBCBC;
  }
  .applied-filters__pill {
    font-size: 1rem;
    padding: 0.5555555556rem 1.3333333333rem;
  }
  .applied-filters__pill--clear {
    width: auto;
    margin-top: 0;
  }
}

.callout-listing__title {
  margin-bottom: 1rem;
}
.callout-listing__intro {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: #585858;
}
.callout-listing__item {
  margin-top: 1.7777777778rem;
}
.callout-listing__link {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 1.2222222222rem;
  color: #009a45;
}
@media screen and (min-width: 768px) {
  .callout-listing__items {
    margin-top: 2.6666666667rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .callout-listing__items:after {
    content: "";
    display: block;
    width: 32.3333%;
  }
  .callout-listing__item {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-top: 0;
    padding-bottom: 1.3333333333rem;
    width: 32.3333%;
  }
  .callout-listing__title {
    margin-bottom: 1.3333333333rem;
  }
  .callout-listing__intro {
    font-size: 1.2222222222rem;
  }
}
@media screen and (min-width: 1080px) {
  .callout-listing__items {
    justify-content: flex-start;
  }
  .callout-listing__items:after {
    display: none;
  }
  .callout-listing__item {
    width: 25%;
    padding-right: 1rem;
  }
}

.hero-banner {
  background-color: black;
  color: #fff;
  padding: 2.5rem 0;
  margin-bottom: 1.7777777778rem;
}
.hero-banner__tag {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 0.8888888889rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #EC572F;
}
.hero-banner__tag:not(:last-child)::after {
  content: "|";
  color: #fff;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.hero-banner__title {
  font-family: "Amiri", serif;
  font-size: 2.7777777778rem;
  line-height: 1;
  position: relative;
  z-index: 2;
  padding-right: 20%;
}
.hero-banner__tags {
  margin-bottom: 0.6666666667rem;
  position: relative;
  z-index: 2;
}
.hero-banner__main {
  position: relative;
  min-height: 13.8888888889rem;
}
.hero-banner__image {
  width: 54%;
  right: 16px;
  top: 0;
  bottom: -3.8888888889rem;
  position: absolute;
  background: no-repeat center;
  background-size: cover;
}
.hero-banner__image:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
@media screen and (min-width: 768px) {
  .hero-banner {
    padding: 0;
  }
  .hero-banner__wrapper {
    padding-top: 3.8888888889rem;
    padding-bottom: 3.8888888889rem;
    position: relative;
  }
  .hero-banner__main {
    position: static;
  }
  .hero-banner__title {
    font-size: 3.8888888889rem;
    line-height: 1;
  }
  .hero-banner__image {
    width: 25.5555555556rem;
  }
  .hero-banner + .breadcrumb-nav {
    padding-right: 28.5555555556rem;
  }
  .hero-banner ~ .page-body .article-detail-body {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1080px) {
  .hero-banner__wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .hero-banner__title {
    font-size: 5rem;
    line-height: 1;
  }
  .hero-banner__image {
    bottom: auto;
    height: 34.4444444444rem;
  }
  .hero-banner ~ .page-body {
    margin-top: 6.6666666667rem;
  }
  .hero-banner ~ .page-body .article-detail-body {
    margin-top: 0;
  }
  .hero-banner ~ .page-body .article-detail-people,
.hero-banner ~ .page-body .article-detail-authors {
    padding-right: 20rem;
    min-height: 20px;
  }
}

.site-brand {
  float: left;
  height: 100%;
  z-index: 5;
}
@media screen and (max-width: 979px) {
  .site-brand {
    position: fixed;
    width: 100%;
    float: none;
    top: 0;
    left: 0;
    right: 0;
    height: 7%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 505;
  }
}
@media screen and (max-width: 667px) {
  .site-brand {
    height: 4.4444444444rem;
  }
}
.site-brand .site-header-wrapper {
  display: table;
  height: 100%;
}
@media screen and (max-width: 979px) {
  .site-brand .site-header-wrapper {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    padding: 0 1.9rem;
  }
  .site-brand .site-header-wrapper:after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
  }
}
@media screen and (max-width: 899px) {
  .site-brand .site-header-wrapper {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 667px) {
  .site-brand .site-header-wrapper {
    padding: 0 1.2rem;
  }
}
.site-brand .site-header-wrapper .site-brand__logo--interior {
  vertical-align: middle;
}

.site-brand__logo {
  display: block;
  height: 50px;
}
@media screen and (max-width: 1024px) {
  .site-brand__logo {
    height: 40px;
  }
}

.site-brand__title {
  /* @include vertical-align; */
  position: relative;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
  width: 7.6666666667rem;
  z-index: 10;
}
@media screen and (max-width: 979px) {
  .site-brand__title {
    position: static;
    top: auto;
    transform: translateY(0);
    float: left;
    position: static;
    top: auto;
  }
}
@media screen and (max-width: 667px) {
  .site-brand__title {
    width: 6.6666666667rem;
  }
}

.site-full-nav {
  text-align: right;
  height: 100%;
}
@media screen and (max-width: 979px) {
  .site-full-nav {
    position: fixed;
    background-color: rgba(70, 70, 70, 0.85);
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 100%;
    overflow: hidden;
    text-align: center;
    z-index: 500;
    transition: height 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s, visibility 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .site-full-nav {
    overflow-y: auto;
  }
}
.is-active-mobile-menu .site-full-nav, #homepage.is-active-mobile-menu .site-full-nav {
  height: 100vh;
  visibility: visible;
  opacity: 1;
  transition: height 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.site-full-nav .site-header-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}
.is-active-mobile-menu .site-full-nav .site-header-wrapper {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.2s;
}
.site-full-nav .button--icon {
  font-size: 0.8888888889rem;
  position: absolute;
  top: 0;
  right: 0;
  color: #e1e1e1;
}
.site-full-nav .button--icon:hover {
  color: black;
}
@media screen and (max-width: 979px) {
  .site-full-nav .button--icon {
    right: auto;
    left: 1.5rem;
    top: 10vh;
    margin-top: 1.6666666667rem;
    transform: translate(0);
    width: 90%;
    font-size: 1.1111111111rem;
    color: #e1e1e1;
    text-align: left;
  }
  .site-full-nav .button--icon:before {
    content: "Search";
    font-family: "Oxygen", sans-serif;
    font-weight: 300;
    display: inline-block;
    position: absolute;
    left: 40px;
    -webkit-font-smoothing: antialiased;
  }
  .site-full-nav .button--icon:after {
    content: "";
    display: block;
    position: absolute;
    top: 120%;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
  }
}
@media screen and (max-width: 667px) {
  .site-full-nav .button--icon {
    top: 80px;
    margin-top: 0.5555555556rem;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .site-full-nav .button--icon {
    margin-top: 0;
  }
}

.page-header {
  padding: 1.3333333333rem 0;
  position: relative;
  margin-top: 125px;
}
.page-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 72, 91, 0.5);
  z-index: 1;
}
.page-header .page-wrapper {
  position: relative;
  z-index: 5;
}
@media screen and (max-width: 979px) {
  .page-header {
    margin-top: 86px;
  }
}

.page-header-article {
  margin: 2.5rem 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-header-article {
    margin: 1.6666666667rem 0;
  }
}

.rte-header {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  text-transform: none;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #666;
}

.page-header-title {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 3.8888888889rem;
  line-height: 1.1;
  color: #fff;
  text-align: center;
  display: table;
  min-height: 16.6666666667rem;
  padding: 1.6666666667rem 0 0;
  width: 100%;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .page-header-title {
    min-height: 10rem;
    font-size: 3.8888888889rem;
  }
}
@media screen and (max-width: 667px) {
  .page-header-title {
    font-size: 2.7777777778rem;
  }
}

.page-header-label {
  display: table-cell;
  vertical-align: middle;
}

.page-header-title--article {
  font-size: 3.8888888889rem;
  line-height: 1.3;
  font-weight: 500;
  min-height: 16.6666666667rem;
}
@media screen and (max-width: 767px) {
  .page-header-title--article {
    font-size: 1.6666666667rem;
  }
}
@media screen and (max-width: 667px) {
  .page-header-title--article {
    font-size: 1.3333333333rem;
  }
}

.field-group:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.field--1of2 {
  float: left;
  width: 50%;
  padding: 0 1rem 1rem 0;
}
.field--1of2:last-child {
  padding-right: 0;
}
.field--1of2 .textbox {
  width: 100%;
}

.checkbox-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkbox-list--stacked:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}
.checkbox-list--stacked .checkbox-list__item {
  float: left;
  width: 25%;
}
.checkbox-list--stacked .checkbox-list__item:nth-child(4n+1) {
  clear: left;
}
@media screen and (max-width: 667px) {
  .checkbox-list--stacked .checkbox-list__item {
    width: 100%;
    float: none;
  }
}

.checkbox-list__item {
  padding: 0 1rem 0.5rem 1rem;
}

.checkbox-label {
  display: inline-block;
}
.checkbox-label:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.checkbox-label__input,
.checkbox-label__text {
  display: inline-block;
  float: left;
  line-height: 1.2;
  cursor: pointer;
}

.checkbox-label__input {
  width: 1rem;
  margin-left: -1rem;
  margin-top: 1px;
}

.checkbox-label__text {
  padding-left: 4px;
}

.related-items__no-results {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 7px;
  font-family: "Quattrocento", serif;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ffcc33;
  background-color: #fff4c2;
}

.related-authors {
  margin-top: 3.3rem;
}

.focus-areas__header {
  margin: 1.65rem 0;
  padding-top: 1.65rem;
  border-top: 1px solid #e1e1e1;
}

.contacts__header {
  margin-bottom: 1.65rem;
}
.contacts .intro-description {
  padding-bottom: 3.3rem;
  margin-bottom: 3.3rem;
  border-bottom: 1px solid #d7d7d7;
}
.contacts__list {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contacts__list:after {
  content: " ";
  display: block;
  clear: both;
}
.contacts__list-item {
  width: 30.4350094567%;
  float: left;
  margin-bottom: 4.3474858149%;
}
.contacts__list-item:nth-child(3n+1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}
.contacts__list-item:nth-child(3n+2) {
  margin-left: 34.7824952716%;
  margin-right: -100%;
  clear: none;
}
.contacts__list-item:nth-child(3n+3) {
  margin-left: 69.5649905433%;
  margin-right: -100%;
  clear: none;
}
@media screen and (max-width: 899px) {
  .contacts__list-item {
    width: 47.8262570925%;
    float: left;
  }
  .contacts__list-item:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .contacts__list-item:nth-child(2n+2) {
    margin-left: 52.1737429075%;
    margin-right: -100%;
    clear: none;
  }
}
@media screen and (max-width: 419px) {
  .contacts__list-item {
    width: 100%;
    float: left;
    float: none;
  }
  .contacts__list-item:nth-child(1n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: none;
  }
}
.contacts__view-more {
  margin: 0 auto;
  display: block;
}
.contacts .contacts__view-more {
  max-width: 133px;
}
@media screen and (max-width: 768px) {
  .contacts .contacts__list .contacts__list-item .contact-card__image {
    max-width: 100%;
    margin-right: 0;
  }
  .contacts .contacts__list .contacts__list-item .contact-card__image img {
    height: 100%;
    width: 100%;
  }
}

.location {
  margin-bottom: 3.3rem;
}
.location img[data-src] {
  opacity: 0;
}

.location-link--complex {
  display: block;
  margin-bottom: 0.5rem;
}
.location-link--complex img {
  height: 136px;
  width: 372px;
}

.location-name {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  line-height: 1.1;
  margin-top: 10px;
  margin-bottom: 0.5rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.location-image {
  display: block;
  margin-bottom: 1.65rem;
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.location-image--thumb {
  min-height: 180px;
  max-height: 180px;
  height: auto;
  width: 100%;
}

.location-address {
  color: rgba(70, 70, 70, 0.85);
  margin: 10px 0px;
  display: block;
}
.location-address .location-address__line {
  margin-bottom: 0;
}

.location__city:after {
  content: ", ";
  display: inline-block;
}

.location__professional {
  margin-top: 20px;
}
.location__professional .professional {
  margin-top: 10px;
}
.location__professional .professional .professional__title,
.location__professional .professional .professional__phone,
.location__professional .professional .professional__email {
  margin-bottom: 0px;
  display: block;
}

.location-phone__number,
.location-email {
  font-size: 1.1111111111rem;
  color: #93bed3;
  margin-left: 1.5em;
  position: relative;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.location-phone__number:before,
.location-email:before {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: absolute;
  left: -1.5em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1em;
}

.location-phone__number {
  color: rgba(70, 70, 70, 0.85);
}
.location-phone__number:before {
  content: "";
  transform: rotate(-270deg);
  transform-origin: 70% 30%;
}

.location-email {
  display: inline-block;
  color: #578fdb;
}
.location-email:before {
  content: "";
}
.location-email:after {
  content: "|";
  color: rgba(70, 70, 70, 0.85);
  display: inline-block;
  margin-right: 0.4444444444rem;
  padding-left: 0.4444444444rem;
}

.trending-topics .trending-topics__header {
  display: inline-block;
}
.trending-topics .trending-topics__content {
  margin-top: 0.825rem;
  margin-bottom: 1.65rem;
}
.trending-topics .list-topics__btn {
  float: right;
  display: inline-block;
}
@media screen and (max-width: 667px) {
  .trending-topics .list-topics__btn {
    float: none;
    display: block;
    margin-bottom: 1.65rem;
  }
}
.trending-topics .list__item {
  font-family: "Oxygen", sans-serif;
  color: #FFF;
  margin-bottom: 1px;
  position: relative;
  overflow: hidden;
}
.trending-topics .list__item .list__header {
  font-size: 1.3333333333rem;
  margin-bottom: 1.6666666667rem;
  text-transform: uppercase;
  font-weight: 700;
}
.trending-topics .trending-topics__bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transform: scale(1);
  transition: transform 1s ease-out;
}
.trending-topics .trending-topics__link {
  display: block;
  color: #e1e1e1;
  position: relative;
  z-index: 5;
  padding: 2.7777777778rem 2.2222222222rem;
  min-height: 18vh;
}
.trending-topics .trending-topics__link:hover + .trending-topics__bg {
  transform: scale(1.05);
}
.trending-topics .seemore-topics__btn {
  display: block;
  margin: auto;
}

.in-page-article-search .filters {
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
  margin-bottom: 3.3rem;
}
.in-page-article-search .filters .filter-label {
  display: inline-block;
  color: #464645;
  font-weight: 700;
}
.in-page-article-search .filters .filter-list {
  display: inline-block;
  position: relative;
  padding-left: 1rem;
  margin-bottom: 0;
}
@media screen and (max-width: 667px) {
  .in-page-article-search .filters .filter-list {
    margin: 0;
    padding: 0;
    display: block;
  }
}
.in-page-article-search .filters .filter-list li {
  display: inline-block;
  padding: 0 0.25rem;
  color: #666666;
  cursor: pointer;
}
@media screen and (max-width: 667px) {
  .in-page-article-search .filters .filter-list li {
    display: block;
    margin: 0.5rem 0;
    padding: 0;
  }
}
@media screen and (min-width: 669px) {
  .in-page-article-search .filters .filter-list li + li:before {
    padding-right: 0.75rem;
    content: "|";
  }
}
.in-page-article-search .filters .filter-list li.active-selection {
  color: #5b7e99;
}
.in-page-article-search .filters .filter .site-nav__link {
  font-size: inherit;
  margin-bottom: inherit;
  color: inherit;
}
.in-page-article-search .filters .filter .subnav {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in, visibility 0.2s ease-in 0.4s;
}
.in-page-article-search .filters .filter .subnav .filter {
  font-size: 0.7777777778rem;
}
.in-page-article-search .filters .filter .is-visible-subnav + .subnav {
  transition: opacity 0.4s ease-in, visibility 0.4s ease-in;
  opacity: 1 !important;
  visibility: visible !important;
}
@media screen and (max-width: 667px) {
  .in-page-article-search .filters .filter .is-visible-subnav + .subnav {
    position: relative;
  }
}

.faq .faq__header {
  font-weight: 500;
  color: #009a45;
}
.faq .faq-question-set {
  display: inline-block;
  margin-bottom: 2%;
  vertical-align: top;
  width: 46%;
  margin-right: 2%;
  font-size: 1rem;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .faq .faq-question-set {
    display: block;
    width: 100%;
    max-width: 33.3333333333rem;
    margin: 0 auto 2%;
    vertical-align: top;
  }
}
@media screen and (max-width: 667px) {
  .faq .faq-question-set {
    max-width: 20.5555555556rem;
  }
}
.faq .faq-question-set:nth-of-type(2n) .faq-answer {
  margin-left: -104%;
}
.faq .faq-question-set:nth-of-type(2n) .pointer {
  right: 25%;
}
@media screen and (max-width: 767px) {
  .faq .faq-question-set:nth-of-type(2n) .faq-answer {
    margin-left: 0;
  }
  .faq .faq-question-set:nth-of-type(2n) .pointer {
    left: 50%;
    transform: translateX(-50%);
  }
}
.faq .faq__content {
  position: relative;
  margin-right: -2%;
  font-size: 0;
  line-height: 0;
}
.faq .faq-question-wrapper {
  background-color: #FFF;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.faq .faq-question-wrapper:hover .faq-question {
  color: #455363;
}
.faq .faq-question-wrapper:hover .faq-question:before, .faq .faq-question-wrapper:hover .faq-question:after {
  border-color: #455363;
}
.faq .faq-question {
  transition: color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: absolute;
  text-align: center;
  font-family: "Oxygen", sans-serif;
  font-size: 1.3333333333rem;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #5b7e99;
}
.faq .faq-question:before, .faq .faq-question:after {
  content: "";
  margin: auto;
  display: block;
  width: 40%;
  border: 1px solid #b5b5b5;
  transition: border-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.faq .faq-question .question {
  padding: 1.5rem 1rem;
}
.faq .faq-answer {
  display: none;
  position: relative;
  float: left;
  width: 204%;
  background-color: #506e85;
  color: #FFF;
  padding: 3rem 8rem 1rem 8rem;
  margin: 4% 0;
  line-height: 1.4;
  text-align: left;
}
.faq .faq-answer .pointer {
  width: 0;
  display: inline-block;
  position: absolute;
  top: -14px;
  border-bottom: solid 15px #506e85;
  border-right: solid 15px transparent;
  border-left: solid 15px transparent;
}
@media screen and (max-width: 767px) {
  .faq .faq-answer .pointer {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  .faq .faq-answer {
    float: none;
    width: 100%;
    padding: 3rem 2rem 1rem 2rem;
  }
}
.faq .faq-answer-question {
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
  font-size: 1.4444444444rem;
  margin-bottom: 1rem;
}
.faq .faq-answer-answer {
  font-family: "Quattrocento", serif;
  font-size: 1.3333333333rem;
}
.faq .close-btn-section {
  text-align: center;
  cursor: pointer;
  margin-top: 3rem;
}
.faq .close-btn-section .close-label {
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
  margin-left: 0.5rem;
}

.footer-options .list-horizontal {
  display: inline-block;
}
.footer-options .footer-nav {
  text-transform: uppercase;
}
.footer-options .footer-nav__link,
.footer-options .social-icon__link {
  color: #e1e1e1;
}
.footer-options .footer-nav__link:hover,
.footer-options .social-icon__link:hover {
  color: #fff;
}
.footer-options .footer-nav .list__item {
  margin-left: 2.5vw;
}
.footer-options .footer-nav .list__item:first-child {
  margin-left: 0;
}
.footer-options .social-icon__link {
  font-size: 0.7777777778rem;
  margin-left: 1rem;
}

.people-additionalSearch__title button {
  color: #5b7e99;
  display: block;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.8333333333rem;
  font-weight: 500;
  margin-bottom: 1.55rem;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
}
@media (min-width: 33.3333333333rem) {
  .people-additionalSearch__title button {
    font-size: 1.1111111111rem;
  }
}
.people-additionalSearch__title button:hover {
  color: #578fdb;
}
@media screen and (max-width: 667px) {
  .people-additionalSearch__title button {
    margin-left: 0;
  }
}

.people-additional-search-btn {
  color: #5b7e99;
  display: block;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 0.8333333333rem;
  font-weight: 500;
  margin-bottom: 1.55rem;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
}
@media (min-width: 33.3333333333rem) {
  .people-additional-search-btn {
    font-size: 1.1111111111rem;
  }
}
.people-additional-search-btn:hover {
  color: #578fdb;
}

.additional-search-open {
  display: none;
}

.people-infographic-container {
  border-bottom: 2px solid #d7d7d7;
  border-top: 2px solid #d7d7d7;
  display: flex;
  margin-top: 3.1rem;
  justify-content: center;
  margin-bottom: 5rem;
  padding-left: 0;
  align-items: center;
}
@media (min-width: 42.6666666667rem) {
  .people-infographic-container {
    align-items: center;
    flex-direction: row;
    min-height: 18.5rem;
    justify-content: center;
    max-width: 100%;
  }
}

.people-infographic-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  align-items: flex-end;
}
@media (min-width: 42.6666666667rem) {
  .people-infographic-list {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (max-width: 667px) {
  .people-infographic-list {
    align-items: center;
  }
}
.people-infographic-list li {
  align-items: center;
  display: flex;
  /*flex-basis: 9rem;*/
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
  width: 8rem;
}
.people-infographic-list li img {
  margin-bottom: 1.3888888889rem;
  max-height: 13.8888888889rem;
}
@media (min-width: 42.6666666667rem) {
  .people-infographic-list li {
    flex-basis: 13.8888888889rem;
    font-size: 1rem;
  }
}

.people-infographic-label {
  font-family: "Quattrocento", serif;
  font-size: 0.8rem;
  max-width: 100% !important;
}
@media (min-width: 42.6666666667rem) {
  .people-infographic-label {
    font-size: 1.3333333333rem;
  }
}

.people-infographic-count {
  color: #009a45;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 4.1rem;
  font-weight: 300;
}
@media (min-width: 42.6666666667rem) {
  .people-infographic-count {
    font-size: 6.1rem;
    font-size: 5rem;
  }
}

.people-search-results > ol {
  padding-left: 0;
}
.people-search-results ol, .people-search-results ul {
  margin-bottom: 0;
  list-style-type: none;
}

.people-sort-container ol {
  margin-bottom: 3rem;
}

.people-results-load-more {
  text-align: center;
  margin-top: 6rem;
}
.people-results-load-more button {
  padding: 0.75rem 4rem;
}

.careerSegments-segment {
  position: relative;
  min-height: 16.5rem;
  padding: 1.65rem;
}

.careerSegments-segment--hasBackground {
  background-position: 0 0;
  background-size: cover;
}
.careerSegments-segment--hasBackground:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.careerSegments-segmentLink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: #5b7e99;
}

.careerSegments-segmentTitle {
  position: relative;
  padding-top: 50%;
  margin-top: -25%;
  text-align: center;
  color: #e1e1e1;
}

.actionModal--is-ready {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity background-color;
}
.actionModal--is-ready[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
}
.actionModal--is-ready[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  background-color: rgba(69, 83, 99, 0.9);
}
.actionModal--is-ready .actionModal-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 95%;
  width: 95%;
  padding: 1rem;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(255, 255, 255, 0.96);
}

.actionModal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 24px;
  height: 24px;
}

.scrollspy {
  position: relative;
}

.scrollspy-sections {
  margin-left: 100px;
}

@media screen and (min-width: 1025px) {
  .scrollspy-nav-offset {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .scrollspy {
    position: relative;
  }

  .scrollspy-sections {
    margin-left: 0;
  }

  .mobile-nav-offset {
    margin-top: 120px;
  }
}
@media screen and (max-width: 1024px) {
  .scrollspy {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.careers__hero {
  width: 100%;
  background-size: cover;
  position: relative;
  margin-bottom: 6.9444444444rem;
  margin-top: 125px;
}
@media screen and (max-width: 899px) {
  .careers__hero {
    margin-top: 86px;
  }
}
@media screen and (max-width: 767px) {
  .careers__hero {
    margin-bottom: 6rem;
  }
}
.careers__hero .careers__business-card {
  width: 100%;
  height: 100%;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
}
.careers__hero .careers__business-card:hover .careers__business-card-name {
  text-decoration: underline;
}
.careers__hero .careers__business-card-wrapper {
  position: absolute;
  bottom: -40px;
  z-index: 10;
  background-image: url("/assets/site/Main/public/images/careers/whiteTexture.jpg");
  background-size: cover;
  max-width: 500px;
  width: 40%;
  right: 20px;
  height: auto;
  top: inherit;
  left: inherit;
}
.careers__hero .careers__business-card-name {
  position: relative;
  margin-top: 2.2222222222rem;
  height: 47px;
  display: block;
  text-align: left;
  color: #009a45;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: bold;
}
.careers__hero .careers__business-card-name a {
  color: #009a45;
}
.careers__hero .careers__business-card-name .name-hidden {
  position: absolute;
  top: 0;
  left: 0;
  color: transparent;
}
.careers__hero .careers__business-card-name .name-input {
  background-color: transparent;
  color: #009a45;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: bold;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 100%;
  overflow: visible;
}
.careers__hero .careers__business-card-name .name-input::placeholder {
  color: #009a45;
}
.careers__hero .careers__business-card-name .name-input:focus {
  color: transparent;
  text-shadow: 0 0 0 #009a45;
}
.careers__hero .careers__business-card-name .name-input:focus ~ .name-hidden .name-blinker {
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 3.8888888889rem;
  background-color: #009a45;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
  margin-left: 1px;
  transform: translateY(-15%);
}
.careers__hero .careers__business-card-address {
  position: relative;
  margin-top: 2.2222222222rem;
  display: block;
  text-align: right;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1rem;
  color: black;
}
.careers__hero .careers__business-card-address address {
  margin-bottom: 0;
}
.careers__hero .careers__business-card-address a {
  margin-left: 10px;
  color: black;
}
.careers__hero .careers__business-card-address a span {
  color: #009a45;
  margin-right: 2px;
}
.careers__hero .careers__business-card-address a.careers__business-card-website {
  color: #009a45;
}
.careers__hero .careers__carousel-slide {
  max-height: 620px;
}
.careers__hero .careers__carousel-pagination {
  display: none;
}
.careers__hero .careers__carousel-arrows {
  display: none;
}
.careers__hero .swiper-container:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(48, 72, 91, 0) 50%, rgba(48, 72, 91, 0.7) 100%);
  position: absolute;
  z-index: 3;
}
.careers__hero-names {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30%;
  z-index: 3;
  padding-right: 12px;
  text-align: right;
  overflow: visible;
}
.careers__hero-names--title {
  font-family: "Gloss_And_Bloom", sans-serif;
  font-size: 2.3333333333rem;
  color: white;
}
.careers__hero-names--container {
  margin-bottom: 3px;
}
.careers__hero-names--item {
  font-size: 2.5555555556rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "brandon-grotesque", sans-serif;
  background-color: #009a45;
  padding: 0 10px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}
.careers__hero-names--item:after {
  content: "";
  display: block;
  width: 0;
  max-width: 0;
  background-color: #9DFF78;
  height: 0.3888888889rem;
  position: absolute;
  left: calc(100% - 8px);
  top: calc(50% - 3px);
  transition: max-width 0.2s ease-in-out;
}
.careers__hero-names--item:hover {
  color: white;
}
.careers__hero-names--item.active {
  color: white;
}
.careers__hero-names--item.active:after {
  width: 5rem;
  max-width: 5rem;
}
.careers__hero-names--item.you-active {
  color: white;
}
.careers__hero-names--item.you-active:after {
  width: 56rem;
  max-width: 56rem;
  height: 0;
  background-color: transparent;
  border-top: 6px dashed #9DFF78;
}
.careers__hero-names span {
  display: inline-block;
}
@media screen and (max-width: 1249px) {
  .careers__hero .careers__hero-names--item.you-active:after {
    width: 30rem;
    max-width: 30rem;
  }
}
@media screen and (max-width: 899px) {
  .careers__hero .careers__hero-names--title {
    font-size: 1.7777777778rem;
  }
  .careers__hero .careers__hero-names--item {
    font-size: 2.5555555556rem;
  }
  .careers__hero .careers__business-card-name {
    font-size: 1.8rem;
    line-height: 1.1;
  }
  .careers__hero .careers__business-card-name .name-input {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 667px) {
  .careers__hero {
    height: auto;
    margin-bottom: 1.6666666667rem;
  }
  .careers__hero .careers__carousel-slide {
    max-height: 400px;
  }
  .careers__hero .careers__carousel-pagination {
    display: block;
    position: absolute;
    bottom: 20px !important;
  }
  .careers__hero .careers__carousel-arrows {
    display: none;
  }
  .careers__hero .careers__hero-names {
    display: block;
    top: calc(100% - 100px);
    left: 10px;
    width: 40%;
  }
  .careers__hero .careers__hero-names--title {
    display: block;
  }
  .careers__hero .careers__hero-names--container {
    display: inline-block;
  }
  .careers__hero .careers__hero-names--item {
    display: none;
  }
  .careers__hero .careers__hero-names--item.active, .careers__hero .careers__hero-names--item.you-active {
    display: block;
  }
  .careers__hero .careers__hero-names--item:after {
    display: none;
  }
  .careers__hero .careers__business-card-wrapper {
    position: relative;
    width: 90%;
    top: -14px;
    right: 0px;
    height: auto;
    margin: 0 auto;
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.section-wrapper {
  padding: 60px 0;
}
.section-wrapper__container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.section-wrapper__page-main {
  padding-top: 125px;
}
.section-wrapper--white {
  background-color: #fff;
}
.section-wrapper--off-white {
  background-color: #FAFAFA;
}
.section-wrapper--gray-light {
  background-color: #F1F1F1;
}
.section-wrapper--black {
  background-color: black;
}
.section-wrapper--black .section-wrapper__title,
.section-wrapper--black .section-wrapper__text {
  color: #fff;
}
.section-wrapper--green {
  background-color: #009B48;
}
.section-wrapper--green .section-wrapper__title,
.section-wrapper--green .section-wrapper__text {
  color: #fff;
}
.section-wrapper--pattern {
  background-image: url("/assets/site/Main/public/images/careers/background-texture.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.section-wrapper__container {
  max-width: calc(1160px + 3rem);
  margin: 0 auto;
}
.section-wrapper__title {
  font-family: "Amiri", serif;
  font-size: 64px;
  line-height: 1;
  color: #585858;
  text-transform: unset;
  letter-spacing: 0;
}
.section-wrapper__title--h2 {
  font-size: 38px;
  color: #585858;
}
.section-wrapper__title--dark {
  color: black;
}
.section-wrapper__text {
  font-family: "brandon-grotesque", sans-serif;
  color: #585858;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  max-width: 805px;
  margin-top: 24px;
  margin-bottom: 1.65rem;
}
@media screen and (max-width: 979px) {
  .section-wrapper__page-main {
    padding-top: 86px;
  }
}
@media screen and (max-width: 767px) {
  .section-wrapper__container {
    padding-left: 1.9rem;
    padding-right: 1.9rem;
  }
  .section-wrapper__title {
    font-size: 32px;
  }
  .section-wrapper__text {
    font-size: 20px;
    line-height: 22px;
  }
}

.featured-break {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 70px 80px;
  margin-top: 60px;
  position: relative;
  background-color: black;
}
.featured-break--dark-theme {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
}
.featured-break--dark-theme .featured-break__title {
  color: #fff;
}
.featured-break--dark-theme .featured-break__date,
.featured-break--dark-theme .featured-break__text,
.featured-break--dark-theme .featured-break__related-professional,
.featured-break--dark-theme a {
  color: #fff;
}
.featured-break--dark-theme .featured-break__text {
  color: #BCBCBC;
}
.featured-break--dark-theme .featured-break__text span, .featured-break--dark-theme .featured-break__text p {
  color: inherit !important;
}
.featured-break--dark-theme .button {
  border: 1px solid #fff;
}
.featured-break--light-theme.featured-break--no-img {
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.98);
}
.featured-break--light-theme .featured-break__title {
  color: #009B48;
}
.featured-break--light-theme .featured-break__date,
.featured-break--light-theme .featured-break__text,
.featured-break--light-theme .featured-break__related-professional,
.featured-break--light-theme .featured-break__text,
.featured-break--light-theme a {
  color: black;
}
.featured-break--light-theme .button {
  border: 1px solid black;
}
.featured-break--no-img .featured-break__title {
  color: #009B48;
}
.featured-break__wrapper {
  display: flex;
  align-items: flex-start;
}
.featured-break video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.featured-break__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 60%;
  padding-bottom: 530px;
  margin-right: 55px;
  margin-top: -100px;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  max-width: 556px;
}
.featured-break__title {
  font-family: "Amiri", serif;
  font-size: 80px;
  line-height: 88px;
  margin-bottom: 28px;
}
.featured-break__text-wrapper {
  position: relative;
  z-index: 1;
}
.featured-break__date {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
}
.featured-break__text {
  font-family: "Amiri", serif;
  font-size: 32px;
  line-height: 35px;
  font-style: italic;
  margin: 14px 0 28px;
  max-width: 980px;
}
.featured-break__related-professionals {
  display: flex;
  margin-bottom: 32px;
}
.featured-break__related-professional {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 24px;
  line-height: 1;
  text-decoration: underline;
  font-weight: 500;
}
.featured-break__related-professional:not(:last-of-type)::after {
  content: ",";
  margin-right: 3px;
}
.featured-break .button {
  padding: 12px 32px;
  font-family: "brandon-grotesque", sans-serif;
  text-align: center;
}
@media screen and (max-width: 1249px) {
  .featured-break {
    padding-left: 40px;
    padding-right: 40px;
  }
  .featured-break__title {
    font-size: 65px;
    line-height: 1;
  }
  .featured-break__image {
    width: 50%;
  }
}
@media screen and (max-width: 1249px) {
  .featured-break__title {
    font-size: 50px;
  }
  .featured-break__image {
    width: 45%;
  }
}
@media screen and (max-width: 767px) {
  .featured-break {
    padding: 16px;
    padding: 0px 16px 60px;
    margin-top: 20px;
  }
  .featured-break__wrapper {
    display: block;
  }
  .featured-break__image {
    margin-right: 0;
    padding-bottom: 295px;
    transform: translateY(-14%);
    width: 100%;
    margin-top: 0;
  }
  .featured-break__title {
    font-size: 52px;
    line-height: 57px;
  }
  .featured-break__date {
    font-size: 18px;
    line-height: 20px;
  }
  .featured-break__text {
    font-size: 24px;
    line-height: 26px;
  }
  .featured-break__related-professionals {
    display: block;
    margin-bottom: 32px;
  }
  .featured-break__related-professional {
    font-size: 18px;
    line-height: 20px;
    display: block;
    margin-bottom: 20px;
  }
  .featured-break__related-professional:not(:last-of-type)::after {
    display: none;
  }
  .featured-break .button {
    display: block;
  }
}

.search-module {
  max-width: 770px;
  margin: 0 auto;
  padding: 108px 0;
}
.search-module__text {
  margin: 0 auto;
  max-width: 510px;
}
.search-module__line1, .search-module__line2 {
  color: #009B48;
  font-family: "Amiri", serif;
}
.search-module__line1 {
  font-size: 64px;
  line-height: 52px;
}
.search-module__line2 {
  text-align: right;
  font-style: italic;
  font-size: 96px;
  line-height: 1;
}
.search-module__input-wrapper {
  display: flex;
  position: relative;
  width: 100%;
}
.search-module__input {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid #BCBCBC;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 20px;
  color: #585858;
  font-weight: 500;
}
.search-module__input::placeholder {
  font-style: italic;
  color: #585858;
  font-weight: 500;
}
.search-module__btn {
  color: #fff;
  background-color: #009B48;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  padding: 0;
}
.search-module__btn .bi.bi-interface-search {
  font-size: 21px;
}
.search-module__close {
  padding: 0;
  position: absolute;
  right: 70px;
  bottom: 12px;
  display: none;
}
.search-module__close--visible {
  display: block;
}
@media screen and (max-width: 767px) {
  .search-module {
    padding: 0;
  }
  .search-module__input {
    font-size: 16px;
    width: 89%;
  }
  .search-module__text {
    margin-bottom: 24px;
  }
  .search-module__line1 {
    font-size: 32px;
    line-height: 34px;
    text-align: center;
  }
  .search-module__line2 {
    font-size: 48px;
    text-align: center;
  }
  .search-module__btn {
    height: 40px;
    width: 40px;
  }
  .search-module__btn .bi.bi-interface-search {
    font-size: 16px;
  }
}

.stats-module {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 64px;
}
.stats-module.three-stats .stats-module__wrapper {
  width: calc(33.3333% - 24px);
}
.stats-module__wrapper {
  width: calc(50% - 24px);
  height: 100%;
  max-width: 520px;
  margin-bottom: 50px;
}
.stats-module__description {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 32px;
  line-height: 1.15;
  font-weight: 500;
  color: black;
  margin-top: 8px;
}
.stats-module .counter {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 70px;
  line-height: 1;
  letter-spacing: 8px;
}
.stats-module .counter-symbol {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 70px;
  line-height: 1;
  letter-spacing: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.stats-module__icon {
  max-height: 120px;
}
.stats-module--small-container .section-wrapper__container {
  max-width: calc(828.889px + 3rem);
}
@media screen and (max-width: 767px) {
  .stats-module {
    display: block;
  }
  .stats-module__wrapper {
    width: 100%;
    max-width: 280px;
    margin: 0 auto 32px;
    text-align: center;
  }
  .stats-module__icon {
    display: block;
    margin: 0 auto;
  }
  .stats-module__description {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    margin-top: 16px;
  }
  .stats-module .counter {
    text-align: center;
  }
  .stats-module.three-stats .stats-module__wrapper {
    width: calc(100% - 24px);
  }
}

.featured-crosslinks {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 24px;
}
.featured-crosslinks .careers__cta {
  border-radius: 4px;
}
.featured-crosslinks .careers__cta:hover {
  transform: none;
  box-shadow: none;
  background-color: #009B48;
  color: #fff !important;
}
.featured-crosslinks__bar {
  position: absolute;
  top: 10px;
  left: 0;
  height: 8px;
  width: 180px;
  background-color: #9DFF78;
}
.featured-crosslinks__left-col {
  margin-right: 50px;
}
.featured-crosslinks__left-col:last-child {
  width: 50%;
  margin-right: calc(28% + 50px);
}
.featured-crosslinks__left-col, .featured-crosslinks__right-col {
  width: 39%;
}
.featured-crosslinks__card {
  margin-top: 70px;
}
.featured-crosslinks__left-col .featured-crosslinks__card:first-child {
  margin-top: 150px;
}
.featured-crosslinks__main-text, .featured-crosslinks__text {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  color: #585858;
}
.featured-crosslinks__image {
  width: 100%;
  padding-bottom: 235px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.featured-crosslinks__title {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin: 28px 0 18px;
}
.featured-crosslinks__main-text strong, .featured-crosslinks__text strong {
  position: relative;
  background-image: url("/assets/site/Main/public/images/careers/circle.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 14px 0;
  font-weight: inherit;
}
@media screen and (max-width: 767px) {
  .featured-crosslinks {
    display: block;
    margin-top: 18px;
  }
  .featured-crosslinks__bar {
    position: static;
  }
  .featured-crosslinks__left-col {
    margin-right: 0;
    margin-top: 26px;
  }
  .featured-crosslinks__left-col .featured-crosslinks__card {
    margin-top: 70px;
  }
  .featured-crosslinks__left-col .featured-crosslinks__card:first-child {
    margin-top: 70px;
  }
  .featured-crosslinks__left-col:last-child {
    width: 100%;
    margin-right: 0;
  }
  .featured-crosslinks__right-col .featured-crosslinks__card {
    margin-top: 32px;
  }
  .featured-crosslinks__left-col, .featured-crosslinks__right-col {
    width: 100%;
  }
  .featured-crosslinks__title {
    font-weight: 500;
    font-size: 24px;
    margin: 18px 0 8px;
  }
  .featured-crosslinks__main-text, .featured-crosslinks__text {
    font-size: 20px;
  }
  .featured-crosslinks .careers__cta {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }
}

.callout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0;
}
.callout__text-wrapper {
  width: 60%;
  max-width: 675px;
}
.callout__title {
  font-size: 48px;
  margin-bottom: 24px;
}
.callout__text {
  color: #fff;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
}
.callout__text a {
  color: #fff;
  text-decoration: underline;
}
.callout__text a:hover {
  color: #fff;
  text-decoration: none;
}
.callout__images {
  width: 40%;
  max-width: 425px;
}
.callout__image {
  width: 100%;
  padding-bottom: 236px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.callout__logo {
  margin-top: 32px;
}
.callout .button {
  padding: 12px 32px;
  font-family: "brandon-grotesque", sans-serif;
  text-align: center;
  border-width: 1px;
}
@media screen and (max-width: 767px) {
  .callout {
    flex-direction: column-reverse;
    padding: 0;
  }
  .callout__text-wrapper, .callout__images {
    width: 100%;
  }
  .callout__images {
    margin-bottom: 18px;
  }
  .callout__title {
    font-size: 28px;
    margin-bottom: 18px;
  }
  .callout__text {
    font-size: 20px;
    line-height: 22px;
  }
  .callout .button {
    width: 100%;
  }
}

.awards-module {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.app-dei .awards-module {
  align-items: flex-start;
}
.awards-module__award {
  height: 100%;
  width: calc(50% - 16px);
  display: flex;
  margin-bottom: 24px;
}
.awards-module__image {
  height: 152px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 216px;
  margin-right: 24px;
}
.awards-module__content-wrapper {
  width: 50%;
}
.awards-module__content-wrapper > img {
  height: 35px;
}
.awards-module__stat {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
}
.awards-module__title {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 24px;
  line-height: 25px;
  margin: 6px 0;
}
.awards-module__subtitle {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: black;
}
.awards-module__link {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  color: #009B48;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .awards-module {
    display: block;
    padding-top: 24px;
  }
  .awards-module__award {
    width: 100%;
    margin-bottom: 24px;
  }
  .awards-module__image {
    height: 87px;
    width: 50%;
  }
  .awards-module__content-wrapper {
    width: 80%;
    margin-left: 12px;
  }
  .awards-module__title {
    font-size: 20px;
    line-height: 20px;
  }
  .awards-module__link {
    font-size: 16px;
  }
}

.dei-header {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #627278;
  color: #e1e1e1;
  position: relative;
  z-index: -1;
}
.dei-header__wrapper {
  width: 100%;
  max-width: calc(1160px + 3rem);
  padding: 4em 2em 4em 2em;
  margin: auto;
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.dei-header__wrapper:after {
  content: "";
  position: absolute;
  right: 2em;
  top: 0;
  z-index: 0;
  background-color: #464646;
  height: calc(100% + 3em);
  width: 30%;
}
.dei-header__wrapper--blue:after {
  background-color: #3D7BCF;
}
.dei-header__wrapper--citrus:after {
  background-color: #EC572F;
}
.dei-header__wrapper--royal:after {
  background-color: #2C4497;
}
.dei-header__wrapper--royal-light:after {
  background-color: #4F72ED;
}
.dei-header__wrapper--green:after {
  background-color: #009B48;
}
.dei-header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: black;
  opacity: 0.4;
  height: 100%;
  width: 100%;
}
.dei-header__content {
  width: 100%;
}
.dei-header__eyebrow {
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  z-index: 1;
}
.dei-header__title {
  font-family: "Amiri", serif;
  font-size: 80px;
  max-width: 80%;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 899px) {
  .dei-header {
    margin-bottom: 5em;
  }
}
@media screen and (max-width: 767px) {
  .dei-header__title {
    font-size: 3.3333333333rem;
  }
}
@media screen and (max-width: 667px) {
  .dei-header__title {
    max-width: none;
  }
}

.page-dei .breadcrumb-nav {
  padding-bottom: 3em;
}

#app.app-dei {
  background-image: none;
}

.priorities {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #464646;
  color: #e1e1e1;
  position: relative;
}
.priorities__wrapper {
  width: 100%;
  max-width: calc(1160px + 3rem);
  margin: auto;
  position: relative;
  z-index: 1;
}
.priorities__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: black;
  opacity: 0.6;
  height: 100%;
  width: 100%;
}
.priorities__content {
  padding: 4em 2em 250px 2em;
}
.priorities__title {
  font-family: "Amiri", serif;
  font-size: 64px;
  font-style: italic;
  color: #e1e1e1;
  text-transform: none;
}
.priorities__grid {
  margin-top: 80px;
}
.priorities__item {
  min-width: 500px;
  width: 100%;
  transition: 0.3s ease all;
  position: relative;
  height: 140px;
}
.priorities__item:hover {
  z-index: 1;
}
.priorities__item:hover .priorities__item-inner {
  opacity: 1;
}
.priorities__item:hover .priorities__item-title,
.priorities__item:hover .priorities__item-inner {
  background-color: rgba(0, 0, 0, 0.8);
}
.priorities__item:first-child {
  right: 0%;
  margin-left: auto;
}
.priorities__item:nth-child(2) {
  left: 5%;
}
.priorities__item:nth-child(3) {
  right: 10%;
  margin-left: auto;
}
.priorities__item:nth-child(4) {
  left: 0;
}
.priorities__item:nth-child(5) {
  right: 0;
  margin-left: auto;
}
.priorities__item-title {
  font-family: "Amiri", serif;
  font-size: 86px;
  line-height: 1;
  padding: 50px 50px 0 50px;
  transition: 0.3s ease all;
}
.priorities__item-title span {
  display: inline-block;
}
.priorities__item-content {
  overflow: visible;
}
.priorities__item-inner {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: 0.3s ease all;
  width: 100%;
}
.priorities__item-wrapper {
  padding: 0 50px 50px 50px;
}
.priorities__item-subtitle {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 0.5em;
  border-bottom: 5px solid #009B48;
}
.priorities__item-details {
  font-family: "Quattrocento", serif;
  font-size: 20px;
  padding: 1em;
}
@media screen and (max-width: 767px) {
  .priorities__title {
    font-size: 3.3333333333rem;
  }
  .priorities__item-title {
    font-size: 3.3333333333rem;
  }
  .priorities__item {
    max-width: none !important;
    min-width: 0 !important;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 2em;
    height: auto;
  }
  .priorities__item:nth-child(2), .priorities__item:nth-child(3) .priorities__item:nth-child(4) .priorities__item:nth-child(5) {
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 667px) {
  .priorities__title {
    font-size: 3.3333333333rem;
  }
  .priorities__item-title {
    font-size: 2.5rem;
  }
}

.logo-carousel {
  background-color: #585858;
  color: #e1e1e1;
}
.logo-carousel__title {
  font-size: 3.5555555556rem;
  margin-bottom: 1.8888888889rem;
}
.logo-carousel__wrapper {
  max-width: calc(1160px + 3rem);
  width: 100%;
  margin: auto;
  padding: 4em 2em 2em 2em;
}
.logo-carousel .swiper-container {
  padding: 0 2em;
}
.logo-carousel .swiper-wrapper {
  align-items: center;
}
.logo-carousel .swiper-nav {
  text-align: center;
  margin-top: 2em;
  opacity: 0.5;
  transition: 0.3s ease all;
}
.logo-carousel .swiper-nav--playing {
  opacity: 1;
}
.logo-carousel .swiper-button-prev {
  color: #e1e1e1;
  height: calc(100% - 1.5rem);
  top: 0;
  left: 0;
  transform: rotate(180deg);
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.3s ease all;
}
.logo-carousel .swiper-button-prev:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #e1e1e1;
  font-size: 3.3333333333rem;
}
.logo-carousel .swiper-button-prev::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: -10px;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #585858 70%);
  z-index: 0;
}
.logo-carousel .swiper-button-next {
  color: #e1e1e1;
  background-image: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 1.5rem);
  top: 0;
  right: 0;
  opacity: 1;
  transition: 0.3s ease all;
}
.logo-carousel .swiper-button-next:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #e1e1e1;
  font-size: 3.3333333333rem;
}
.logo-carousel .swiper-button-next::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  right: -10px;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #585858 70%);
  z-index: 0;
}
.logo-carousel .swiper-button-disabled,
.logo-carousel .swiper-button-disabled.swiper-button-prev,
.logo-carousel .swiper-button-disabled.swiper-button-next {
  opacity: 0;
  pointer-events: none;
}
.logo-carousel .swiper-slide {
  width: auto;
}
.logo-carousel__slide-content {
  padding: 0;
  display: block;
}
.logo-carousel__slide-content img {
  max-width: 195px;
  width: auto;
  display: block;
}
@media screen and (max-width: 767px) {
  .logo-carousel__title {
    font-size: 1.7777777778rem;
    margin-bottom: 1.5rem;
  }
  .logo-carousel .swiper-container {
    margin-left: -2em;
    margin-right: -2em;
  }
  .logo-carousel__slide-content img {
    max-width: 100%;
  }
}
.quote-carousel {
  background-color: #BCBCBC;
  color: #e1e1e1;
}
.quote-carousel__wrapper {
  width: 100%;
  margin: auto;
}
.quote-carousel__slide-content {
  display: flex;
  align-items: stretch;
}
.quote-carousel__image {
  flex: 1 1 55%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.quote-carousel__text {
  flex: 1 1 45%;
  background-color: #585858;
  padding: 4em;
  min-height: 700px;
  display: flex;
  align-items: center;
}
.quote-carousel__text--blue {
  background-color: #3D7BCF;
}
.quote-carousel__text--citrus {
  background-color: #EC572F;
}
.quote-carousel__text--royal {
  background-color: #2C4497;
}
.quote-carousel__text--royal-light {
  background-color: #4F72ED;
}
.quote-carousel__text--green {
  background-color: #009B48;
}
.quote-carousel__heading {
  font-family: "Amiri", serif;
  font-size: 2rem;
}
.quote-carousel__quote {
  position: relative;
  z-index: 0;
  font-family: "Quattrocento", serif;
  font-size: 1.1111111111rem;
}
.quote-carousel__quote::before {
  content: url("/assets/site/Main/public/images/icons/quote-mark.svg");
  position: absolute;
  top: -1.1111111111rem;
  left: -1.1111111111rem;
  z-index: -1;
  opacity: 0.15;
}
.quote-carousel__quote::after {
  content: url("/assets/site/Main/public/images/icons/quote-mark.svg");
  transform: rotate(180deg);
  position: absolute;
  bottom: -1.1111111111rem;
  right: -0.4444444444rem;
  z-index: -1;
  opacity: 0.15;
}
.quote-carousel__details {
  margin-top: 2em;
}
.quote-carousel__name {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.1111111111rem;
  text-transform: uppercase;
  font-weight: bold;
}
.quote-carousel__title {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.1111111111rem;
  text-transform: uppercase;
  font-weight: bold;
}
.quote-carousel__supporting {
  font-family: "Amiri", serif;
  font-size: 1.3333333333rem;
  line-height: 1.2;
}
.quote-carousel__cta {
  background-color: #e1e1e1;
  padding: 0.5555555556rem 0.8888888889rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  color: black !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.8888888889rem;
  margin-top: 1.9444444444rem;
  transform: translate(0, 0);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.quote-carousel__cta:hover {
  color: black;
  transform: translate(2px, -2px);
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
}
.quote-carousel .swiper-nav {
  text-align: center;
  margin-top: 2em;
}
.quote-carousel .swiper-button-prev {
  color: #e1e1e1;
  transform: rotate(180deg);
  background-image: none;
  left: 2em;
}
.quote-carousel .swiper-button-prev:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #e1e1e1;
  font-size: 3.3333333333rem;
}
.quote-carousel .swiper-button-next {
  color: #e1e1e1;
  background-image: none;
  right: 2em;
}
.quote-carousel .swiper-button-next:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #e1e1e1;
  font-size: 3.3333333333rem;
}
.quote-carousel .swiper-pagination-bullet {
  height: 15px;
  width: 15px;
  display: inline-block;
  margin: 8px;
  border-radius: 100%;
  border: 3px solid #e1e1e1;
  background-color: transparent;
}
.quote-carousel .swiper-pagination-bullet-active {
  background-color: #e1e1e1;
}
@media screen and (max-width: 767px) {
  .quote-carousel__slide-content {
    flex-wrap: wrap;
  }
  .quote-carousel__image {
    flex: 1 1 100%;
    height: 200px;
  }
  .quote-carousel__text {
    flex: 1 1 100%;
    min-height: 0;
  }
  .quote-carousel .swiper-pagination {
    top: 170px;
  }
  .quote-carousel .swiper-button-prev,
.quote-carousel .swiper-button-next {
    top: 100px;
  }
}
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: #fff;
  z-index: 12;
}
.site-header--dark {
  background-color: rgba(0, 0, 0, 0.5);
}
.site-header__wrapper {
  padding: 24px 32px 22px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.site-header__primary-link-wrapper, .site-header__secondary-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.site-header__primary-link-wrapper {
  margin-bottom: 14px;
}
.site-header__primary-links, .site-header__secondary-links {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.site-header__primary-links--mm a, .site-header__secondary-links--mm a {
  padding-left: 0;
}
.site-header__primary-links--mm {
  display: block;
  padding-left: 0;
}
.site-header__secondary-links--mm {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding-left: 60px;
}
.site-header__primary-link {
  margin-left: 36px;
}
.site-header__primary-link a {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 22px;
  letter-spacing: -1.5%;
  font-weight: 700;
}
.site-header__primary-link--mm {
  margin-left: 0;
  margin-bottom: 30px;
}
.site-header__primary-link--mm a {
  font-size: 40px;
}
.site-header__secondary-link {
  margin-left: 32px;
}
.site-header__secondary-link a {
  color: #585858;
  font-weight: 500;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 18px;
}
.site-header__secondary-link--mm {
  margin-left: 0;
  width: calc(100% / 3);
  padding-right: 32px;
  padding-bottom: 50px;
}
.site-header__secondary-link--mm a {
  color: #009B48;
  font-weight: 700;
  font-size: 28px;
}
.site-header__secondary-link--dark a {
  color: #fff;
}
.site-header__search {
  color: #009B48;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  margin-left: 16px;
}
.site-header__search--open {
  color: #fff;
  background-color: #009B48;
}
.site-header__search .bi.bi-interface-search {
  font-size: 16px;
}
.site-header__search-wrapper {
  display: flex;
  position: relative;
  opacity: 0;
  visibility: hidden;
  max-width: 0;
  transition: all 0.75s ease-in-out;
}
.site-header__search-wrapper--open {
  margin-left: 36px;
  opacity: 1;
  visibility: visible;
  max-width: 100%;
}
.site-header__search-input {
  border: none;
  background: transparent;
  border-bottom: 1px solid #585858;
  width: 300px;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 14px;
  color: #585858;
  font-weight: 500;
}
.site-header__search-input::placeholder {
  font-style: italic;
  color: #585858;
  font-weight: 500;
}
.site-header__search-input--dark {
  border-bottom: 1px solid #fff;
  color: #fff;
}
.site-header__search-input--dark::placeholder {
  color: #fff;
}
.site-header__search-close {
  padding: 0;
  position: static;
  right: 0;
}
.site-header__search-close--open {
  position: absolute;
}
.site-header__progress {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3px;
  background: #BCBCBC;
}
.site-header__progress-bar {
  height: 3px;
  background: #009B48;
  width: 0%;
}
.site-header__mega-menu {
  background-color: #F8F8F8;
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 125px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  overflow-y: auto;
  max-height: calc(100vh - 126px);
}
.site-header__mega-menu--open {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.site-header__mm-outer-wrapper {
  margin: 0 auto;
}
.site-header__mm-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 80px;
  max-width: 1420px;
}
.site-header__mm-inner-wrapper-children {
  display: none;
}
.site-header__mm-secondary {
  width: 60%;
  border-left: 2px solid #BCBCBC;
}
.site-header__mm-children {
  padding-left: 0;
  margin-top: 10px;
}
.site-header__mm-child {
  margin-bottom: 4px;
}
.site-header__mm-child a {
  color: #009B48;
  font-weight: 500;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 16px;
}
.site-header__mm-child-next {
  display: none;
}
@media screen and (max-width: 979px) {
  .site-header__mega-menu {
    display: block;
    top: 86px;
    height: 100vh;
    overflow: auto;
  }
  .site-header__mm-outer-wrapper {
    display: flex;
    overflow-x: hidden;
  }
  .site-header__mm-inner-wrapper, .site-header__mm-inner-wrapper-children {
    display: block;
    padding: 40px 16px;
    width: 100%;
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  .site-header__mm-inner-wrapper--slide, .site-header__mm-inner-wrapper-children--slide {
    transform: translateX(-100%);
  }
  .site-header__mm-inner-wrapper-children {
    transform: translateX(100%);
  }
  .site-header__mm-inner-wrapper-children--slide {
    transform: translateX(-100%);
  }
  .site-header__primary-links, .site-header__secondary-link-wrapper {
    display: none;
  }
  .site-header__mm-primary {
    padding: 0 10px 24px;
  }
  .site-header__primary-links--mm {
    display: block;
  }
  .site-header__primary-link-wrapper {
    margin-bottom: 0;
  }
  .site-header__primary-link--mm {
    margin-bottom: 0;
    margin-left: 0;
    padding-bottom: 16px;
  }
  .site-header__primary-link--mm a {
    font-size: 28px;
  }
  .site-header__mm-secondary {
    border-left: none;
    width: 100%;
    border-top: 1px solid #BCBCBC;
    padding: 40px 10px 0;
  }
  .site-header__secondary-links--mm {
    display: block;
    padding-left: 0;
    width: 100%;
  }
  .site-header__secondary-link--mm {
    margin-left: 0;
    padding: 0;
    padding-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .site-header__secondary-link--mm a {
    font-size: 18px;
    font-weight: 500;
  }
  .site-header__mm-child-next {
    display: inline;
  }
  .site-header__mm-children {
    display: none;
  }
  .site-header__mm-back {
    font-family: "brandon-grotesque", sans-serif;
    color: #009B48;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 32px;
  }
  .site-header__mm-back span {
    display: inline-block;
    margin-left: 12px;
  }
  .site-header__search-wrapper--open {
    margin-left: 0;
  }
}

.site-searchbox__wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  height: 80%;
  width: 100%;
  z-index: 1005;
  visibility: hidden;
  opacity: 0;
}
.is-site-search-active .site-searchbox__wrapper {
  position: fixed;
  opacity: 1;
  visibility: visible;
  transition: visibility 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.is-overlay-active-fadeout .site-searchbox__wrapper {
  transition: visibility 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s, opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.site-searchbox {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 667px) {
  .site-searchbox {
    width: 95%;
  }
}
.site-searchbox .searchbox__input {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.9rem 1.5rem 0.9rem 5rem;
  text-align: center;
  box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.4);
  text-align: left;
  line-height: 2rem;
}
@media screen and (max-width: 667px) {
  .site-searchbox .searchbox__input {
    padding-left: 3rem;
    font-size: 1rem;
  }
}
.site-searchbox .searchbox__btn {
  color: #009a45;
  right: auto;
  left: 0;
  font-size: 1.3rem;
}
@media screen and (max-width: 667px) {
  .site-searchbox .searchbox__btn {
    font-size: 1.1rem;
    padding: 0 1.2rem;
  }
}

.site-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(69, 83, 99, 0.9);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
}
.is-overlay-active .site-overlay {
  opacity: 1;
  visibility: visible;
  transition: visibility 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.is-overlay-active-fadeout .site-overlay {
  transition: visibility 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s, opacity 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.site-overlay .site-layout-wrapper, .site-overlay .bio-detail .scrollspy .page-wrapper, .bio-detail .scrollspy .site-overlay .page-wrapper {
  position: relative;
}

.site-overlay__close {
  color: #e1e1e1;
  position: absolute;
  right: 1.9rem;
  top: 0;
  height: 7.6%;
  text-transform: uppercase;
}

.site-footer {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  color: #fff;
}
.site-footer__columns {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.site-footer__logo {
  width: 138px;
  margin-right: 5%;
  display: flex;
  align-items: center;
}
.site-footer__logo > img {
  display: block;
  width: 100%;
}
.site-footer__message {
  max-width: 390px;
  width: 29%;
  margin-right: 5%;
}
.site-footer__nav {
  flex-grow: 1;
  columns: 3 auto;
}
.site-footer__nav .list__item {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.site-footer__nav-link {
  color: #fff;
  font-size: 0.8888888889rem;
  text-decoration: underline;
  padding-bottom: 12px;
  display: inline-block;
}
.site-footer .social-icon__link {
  color: #fff;
  font-size: 1.1111111111rem;
}
.site-footer__nav-link:hover,
.site-footer .social-icon__link:hover {
  color: #009a45;
}
.site-footer .social-icons {
  float: right;
}
.site-footer .social-icons .list__item + .list__item {
  margin-left: 20px;
}
.site-footer__closing {
  padding-top: 3.3333333333rem;
}
.site-footer__copyright {
  font-size: 0.7777777778rem;
  text-transform: uppercase;
}
@media screen and (max-width: 899px) {
  .site-footer__columns {
    flex-wrap: wrap;
  }
  .site-footer__message {
    flex-grow: 1;
  }
  .site-footer__nav {
    width: 100%;
    margin-top: 3.3333333333rem;
  }
}
@media screen and (max-width: 667px) {
  .site-footer {
    text-align: center;
  }
  .site-footer__message {
    flex-grow: 1;
    width: auto;
    max-width: none;
  }
  .site-footer__nav-link.hide-mobile {
    display: none;
  }
  .site-footer__columns {
    display: block;
  }
  .site-footer__logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.7777777778rem;
  }
  .site-footer__logo {
    max-width: none;
  }
  .site-footer__nav {
    columns: 1;
  }
  .site-footer .social-icon__link {
    font-size: 1.6666666667rem;
  }
  .site-footer .social-icons {
    float: none;
    display: flex;
    justify-content: space-around;
  }
  .site-footer .social-icons .list__item + .list__item {
    margin-left: 0;
  }
  .site-footer__copyright {
    margin-top: 1.6666666667rem;
  }
}

.cube {
  position: relative;
  height: 93%;
  width: 100%;
  z-index: 100;
  perspective: 5000px;
  perspective-origin: 0 50%;
  overflow: hidden;
}
@media screen and (max-width: 899px) {
  .cube {
    perspective: 2000px;
    height: 87%;
  }
}
@media screen and (max-width: 667px) {
  .cube {
    perspective: 2000px;
    height: 85%;
  }
}

.cube__slide {
  background-color: #5a7d99;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 7.6% 0;
  z-index: 110;
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
}
@media screen and (max-width: 899px) {
  .cube__slide {
    padding: 15% 0;
  }
}
@media screen and (max-width: 668px) and (max-height: 420px) {
  .cube__slide {
    padding: 8% 0;
  }
}
.cube__slide.is-slide-current {
  opacity: 1;
  visibility: visible;
}
.cube__slide.is-cube-slide-out {
  z-index: 120;
  transform-origin: 100% 50%;
  opacity: 1;
  visibility: visible;
}
.is-animating-left .cube__slide.is-cube-slide-out {
  animation: slideCurrent 1s ease forwards;
}
.is-animating-right .cube__slide.is-cube-slide-out {
  animation: slideCurrent-reverse 1s ease forwards;
}
.cube__slide.is-cube-slide-in {
  left: 100%;
  transform-origin: 0% 100%;
  z-index: 110;
  opacity: 1;
  visibility: visible;
}
.is-animating-left .cube__slide.is-cube-slide-in {
  animation: slideNext 1s ease forwards;
}
.is-animating-right .cube__slide.is-cube-slide-in {
  animation: slideNext-reverse 1s ease forwards;
}

@keyframes slideCurrent {
  0% {
    transform: translateX(0%) rotateY(0deg) translateZ(0);
  }
  100% {
    transform: translateX(-100%) rotateY(-90deg) translateZ(0);
  }
}
@keyframes slideNext {
  0% {
    transform: translateX(0) translateZ(0) rotateY(75deg);
  }
  100% {
    transform: translateX(-100%) translateZ(0) rotateY(0deg);
  }
}
@keyframes slideCurrent-reverse {
  0% {
    transform: translateX(-100%) rotateY(-90deg) translateZ(0);
  }
  100% {
    transform: translateX(0%) rotateY(0deg) translateZ(0);
  }
}
@keyframes slideNext-reverse {
  0% {
    transform: translateX(-100%) rotateY(0deg) translateZ(0);
  }
  100% {
    transform: translateX(0) rotateY(70deg) translateZ(0);
  }
}
.cube__slide-content {
  height: 100%;
  position: relative;
}

.cube__wrapper {
  height: 0;
}

.cube__arrow {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 150;
  color: #e1e1e1;
  transition: transform 300ms ease-out;
  font-size: 1.7rem;
}
.cube__arrow:hover {
  transform: translate(10px, -50%);
}
@media screen and (max-width: 667px) {
  .cube__arrow:hover {
    transform: translate(10px, 0);
  }
}
.cube__arrow.cube__arrow--left:hover {
  transform: translate(-10px, -50%);
}
@media screen and (max-width: 667px) {
  .cube__arrow.cube__arrow--left:hover {
    transform: translate(-10px, 0);
  }
}
@media screen and (max-width: 667px) {
  .cube__arrow {
    top: auto;
    bottom: 2.5%;
    transform: translate(0);
    font-size: 1.4rem;
  }
}

.js-cube-arrow-right {
  margin-left: -20px;
  float: right;
}

.util-text-align-right {
  float: right;
}

.cube__slide-refresh {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
  height: 10%;
  z-index: 150;
  color: #e1e1e1;
}
.cube__slide-refresh:hover {
  opacity: 0.8;
}
@media screen and (max-width: 667px) {
  .cube__slide-refresh:hover {
    opacity: 1;
  }
}
.cube__slide-refresh .bi {
  font-size: 2.4em;
  transition: transform 0.6s linear;
}
@media screen and (max-width: 667px) {
  .cube__slide-refresh .bi {
    font-size: 2em;
  }
}
.cube__slide-refresh .bi:before {
  transform: rotate(-90deg);
}
@media screen and (max-width: 667px) {
  .cube__slide-refresh {
    height: auto;
    bottom: 3%;
  }
}

.article {
  font-weight: 300;
}

.article__header {
  margin-bottom: 0.825rem;
}

.article__title {
  line-height: 1.1;
  margin-bottom: 0;
}

.article__lead {
  font-size: 120%;
}

.article__body {
  margin-bottom: 1.65rem;
}

.article--modal time {
  color: #707070;
  font-weight: 400;
  margin-bottom: 0.825rem;
}

.location-listing:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}
@media screen and (min-width: 768px) {
  .location-listing {
    margin: 0 -2.5%;
  }
}
@media screen and (min-width: 668px) {
  .location-listing .location {
    float: left;
    width: 50%;
    padding: 0 2.5%;
  }
}
@media screen and (min-width: 768px) {
  .location-listing .location {
    width: 50%;
    padding: 0 2.5%;
  }
}
@media screen and (min-width: 420px) {
  .location-listing .location:nth-child(2n+1) {
    padding-left: 0;
  }
}
@media screen and (min-width: 768px) {
  .location-listing .location:nth-child(2n+1) {
    padding-left: 2.5%;
  }
}
@media screen and (min-width: 420px) {
  .location-listing .location:nth-child(2n) {
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .location-listing .location:nth-child(2n) {
    padding-right: 2.5%;
  }
}
@media screen and (min-width: 768px) {
  .location-listing .location:nth-child(3n+1) {
    /*clear: left;*/
  }
}

[data-modal] {
  opacity: 1;
  visibility: visible;
  transition-property: opacity;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-delay: 0.5s;
  z-index: 1;
}

[data-visible=false] {
  visibility: hidden;
  opacity: 0;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.boost-disclaimer-modal {
  position: fixed;
  width: 600px;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  padding: 1.65rem;
  transform: translateY(-50%);
  background-color: white;
  color: #707070;
  z-index: 100000;
}
@media screen and (max-width: 667px) {
  .boost-disclaimer-modal {
    width: 100vw;
    left: 0;
    margin-left: 0;
  }
  .boost-disclaimer-modal .modal__message.rich-text {
    font-size: 1rem;
  }
}
.boost-disclaimer-modal .modal__close {
  color: #464645;
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
}
.boost-disclaimer-modal .modal__title {
  margin-top: 0.75rem;
  color: #464645;
  margin-bottom: 1.65rem;
}
.boost-disclaimer-modal .modal__message {
  font-weight: 300;
  margin-bottom: 1.65rem;
}

.email-disclaimer-modal {
  position: fixed;
  width: 600px;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  padding: 1.65rem;
  transform: translateY(-50%);
  background-color: #e1e1e1;
  color: #707070;
  z-index: 100000;
}
@media screen and (max-width: 667px) {
  .email-disclaimer-modal {
    width: 100vw;
    left: 0;
    margin-left: 0;
  }
  .email-disclaimer-modal .modal__message.rich-text {
    font-size: 1rem;
  }
  .email-disclaimer-modal .modal__button-group .modal__button {
    width: calc(50% - 7px);
    padding: 0.4rem 1rem;
  }
}
.email-disclaimer-modal .modal__title {
  color: #464645;
  margin-bottom: 1.65rem;
}
.email-disclaimer-modal .modal__message {
  font-weight: 300;
  margin-bottom: 1.65rem;
}
.email-disclaimer-modal .modal__button-group {
  text-align: center;
}
.email-disclaimer-modal .modal__button-group .modal__button {
  display: inline-block;
}
.email-disclaimer-modal .modal__button-group .modal__button + .modal__button {
  margin-left: 1rem;
}
.email-disclaimer-modal button {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-rendering: optimizeLegibility;
}
.email-disclaimer-modal button:focus {
  outline: 1px solid yellow;
}
.email-disclaimer-modal [data-action=agree] {
  background-color: white;
}
.email-disclaimer-modal [data-action=cancel] {
  color: white;
}

.footer {
  padding: 1em;
}

.hero-profile {
  background-color: #0F0803;
  background-size: auto 100%;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  padding-top: 1.65rem;
  display: table;
  width: 100%;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 900px) {
  .hero-profile {
    height: 55vw;
    max-height: 52.7777777778rem;
  }
}
@media screen and (max-width: 979px) {
  .hero-profile {
    height: 65vw;
  }
}
.hero-profile .hero-content {
  display: table-row;
  height: 1em;
  left: 0;
  bottom: 0;
}
.hero-profile .breadcrumb-nav {
  padding: 1.5em 0;
  position: relative;
  z-index: 5;
}
.hero-profile .hero-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: center top;
}
.hero-profile .hero-image:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
}
.hero-profile .hero-image:before {
  background-repeat: repeat-x;
  background-image: linear-gradient(90deg, #0f080d 65%, rgba(15, 8, 3, 0) 100%);
  opacity: 0.7;
  width: 60%;
  left: -1px;
}
.hero-profile .hero-image-stack {
  visibility: hidden;
  opacity: 0;
}
.hero-profile .profile-content {
  display: table-cell;
  padding: 1.5em 0;
  vertical-align: middle;
  position: relative;
  z-index: 5;
}
.hero-profile .profile-content .page-wrapper {
  max-width: 77.7777777778rem;
}
.hero-profile .profile-content a {
  color: #93bed3;
}
@media screen and (max-width: 667px) {
  .hero-profile .profile-content a {
    color: #5b7e99;
  }
}
.hero-profile .profile-card {
  max-width: 45%;
  position: relative;
}
.hero-profile .name, .hero-profile .level {
  text-transform: uppercase;
  color: #fff;
}
.hero-profile .name {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  font-size: 2.1111111111rem;
  margin: 0;
}
.hero-profile .profile-card-details {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: #D2D2D2;
  font-size: 1.5555555556rem;
  margin: 0.8333333333rem 0;
}
.hero-profile .level {
  font-size: 1.6666666667rem;
}
.hero-profile .level + .profile-card-location:after {
  content: "|";
  color: #D2D2D2;
  display: inline-block;
  margin-right: 0.4444444444rem;
  margin-left: 0.4444444444rem;
}
.hero-profile .level + .profile-card-location:last-child:after {
  display: none;
}
@media screen and (max-width: 667px) {
  .hero-profile .level + .profile-card-location:before {
    color: #464645;
  }
}
.hero-profile .profile-card-location {
  font-size: 1.1111111111rem;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.hero-profile .profile-card-contact {
  font-size: 1.2222222222rem;
}
.hero-profile .profile-card-contact ul {
  margin: 0 1.1111111111rem 0 0;
  padding: 0;
  display: inline-block;
}
.hero-profile div.profile-card-contact {
  display: flex;
}
.hero-profile .expertise {
  font-size: 1.4444444444rem;
  line-height: 1.6;
  color: #93bed3;
  margin: 2rem 0;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.hero-profile .expertise .list__item {
  color: #fff;
}
.hero-profile .expertise .list__item:after {
  content: "|";
  color: #fff;
  display: inline-block;
  margin-right: 0.2777777778rem;
  margin-left: 0.4444444444rem;
}
.hero-profile .expertise .list__item:last-child:after {
  display: none;
}
.hero-profile .expertise .expertise__link:hover {
  color: #93bed3;
}
.hero-profile .list-vertical.expertise .list__item:after {
  content: none;
}
.hero-profile .summary-btn {
  display: block;
  background-color: rgba(147, 190, 211, 0.5);
  border-color: transparent;
  margin-top: 2.0625rem;
  font-size: 1.2222222222rem;
}
@media screen and (max-width: 667px) {
  .hero-profile .summary-btn {
    background-color: #5b7e99;
  }
}
.hero-profile .summary-btn:hover {
  background-color: #71848b;
}
.hero-profile .summary-btn:before {
  content: "+ ";
}
.hero-profile .close:before {
  content: "- ";
}
.hero-profile .profile-card-phone,
.hero-profile .profile-card-email,
.hero-profile .profile-card-attachments,
.hero-profile .profile-card-location {
  font-size: 1.2222222222rem;
  color: #93bed3;
  margin-top: 0.5em;
  margin-left: 1.5em;
  position: relative;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
}
.hero-profile .profile-card-phone:before,
.hero-profile .profile-card-email:before,
.hero-profile .profile-card-attachments:before,
.hero-profile .profile-card-location:before {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  position: absolute;
  left: -1.5em;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1em;
}
.hero-profile .profile-card-phone {
  color: #fff;
  margin-right: 1.5em;
}
.hero-profile .profile-card-phone:not(.profile-card-phone-mobile)::before {
  content: "";
  transform: rotate(-270deg);
  transform-origin: 70% 30%;
}
.hero-profile .profile-card-phone:empty:before, .hero-profile .profile-card-phone-mobile:before {
  content: unset;
}
.hero-profile .profile-card-email:before {
  content: "";
}
.hero-profile .profile-card-attachments {
  display: inline-block;
  margin-top: 1rem;
  margin-right: 1rem;
}
.hero-profile .profile-card-attachments:before {
  content: "";
}
.hero-profile .profile-card-location:before {
  content: "";
}
@media screen and (max-width: 1099px) {
  .hero-profile .profile-content {
    padding-left: 0;
  }
}
@media screen and (max-width: 765px) {
  .hero-profile {
    background-image: none !important;
    background-color: #fff;
    padding: 0;
    display: block;
    height: auto;
  }
  .hero-profile .hero-content {
    position: relative;
    display: block;
    height: auto;
  }
  .hero-profile .profile-card {
    max-width: none;
  }
  .hero-profile .profile-card:before {
    display: none;
  }
  .hero-profile .breadcrumb-nav {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 1;
  }
  .hero-profile .hero-image {
    position: relative;
    left: 0;
    transform: translateX(0);
  }
  .hero-profile .hero-image:before, .hero-profile .hero-image:after {
    display: none;
  }
  .hero-profile .hero-image-stack {
    position: relative;
    max-height: 350px;
    left: 100%;
    transform: translateX(-100%);
    height: auto;
  }
  .hero-profile .profile-content {
    padding: 1.65rem 0 0;
    display: block;
  }
  .hero-profile .name {
    color: #666666;
    max-width: none;
  }
  .hero-profile .level {
    color: #666666;
  }
  .hero-profile .expertise .list__item {
    color: #464645;
  }
  .hero-profile .expertise .expertise__link {
    color: #93bed3;
  }
}
@media screen and (max-width: 765px) and (max-width: 667px) {
  .hero-profile .expertise .expertise__link {
    color: #5b7e99;
  }
}
@media screen and (max-width: 765px) {
  .hero-profile .expertise .expertise__link:hover {
    color: #464645;
  }
}
@media screen and (max-width: 765px) {
  .hero-profile .profile-card-location,
.hero-profile .profile-card-phone,
.hero-profile .profile-card-email,
.hero-profile .profile-card-attachments {
    color: #666666;
  }
  .hero-profile .profile-card-location:before,
.hero-profile .profile-card-phone:before,
.hero-profile .profile-card-email:before,
.hero-profile .profile-card-attachments:before {
    color: #666666;
  }
}
@media screen and (max-width: 765px) {
  .hero-profile .profile-card-phone {
    margin-top: 0;
  }
}
@media screen and (max-width: 413px) {
  .hero-profile .hero-image-stack {
    max-height: 250px;
  }
  .hero-profile .first-name {
    font-size: 1.6666666667rem;
  }
  .hero-profile .last-name {
    font-size: 1.6666666667rem;
  }
  .hero-profile .level {
    font-size: 1.3333333333rem;
  }
  .hero-profile .expertise {
    font-size: 1rem;
  }
  .hero-profile .profile-card-location,
.hero-profile .profile-card-phone,
.hero-profile .profile-card-email,
.hero-profile .profile-card-attachments {
    font-size: 1rem;
    color: #666666;
    margin-top: 0;
  }
  .hero-profile .profile-card-location:before,
.hero-profile .profile-card-phone:before,
.hero-profile .profile-card-email:before,
.hero-profile .profile-card-attachments:before {
    color: #666666;
  }
}

.about-detail .page-header {
  background-position-x: 35%;
}

.careers-intro {
  position: relative;
}
.careers-intro .page-header-title {
  padding-top: 6.5rem;
  padding-bottom: 1rem;
  margin: 0;
  min-height: inherit;
}
@media screen and (max-width: 767px) {
  .careers-intro .page-header-title {
    padding-top: 1rem;
  }
}
.careers-intro .page-header-title .page-header-label {
  display: block;
}
.careers-intro .extras {
  margin: auto;
  margin-bottom: 1rem;
}
@media screen and (min-width: 769px) {
  .careers-intro .extras {
    bottom: 15%;
  }
}
.careers-intro .extras .apply-btn {
  width: 13rem;
  display: block;
  margin: auto;
}
.careers-intro .extras .divider {
  border: 0;
  background-color: #8a969b;
  width: 5rem;
  height: 2px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 767px) {
  .careers-intro .extras .divider {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
.careers-intro .extras .social-icons {
  text-align: center;
}
.careers-intro .extras .social-icons .list__item {
  display: inline-block;
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  background-color: #788189;
}
.careers-intro .extras .social-icons .list__item i {
  color: white;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.careerSegments {
  margin-bottom: 4.125rem;
}

.careerSegments-header {
  margin-bottom: 1.65rem;
}

.subscriptions-heading {
  text-transform: uppercase;
  color: #009a45;
}

.subscriptions-message {
  margin-bottom: 1rem;
}

.subscriberInfo:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.subscriptionSection:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.subscriptionSection-heading {
  text-transform: uppercase;
  color: #464645;
}

#homepage {
  background-color: #fff;
  /*==================================
  =            Site Navigation
  ==================================*/
}
#homepage #app {
  background-image: none;
}
#homepage .site-overlay > .site-layout-wrapper, #homepage .bio-detail .scrollspy .site-overlay > .page-wrapper, .bio-detail .scrollspy #homepage .site-overlay > .page-wrapper {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.9rem;
}
#homepage .site-header-wrapper {
  display: block;
  height: auto;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.9rem;
}
@media screen and (max-width: 899px) {
  #homepage .site-header-wrapper {
    padding: 0 1.5rem;
    width: 100%;
  }
}
@media screen and (max-width: 667px) {
  #homepage .site-header-wrapper {
    padding: 0 1.2rem;
  }
}
#homepage .site-brand__title {
  position: static;
  top: auto;
  transform: translateY(0);
}
@media screen and (max-width: 979px) {
  #homepage .site-brand__title {
    position: static;
    height: auto;
    bottom: auto;
  }
}
#homepage .site-brand__logo-link {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  transform: translateY(50%);
}
@media screen and (max-width: 979px) {
  #homepage .site-brand__logo-link {
    position: static;
    top: auto;
    transform: translateY(0);
  }
}
#homepage .site-full-nav .button--icon {
  right: 1.9rem;
  color: #e1e1e1;
}
#homepage .site-footer {
  z-index: 100;
  position: relative;
  width: 100%;
  min-height: 15%;
}
#homepage .site-nav__link,
#homepage .subnav__link {
  color: #e1e1e1;
}
#homepage .site-nav__link:hover,
#homepage .subnav__link:hover {
  color: #e1e1e1;
}

.bio-detail .page-wrapper {
  padding-bottom: 0.7407407407rem;
}
.bio-detail .header-rich-text {
  margin-top: 5rem;
  margin-bottom: 1.65rem;
}
.bio-detail .page-launch {
  margin-top: 4.4444444444rem;
}
.bio-detail .accordion-list {
  margin-top: 4.4444444444rem;
  margin-bottom: 4.4444444444rem;
}
.bio-detail .accordion-list .header {
  width: 100%;
}
.bio-detail .social-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 2.475rem;
}
.bio-detail .social-link {
  display: inline-block;
}
.bio-detail .social-link a {
  display: inline-block;
  margin-right: 0.8em;
  border-radius: 2.4em;
  position: relative;
  background-color: rgba(147, 190, 211, 0.5);
  width: 2.4em;
  height: 2.4em;
}
@media screen and (max-width: 667px) {
  .bio-detail .social-link a {
    background-color: #5b7e99;
  }
}
.bio-detail .social-link a:before {
  content: "";
  display: inline-block;
  speak: none;
  font: normal normal normal 14px/1 "Font Awesome Brands";
  font-size: inherit;
  vertical-align: -14.2857142857%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  transition: color 0.25s;
}
.bio-detail .social-link a:hover {
  background-color: #71848b;
}
.bio-detail .social-link .social-link-profile {
  color: #fff;
}
.bio-detail .social-link .social-link-profile img {
  width: 60%;
  vertical-align: bottom;
  position: absolute;
  left: 20%;
  top: 20%;
}
.bio-detail .social-link .social-link-profile:before {
  content: "";
  font-family: "Arial";
}
.bio-detail .social-link .social-link-linkedin:before {
  content: "";
}
.bio-detail .social-link .social-link-twitter:before {
  content: "";
}
.bio-detail .related-practices {
  padding: 2.5rem 0;
  border-top: 5px solid #eeeeee;
  border-bottom: 5px solid #eeeeee;
}
.bio-detail .related-practices .list__item {
  white-space: nowrap;
  font-size: 1.3333333333rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
}
.bio-detail .related-practices .list__item:after {
  content: "|";
  color: #464646;
  display: inline-block;
  margin-right: 0.4444444444rem;
  margin-left: 0.4444444444rem;
}
.bio-detail .related-practices .list__item:last-child:after {
  display: none;
}
@media screen and (max-width: 667px) {
  .bio-detail .related-practices .header--emphasis {
    font-size: 1.25rem;
    color: #666666;
    letter-spacing: 0.05rem;
  }
  .bio-detail .related-practices .list__item {
    font-size: 1rem;
  }
  .bio-detail .related-practices .expertise__link {
    color: #009a45;
  }
}
@media screen and (max-width: 1024px) {
  .bio-detail .site-layout-wrapper.related-practices-wrapper, .bio-detail .scrollspy .related-practices-wrapper.page-wrapper {
    padding-top: 5rem;
    background: #fff;
    position: relative;
  }
  .bio-detail .site-layout-wrapper.related-practices-wrapper .related-practices, .bio-detail .scrollspy .related-practices-wrapper.page-wrapper .related-practices {
    margin-top: 0;
  }
}
.bio-detail .scrollspy .scrollspy-sections {
  margin-right: 0;
}
@media screen and (min-width: 900px) {
  .bio-detail .scrollspy .scrollspy-sections {
    margin-right: 0;
    margin-left: calc(18rem + 1.9rem);
  }
}
.bio-detail .scrollspy .scrollspy-sections--shade {
  margin-top: 4.125rem;
  margin-bottom: 4.125rem;
}
.bio-detail .scrollspy .scrollspy-sections--shade .js-scrollspy-section {
  padding-bottom: 4rem;
}
.bio-detail .scrollspy .header-text {
  color: #464645;
  margin-bottom: 1.3888888889rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
}

.people-landing .dropdown-label-icon {
  display: inline;
}
.people-landing .people-sort-by-container .is-active {
  font-weight: bold;
}
.people-landing .people-letters-container .filters {
  width: 55%;
  display: inline-block;
}
.people-landing .people-letters-container .sort {
  display: inline-block;
  float: right;
}

@media screen and (max-width: 767px) {
  .about-detail {
    margin-right: 0 !important;
  }
  .about-detail .page-header {
    margin-right: 0 !important;
  }
  .about-detail .subheader-nav {
    margin-right: 0 !important;
  }
}
.about-detail .accordion-list {
  margin: 3.3rem 0;
}
.about-detail .general-content-section + .general-content-section {
  border-top: 1px solid #d7d7d7;
  padding-top: 2.35rem;
  margin-top: 1.65rem;
}
.about-detail .general-content-section__video-container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.about-detail .general-content-section__video-item {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .about-detail .general-content-section.column-layout {
    display: flex;
    flex-direction: column;
  }
  .about-detail .general-content-section.column-layout .rich-text {
    width: 100% !important;
    float: none !important;
    padding: 0;
  }
  .about-detail .general-content-section.column-layout .text-column {
    order: 1;
    padding: 0 0 3.3rem 0 !important;
  }
  .about-detail .general-content-section.column-layout .video-embed-column {
    order: 2;
  }
  .about-detail .general-content-section .general-content-section__video-container {
    flex-wrap: wrap;
  }
  .about-detail .general-content-section .general-content-section__video-item {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
.about-detail .general-content-section .video-embed-column {
  width: 40%;
  float: left;
}
.about-detail .general-content-section .text-column {
  width: 60%;
  float: left;
  padding: 0 2.35rem 0 0;
}
.about-detail .general-content-section.text-to-right .text-column {
  padding-left: 2.35rem;
}

.service-listing-container .list-related__left,
.service-listing-container .list-related__right {
  width: 100%;
}
@media screen and (min-width: 669px) {
  .service-listing-container .related-items {
    padding: 0;
    width: 100%;
    position: relative;
  }
  .service-listing-container .related-items + .related-items {
    margin: 5rem 0 0 0;
  }
  .service-listing-container .related-items .section-header {
    display: inline-block;
  }
  .service-listing-container .related-items .list-related__left,
.service-listing-container .related-items .list-related__right {
    width: 49.5%;
  }
  .service-listing-container .related-items .list__item__left {
    display: block;
    float: left;
    vertical-align: top;
  }
  .service-listing-container .related-items .list__item__right {
    display: block;
    float: right;
    vertical-align: top;
  }
  .service-listing-container .related-items .list__item__container {
    margin-bottom: 0.5rem;
    position: relative;
  }
  .service-listing-container .related-item-fix {
    padding: 3.8rem 0 0 0 !important;
  }
}
.service-listing-container .services__content {
  display: none;
  padding: 1rem 3rem;
}
.service-listing-container .services__content h3 {
  font-weight: 500;
  font-size: 1.3333333333rem;
  margin-bottom: 1rem;
}
.service-listing-container .services__content h3 a {
  font-size: 1.25rem;
  color: #666666;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  font-weight: 700;
}
.service-listing-container .services__content h3 a:hover {
  color: #578fdb;
}
.service-listing-container .services__content ul {
  padding: 0;
  margin: 0;
}
.service-listing-container .services__content--expanded {
  display: block;
}

.careers-segment-landing .general-content-section + .general-content-section {
  border-top: 1px solid #d7d7d7;
  padding-top: 2.35rem;
  margin-top: 1.65rem;
}
.careers-segment-landing .general-content-section__video-container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.careers-segment-landing .general-content-section__video-item {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .careers-segment-landing .general-content-section.column-layout {
    display: flex;
    flex-direction: column;
  }
  .careers-segment-landing .general-content-section.column-layout .rich-text {
    width: 100% !important;
    float: none !important;
    padding: 0;
  }
  .careers-segment-landing .general-content-section.column-layout .text-column {
    order: 1;
    padding: 0 0 3.3rem 0 !important;
  }
  .careers-segment-landing .general-content-section.column-layout .video-embed-column {
    order: 2;
  }
  .careers-segment-landing .general-content-section .general-content-section__video-container {
    flex-wrap: wrap;
  }
  .careers-segment-landing .general-content-section .general-content-section__video-item {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
.careers-segment-landing .general-content-section .video-embed-column {
  width: 40%;
  float: left;
}
.careers-segment-landing .general-content-section .text-column {
  width: 60%;
  float: left;
  padding: 0 2.35rem 0 0;
}
.careers-segment-landing .general-content-section.text-to-right .text-column {
  padding-left: 2.35rem;
}
.careers-segment-landing .banner__button {
  margin-bottom: 1.65rem;
}
.careers-segment-landing a {
  color: #009B48;
}
.careers-segment-landing a:hover {
  color: #585858;
}
.careers-segment-landing h3 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 1.3333333333rem;
  color: black;
  padding-bottom: 0.8888888889rem;
}
.careers-segment-landing h3 strong {
  position: relative;
  font-weight: 400;
  background-image: url("/assets/site/Main/public/images/careers/circle.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 14px 2px;
}
.careers-segment-landing .button--ghost-reverse {
  background-color: #9DFF78;
  padding: 1rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  display: inline-block;
  font-size: 1rem;
  margin-top: 1.9444444444rem;
  transform: translate(0, 0);
  line-height: 1.4;
  box-shadow: none;
  border: none;
  border-radius: 0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.careers-segment-landing .button--ghost-reverse:hover {
  color: black;
  transform: translate(2px, -2px);
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
}
.careers-segment-landing .rich-text li:before {
  background-color: #9DFF78;
}
.careers-segment-landing .header-text {
  color: #009a45;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: bold;
  margin-bottom: 1.1111111111rem;
}
.careers-segment-landing h2 {
  color: #585858;
}
.careers-segment-landing .question:hover .question-text {
  color: #585858;
}
.careers-segment-landing .question-text {
  color: #009B48;
  font-family: "brandon-grotesque", sans-serif;
  transition: color 0.3s;
}
.careers-segment-landing .button-expand-all {
  color: #009B48;
}
.careers-segment-landing .button-expand-all:hover {
  color: #585858;
}

.careers-segment-landing.page-main {
  background-image: url("/assets/site/Main/public/images/careers/background-texture.png");
}

.blogs-landing .general-content-section + .general-content-section {
  border-top: 1px solid #d7d7d7;
  padding-top: 2.35rem;
  margin-top: 1.65rem;
}
@media screen and (max-width: 767px) {
  .blogs-landing .general-content-section.column-layout {
    display: flex;
    flex-direction: column;
  }
  .blogs-landing .general-content-section.column-layout .rich-text {
    width: 100% !important;
    float: none !important;
    padding: 0;
  }
  .blogs-landing .general-content-section.column-layout .text-column {
    order: 1;
    padding: 0 0 3.3rem 0 !important;
  }
  .blogs-landing .general-content-section.column-layout .video-embed-column {
    order: 2;
  }
}
.blogs-landing .general-content-section .video-embed-column {
  width: 40%;
  float: left;
}
.blogs-landing .general-content-section .text-column {
  width: 60%;
  float: left;
  padding: 0 2.35rem 0 0;
}
.blogs-landing .general-content-section.text-to-right .text-column {
  padding-left: 2.35rem;
}

.services-detail .relatedfocus__jumplink {
  margin: 1.65rem 0;
}
.services-detail .focus-areas {
  margin-bottom: 1.65rem;
  padding-bottom: 1.65rem;
  border-bottom: 1px solid #e1e1e1;
}
.services-detail .list-focus-areas {
  margin-top: 1.5rem;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.services-detail .rich-text.line-height-fix p {
  line-height: 1.5 !important;
}
.services-detail .general-content-section + .general-content-section {
  border-top: 1px solid #d7d7d7;
  padding-top: 2.35rem;
  margin-top: 1.65rem;
}
@media screen and (max-width: 767px) {
  .services-detail .general-content-section.column-layout {
    display: flex;
    flex-direction: column;
  }
  .services-detail .general-content-section.column-layout .rich-text {
    width: 100% !important;
    float: none !important;
    padding: 0;
  }
  .services-detail .general-content-section.column-layout .text-column {
    order: 1;
    padding: 0 0 3.3rem 0 !important;
  }
  .services-detail .general-content-section.column-layout .video-embed-column {
    order: 2;
  }
}
.services-detail .general-content-section .video-embed-column {
  width: 40%;
  float: left;
}
.services-detail .general-content-section .text-column {
  width: 60%;
  float: left;
  padding: 0 2.35rem 0 0;
}
.services-detail .general-content-section.text-to-right .text-column {
  padding-left: 2.35rem;
}

.sitesearch .results--list {
  border-top: 1px solid #E9E9E9;
}
.sitesearch .results--item {
  display: flex;
  flex-direction: row;
  padding: 0.5em 0;
  border-bottom: 1px solid #E9E9E9;
}
.sitesearch .results--item--image {
  flex: 0 1 auto;
  margin-right: 10px;
}
.sitesearch .results--item--text {
  flex: 1 1 auto;
}
.sitesearch .results--item--text--title {
  margin-bottom: 7px;
  color: #5b7e99;
}
.sitesearch .results--item--text--file-format {
  font-size: 13px;
}
.sitesearch .results--item--text--url {
  color: #5b7e99;
  font-size: 13px;
}
.sitesearch .google-search-filters ul {
  padding-left: 0;
}
.sitesearch .google-search-filters ul li {
  display: inline-block;
  margin-bottom: 0.2rem;
  height: 2.5rem;
  width: calc(25% - 3px);
}
.sitesearch .google-search-filters ul li button {
  width: 100%;
  height: 100%;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .sitesearch .google-search-filters ul li {
    height: 3.5rem;
    width: calc(50% - 3px);
  }
}
.sitesearch .google-filter {
  width: 195px;
}
.sitesearch #cse {
  margin-top: 50px;
}
.sitesearch #cse a {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  font-size: 1.3333333333rem;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
}
.sitesearch #cse a :hover {
  color: #578fdb;
}
.sitesearch #cse .gs-title {
  color: #5b7e99;
  margin-bottom: 7px;
}
.sitesearch #cse .gs-title b {
  color: #5b7e99;
  text-decoration: none;
}
.sitesearch #cse .gs-fileFormat {
  font-family: "Quattrocento", serif;
}
.sitesearch #cse .gs-snippet {
  font-size: 1rem;
  line-height: 1.3;
  font-family: "Quattrocento", serif;
  margin-bottom: 7px;
}
.sitesearch #cse .gs-visibleUrl {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  color: #5b7e99;
}
.sitesearch #cse .gsc-webResult.gsc-result:first-of-type {
  border-bottom: none;
}
.sitesearch #cse .gsc-webResult.gsc-result:first-of-type .gs-spelling {
  display: none;
  border: none;
}

.subscription-landing .InputNotValid {
  border-color: red;
}
.subscription-landing .CheckboxsNotValid {
  color: red;
}
.subscription-landing .section-header {
  margin-top: 1.6666666667rem;
}
.subscription-landing .searchbox__input {
  width: 49%;
  float: left;
  margin-top: 5px;
}
@media screen and (max-width: 667px) {
  .subscription-landing .searchbox__input {
    width: 100%;
  }
}
.subscription-landing .searchbox__input:nth-child(even) {
  margin-left: 10px;
}
@media screen and (max-width: 667px) {
  .subscription-landing .searchbox__input:nth-child(even) {
    margin-left: 0px;
  }
}

.probono-landing .InputNotValid {
  border-color: red;
}
.probono-landing .email-send-error {
  margin-top: 24px;
  text-align: center;
}
.probono-landing .ErrorMessage {
  color: red;
}
.probono-landing .section-header {
  margin-top: 1.6666666667rem;
}
.probono-landing .searchbox__input__full {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  float: left;
  margin-top: 0.5rem;
}
.probono-landing .searchbox__input__full::-webkit-input-placeholder {
  color: #898989;
}
.probono-landing .searchbox__input__full::-moz-placeholder {
  color: #898989;
}
.probono-landing .searchbox__input__full:-moz-placeholder {
  color: #898989;
}
.probono-landing .searchbox__input__full:-ms-input-placeholder {
  color: #898989;
}
@media screen and (max-width: 667px) {
  .probono-landing .searchbox__input__full {
    width: 100%;
    padding-left: 3rem;
    font-size: 1rem;
  }
}
.probono-landing .checkbox-label {
  display: inline-block;
  margin-left: 1rem;
  margin-top: 1rem;
}
.probono-landing .checkbox-label:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}
.probono-landing .searchbox__input {
  width: 49%;
  float: left;
  margin-top: 0.5rem;
}
.probono-landing .searchbox__input--full {
  width: 99.5%;
}
@media screen and (max-width: 667px) {
  .probono-landing .searchbox__input {
    width: 100%;
  }
}
.probono-landing .searchbox__input:nth-child(even) {
  margin-left: 10px;
}
@media screen and (max-width: 667px) {
  .probono-landing .searchbox__input:nth-child(even) {
    margin-left: 0px;
  }
}

.page--office-detail .page-body {
  position: relative;
}
.page--office-detail .location {
  margin-bottom: 10px;
}
.page--office-detail address {
  margin-bottom: 10px;
}
.page--office-detail section.office-management {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.9rem;
  width: 30%;
}
.page--office-detail section.office-management .related-professionals {
  margin-top: 20px;
}
.page--office-detail section.office-management .related-professionals .list-related-professionals .media__img {
  width: 80%;
}
.page--office-detail section.office-management .related-professionals .list-related-professionals .media__body {
  margin-top: 10px;
}
.page--office-detail section.office-management .related-professionals .professional__phone,
.page--office-detail section.office-management .related-professionals .professional__title,
.page--office-detail section.office-management .related-professionals .professional__email {
  display: block;
}
@media screen and (max-width: 667px) {
  .page--office-detail section.office-management {
    position: inherit;
    padding-right: 0;
    width: 100%;
    margin-bottom: 2.45rem;
  }
}
.page--office-detail section.related-professionals .list-related-professionals {
  width: 50%;
}
.page--office-detail section.related-professionals .list-related-professionals .media__img {
  width: 50%;
}
.page--office-detail .office-card {
  margin-bottom: 6.45rem;
}
@media screen and (max-width: 667px) {
  .page--office-detail .office-card {
    margin-bottom: 2.45rem;
  }
}
.page--office-detail .office-card__contact {
  margin-bottom: 20px;
}
.page--office-detail .office-card__contact .link--block {
  display: block;
}
@media screen and (max-width: 768px) {
  .page--office-detail .contacts__list .contacts__list-item .contact-card__image {
    max-width: 100%;
    margin-right: 0;
  }
  .page--office-detail .contacts__list .contacts__list-item .contact-card__image img {
    height: 100%;
    width: 100%;
  }
}

.topic-detail .page-body .rich-text {
  margin-bottom: 44px;
}
.topic-detail__tag-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.topic-detail__select-tag {
  padding-right: 1rem;
  text-transform: none;
  margin-top: 0.5rem;
}
.topic-detail__tags {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #009a45;
  padding: 6px 15px;
  border-radius: 15px;
  font-weight: 900;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1rem;
  outline: none;
  align-self: center;
  transition: color 0.25s ease-in, background-color 0.25s ease-in;
}
.topic-detail__tags-section {
  flex: 1 0 70%;
}
.topic-detail__tags + .topic-detail__tags {
  margin-left: 0;
}
.topic-detail__tags:hover, .topic-detail__tags--active {
  background-color: #009a45;
  color: #e1e1e1;
}
.topic-detail__clear {
  cursor: pointer;
  text-decoration: underline;
  float: right;
  font-size: 1.3rem;
  font-weight: 400;
  align-self: flex-end;
}
@media screen and (max-width: 767px) {
  .topic-detail__tags-section {
    flex: 1 1 auto;
  }
  .topic-detail__clear {
    width: 100%;
    text-align: end;
  }
}

.event-detail .event-register {
  margin: 20px 0px;
}
.event-detail .guest-speakers {
  margin-top: 1.65rem;
}

.careers-segment-detail .general-content-section + .general-content-section {
  border-top: 1px solid #d7d7d7;
  padding-top: 2.35rem;
  margin-top: 1.65rem;
}
.careers-segment-detail .general-content-section__video-container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.careers-segment-detail .general-content-section__video-item {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .careers-segment-detail .general-content-section.column-layout {
    display: flex;
    flex-direction: column;
  }
  .careers-segment-detail .general-content-section.column-layout .rich-text {
    width: 100% !important;
    float: none !important;
    padding: 0;
  }
  .careers-segment-detail .general-content-section.column-layout .text-column {
    order: 1;
    padding: 0 0 3.3rem 0 !important;
  }
  .careers-segment-detail .general-content-section.column-layout .video-embed-column {
    order: 2;
  }
  .careers-segment-detail .general-content-section .general-content-section__video-container {
    flex-wrap: wrap;
  }
  .careers-segment-detail .general-content-section .general-content-section__video-item {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
.careers-segment-detail .general-content-section .video-embed-column {
  width: 40%;
  float: left;
}
.careers-segment-detail .general-content-section .text-column {
  width: 60%;
  float: left;
  padding: 0 2.35rem 0 0;
}
.careers-segment-detail .general-content-section.text-to-right .text-column {
  padding-left: 2.35rem;
}
.careers-segment-detail .banner__button {
  margin-bottom: 1.65rem;
}

.latest-pubs {
  margin-top: -70px;
}

.comma-separated {
  display: flex;
}

.comma-separated > span + span:before {
  content: ", ";
}

.careers__component {
  margin-bottom: 4.4444444444rem;
}
.careers__component label {
  display: block;
  font-weight: bold;
}
.careers__component .accordion-list {
  max-width: 786px;
  margin: 0 auto;
}
.careers__component .accordion-list .question-text {
  font-family: "brandon-grotesque", sans-serif;
}
.careers__component .accordion-list .question-answer ul li:before {
  background-color: #009B48;
}
@media screen and (max-width: 419px) {
  .careers__component--carousel .careers__carousel-pagination {
    bottom: 2.2222222222rem !important;
  }
  .careers__component--carousel .careers__carousel-dot {
    height: 12px;
    width: 12px;
  }
  .careers__component--carousel .careers__carousel-arrows {
    top: 50%;
    transform: translateY(-50%);
  }
  .careers__component--carousel .careers__carousel-arrows .careers__carousel-arrow {
    padding: 0;
  }
  .careers__component--carousel .careers__carousel-arrows .careers__carousel-arrow::after {
    font-size: 1.7777777778rem;
  }
}
.careers__h1 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 4.3333333333rem;
  text-transform: uppercase;
  color: #009B48;
  padding-top: 1.9444444444rem;
}
@media screen and (max-width: 419px) {
  .careers__h1 {
    font-size: 3.5rem;
  }
}
.careers__h2 {
  font-family: "Big-Caslon-Roman", serif;
  font-weight: 500;
  font-size: 2rem;
  color: #585858;
}
.careers__h3 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  color: #009B48;
}
.careers__center-title {
  text-align: center;
  margin: 0 auto 2rem;
}
.careers__subtitle {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 1.3333333333rem;
  color: black;
  padding-bottom: 0.8888888889rem;
}
.careers__subtitle strong {
  position: relative;
  font-weight: 400;
  background-image: url("/assets/site/Main/public/images/careers/circle.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 14px 0;
}
.careers__cta {
  background-color: #9DFF78;
  padding: 0.5555555556rem 0.8888888889rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  color: black !important;
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.8888888889rem;
  margin-top: 1.9444444444rem;
  transform: translate(0, 0);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.careers__cta:hover {
  color: black;
  transform: translate(2px, -2px);
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
}
.careers__full-wrapper {
  width: 100%;
  background-color: #fff;
  z-index: 0;
}
.careers__inner-wrapper {
  justify-content: space-between;
  padding-top: 5rem;
  margin-top: -6.9444444444rem;
}
@media screen and (max-width: 419px) {
  .careers__inner-wrapper {
    padding-top: 5.5rem;
  }
}
.careers__card-input-outside.scroll-appear--visible {
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition-delay: 1s !important;
}
@media screen and (max-width: 768px) {
  .careers__card-input-outer-wrapper {
    padding: 0 5rem;
  }
}
@media screen and (max-width: 419px) {
  .careers__card-input-outer-wrapper {
    padding: 0.75rem;
  }
}
.careers__card-input-wrapper {
  position: relative;
}
.careers__card-input-wrapper span {
  position: absolute;
  width: 1px;
  height: 80%;
  background-color: #585858;
  left: 250px;
  top: 10%;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}
.careers__card-input-wrapper input:focus + span {
  display: none;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.careers__card-input {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;
  font-family: "Big-Caslon-Roman", serif !important;
  font-size: 36px !important;
  color: #585858 !important;
  line-height: 1 !important;
  caret-color: #585858;
  margin-top: 0.2222222222rem;
}
@media screen and (max-width: 419px) {
  .careers__card-input {
    margin-top: 1rem;
  }
}
.careers__card-input::placeholder {
  color: #585858 !important;
  font-size: 36px !important;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3.5s steps(40, end);
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.careers__card-input-underline {
  background-image: url("/assets/site/Main/public/images/careers/underline.png");
  width: 230px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -1.3888888889rem;
  bottom: -1.7777777778rem;
  z-index: -1;
}
.careers__scroll-hint-wrapper {
  margin-top: 1.6666666667rem;
  display: inline-block;
}
.careers__scroll-hint-wrapper:hover .careers__scroll-hint {
  color: #585858;
}
.careers__scroll-hint-wrapper:hover .careers__scroll-hint-arrow {
  animation: hoverMe 0.3s infinite cubic-bezier(0.645, 0.045, 0.355, 1) alternate;
}
@keyframes hoverMe {
  100% {
    transform: translate3d(0, 10px, 0);
  }
}
.careers__scroll-hint-wrapper.scroll-appear--visible {
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition-delay: 1.25s !important;
}
.careers__scroll-hint {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 0.8888888889rem;
  text-transform: uppercase;
  color: black;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.careers__scroll-hint-arrow:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #9DFF78;
  font-size: 1.6666666667rem;
}
@media screen and (max-width: 419px) {
  .careers__business-card {
    width: 100%;
    height: 18rem;
  }
}
.careers__business-card-lower-fields {
  display: flex;
  justify-content: flex-end;
}
.careers__business-card-website {
  text-align: right;
}
.careers__display-wrapper {
  margin-top: 0.5rem;
}
.careers__display-wrapper div {
  text-align: center;
  margin: 0 auto;
}
.careers__name-field {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 3.3333333333rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  text-shadow: 1px 2px 3px #00bd58, 0 0 0 black, -1px 1px 0 #717070, 1px 1px 0 #4e4e4e;
  margin-top: 3.6666666667rem;
}
.careers__label {
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  margin-top: 1rem;
  text-shadow: 1px 2px 3px #00bd58, 0 0 0 black, -1px 1px 0 #717070, 1px 1px 0 #4e4e4e;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.careers__label--visible {
  height: auto;
  visibility: visible;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .careers__cta-column--left {
    padding-left: 0;
    padding-right: 32px;
  }
  .careers__cta-column--right {
    padding-right: 0;
    padding-left: 32px;
  }
}
.careers__cta-wrapper {
  align-items: center;
  z-index: 1;
  position: relative;
}
@media screen and (max-width: 419px) {
  .careers__cta-wrapper {
    display: block;
  }
}
.careers__cta-wrapper--dark-theme {
  background-color: #585858;
  padding: 3.3333333333rem;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
}
@media screen and (max-width: 768px) {
  .careers__cta-wrapper--dark-theme {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 419px) {
  .careers__cta-wrapper--dark-theme {
    margin-top: 0;
  }
}
.careers__cta-wrapper--dark-theme .careers__h3 {
  color: #9DFF78;
}
.careers__cta-wrapper--dark-theme .rich-text {
  color: #fff;
}
.careers__cta-wrapper--dark-theme .careers__subtitle {
  color: #fff;
}
.careers__cta-image-wrapper {
  position: relative;
  display: inline-block;
  line-height: 0;
}
.careers__cta-image {
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
  padding: 0;
}
@media screen and (max-width: 768px) {
  .careers__cta-image {
    max-width: 300px;
  }
}
@media screen and (max-width: 419px) {
  .careers__cta-image {
    max-width: 100%;
  }
}
.careers__word-highlight {
  content: url("/assets/site/Main/public/images/careers/circle.png");
  position: absolute;
  top: 0;
  left: 0;
}
.careers__video-btn {
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out;
}
.careers__video-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.careers__video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 501;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.careers__video-modal--open {
  visibility: visible;
  opacity: 1;
}
.careers__modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.careers__modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 3rem;
}
.careers__video-embed {
  display: block;
  margin: auto;
  text-align: center;
}
.careers__modal-close {
  color: #fff;
  z-index: 12;
  font-size: 18px;
  margin-left: 13.5%;
  margin-bottom: 1rem;
}
.careers__responsive-frame {
  position: relative;
  padding-bottom: 40%;
  height: 0;
}
.careers__responsive-frame iframe,
.careers__responsive-frame object {
  position: absolute;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.careers__freeze {
  overflow: hidden;
}
.careers__carousel {
  z-index: 11;
}
.careers__carousel-arrows {
  z-index: 11;
}
@media screen and (max-width: 419px) {
  .careers__carousel--swiper-container {
    overflow: visible !important;
    margin-bottom: 38.8888888889rem !important;
  }
}
@media screen and (max-width: 419px) {
  .careers__carousel-quote {
    position: relative;
    margin-top: 3.3rem !important;
    width: 90%;
  }
}
.careers__carousel-slide {
  width: 100%;
  height: 90vh !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 6.9444444444rem;
}
@media screen and (max-width: 768px) {
  .careers__carousel-slide {
    height: 70vh !important;
  }
}
@media screen and (max-width: 419px) {
  .careers__carousel-slide {
    height: 90vh !important;
  }
}
@media screen and (max-height: 650px) {
  .careers__carousel-slide {
    height: 110vh !important;
  }
}
.careers__carousel-light-wrapper {
  background-color: #F1F1F1;
  width: 40%;
  padding: 3.45rem;
  float: right;
}
@media screen and (max-width: 768px) {
  .careers__carousel-light-wrapper {
    width: 60%;
  }
}
@media screen and (max-width: 419px) {
  .careers__carousel-light-wrapper {
    padding: 2.25rem;
    width: 100%;
  }
}
.careers__carousel-arrows {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 419px) {
  .careers__carousel-arrows {
    bottom: 0;
    top: unset;
  }
}
.careers__carousel-arrow--prev {
  transform: rotate(180deg);
}
.careers__carousel-arrow--prev:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #9DFF78;
  font-size: 3.3333333333rem;
}
.careers__carousel-arrow--next:after {
  content: "";
  display: inline-block;
  font-family: "budicon";
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: -7.1428571429%;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  width: 1em;
  font-size: 110%;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: 0 auto;
  color: #9DFF78;
  font-size: 3.3333333333rem;
}
@media screen and (max-width: 419px) {
  .careers__carousel-slide--background-image {
    height: 12.2222222222rem !important;
  }
}
.careers__carousel-dot {
  border: 2px solid #9DFF78;
  display: inline-block;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0;
  margin-right: 1rem;
  background-color: transparent;
}
.careers__carousel-dot:last-of-type {
  margin-right: 0;
}
.careers__carousel-dot--active {
  background-color: #9DFF78;
}
.careers__quotes-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.careers__quotes-wrapper.more-than-four {
  justify-content: space-around;
}
.careers__quotes-wrapper.more-than-four .careers__quote-wrapper--display {
  width: 34%;
}
.careers__quote-wrapper--display {
  margin-top: 2rem;
  padding-right: 3rem;
  padding-bottom: 0;
  width: 33%;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.careers__quote-wrapper--display:nth-child(-n+3) {
  margin-top: 0;
}
.careers__quote-wrapper--display:nth-child(3n) {
  padding-right: 0.75rem;
}
@media screen and (max-width: 768px) {
  .careers__quote-wrapper {
    width: 100%;
  }
}
.careers__quote-headline {
  color: black;
  margin-bottom: 1rem;
}
.careers__quote {
  position: relative;
  z-index: 0;
}
.careers__quote::before {
  content: url("/assets/site/Main/public/images/careers/quote-mark.svg");
  position: absolute;
  top: -1.1111111111rem;
  left: -1.1111111111rem;
  z-index: -1;
}
.careers__quote::after {
  content: url("/assets/site/Main/public/images/careers/quote-mark.svg");
  transform: rotate(180deg);
  position: absolute;
  bottom: -1.1111111111rem;
  right: -0.4444444444rem;
  z-index: -1;
}
.careers__quote--display {
  font-family: "Big-Caslon-Roman", serif;
  font-size: 1.4444444444rem;
  color: black;
}
.careers__quote-attribution {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  color: black;
  font-size: 1rem;
  line-height: 1.4;
  text-transform: uppercase;
}
.careers__quote-attribution--line1 {
  color: black;
}
.careers__quote-attribution--line2 {
  color: #585858;
}
.careers__quote-supporting-text {
  font-family: "Big-Caslon-Roman", serif;
  font-weight: 500;
  font-size: 1.3333333333rem;
  line-height: 1.3;
  margin-top: 1rem;
}
.careers__highlight-image {
  width: 100%;
  height: 18.5555555556rem;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .careers__highlight-image {
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 419px) {
  .careers__highlight-image {
    margin-bottom: 3rem;
  }
}
.careers__highlight-wrapper {
  margin-right: 1.3888888889rem;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .careers__highlight-wrapper {
    width: 65%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 419px) {
  .careers__highlight-wrapper {
    width: 100%;
  }
}
.careers__highlight-text-wrapper {
  background-color: #585858;
  padding: 1.9444444444rem;
  margin-top: -3rem;
  margin-right: -1.1111111111rem;
  float: right;
  width: 80%;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .careers__highlight-text-wrapper {
    margin-top: -9rem;
  }
}
@media screen and (max-width: 419px) {
  .careers__highlight-text-wrapper {
    margin-top: -8rem;
    margin-right: 0;
  }
}
.careers__highlight-title {
  color: #9DFF78;
  text-align: right;
  font-size: 2rem;
}
.careers__highlight-link {
  color: #fff !important;
  display: block;
  text-align: right;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.careers__highlight-link:hover {
  color: #9DFF78;
}
.careers__infostat-wrapper {
  align-items: center;
}
@media screen and (max-width: 768px) {
  .careers__infostat-columns-wrapper {
    display: flex;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 419px) {
  .careers__infostat-columns-wrapper {
    display: block;
    margin-top: 0;
  }
}
.careers__infostat-inner-wrapper {
  margin-left: 12%;
}
.careers__infostat {
  text-align: center;
}
@media screen and (max-width: 419px) {
  .careers__infostat {
    margin-bottom: 3rem;
  }
}
.careers__infostat-number {
  font-family: "Gloss_And_Bloom", sans-serif;
  line-height: 1;
  font-size: 5.5555555556rem;
  color: #009B48;
}
.careers__infostat-label {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  font-size: 1.2222222222rem;
  color: black;
}
.careers__tabs-nav {
  margin-top: 2.5rem;
}
@media screen and (max-width: 768px) {
  .careers__tabs-nav {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .careers__tabs-nav::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}
.careers__tabs-nav-item {
  display: block;
  margin-left: 0;
  font-size: 1.1111111111rem;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #585858;
  text-align: left;
  position: relative;
}
@media screen and (max-width: 768px) {
  .careers__tabs-nav-item {
    font-size: 1.1111111111rem;
    white-space: nowrap;
    display: none;
  }
}
.careers__tabs-nav-item--active {
  color: black;
}
.careers__tabs-nav-item--active::before {
  content: "";
  background-color: #9DFF78;
  height: 1.4444444444rem;
  padding: 2px;
  position: absolute;
  left: 3px;
  top: 6px;
}
@media screen and (max-width: 768px) {
  .careers__tabs-nav-item--active::before {
    top: 8px;
  }
}
.careers__tabs {
  padding: 0;
}
.careers__tab {
  position: relative;
}
@media screen and (max-width: 419px) {
  .careers__tab {
    height: 100%;
    padding: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .careers__tab {
    display: block !important;
  }
}
.careers__tab-background {
  background-size: cover;
  background-position: 100% 50%;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 31.1111111111rem;
}
@media screen and (max-width: 767px) {
  .careers__tab-background {
    height: 100%;
  }
}
.careers__tabs-wrapper {
  position: relative;
  margin-bottom: 10rem !important;
}
@media screen and (max-width: 419px) {
  .careers__tabs-wrapper {
    margin-bottom: 0 !important;
  }
}
.careers__tab-text-wrapper {
  width: 50%;
  background-color: #fff;
  padding: 2.7777777778rem;
  margin-left: -1.3888888889rem;
  box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, 0.15);
  position: relative;
}
@media screen and (max-width: 1079px) {
  .careers__tab-text-wrapper {
    width: 65%;
  }
}
@media screen and (max-width: 767px) {
  .careers__tab-text-wrapper {
    width: 100%;
    padding: 2rem;
    margin-left: 0;
  }
}
.careers__tab-text-wrapper .rich-text {
  font-size: 1.2222222222rem;
  padding-bottom: 1rem;
}
.careers__tab-text-wrapper .rich-text:last-of-type {
  padding-bottom: 0;
}
.careers__instagram-feed {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.careers__instagram-tile {
  width: 25%;
  height: 350px;
}
@media screen and (max-width: 899px) {
  .careers__instagram-tile {
    width: 50%;
  }
}
@media screen and (max-width: 419px) {
  .careers__instagram-tile {
    width: 100%;
  }
}
.careers__instagram-photo {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.careers__photo-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  min-height: 400px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.careers__photo-slider.swiper-container {
  position: static !important;
}
.careers__photo-slider .careers__carousel-arrows {
  transform: translateY(-50%);
  width: calc(100% + 40px);
  left: -22px;
  top: 50%;
}
.careers__photo-slider .careers__carousel-arrows--slider {
  width: calc(100% - 4px);
  left: 6px;
}
@media screen and (max-width: 419px) {
  .careers__photo-slider .careers__carousel-arrows--slider {
    width: calc(100% - 15px);
    left: 8px;
  }
}
.careers__photo-slider p {
  text-align: center;
  color: white;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
}
.careers__photo-slider .careers__carousel-arrow--prev,
.careers__photo-slider .careers__carousel-arrow--next {
  background-color: #585858;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}
.careers__photo-slider .careers__carousel-arrow--prev:after,
.careers__photo-slider .careers__carousel-arrow--next:after {
  top: 2px;
  position: relative;
  font-size: 2.2rem;
}

.careers__photo-slider .swiper-pagination {
  bottom: -50px !important;
}

#app {
  background-image: url("/assets/site/Main/public/images/careers/background-texture.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 768px) {
  #app {
    background-size: contain;
    background-repeat: repeat-y;
  }
}

.career__text.rich-text {
  max-width: 786px;
  margin: 0 auto;
}

.swiper-wrapper {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.careers-professional .page-wrapper {
  max-width: 61.1111111111rem;
}
.careers-professional .careers__quote {
  margin: 3.3333333333rem 0;
  font-family: "Big-Caslon-Roman", serif;
  font-size: 1.4444444444rem;
  color: black;
  position: relative;
  z-index: 0;
}
.careers-professional .careers__quote::before {
  content: url("/assets/site/Main/public/images/careers/quote-mark.svg");
  position: absolute;
  top: -1.1111111111rem;
  left: -1.1111111111rem;
  z-index: -1;
}
.careers-professional .careers__quote::after {
  content: url("/assets/site/Main/public/images/careers/quote-mark.svg");
  transform: rotate(180deg);
  position: absolute;
  bottom: -1.1111111111rem;
  right: -0.4444444444rem;
  z-index: -1;
}
.careers-professional .careers__hero .careers__business-card {
  cursor: default;
}
.careers-professional .careers__hero .careers__business-card:hover .careers__business-card-name {
  text-decoration: none;
}
.careers-professional .careers__hero .careers__business-card img {
  max-height: 46px;
}
.careers-professional .careers__hero .careers__business-card-title {
  color: black;
  font-weight: bold;
  font-size: 1.1111111111rem;
  margin-bottom: 2.2222222222rem;
}
.careers-professional .careers__hero .careers__business-card-name {
  cursor: default;
}
.careers-professional .careers__hero .careers__business-card-name:hover {
  text-decoration: none;
}
.careers-professional .column {
  padding: 22px;
  position: relative;
}
.careers-professional .column .is-sticky {
  position: fixed;
  top: 80px;
}
.careers-professional .column .bottom-position {
  position: absolute;
  bottom: 0;
}
.careers-professional .careers__infostat {
  margin-top: 3.3333333333rem;
  margin-bottom: 3.3333333333rem;
}
.careers-professional .careers__carousel-slide {
  max-height: 620px;
  background-position-y: top;
}
.careers-professional .careers-detail-carousel .swiper-slide {
  background-size: cover;
  min-height: 400px;
}
.careers-professional .careers-detail-carousel.swiper-container {
  overflow: visible;
}
.careers-professional .careers-detail-carousel .careers__carousel-arrows {
  transform: translateY(-50%);
  width: calc(100% + 40px);
  left: -22px;
  top: 50%;
}
.careers-professional .careers-detail-carousel .careers__carousel-arrow--prev, .careers-professional .careers-detail-carousel .careers__carousel-arrow--next {
  background-color: #585858;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}
.careers-professional .careers-detail-carousel .careers__carousel-arrow--prev:after, .careers-professional .careers-detail-carousel .careers__carousel-arrow--next:after {
  top: 2px;
  position: relative;
  font-size: 2.2rem;
}
.careers-professional .careers__cta {
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
}
.careers-professional .career__text {
  margin-bottom: 3.3333333333rem;
}
.careers-professional .career__text-label {
  color: #009a45;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: bold;
  margin-bottom: 1.1111111111rem;
  display: block;
}
.careers-professional .career__text-img {
  margin-top: 2.6666666667rem;
  width: 100%;
  max-height: 500px;
}
.careers-professional .career__text-caption {
  font-size: 1rem;
  text-align: center;
  margin: 6px 0;
}
.careers-professional .career__text img {
  max-width: 100%;
  max-height: 500px;
  margin: 0 auto;
  display: block;
}
.careers-professional .career__text h3 {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;
  font-size: 1.3333333333rem;
  color: black;
  padding-bottom: 0.8888888889rem;
}
.careers-professional .career__text h3 strong {
  position: relative;
  font-weight: 400;
  background-image: url("/assets/site/Main/public/images/careers/circle.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 14px 2px;
}
.careers-professional .career__text.rich-text {
  max-width: 786px;
  margin: 0 auto;
}
.careers-professional .careers__related-services {
  font-family: "brandon-grotesque", sans-serif;
  margin-top: 1.5rem;
}
.careers-professional .careers__related-services-list {
  margin: 8px 0;
  padding: 0 0 0 0;
}
.careers-professional .careers__related-services-list li {
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 5px;
}
.careers-professional .careers__related-services-list li a {
  color: #009a45;
}
.careers-professional .careers__related-services-list li a:hover {
  color: #585858;
}
.careers-professional .careers__related-services-label {
  font-size: 1rem;
  text-transform: none;
  font-weight: 600;
  color: black;
}
.careers-professional .careers-professional__footer {
  padding: 4.4444444444rem 1rem;
  background-color: #585858;
}
.careers-professional .careers-professional__footer-header {
  color: white;
  text-align: center;
  font-family: "brandon-grotesque", sans-serif;
  font-size: 2.1111111111rem;
  text-transform: uppercase;
  margin-bottom: 3.3333333333rem;
}
.careers-professional .careers-professional__footer--list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
}
.careers-professional .careers-professional__footer--item {
  width: calc(100% / 3 - 40px);
  min-height: 330px;
  margin: 0 20px 80px;
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: "brandon-grotesque", sans-serif;
}
.careers-professional .careers-professional__footer--item-box {
  position: absolute;
  width: 85%;
  background-color: white;
  color: #009a45;
  font-size: 2.1111111111rem;
  padding: 1.6666666667rem;
  right: -15px;
  top: calc(100% - 50px);
}
.careers-professional .careers-professional__footer--item-name {
  display: block;
  text-align: right;
}
.careers-professional .careers-professional__footer--item-link {
  font-size: 0.8888888889rem;
  display: block;
  text-align: right;
  color: black;
  text-transform: uppercase;
}
.careers-professional .careers-professional__footer--item-link:after {
  content: ">";
  margin-left: 6px;
  position: relative;
  bottom: 2px;
}
.careers-professional .careers-professional__footer--item-link:hover {
  color: #009a45;
}
.careers-professional a {
  color: #009B48;
}
.careers-professional a:hover {
  color: #585858;
}
.careers-professional .careers__cta {
  color: black;
}
.careers-professional .careers__cta:hover {
  color: black;
}
@media screen and (max-width: 899px) {
  .careers-professional .careers-professional__footer--list {
    flex-wrap: wrap;
  }
  .careers-professional .careers-professional__footer--item {
    width: calc(50% - 40px);
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 667px) {
  .careers-professional .careers-detail-carousel .careers__carousel-arrow--next {
    right: 8px;
  }
  .careers-professional .careers-detail-carousel .careers__carousel-arrow--prev {
    left: 8px;
  }
  .careers-professional .careers-detail-carousel .careers__carousel-arrow--next, .careers-professional .careers-detail-carousel .careers__carousel-arrow--prev {
    width: 42px;
    height: 42px;
    position: relative;
  }
  .careers-professional .careers-detail-carousel .careers__carousel-arrow--next:after, .careers-professional .careers-detail-carousel .careers__carousel-arrow--prev:after {
    font-size: 18px;
    top: 1px;
  }
  .careers-professional .careers-professional__footer--item {
    width: 90%;
    margin: 0 auto 100px;
  }
  .careers-professional .careers-professional__footer--item-name {
    line-height: 1;
  }
  .careers-professional .careers-professional__footer--item:last-child {
    margin: 0 auto;
  }
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
  text-align: center;
}