.util-pull-left {
	float: left;
}

.util-pull-right {
	float: right;
}

.util-text-align-right {
	text-align: right;
}

.util-text-align-center {
	text-align: center;
}

.clearfix {
    clear: both;
}

[v-cloak] {
	display:none;
}

