.search-landing {
    &__panel-col {
        margin-bottom: px-to-rem(24px);
    }

    .advanced-search {
        margin-top:px-to-rem(32px);
    }

    &__count {
        font-family: $font-family-serif-new;
        color: $color-brand-black;
        font-size: 1rem;
        margin-bottom:px-to-rem(32px);
    }

    &__components-list {
        .section-wrapper {
            &:first-child {
                padding-top: 0;
            }
        }
    }
    
    @include bp-min($bp-tablet-sm) {
        &__count {
            font-size: px-to-rem(22px);
            margin: px-to-rem(32px) 0 px-to-rem(40px);
        }
        .section-wrapper__title {
            margin-bottom: px-to-rem(64px);
        }

        &__panel {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__panel-col {
            width: calc(50% - 2rem);
        }

        .advanced-search,
        .applied-filters {
            width: 100%;
        }
    }
}