.subscription-landing{

  .InputNotValid{
    border-color: red;
  }

  .CheckboxsNotValid{
    color: red;
  }

  .section-header{
    margin-top: px-to-rem(30px);
  }

  .searchbox__input{
    width: 49%;
    float: left;
    margin-top: 5px;

    @include bp-max($bp-mobile) {
      width: 100%;
    }
  }

  .searchbox__input:nth-child(even){
    margin-left: 10px;

    @include bp-max($bp-mobile) {
      margin-left: 0px;
    }
  }
}
