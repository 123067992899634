.people-letters-container-sort {
    > ol {
        margin-bottom: 2.2rem;
    }
}

.people-sort-letters-container {
    display: inline-block;
    letter-spacing: 0.05rem;
    background:white;
    background-color:#5a7d99;
    border-radius:4px;
    padding:1px 3px;
    border: 2px solid #5a7d99;
    cursor:pointer;
    color: white;
    font-family:Quattrocento, serif;
    margin: 0 0 .7em 0;

    button {
        color: white;
        font-size: px-to-rem(23px);

        &::before {
            content: "\f00d";
            font-family: "Font Awesome";
        } 
    }

    &:hover{
        color:#5a7d99;
        background-color:white;
        button {
            color: #d00b10;
        }
    }
}

.people-sort-letters-label {
    
    font-size: px-to-rem(20px);
    //font-weight: $font-weight-medium;
    margin-right: 0.5rem;
    text-transform: uppercase;
}

.people-sort-by-container {
    float: right;
    font-family: $font-family-secondary;
    font-size: px-to-rem(20px);
    font-weight: $font-weight-medium;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    button {
        color: $color-blue-waikawa-gray;
        font-size: inherit;
        //font-weight: $font-weight-medium;
        text-transform: uppercase;
        &:hover {
            color: mix(map-get($colors, lightblue), map-get($colors, dark), 95%);
        }
    }
}

.people-sort-by-separator {
    color: #000;
    font-size: px-to-rem(25px);
    font-weight: $font-weight-light;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.people-sort-by-label {
    margin-right: 0.5rem;
}
