.dei-header {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-opaque-gray;
    color: $color-brand-white;
    position: relative;
    z-index: -1;
    
    &__wrapper {
        width: 100%;
        max-width: calc(1160px + #{($site-padding-tablet * 2)});
        padding: 4em 2em 4em 2em; 
        margin: auto;
        position: relative;
        min-height: 200px;
        display: flex;
        align-items: center;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            right: 2em;
            top: 0;
            z-index: 0;
            background-color: $color-brand-dark-gray;
            height: calc(100% + 3em);
            width: 30%;
        }

        &--blue {
            &:after {
                background-color: $color-content-blue;
            }
        }

        &--citrus {
            &:after {
                background-color: $color-content-citrus;
            }
        }

        &--royal {
            &:after {
                background-color: $color-content-royal;
            }
        }

        &--royal-light {
            &:after {
                background-color: $color-content-royal-hl;
            }
        }

        &--green {
            &:after {
                background-color: $color__green;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: $color-brand-black;
        opacity: .4;
        height: 100%;
        width: 100%;
    }

    &__content {
        width: 100%;
    }

    &__eyebrow {
        font-family: $font-family-titles;
        text-transform: uppercase;
        font-size: 14px;
        position: relative;
        z-index: 1;
    }

    &__title {
        font-family: $font-family-serif-new;
        font-size: 80px;
        max-width: 80%;
        position: relative;
        z-index: 1;
    }

    @include bp-max($bp-tablet) {
        margin-bottom: 5em;
    }


    @include bp-max($bp-tablet-sm) {
        &__title {
            font-size: px-to-rem(60px);
        }
    }

	@include bp-max($bp-mobile) {
        &__title {
            max-width: none;
        }
    }
}