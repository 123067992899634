.sidebar-call {
    @include font-secondary-medium;

    padding: px-to-rem(26px);
    border-top: 4px solid $color-brand-black;

    &+& {
        margin-top: 1rem;
    }

    &__heading {
        font-size: px-to-rem(16px);
        line-height: 1.1;
        text-transform: uppercase;
        margin-bottom: px-to-rem(24px);
    }

    &__title {
        font-size: px-to-rem(22px);
        line-height: 1.1;
        margin-bottom: 0.25em;
    }
    &__copy {
        line-height: 1.3;
    }
    &__link {
        display: inline-block;
        margin-top: px-to-rem(24px);
        color: $color-brand-green;
    }
    
    @include bp-min($bp-tablet-sm) {
        &+& {
            margin-top: 20px;
        }
        &__title {
            font-size: px-to-rem(26px);
        }
        &__copy {
            font-size: px-to-rem(22px);
        }
    }
}