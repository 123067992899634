.list {
  @extend %unstyled-list;
}

.list-horizontal {
  @extend %unstyled-list;

  .list__item {
    display: inline;
  }
  .list__link {
  	display: inline-block;
  }

}

.list-vertical {
    padding: 0;

    .list__item:after {
        content: none;
    }
}

.list-linked-white {
	.list__link {
		color: $color-brand-white;
		display: inline-block;
	}
}