$desktop-header: 125px;
$mobile-header: 86px;

.site-layout-wrapper {
	max-width: px-to-rem($site-max-width);
	margin: 0 auto;
	padding: 0 $site-padding;
	height: 100%;

	@include bp-max($bp-tablet) {
		padding: 0 $site-padding-tablet;
	}

	@include bp-max($bp-mobile) {
		padding: 0 $site-padding-mobile;
	}
}

.page-wrapper {
	max-width: px-to-rem(1000px);
	margin-left: auto;
	margin-right: auto;
	padding-right: $site-padding;
	padding-left: $site-padding;

	@include bp-max($bp-tablet) {
		padding: 0 $site-padding-tablet;
	}

	@include bp-max($bp-mobile) {
		padding: 0 $site-padding-mobile;
	}
}

.page-wrapper--centered {
	text-align: center;
}

.page-main {
	//padding-top: 7.5%; //fallback for vh
	//padding-top: 13vh;
	@include clearfix;

	display: block;
	margin-top: $desktop-header;

	@include bp-max($bp-hamburger) {
		margin-top: $mobile-header;
	}

	@include bp-max($bp-mobile) {

		.rich-text {
			iframe {
				width: 100%;
			}
		}
	}
}


.page-body {
	margin-top: $spacing-unit-section;
	margin-bottom: $spacing-unit-section;
}

.page-content {
	overflow: hidden;
	&.case-study {
		background-color: $color-brand-white-abs;
	}
}

.page-content-shade {
	background-color: $color-brand-light;
	overflow: hidden;
}

.page-module {
	&+& {
		margin-top: $spacing-unit-block;
	}
}