    ////////////////////
/// THEME COLORS
////////////////////
$base-colors: (
    dark: rgb(30, 30, 30),
    medium: rgb(160, 160, 160),
    light: rgb(242, 242, 242),
    black: rgb(0, 0, 0),
    white: rgb(255, 255, 255),
    blue: rgb(90, 125, 153),
    lightblue:rgb(90, 149, 229)
);

$theme-dark: (
    menu-background: lighten(map-get($base-colors, blue), 4%),
    emphasis: map-get($base-colors, light),
    normal: map-get($base-colors, medium),
    whisper: lighten(map-get($base-colors, medium), 15%)
);

$theme-light: (
    menu-background: map-get($base-colors, light),
    emphasis: map-get($base-colors, dark),
    normal: darken(map-get($base-colors, medium), 10%)
);

$theme-medium: (
    menu-background: rgb(75,75,75),
    emphasis: map-get($base-colors, medium),
    normal: darken(map-get($base-colors, medium), 15%)
);

$theme: $theme-dark;

$colors: map-merge($base-colors, $theme);

$pdf-print-blue: #669dba;
//Balch Variables



///////////////////////////////////////////////////////////////////////////////
//
//  Vertical Rhythm
//
///////////////////////////////////////////////////////////////////////////////

$spacing-unit:                      1.65rem;   //30px
$spacing-unit-block:                $spacing-unit * 2; //36
$spacing-unit-section:              $spacing-unit * 2.5;   //45
$spacing-unit-small:                .5rem;
$spacing-unit-xsmall:               .25rem;


///////////////////////////////////////////////////////////////////////////////
//
//  Structure
//
///////////////////////////////////////////////////////////////////////////////

$site-max-width:                    1400px;
$site-padding:                      1.9rem; //35px;
$site-padding-tablet:               1.5rem; //24px
$site-padding-mobile:               1.2rem; //18px

$site-header-height:                7.6%;
$site-footer-height:                10%;

$site-header-width:                 138px;
$site-header-height-mobile:         80px;
$site-logo-height:                  48px;
$site-logo-width-mobile:            120px;



///////////////////////////////////////////////////////////////////////////////
//
//  Animations
//
///////////////////////////////////////////////////////////////////////////////

$base-animation-timing:             .25s;
$base-animation-ease:               ease-in;
$base-animation:                    color $base-animation-timing $base-animation-ease,
                                    border-color $base-animation-timing $base-animation-ease,
                                    opacity $base-animation-timing $base-animation-ease,
                                    background-color $base-animation-timing $base-animation-ease;


$animation-locations-timing:        .4s;
$animation-locations-ease:          ease-in;


$easeInOutQuint:                    cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutCirc:                     cubic-bezier(0.785, 0.135, 0.15, 0.86);

$animationEase:                     $easeInOutCirc;


$animation-mobile-timing:           .6s;


///////////////////////////////////////////////////////////////////////////////
//
//  ZINDEX
//
///////////////////////////////////////////////////////////////////////////////


$zindex-modal-bg:                   1000;
$zindex-modal-body:                 1005;

$zindex-site-brand:                 600;
$zindex-header:                     500;

$zindex-cube:                       100;
$zindex-cube-arrows:                150;


$zindex-panel-current:              120;
$zindex-panel-next:                 110; //130
$zindex-panel-previous:             110;


///////////////////////////////////////////////////////////////////////////////
//
//  MISC
//
///////////////////////////////////////////////////////////////////////////////

$border-radius: 4px;
$standard-logo-width: 117px;

///////////////////////////////////////////////////////////////////////////////
//
//              Grid
//
///////////////////////////////////////////////////////////////////////////////

$susy: (
        flow: ltr,
        math: fluid,
        output: float,
        gutter-position: after,
        container: auto,
        container-position: center,
        columns: 12,
        gutters: .3333,
        column-width: false,
        global-box-sizing: border-box,
        last-flow: to,
        debug: (
                image: hide,
                color: rgba(#66f, .25),
                output: background,
                toggle: top right,
        ),
        use-custom: (
                background-image: false,
                background-options: false,
                box-sizing: false,
                clearfix: false,
                rem: false,
        )
);


$legacy-support-for-ie:false;
