.mobile-toggle {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.mobile-toggle__label {
  color: $color__gray--dark;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -1.5%;
  font-family: $font-family-secondary;
  display: inline-block;
  margin-left: 8px;

  &--dark {
    color: $color-brand-white-abs;
  }
}