.casestudy-module {
    color: $color-brand-white-abs;
    .rich-text li {
        color: $color-brand-white-abs;
    }
    .section-wrapper__title {
        font-style: italic;
    }
    &__cards {
        margin-top: 50px;
    }
    &__card-item {
        counter-increment: questionNumber;
        color: $color-brand-white-abs;
        &:before {
            content: counters(questionNumber, ".", decimal-leading-zero);
            display: block;
            position: absolute;
            left: 98px;
            margin-top: -15px;
            font-size: px-to-rem(30px);
            pointer-events: none;
        }

        & + & {
            margin-top: px-to-rem(60px);
        }
    }
    &__cta {
        margin-top: px-to-rem(30px);
    }

    // Swiper Overrides
    .swiper-wrapper {
        flex-direction: column;
    }

    .swiper-button-prev {
        color: $color__green;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color__green;
            font-size: px-to-rem(60px);
        }
        transform: rotate(180deg);
        background-image: none;
    }

    .swiper-button-next {
        color: $color__green;
        background-image: none;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color__green;
            font-size: px-to-rem(60px);
        }
    }

    .swiper-button-disabled,
    .swiper-button-disabled.swiper-button-prev,
    .swiper-button-disabled.swiper-button-next {
        display: none;
    }

    @include bp-min($bp-tablet-sm) {
        .swiper-wrapper {
            flex-direction: row;
        }
        &__wrapper {
            overflow: hidden;
        }
        .swiper-container {
            overflow: visible;
        }

        & &__card-item {
            display: inline-flex;
            width: auto;
        }

        &__cards--hovered & {
            &__card-item {
                &:before,
                &:after {
                    opacity: 0.25
                }
            }
        }
        &__cards & {
            &__card-item {
                &:hover {
                    &:before,
                    &:after {
                        opacity: 1
                    }
                    &:before {
                        color: $color-content-citrus;
                    }
                }
            }
        }

        &__card-item {
            position: relative;

            &+& {
                margin-top: 0;
            }

            &:nth-child(2n+1) {
                margin-top: px-to-rem(60px);
            }
        
            &:before {
                left: calc(100% - 1.5rem);
                bottom: 50px;
                font-size: px-to-rem(120px);
                line-height: 1;

                @include transition(opacity);
            }

            &:after {
                content: 'Case Study';
                @include font-secondary-medium;
                @include transition(opacity);
                font-size: px-to-rem(18px);
                color: $color-brand-white-abs;
                text-transform: uppercase;
                white-space: nowrap;
                position: absolute;
                left: 100%;
                bottom: 30px;
                margin-left: 1rem;
                pointer-events: none;
            }
        }
        &__cta {
            margin-top: px-to-rem(60px);
        }
    }
    @include bp-min(1220px) {
        .swiper-container {
            margin-left: -30px;
        }
    }
    @include bp-max($bp-tablet-sm) {
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}