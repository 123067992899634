%uc {
  text-transform: uppercase;
}



/**
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    contenteditable attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that are clearfixed.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
* 3. Use width: 100% instead of clear: both on the :after pseudo-element
*    The clear: both on the after pseuod-element is known to causes issues
*    when used as clearfix technique on elements within a containing element
*    that which uses margin offset to position the containing element
*    relative to a floated sibling element
*
*    Reference:
*    http://nicolasgallagher.com/micro-clearfix-hack/#comment-43304
*    http://nicolasgallagher.com/micro-clearfix-hack/#comment-77581
*
*/
%clearfix {
  
  &:after {
      content: ""; // 1
      display: table; // 2
      width: 100%; // 3
      clear: both;
  }

  /**
   * For IE 6/7 only
   * Include this rule to trigger hasLayout and contain floats.
   */
  *zoom: 1;
}

%bg-cover {
	background:transparent no-repeat center center;
	background-size: cover;
}


%unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}