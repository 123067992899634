.services-landing {

}

.service-listing-container {

    .list-related__left,
    .list-related__right {
        width: 100%;
    }

    @include bp-min($bp-mobile+1) {
        .related-items {
            padding: 0 ;
            width: 100%;
            position: relative;

            + .related-items {
                margin: 5rem 0 0 0;
            }

            .section-header {
                display: inline-block;
            }

            .list-related__left,
            .list-related__right {
                width: 49.5%;
            }

            .list__item__left {
                display: block;
                float: left;
                vertical-align: top;
            }

            .list__item__right {
                display: block;
                float: right;
                vertical-align: top;
            }

            .list__item__container {
                margin-bottom: 0.5rem;
                position: relative;
            }

        }

        .related-item-fix {
            padding: 3.8rem 0 0 0 !important;
        }
        //fonts
    }

    .services__content {
        display: none;
        padding: 1rem 3rem;

        h3 {
            font-weight: $font-weight-medium;
            font-size: px-to-rem(24px);
            margin-bottom: px-to-rem(18px);

            a {
                font-size: 1.25rem;
                color: $color-gray-dim-gray;
                @include font-accent();
                font-weight: 700;

                &:hover {
                    color: $color-blue-dodger-blue;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
        }

        &--expanded {
            display: block;
        }
    }
}
