.careers-professional {
    $careers-section-spacing: px-to-rem(80px);
    $box-shadow: -5px 25px 25px 0 rgba(0,0,0,.15);
    .page-wrapper {
        max-width: px-to-rem(1100px);
    }
    $component-spacing: 60px;
    .careers__quote {
        margin: px-to-rem($component-spacing) 0;
        font-family: $font-family-careers-serif;
        font-size: px-to-rem(26px);
        color: $color-brand-black;
        position: relative;
        z-index: 0;

        &::before {
            content: url('/assets/site/Main/public/images/careers/quote-mark.svg');
            position: absolute;
            top: px-to-rem(-20px);
            left: px-to-rem(-20px);
            z-index: -1;
        }
        &::after {
            content: url('/assets/site/Main/public/images/careers/quote-mark.svg');
            transform: rotate(180deg);
            position: absolute;
            bottom: px-to-rem(-20px);
            right: px-to-rem(-8px);
            z-index: -1;
        }
    }
    .careers__hero {
        .careers__business-card {
            &:hover {
                .careers__business-card-name {
                    text-decoration: none;
                }
            }
            img {
                max-height: 46px;
            }
            cursor: default;
            &-title {
                color: black;
                font-weight:  bold;
                font-size: px-to-rem(20px); 
                margin-bottom: px-to-rem(40px)
            }
            &-name {  
                cursor: default;  
                &:hover {
                    text-decoration: none;
                }          
            }

        }
    }
    .column {
        padding: 22px;
        position: relative;
        .is-sticky {
            position: fixed;
            top: 80px;
        }
        .bottom-position {
            position: absolute;
            bottom: 0;
        }
    }
    .careers__infostat {
        margin-top: px-to-rem(60px);
        margin-bottom: px-to-rem(60px);
    }
    .careers__carousel-slide {
        //height: 90vh !important;
        max-height: 620px;
        background-position-y: top;
    }
    .careers-detail-carousel {
        // .swiper-wrapper {
        //     max-width: px-to-rem(480px);
        // }
        .swiper-slide {
            background-size: cover;
            min-height: 400px;
        }    
        &.swiper-container {
            overflow: visible;
        }
        $arrow-diameter: 55px;
        .careers__carousel-arrows {
            transform: translateY(-50%);
            width: calc(100% + 40px);
            left: -22px;
            top: 50%;
        }

        .careers__carousel-arrow--prev,.careers__carousel-arrow--next {
            background-color: $color__gray--dark;
            border-radius: 50%;
            height: $arrow-diameter;
            width: $arrow-diameter;
            &:after {
                top: 2px;
                position: relative;
                font-size: 2.2rem;
            }
        }
    }
    .careers__cta {
        width: 100%;
        text-align: center;
        padding: 1rem;
        font-size: 1rem;
    }
    .career__text {
        &-label {
            color: $color-brand-green;
            font-size: px-to-rem(38px);
            text-transform:  uppercase;
            font-family: $font-family-titles;
            font-weight: bold;
            margin-bottom: px-to-rem(20px);
            display: block;
        }
        &-img {
            margin-top: px-to-rem(48px);
            width: 100%;
            max-height: 500px;
        }
        &-caption {
            font-size: px-to-rem(18px);
            text-align: center;
            margin: 6px 0;
        }
        img {
            max-width: 100%;
            max-height: 500px;
            margin: 0 auto;
            display: block;
        }
        h3 {
            @include careers-subtitle;
            color: $color-brand-black;
            padding-bottom: px-to-rem(16px);
            
            strong {
                position: relative;
                font-weight: 400;
                background-image: url('/assets/site/Main/public/images/careers/circle.png');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 14px 2px;
            }
        }
        margin-bottom: px-to-rem($component-spacing);
    }
    .career__text.rich-text {
        max-width: 786px;
        margin: 0 auto;
    }
    //related services section
    .careers__related-services {
        font-family: $font-family-titles;
        margin-top: 1.5rem;

        &-list {
            margin: 8px 0;
            padding: 0 0 0 0;
            li {
                font-size: 1rem;
                font-weight: 500;
                a {
                    color: $color-brand-green;
                    &:hover {
                        color: $color__gray--dark;
                    }
                }
                padding-bottom:5px;
            }
        }
    }

    .careers__related-services-label {
        font-size: 1rem;
        text-transform: none;
        font-weight: 600;
        color: $color-brand-black;
    }

    //meet our professionals
    .careers-professional__footer {
        padding: px-to-rem(80px) px-to-rem(18px);
        background-color: $color__gray--dark;
        &-header {
            color: white;
            text-align: center;
            font-family: $font-family-titles;
            font-size: px-to-rem(38px);
            text-transform: uppercase;
            margin-bottom: px-to-rem(60px);
        }
        &--list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: px-to-rem(90px);
        }
        &--item {
            width: calc(100% / 3 - 40px);
            min-height: 330px;
            margin: 0 20px 80px;
            background-size: cover;
            background-position: center;
            position: relative;
            font-family: $font-family-titles;
            &-box {
                position: absolute;
                width: 85%;
                background-color: white;
                color: $color-brand-green;
                font-size: px-to-rem(38px);
                padding: px-to-rem(30px);
                right: -15px;
                top: calc(100% - 50px);
            }
            &-name {
                display: block;
                text-align: right;
            }
            &-link {
                font-size: px-to-rem(16px);
                display: block;
                text-align: right;
                color: black;
                text-transform: uppercase;
                &:after {
                    content: '>';
                    margin-left: 6px;
                    position: relative;
                    bottom: 2px;
                }
                &:hover {
                    color: $color-brand-green;
                }
            }
        }       
    }

    a {
        color: $color__green;

        &:hover {
            color: $color__gray--dark;
        }
    }

    .careers__cta {
        color: $color-brand-black;

        &:hover {
            color: $color-brand-black;
        }
    }

    @include bp-max($bp-tablet) {
        .careers-professional__footer  {
            &--list {
                flex-wrap: wrap;
            }
            &--item {
                width: calc(50% - 40px);
                margin-bottom: 80px;
            }
        }
    }
    @include bp-max($bp-mobile) {
        .careers-detail-carousel .careers__carousel-arrow {
            $button-size: 42px;
            &--next {
                right: 8px;
            }
            &--prev {
                left: 8px; 
            }
            &--next, &--prev {
                width: $button-size;
                height: $button-size;    
                position: relative;
                &:after {
                    font-size: 18px;
                    top: 1px;
                }
            }
        }
        .careers-professional__footer  {
            &--item {
                width: 90%;
                margin: 0 auto 100px; 
                &-name {
                    line-height: 1;
                }
                &:last-child {
                    margin: 0 auto;
                }
            }
        }
    }

}