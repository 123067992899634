.article-detail-body {
	font-weight:normal;

    .rich-text {
        ul {
            padding-left: px-to-rem(40px);
        }
    }

    .article-head-title{
        color:$color-text-green;
        font-size: px-to-rem(40px);
        text-transform:uppercase;
        margin-bottom:1.65rem;
        display:block;
        letter-spacing: .05rem;

        &--citrus {
            color: $color-content-citrus;
        }
    }

     ul {
         margin:0;
         padding:0;
     }
     .topics {
     }
    .article-detail-author::before,
    .article-detail-filter::before {
        color: #555;
        display: inline-block;
        font-family: "Font Awesome";
        margin-right: 2rem;
        @media (min-width: px-to-rem(450px)) {
            margin-right: 1.3rem;
        }
    }

    .article-head-items {
        margin-bottom:2em;
        font-family:Oxygen, sans-serif;
        font-weight:bold;
        font-size: px-to-rem(22px);

    }

    .article-detail-people {
        margin-bottom: px-to-rem(32px);
        a {
            &::before{
                content: ',';
                margin-right: 4px;
            }
            &:first-child {
                &:before {
                    content: '';
                    margin-right: 0;
                }
            }
        }
    }

    .article-detail-author {
    margin-bottom: 0;
    &::before {
        content: "\f007"; 
        margin-right:.8em;
        }
    }

    .article-detail-filter {
    margin-bottom: 0;
    &::before {
            content: "\f0b0";
            margin-right:.8em;
        }
    }
}

