
//Insights

.cube__insights {
	height: 100%;
	margin: 0 30px 0 45px;

	@include bp-max($bp-mobile) {
      font-size: 95%;
      margin: 0;
			overflow: scroll;
    }
}

@keyframes scaleDown {
	0% { opacity: 1; }
	100% {  opacity: 0; }
}

@keyframes scaleUp {
	0% { opacity: 0;}
	100% {  opacity: 1; }
}

.cube__insights-block {
	height: 50%;
	padding-bottom: 5px;
	padding-right: 5px;
	position: relative;

	&:not(.cube__insights-block--testimonial):after {
		@include bi-icon();

		content: $bi-enlarge;
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		color: $color-brand-green;

		@include bp-max($bp-mobile) {
			display: none;
		}
	}

	.insights-stacked & {
		height: 25%;
      	width: 100%;
      	padding: 5px 0 0;
	}

	.fade-out-slides & {
		animation: scaleDown 0.6s $animationEase forwards;
	}

	.fade-in-slides & {
		animation: scaleUp 0.6s $animationEase forwards;
	}

	@include bp-max($bp-tablet-sm) {
      height: 25%;
      width: 100%;
      padding: 5px 0 0;

    }

	&:hover {
		.cube__insights-article {
			background-color: $color-brand-white-abs;
		}
	}

	&.mobile__highlight{
		display: none;
		@include bp-max($bp-mobile) {
			display: inline-block;
		}
	}

	&.nonmobile__highlight{
		display: inline;
		@include bp-max($bp-mobile) {
			display: none;
		}
	}
}

.cube__insights-article {
	@extend .article-title-size;
	@include font-secondary;

	background-color: $color-opaque-white;

	border: 1.5rem solid transparent;
    border-top: 1rem solid transparent;
	width: 100%;
	height: 100%;
	display: inline-block;
	position: relative;
	overflow: hidden;
	line-height: 1.29;
	transition: $base-animation;

    @include bp-max($bp-mobile) {
	    padding: 5%;
	    border: 0 none;
    }

    @include bp-max-orientation() {
    	padding: 2% ;
    }

    .article-date {

    	color: $color-text-gray;
    	margin-bottom: .5rem;
        display: block;
        font-size: 1rem;

    }
}
.cube__insights-featured-figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: left center;

    @include bp-max($bp-mobile) {
        width: 100%;
    }
}

.cube__insights-featured-img {
    margin-top: -5%;
    max-width: 125%;

    @include bp-max($bp-mobile) {
        margin-right: 0;
        height: 220px;
        width: 100%;
        max-height: 199%;
    }
}
.cube__insights-testimonial-img {
    float: right;
    max-width: none;
    height: 100%;
    margin-right: -20%;

    .insights-stacked & {
        margin-right:0;
	}

  	@include bp-max($bp-mobile) {
       margin-right:0;
			 height: 220px;
			 width: 100%;
  	}
}

.cube__insights-testimonial-figure{
    float: right;
    height: 100%;
    margin-left: 2%;
    width: 50%;
    text-align: right;
    position: relative;
    overflow: hidden;

		@include bp-max($bp-mobile){
			width: 100%;
		}
}

.cube__insights-testimonial {
	@include font-secondary;

	margin: 0;
	background-color: $color-opaque-blue;
	padding: 5%;
	text-align: center;
	font-size: 1.4rem;
	line-height: 1.3;
	color: $color-brand-white;
	height: 100%;
	-webkit-font-smoothing: antialiased;



	@include bp-max-height($bp-narrow) {
		font-size: 1.3rem;
		line-height: 1.2;
	}


	@include bp-max($bp-tablet-sm) {
    	font-size: 1.3rem;
  	}

  	@include bp-max($bp-mobile) {
    	font-size: 1.1rem;
    	line-height: 1.2;
  	}

	> p {
		@include vertical-align;
	}
}

@keyframes modalScaleUpDefault {
	0% { right: 100%; left: $site-padding;height: 0; opacity: 0 }
	100% { right: $site-padding; left: $site-padding; opacity: 1; height: 100%;}
}

@keyframes modalScaleDownDefault {
	0% { right: $site-padding; left: $site-padding; opacity: 1; height: 100%;}
	100% { right: 100%; left: $site-padding;height: 0; opacity: 0 }
}

@keyframes modalScaleUpRight {
	0% { right: $site-padding; left: 80%;height: 0; opacity: 0 }
	100% { right: $site-padding; left: $site-padding; opacity: 1; height: 100%;}
}

@keyframes modalScaleDownRight {
	0% { right: $site-padding; left: $site-padding; opacity: 1; height: 100%;}
	100% { right: $site-padding; left: 80%;height: 0; opacity: 0 }

}

@keyframes modalScaleUpBottom {
	0% { right: 100%; left: $site-padding; height: 0; opacity: 0 }
	100% { right: $site-padding; left: $site-padding; bottom: 0; opacity: 1; height: 100%;}
}

@keyframes modalScaleDownBottom {
	0% { right: $site-padding; left: $site-padding; opacity: 1; height: 100%;}
	100% { right: 100%; left: $site-padding; height: 0; opacity: 0 }

}

@include bp-max($bp-tablet) {

	@keyframes modalScaleUpDefault {
		0% { right: 100%; left: $site-padding-tablet;height: 0; opacity: 0 }
		100% { right: $site-padding-tablet; left: $site-padding-tablet; opacity: 1; height: 100%;}
	}

	@keyframes modalScaleDownDefault {
		0% { right: $site-padding-tablet; left: $site-padding-tablet; opacity: 1; height: 100%;}
		100% { right: 100%; left: $site-padding-tablet;height: 0; opacity: 0 }
	}

	@keyframes modalScaleUpRight {
		0% { right: $site-padding-tablet; left: 80%;height: 0; opacity: 0 }
		100% { right: $site-padding-tablet; left: $site-padding-tablet; opacity: 1; height: 100%;}
	}

	@keyframes modalScaleDownRight {
		0% { right: $site-padding-tablet; left: $site-padding-tablet; opacity: 1; height: 100%;}
		100% { right: $site-padding-tablet; left: 80%;height: 0; opacity: 0 }

	}

	@keyframes modalScaleUpBottom {
		0% { right: 100%; left: $site-padding-tablet; height: 0; opacity: 0 }
		100% { right: $site-padding-tablet; left: $site-padding-tablet; bottom: 0; opacity: 1; height: 100%;}
	}

	@keyframes modalScaleDownBottom {
		0% { right: $site-padding-tablet; left: $site-padding-tablet; opacity: 1; height: 100%;}
		100% { right: 100%; left: $site-padding-tablet; height: 0; opacity: 0 }

	}
}


.cube__insights-modal {
	background-color: $color-opaque-white-modal;
	position: absolute;
	height: 0;
	margin: 0 30px 0 45px;
	padding: 4.5%;
	overflow-y: auto;
	color: $color-brand-blue;
	opacity: 0;


	.fade-in-modal & {
		top: 0;
		animation: modalScaleUpDefault .5s $animationEase forwards;
	}

	.fade-out-modal & {
		animation: modalScaleDownDefault .5s $animationEase forwards;
	}

	.fade-in-modal--right &{
		animation: modalScaleUpRight .5s $animationEase forwards;

	}

	.fade-out-modal.fade-in-modal--right &{
		animation: modalScaleDownRight .5s $animationEase forwards;

	}

	.fade-in-modal--bottom &{
		top: auto;
		bottom: 0;
		animation: modalScaleUpBottom .5s $animationEase forwards;

	}

	.fade-out-modal.fade-in-modal--bottom &{
		animation: modalScaleDownBottom .5s $animationEase forwards;

	}


	@include bp-max($bp-tablet) {
		right: $site-padding-tablet;
		left: $site-padding-tablet;
	}

	@include bp-max($bp-mobile) {
		right: $site-padding-mobile;
		left: $site-padding-mobile;
	}

	.article__title {
		font-size: 1.444rem;
	}

	.article__body {
		font-size: 1.1rem;
		line-height: 1.5;
	}

	.related-info {
		.list__link {
			color: $color-text-gray-light;
		}
	}
}

.cube__insights-modal-content {
	opacity: 0;
	transition: opacity .5s $animationEase .5s;

	.fade-in-modal & {
		opacity: 1;
	}

	.fade-out-modal & {
		opacity: 0;
		transition-delay: 0;
	}

	.grid-col-sidebar {
		width: 28%;

		@include bp-max($bp-tablet) {
        	width: 30%;
    	}
	}
}

.cube__insights-modal-abstract {
	width: 72%;

	@include bp-max($bp-tablet) {
    	width: 68%;
	}

	.article__body {
		border-right: 1px solid $color-brand-blue;
		padding-right: 5%;
		margin-right: 5%;
	}

	.article__readmore {
		width: 90%;
	}

}

.cube__insights-modal-close {
	position: absolute;
	right: 1rem;
	top: 1em;
	font-size: 1.1rem;
}
