.featured-break {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding: 70px 80px;
    margin-top: 60px;
    position: relative;
    background-color: $color-brand-black;

    &--dark-theme {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .7);

        .featured-break__title {
            color: $color-brand-white-abs;
        }

        .featured-break__date,
        .featured-break__text,
        .featured-break__related-professional,
        a {
            color: $color-brand-white-abs;
        }

        .featured-break__text {
            color: $color__gray--medium;

            span, p {
                color: inherit !important;
            }
        }

        .button {
            border: 1px solid $color-brand-white-abs;
        }
    }

    &--light-theme {
        &.featured-break--no-img {
            box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .98);
        }

        .featured-break__title {
            color: $color__green;
        }

        .featured-break__date,
        .featured-break__text,
        .featured-break__related-professional,
        .featured-break__text,
        a {
            color: $color-brand-black;
        }

        .button {
            border: 1px solid $color-brand-black;
        }
    }

    &--no-img {
        .featured-break__title {
            color: $color__green;
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
    video {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &__image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 60%;
        padding-bottom: 530px;
        margin-right: 55px;
        margin-top: -100px;
        position: relative;
        z-index: 2;
        flex-shrink: 0;
        max-width: 556px;
    }

    &__title {
        font-family: $font-family-serif-new;
        font-size: 80px;
        line-height: 88px;
        margin-bottom: 28px;
    }

    &__text-wrapper {
        position: relative;
        z-index: 1;
    }

    &__date {
        font-family: $font-family-secondary;
        font-weight: $font-weight-medium;
        font-size: 24px;
        line-height: 1;
    }

    &__text {
        font-family: $font-family-serif-new;
        font-size: 32px;
        line-height: 35px;
        font-style: italic;
        margin: 14px 0 28px;
        max-width: 980px;
    }

    &__related-professionals {
        display: flex;
        margin-bottom: 32px;
    }

    &__related-professional {
        font-family: $font-family-secondary;
        font-size: 24px;
        line-height: 1;
        text-decoration: underline;
        font-weight: 500;

        &:not(:last-of-type)::after {
            content: ',';
            margin-right: 3px;
        }
    }

    .button {
        padding: 12px 32px;
        font-family: $font-family-secondary;
        text-align: center;
    }

    @include bp-max($bp-desktop-large) {
        padding-left: 40px;
        padding-right: 40px;
        &__title {
            font-size: 65px;
            line-height: 1
        }

        &__image {
            width: 50%;
        }
    }

    @include bp-max($bp-desktop-large) {
        &__title {
            font-size: 50px;
            // line-height: 1
        }
    
        &__image {
            width: 45%;
        }
    }

    @include bp-max($bp-tablet-sm) {
        padding: 16px;
        padding: 0px 16px 60px;
        margin-top: 20px;

        &__wrapper {
            display: block;
        }

        &__image {
            margin-right: 0;
            padding-bottom: 295px;
            transform: translateY(-14%);
            width: 100%;
            margin-top: 0;
        }

        &__title {
            font-size: 52px;
            line-height: 57px;
        }

        &__date {
            font-size: 18px;
            line-height: 20px;
        }

        &__text {
            font-size: 24px;
            line-height: 26px;
        }

        &__related-professionals {
            display: block;
            margin-bottom: 32px;
        }

        &__related-professional {
            font-size: 18px;
            line-height: 20px;
            display: block;
            margin-bottom: 20px;

            &:not(:last-of-type)::after {
                display: none;
            }
        }

        .button {
            display: block;
        }
    }
}