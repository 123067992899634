

.jump-nav {
    background-color: $color-gray-thunder;
    font-family: $font-family-secondary;
    font-size: px-to-rem(18px);
    line-height: 1.3;
    padding: px-to-rem(18px) 0;
    margin: 0 auto;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;

    .jump-nav__label {
        display: inline-block;
    }

    &.expanded {
        padding-bottom: 0;
    }


    .jump-nav-list {
        @include font-secondary-light;
        padding-left: px-to-rem(10px);
        padding-right: px-to-rem(10px);
        display: inline-block;

        @include bp-max($bp-tablet) {
          display: none;
          padding: px-to-rem(30px) 0 0;
          margin: 0;
        }

        margin: auto;

        .list__item {

            @include bp-max($bp-tablet) {
                display: block;
                float: none;
                text-align: center;
                padding: px-to-rem(10px) 0;


                &:last-child {
                    padding-bottom: px-to-rem(20px);
                }

            }

            &  + .list__item:before {
                content: '|';
                padding-right: .5rem;
                padding-left: .3rem;
                display: inline-block;
                font-weight: $font-weight-light;

                @include bp-max($bp-tablet) {
                    content: '';
                    padding: 0;
                }
            }

            .nav-inner-text {
                display: inline-block;
                cursor: pointer;
                position: relative;
                padding: 0;
                color: #ffffff;

                &:hover {
                    position: relative;

                    &:after  {
                        height: 1px;
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background:$color-brand-white-abs;
                    opacity: 0;
                    transition: height 0.3s, opacity 0.3s, transform 0.3s;
                    transform: translateY(-10px);
                }
            }
        }
    }

    @include bp-max($bp-tablet) {
        display: none;  
    }
}
