.hero-profile {
    $font-size-details: 20px;
    $max-height: px-to-rem(950px);

    background-color: #0F0803;
    background-size: auto 100%;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    padding-top: $spacing-unit;
    display: table;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include bp-min($bp-tablet) {
        height: 55vw;
        max-height: $max-height;
    }

    @include bp-max($bp-hamburger) {
        height: 65vw;
    }

    .hero-content {
        display: table-row;
        height: 1em;
        left: 0;
        bottom: 0;
    }

    .breadcrumb-nav {
        padding: 1.5em 0;
        position: relative;
        z-index: 5;
    }

    .hero-image {
        @extend %bg-cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-position: center top;


        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 3;
            top: 0;
            bottom: 0;
        }

        &:before {
            @include gradient(rgba(15,8,13,1) 65%, rgba(15,8,3, 0), 90deg, 100%);
            opacity: .7;
            width: 60%;
            left: -1px;
        }
    }

    .hero-image-stack {
        visibility: hidden;
        opacity: 0;
    }

    .profile-content {
        display: table-cell;
        padding: 1.5em 0;
        vertical-align: middle;
        position: relative;
        z-index: 5;

        .page-wrapper {
            max-width: px-to-rem($site-max-width);
        }

        a {
            color: $color-blue-regent-blue;

            @include bp-max($bp-mobile) {
                color: $color-blue-waikawa-gray;
            }
        }
    }

    .profile-card {
        max-width: 45%;
        position: relative;
    }

    .name, .level {
        text-transform: uppercase;
        color: $color-brand-white-abs;
    }

    .name {
        $font-size: 38px;
        @include font-secondary-medium();
        font-size: px-to-rem($font-size);
        margin: 0;
    }

    .profile-card-details {
        $font-size: 28px;

        @include font-secondary-medium();
        color: $color-gray-light-gray;
        font-size: px-to-rem($font-size);
        margin: px-to-rem(15px) 0;
    }

    .level {
        $font-size: 30px;

        font-size: px-to-rem($font-size);

        & + .profile-card-location {
            @include vertical-rule-after($color-gray-light-gray);

            &:before {
                @include bp-max($bp-mobile) {
                    color: $color-gray-thunder;
                }
            }
        }
    }

    .profile-card-location {
        font-size: px-to-rem($font-size-details);
        @include font-accent;
    }

    .profile-card-contact {
        $font-size: 22px;

        font-size: px-to-rem($font-size);

        ul {
            margin: 0 px-to-rem(20px) 0 0;
            padding: 0;
            display: inline-block;
        }
    }

    div {
        &.profile-card-contact {
            display: flex;
        }
    }

    .expertise {
        $font-size: 26px;

        font-size: px-to-rem($font-size);
        line-height: 1.6;
        color: $color-blue-regent-blue;
        margin: 2rem 0;

        @include font-accent;

        .list__item {
            color: $color-brand-white-abs;

            @include vertical-rule-after($color-brand-white-abs, px-to-rem(5px), px-to-rem(8px));
        }

        .expertise__link {
            &:hover {
                color: $color-blue-regent-blue;
            }
        }
    }

    .list-vertical.expertise {
        .list__item {
            &:after {
                content: none;
            }
        }
    }
    .summary-btn {
        $font-size: 22px;

        display: block;
        background-color: $color-opaque-regent-blue;


        @include bp-max($bp-mobile) {
            background-color: $color-blue-waikawa-gray;
        }
        
        border-color: transparent;
        margin-top: $spacing-unit * 1.25;
        font-size: px-to-rem($font-size);

        &:hover {
            background-color: $color-gray-lynch;
        }
        
        &:before {
            content: "+ ";
        }
    }
    .close {
        &:before {
            content: "- ";
        }
    }
    .profile-card-phone,
    .profile-card-email,
    .profile-card-attachments,
    .profile-card-location {
        $icon-spacing: 1.5em;
        $font-size: 22px;

        font-size: px-to-rem($font-size);
        color: $color-blue-regent-blue;
        margin-top: .5em;
        margin-left: $icon-spacing;
        position: relative;
        @include font-accent;

        @include bi-icon-before($bi-interface-direction) {
            position: absolute;
            left: $icon-spacing * -1;
            top: 50%;
            transform: translateY(-50%);
            color: $color-brand-white-abs;
            font-size: 1em;
        }
    }

    .profile-card-phone {
        color: $color-brand-white-abs;
        margin-right: 1.5em;

        &:not(.profile-card-phone-mobile)::before {
            content: $bi-com-phone;
            transform: rotate(-270deg);
            transform-origin: 70% 30%;
        }

        &:empty,
        &-mobile {
            &:before {
                content: unset;
            }
        }
    }

    .profile-card-email {
        &:before {
            content: $bi-com-email;
        }
    }

    .profile-card-attachments {
        display: inline-block;
        margin-top: 1rem;
        margin-right: 1rem;

        &:before {
            content: $bi-doc-paper-line;
        }
    }

    .profile-card-location {
        &:before {
            content: $bi-location-pin-reg;
        }
    }

    @include bp-max(1100px) {
        .hero-image {
        }

        .profile-content {
            padding-left: 0;
        }
    }


    @include bp-max(766px) {
        background-image: none !important;
        background-color: $color-brand-white-abs;
        padding: 0;
        display: block;
        height: auto;


        .hero-content {
            position: relative;
            display: block;
            height: auto;
        }

        .profile-card {
            max-width: none;

            &:before {
                display: none;
            }
        }

        .breadcrumb-nav {
            position: absolute;
            top: 1em;
            left: 1em;
            z-index: 1;
        }

        .hero-image {
            position: relative;
            left: 0;
            transform: translateX(0);

            &:before,
            &:after {
                display: none;
            }
        }

        .hero-image-stack {
            position: relative;
            max-height: 350px;
            left: 100%;
            transform: translateX(-100%);
            height: auto;
        }

        .profile-content {
            padding: $spacing-unit 0 0;
            display: block;
        }

        .name {
            color: $color-gray-dim-gray;
            max-width: none;
        }

        .level {
            color: $color-gray-dim-gray;
        }

        .expertise {


            .list__item {
                color: $color-text-base;
            }

            .expertise__link {
                color: $color-blue-regent-blue;

                @include bp-max($bp-mobile) {
                    color: $color-blue-waikawa-gray;
                }

                &:hover {
                    color: $color-text-base;
                }
            }
        }

        .profile-card-location,
        .profile-card-phone,
        .profile-card-email,
        .profile-card-attachments {
            color: $color-gray-dim-gray;

            &:before {
                color: $color-gray-dim-gray;
            }
        }

        .profile-card-phone {
            margin-top: 0;
        }
    }

    @include bp-max(414px) {
        .hero-image-stack {
            max-height: 250px;
        }

        .first-name {
            font-size: px-to-rem(30px);
        }

        .last-name {
            font-size: px-to-rem(30px);
        }

        .level {
            font-size: px-to-rem(24px);
        }

        .expertise {
            font-size: px-to-rem(18px);
        }

        .profile-card-location,
        .profile-card-phone,
        .profile-card-email,
        .profile-card-attachments {
            $font-size: 18px;

            font-size: px-to-rem($font-size);
            color: $color-gray-dim-gray;
            margin-top: 0;

            &:before {
                color: $color-gray-dim-gray;
            }
        }
    }
}
