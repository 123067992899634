.contact-card {
	overflow: hidden;
	@include font-accent;

	&__image  {
		display: block;
		//max-width: px-to-rem(230px);
		//text-align: right;
		overflow: hidden;
		margin-bottom: $spacing-unit-small;

		@include bp-max($bp-tablet) {
			max-width: px-to-rem(200px);
			margin-right: 5%;
		}

		@include bp-max($bp-mobile-portrait) {
			max-width: 35%;
		}

		a img{
			height: 137px;
			width: 239px; 

			.case-study &  {
				height: auto;
			}
		}

		> img {
			//max-width: none;
			//position: relative;
			left: 100%;
			//transform: translateX(-100%);


			@include bp-max($bp-tablet) {
				width: 190%;
				transform: translateX(-95%);
			}
		}
	}

	&__name {
		@include font-secondary-bold;

		font-size: px-to-rem(24px);
		text-transform: uppercase;

		.last-name {
			@include font-secondary;
		}
	}

	&__position {
		@include font-secondary-medium;

		text-transform: uppercase;

	}

	&__phone{
		color:$color-text-rich-text;

		&:hover {
			color:$color-text-rich-text;
		}

	}

	&__office {
		display: block;
	}

	&__primary {

	}
}
