.advanced-search {
    .dropdown-redesign + .dropdown-redesign {
        margin-top: 1rem;
    }

    &__trigger {
        display: block;
        margin-left: auto;
        margin-right: auto;
        font-size: 1rem;
        margin-bottom: px-to-rem(24px);
        padding-left: 0;
        padding-right: 0;
        // icon
        .bi {
            color: $color-brand-green;
            margin-left: 0.2em;
        }
    }

    &__accordion {
        overflow: hidden;
        height: 0;
        @include transition(height);
    }

    @include bp-min($bp-tablet-sm) {
        .dropdown-redesign+.dropdown-redesign {
            margin-top: 0
        }
        .dropdown-redesign {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        &__accordion {
            padding-left: px-to-rem(32px);
        }

        &__trigger {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @include bp-min($bp-tablet) {
    }
}