.article-detail-meta {
	@include font-accent-bold;
	font-size: px-to-rem(24px);


	.authors-list { 
		display: block;
	}

	.topics,
	.date {
		font-size: px-to-rem(22px);
	}
     
	.subtitle{ 
		font-size: px-to-rem(26px);
	}
    time{
        display:inline;
    }
}
