.list-articles,
.list-articles-nojs {
    $list-spacing: px-to-rem(40px);
    margin-bottom: $spacing-unit-block;

    .list__item {
        margin-bottom: $list-spacing;
        padding-bottom: $list-spacing;
        border-bottom: 1px solid $color-border-alt;
    }

    &__link {
        @include font-accent;
        font-size: px-to-rem(24px);
        line-height: 1.4;
        display: inline-block;
        margin-bottom: $spacing-unit-small;
    }

    &__date {

        @include font-accent-bold;
        color: $color-text-dark;
        margin-bottom: $spacing-unit-small;
    }

    &__abstract {
        font-size: px-to-rem(22px);
        line-height: 1.4;
    }

    &__btn {
        display: block;
        margin: 0 auto;
    }

    &__type {
        margin-bottom: 20px;
        margin-top: -7px;
    }

    &__tags {
        @include font-accent;
        color: $color-brand-green;
        font-weight: 500;
        margin-top: $spacing-unit-small;
    }
}
