.services-detail {
    .relatedfocus__jumplink {
        margin: $spacing-unit 0;
    }

    .focus-areas {
        margin-bottom: 1.65rem;
        padding-bottom: 1.65rem;
        border-bottom: 1px solid #e1e1e1;
    }

    .list-focus-areas {
        margin-top: 1.5rem;
        padding-left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }

    .rich-text.line-height-fix p {
        line-height: 1.5 !important;
    }

    .general-content-section {


        & + .general-content-section {
            border-top: 1px solid $color-border-alt;
            padding-top: $spacing-unit+$base-line-height/2;
            margin-top: $spacing-unit;
        }

        @include bp-max($bp-tablet-sm) {
            &.column-layout {
                display: flex;
                flex-direction: column;

                .rich-text {
                    width: 100% !important;
                    float: none !important;
                    padding: 0;
                }

                .text-column {
                    order: 1;
                    padding: 0 0 $spacing-unit-block 0 !important;
                }

                .video-embed-column {
                    order: 2;
                }
            }
        }

        .video-embed-column {
            width: 40%;
            float: left;
        }

        .text-column {
            width: 60%;
            float: left;
            padding: 0 $spacing-unit+$base-line-height/2 0 0;
        }

        &.text-to-right {
            .text-column {
                padding-left: $spacing-unit+$base-line-height/2;
            }
        }
    }
}
