.breadcrumb-nav {
    margin-top: $spacing-unit;
    height: auto;

    @include bp-max($bp-tablet) {
        display: none;
    }

    & + .page-body {
        margin-top: $spacing-unit;
    }
}

.breadcrumb-nav-links {
	font-size: px-to-rem(12px, $font-size-base-new);

	color:$color-links;

	@include font-secondary-light;

	.breadcrumb-links {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	.breadcrumb-link {
		text-transform: uppercase;
		display: inline;

		$spacing: 1em;
		color: inherit;
		padding-right: $spacing;
		margin-right: $spacing;
		border-right: 1px solid $color-gray-light-gray;
		line-height: 1;

		a {
			&:hover {
				opacity: .7;
			}
		}

		&:last-of-type {
			color: $color-text-gray-light;
			font-weight: $font-weight-medium;
			border-right: none;
		}
	}
}
