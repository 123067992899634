.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: background-color $base-animation-timing $animationEase;
    background-color: $color-brand-white-abs;
    z-index: 12;

    &--dark {
        background-color: rgba(0, 0, 0, 0.5);
    }

    // Header Wrapper
    &__wrapper {
        padding: 24px 32px 22px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__primary-link-wrapper,
    &__secondary-link-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__primary-link-wrapper {
        margin-bottom: 14px;
    }

    &__primary-links,
    &__secondary-links {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    &__primary-links,
    &__secondary-links {
        &--mm {
            a {
                padding-left: 0;
            }
        }
    }

    &__primary-links {
        &--mm {
            display: block;
            padding-left: 0;
        }
    }

    &__secondary-links {
        &--mm {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            padding-left: 60px;
        }
    }

    &__primary-link {
        margin-left: 36px;

        a {
            color: $color__green;
            font-family: $font-family-secondary;
            font-size: 22px;
            letter-spacing: -1.5%;
            font-weight: $font-weight-bold;
        }

        &--mm {
            margin-left: 0;
            margin-bottom: 30px;

            a {
                font-size: 40px;
            }
        }
    }

    &__secondary-link {
        margin-left: 32px;

        a {
            color: $color__gray--dark;
            font-weight: $font-weight-medium;
            font-family: $font-family-secondary;
            font-size: 18px;
        }

        &--mm {
            margin-left: 0;
            width: calc(100% / 3);
            padding-right: 32px;
            padding-bottom: 50px;

            a {
                color: $color__green;
                font-weight: $font-weight-bold;
                font-size: 28px;
            }
        }

        &--dark {
            a {
                color: $color-brand-white-abs;
            }
        }
    }

    &__search {
        color: $color__green;
        background-color: $color-brand-white-abs;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 0;
        margin-left: 16px;

        &--open {
            color: $color-brand-white-abs;
            background-color: $color__green;
        }

        .bi.bi-interface-search {
            font-size: 16px;
        }
    }

    &__search-wrapper {
        display: flex;
        position: relative;
        opacity: 0;
        visibility: hidden;
        max-width: 0;
        transition: all 0.75s ease-in-out;

        &--open {
            margin-left: 36px;
            opacity: 1;
            visibility: visible;
            max-width: 100%;
        }
    }

    &__search-input {
        border: none;
        background: transparent;
        border-bottom: 1px solid $color__gray--dark;
        width: 300px;
        font-family: $font-family-secondary;
        font-size: 14px;
        color: $color__gray--dark;
        font-weight: $font-weight-medium;

        &::placeholder {
            font-style: italic;
            color: $color__gray--dark;
            font-weight: $font-weight-medium;
        }

        &--dark {
            border-bottom: 1px solid $color-brand-white-abs;
            color: $color-brand-white-abs;

            &::placeholder {
                color: $color-brand-white-abs;
            }
        }
    }

    &__search-close {
        padding: 0;
        position: static;
        right: 0;

        &--open {
            position: absolute;
        }
    }

    &__progress {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 3px;
        background: $color__gray--medium;
    }

    &__progress-bar {
        height: 3px;
        background: $color__green;
        width: 0%;
    }

    &__mega-menu {
        background-color: #F8F8F8;
        position: absolute;
        display: flex;
        left: 0;
        right: 0;
        top: $desktop-header;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        overflow-y: auto;
        max-height: calc(100vh - 126px);

        &--open {
            z-index: 1;
            opacity: 1;
            visibility: visible;
        }
    }

    &__mm-outer-wrapper {
        margin: 0 auto;
    }

    &__mm-inner-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 80px;
        max-width: 1420px;
    }

    &__mm-inner-wrapper-children {
        display: none;
    }

    &__mm-secondary {
        width: 60%;
        border-left: 2px solid $color__gray--medium;
    }

    &__mm-children {
        padding-left: 0;
        margin-top: 10px;
    }

    &__mm-child {
        margin-bottom: 4px;

        a {
            color: $color__green;
            font-weight: $font-weight-medium;
            font-family: $font-family-secondary;
            font-size: 16px;
        }
    }

    &__mm-child-next {
        display: none;
    }

    @include bp-max($bp-hamburger) {

        &__mega-menu {
            display: block;
            top: $mobile-header;
            height: 100vh;
            overflow: auto;
        }

        &__mm-outer-wrapper {
            display: flex;
            overflow-x: hidden;
        }

        &__mm-inner-wrapper,
        &__mm-inner-wrapper-children {
            display: block;
            padding: 40px 16px;
            width: 100%;
            transform: translateX(0);
            transition: transform 0.5s ease-in-out;

            &--slide {
                transform: translateX(-100%);
            }
        }

        &__mm-inner-wrapper-children {
            transform: translateX(100%);

            &--slide {
                transform: translateX(-100%);
            }
        }

        &__primary-links,
        &__secondary-link-wrapper {
            display: none;
        }

        &__mm-primary {
            padding: 0 10px 24px;
        }

        &__primary-links--mm {
            display: block;
        }

        &__primary-link-wrapper {
            margin-bottom: 0;
        }

        &__primary-link--mm {
            margin-bottom: 0;
            margin-left: 0;
            padding-bottom: 16px;

            a {
                font-size: 28px;
            }
        }

        &__mm-secondary {
            border-left: none;
            width: 100%;
            border-top: 1px solid $color__gray--medium;
            padding: 40px 10px 0;
        }

        &__secondary-links--mm {
            display: block;
            padding-left: 0;
            width: 100%;
        }

        &__secondary-link--mm {
            margin-left: 0;
            padding: 0;
            padding-bottom: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                font-size: 18px;
                font-weight: $font-weight-medium;
            }
        }

        &__mm-child-next {
            display: inline;
        }

        &__mm-children {
            display: none;
        }

        &__mm-back {
            font-family: $font-family-secondary;
            color: $color__green;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 32px;

            span {
                display: inline-block;
                margin-left: 12px;
            }
        }

        &__search-wrapper {
            &--open {
                margin-left: 0;
            }
        }
    }
}