.careerSegments-segment {
    position: relative;
    min-height: $spacing-unit*10;
    padding: $spacing-unit;
}

.careerSegments-segment--hasBackground {
    background-position: 0 0;
    background-size: cover;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $color-opaque-black;
    }
}

.careerSegments-segmentLink {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: $color-links
}

.careerSegments-segmentTitle {
    position: relative;
    padding-top: 50%;
    margin-top: -25%;
    text-align: center;
    color: $color-brand-white;
}