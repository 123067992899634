.site-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: $color-opaque-overlay;
	z-index: $zindex-modal-bg;
	visibility: hidden;
	opacity: 0;

	.is-overlay-active &{
		opacity: 1;
		visibility: visible;
		transition: visibility 1s $animationEase,
					opacity 1s $animationEase;
	}

	.is-overlay-active-fadeout &{
		transition: visibility 1s $animationEase .3s,
					opacity 1s $animationEase;
	}

	.site-layout-wrapper  {
		position: relative;
	}
}

.site-overlay__close {
	color: $color-brand-white;
	position: absolute;
	right: $site-padding;
	top: 0;
	height: $site-header-height;
	text-transform: uppercase;
}
