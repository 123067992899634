.content-carousel {
    height: 840px;

    &__slide {
        background-size: cover;
        background-repeat: no-repeat;
        height: 840px !important;
        padding-top: $desktop-header;
        box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 50%);
        position: relative;
        video {
            background-size: cover;
            background-repeat: no-repeat;
            left: 0;
            position: absolute;
            top: 50%;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: translateY(-50%);
        }
    }

    &__scroll-indicator {
        position: absolute;
        z-index: 1;
        right: 150px;
        bottom: 100px;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        span {
            @include transition(color);
            color: $color-brand-white-abs;
            font-family: $font-family-secondary;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: -0.015em;
            font-weight: $font-weight-bold;
            display: inline-block;
            margin-left: 12px;
        }
    }

    &__outer-text-wrapper {
        display: flex;
        align-items: flex-end;
        height: 100%;
    }

    &__text-wrapper {
        z-index: 2;
        position: relative;
        color: $color-brand-white-abs;
        padding: 90px;
    }

    &__title {
        font-size: clamp(70px, 9vw, 120px);
        line-height: 1.05;
        font-family: $font-family-serif-new;
        max-width: 1380px;
        margin-bottom: 16px;
    }

    &__secondary-text {
        font-family: $font-family-secondary;
        font-weight: $font-weight-medium;
        font-size: 24px;
        line-height: 34px;
        max-width: 620px;
    }

    a {
        font-family: $font-family-secondary;
    }

    .button {
        font-family: $font-family-secondary;
        margin-top: 20px;
        text-align: center;
    }

    &__pagination {
        position: absolute;
        left: 24px;
        top: 18%;
        z-index: 10;
        text-align: center;
    }

    &__btn {
        font-family: $font-family-secondary;
        color: $color-brand-white-abs;
        font-weight: $font-weight-medium;
        font-size: 24px;
        display: block;
        padding: 0;
        margin: 0;

        &::before {
            content: '';
            width: 2px;
            height: 25px;
            background-color: $color-brand-white-abs;
            display: block;
            margin: 11px 0 11px 4px;
            border-radius: 4px;
            @include transition(height);
        }

        &--active {
            &::before {
                height: 150px;
            }
        }
    }

    &__auto-playcontrol {
        color: $color__green;
        background-color: $color-brand-white-abs;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 0;
        margin-left: -12px;
        margin-top: px-to-rem(24px);

        .bi {
            font-size: px-to-rem(20px);
        }
    }

    @include bp-max($bp-tablet-sm) {
        height: 100%;

        &__scroll-indicator {
            display: none;
        }

        a {
            display: block;
        }

        &__title {
            font-size: 50px;
            line-height: 1.1;
        }

        &__secondary-text {
            font-size: 18px;
            line-height: 25px;
        }

        &__slide {
            background-size: cover;
            background-repeat: no-repeat;
            height: calc(100vh - 64px) !important;
            padding-top: 0;
            padding-bottom: 120px;
        }

        &__text-wrapper {
            padding: 20px;
        }

        &__pagination {
            top: unset;
            bottom: 20px;
            right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            z-index: 10;
        }

        &__pagination-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        &__btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            &::before {
                height: 2px;
                width: 48px;
                margin: 0 30px;
            }

            &:first-of-type {
                &::before {
                    height: 2px;
                }
            }

            &:last-of-type {
                &::before {
                    display: none;
                }
            }
        }
    }
}