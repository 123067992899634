﻿$scrollspy-nav-font-size: 18px;
$scrollspy-nav-border-width: 1px;
$scrollspy-nav-width: 18rem;
// "top" offests should be set to site header height
$scrollspy-nav-top: 125px;
$scrollspy-nav-top-mobile: 86px;

.scrollspy-nav {
    position: absolute;
    width: $scrollspy-nav-width;
    float: left;
    transform: translate3d(0,0,0); //left padding of container;
    &.is-sticky-top {
        position: fixed;
        top: $scrollspy-nav-top; //todo calc offset including margins
    }

    &.is-sticky-bottom {
        position: absolute;
        bottom: 0;
        top: auto;
    }
    .scrollspy-nav-list {
        padding:0;
        @include bp-max($bp-tablet) {
            display: none;
        }
    }
}

//the sticky bottom doesn't work very well on mobile here ...
//remove sticky bottom functionality for mobile across the entire site
.scrollspy-nav.scrollspy-nav.is-sticky-bottom {
    @include bp-max($bp-tablet) {
        position: fixed;
        bottom: auto;
        top: $scrollspy-nav-top-mobile;//todo calc top 
    }
}

.scrollspy-nav-link {
    @include font-secondary;
    font-size: $scrollspy-nav-font-size;
    line-height: 1.2;
    display: inline-block;
    border-bottom: $scrollspy-nav-border-width solid $color-gray-dim-gray;
    text-transform: uppercase;
    color: $color-brand-blue;
    width: 95%;
    padding: 1.25rem 0 1.25rem 15px;

    &:hover {
        color: $color-brand-black;
        border-left-color: $color-brand-black;
    }

    .is-inview & {
        color: $color-gray-dim-gray;
        font-weight: $font-weight-bold;
    }
}

$scrollspy-nav-height-tablet: 61px;
$scrollspy-nav-font-size-tablet: 16px;


.scrollspy-nav-toggle {
    @include font-accent();
    font-size: $scrollspy-nav-font-size-tablet;
    line-height: 1.2;
    width: 100%;
    height: px-to-rem($scrollspy-nav-height-tablet, $scrollspy-nav-font-size-tablet);
    text-transform: uppercase;
    color: $color-brand-blue;
    //transition: $transition-default;//fix ttransition
    padding: 1.1em 1em 0.95em;
    position: relative;
    z-index: 10;
    background-color: $color-brand-white-abs;
    display: none;

    @include bi-icon-after($bi-arrow-right) {
        @include transition(transform);
        font-size:1rem;
        vertical-align: middle;
        float:right;
    }

}

@include bp-max($bp-tablet) {

    .scrollspy-nav {
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;

        &.show-menu {
            .scrollspy-nav-list {
                display: block;
                transform: translateY(0);
            }

            .scrollspy-nav-toggle {
                &:after {
                    transform: rotateZ(90deg);
                }
            }
        }

        &.is-sticky-top {
            position: fixed;
            top: $scrollspy-nav-top-mobile;
        }

        &.is-sticky-bottom {
            position: absolute;
            bottom: 0;
            top: auto;
        }
    }

    .scrollspy-nav-toggle {
        display: inline-block;
        text-align: left;
        font-weight:$font-weight-bold;
        background-color: $color-brand-light;
        padding-left:2.25rem;
    }

    .scrollspy-nav-option {
        display: table;
        width: 100%;
    }

    .scrollspy-nav-list {
        @include transition(transform);
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: $color-brand-white-abs;
        transform: translateY(-100%);
    }

    .scrollspy-nav-link {
        margin-left:1.25rem;
        display: table-cell;
        vertical-align: middle;
        font-size: 1em;
        border-left: 0;
        padding: 1em;
        text-align: left;
        height: px-to-rem($scrollspy-nav-height-tablet, $scrollspy-nav-font-size-tablet);
        color: $color-brand-blue;
        border:none;
        .is-inview & {
            display: none;
        }
    }
}
