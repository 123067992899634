.keyword-search {
    display: flex;
    align-items: center;
    
    &__input {
        flex-grow: 1;
        padding: px-to-rem(18px) 0;
        background: transparent;
        border: none;
        border-bottom: 2px solid $color__gray--dark;
        color: $color__gray--dark;
        font-weight: $font-weight-medium;
        @include font-secondary-medium;
    
        &::placeholder {
            font-style: italic;
            color: $color__gray--dark;
            font-weight: $font-weight-medium;
        }
    }
    
    &__button {
        flex-shrink: 0;
        color: $color-brand-white-abs;
        background-color: $color__green;
        border-radius: 50%;
        width: px-to-rem(45px);
        height: px-to-rem(45px);
        padding: 0;
    
        .bi.bi-interface-search {
            font-size: px-to-rem(18px);
        }
    }
    
    @include bp-min($bp-tablet-sm) {
        &__input {
            font-size: 1rem;
        }
    }
}