.bio-detail {
    $spacing: 80px;

    .page-wrapper {
        //padding-top: px-to-rem($spacing/4);
        padding-bottom: px-to-rem($spacing/6);
    }

    .header-rich-text {
        margin-top: 5rem;
        margin-bottom: 1.65rem;
    }

    .page-launch {
        margin-top: px-to-rem($spacing);
    }

    .accordion-list {
        margin-top: px-to-rem($spacing);
        margin-bottom: px-to-rem($spacing);

        .header {
            width: 100%;
        }
    }

    .social-links {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: $spacing-unit * 1.5;
    }

    .social-link {
        display: inline-block;

        a {
            $size: 2.4em;

            display: inline-block;
            margin-right: .8em;
            border-radius: $size;
            position: relative;
            background-color: $color-opaque-regent-blue;

            @include bp-max($bp-mobile) {
                background-color: $color-blue-waikawa-gray;
            }

            @include size($size);

            @include bm-icon-before ($fab-var-blacktie-alt) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                color: $color-brand-white-abs;
                transition: color .25s;
            }

            &:hover {
                background-color: $color-gray-lynch;
            }
        }

        .social-link-profile {
            color: $color-brand-white-abs;

            img {
                width: 60%;
                vertical-align: bottom;
                position: absolute;
                left: 20%;
                top: 20%;
            }

            &:before {
                content: '';
                font-family: "Arial";
            }
        }

        .social-link-linkedin {
            &:before {
                content: $fab-var-linkedin-alt;
            }
        }

        .social-link-twitter {
            &:before {
                content: $fab-var-twitter;
            }
        }
    }

    .related-practices {
        padding: 2.5rem 0;

        border: {
            top: 5px solid #eeeeee;
            bottom: 5px solid #eeeeee;
        }

        .list__item {
            @include vertical-rule-after($color-brand-dark-gray);
            white-space: nowrap;
            font-size: px-to-rem(24px);
            @include font-secondary;
        }

        @include bp-max($bp-mobile) {

            .header--emphasis {
                font-size: 1.25rem;
                color: $color-gray-dim-gray;
                letter-spacing: .05rem
            }

            .list__item {
                font-size: 1rem;
            }

            .expertise__link {
                color: $color-brand-green;
            }
        }
    }

    @include bp-max($bp-scrollspy) {
        .site-layout-wrapper.related-practices-wrapper {
            padding-top: 5rem;
            background: $color-brand-white-abs;
            position: relative;
            
            .related-practices {
                margin-top:0;
            }
        }
    }

    .scrollspy {
        .page-wrapper {
            //override page wrapper max width for scrollspy nav
            //to work with extended sections
            @extend .site-layout-wrapper;
            //sections container functions as as page wrapper
        }

        .scrollspy-sections {
            margin-right: 0;
        }

        @include bp-min($bp-tablet) {
            .scrollspy-sections {
                margin-right: 0;
                margin-left: calc(18rem + 1.9rem);
            }
        }

        .scrollspy-sections--shade {
            margin-top: $spacing-unit-section;
            margin-bottom: $spacing-unit-section;

            .js-scrollspy-section {
                padding-bottom: 4rem;
            }
        }

        .header-text {
            color: $color-gray-thunder;
            margin-bottom: px-to-rem(25px);
            @include font-secondary-medium;
        }
    }
}
