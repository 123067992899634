.page--office-detail{
  .page-body{
    position: relative;
  }

  .location{
    margin-bottom: 10px;
  }

  address{
    margin-bottom: 10px;
  }

  section.office-management{
    position:absolute;
    top: 0;
    right: 0;
    padding-right: $site-padding;
    width: 30%;

    .related-professionals{
      margin-top: 20px;

      .list-related-professionals{
        .media__img{
          width: 80%;
        }

        .media__body {
          margin-top: 10px;
        }
      }

      .professional__phone,
      .professional__title,
      .professional__email{
        display: block;
      }
    }

    @include bp-max($bp-mobile) {
      position: inherit;    
      padding-right: 0;
      width: 100%;
      margin-bottom: 2.45rem;
    }	
  }

  section.related-professionals{
    .list-related-professionals{
      width: 50%;
      .media__img{
        width: 50%;
      }
    }
  }

  .related-services{
    //margin-top:10px;
    //margin-bottom: 10px;
  }
  .office-card { 
      margin-bottom: 6.45rem;
      @include bp-max($bp-mobile) {
        margin-bottom: 2.45rem;
      }	
  }
  .office-card__contact{
    .link--block{
      display: block;
    }
    margin-bottom:20px;
  }


  @include bp-max(769px) {
    .contacts__list{
      .contacts__list-item{
        .contact-card__image{
          max-width: 100%;
          margin-right: 0;
          img{
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }  
}
