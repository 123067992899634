.callout {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0;

    &__text-wrapper {
        width: 60%;
        max-width: 675px;
    }

    &__title {
        font-size: 48px;
        margin-bottom: 24px;
    }

    &__text {
        color: $color-brand-white-abs;
        font-family: $font-family-secondary;
        font-size: 24px;
        line-height: 26px;
        font-weight: $font-weight-medium;

        a {
            color: $color-brand-white-abs;
            text-decoration: underline;

            &:hover {
                color: $color-brand-white-abs;
                text-decoration: none;
            }
        }
    }

    &__images {
        width: 40%;
        max-width: 425px;
    }

    &__image {
        width: 100%;
        padding-bottom: 236px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__logo {
        margin-top: 32px;
    }

    .button {
        padding: 12px 32px;
        font-family: $font-family-secondary;
        text-align: center;
        border-width: 1px;
    }

    @include bp-max($bp-tablet-sm) {
        flex-direction: column-reverse;
        padding: 0;

        &__text-wrapper,
        &__images {
            width: 100%;
        }

        &__images {
            margin-bottom: 18px;
        }

        &__title {
            font-size: 28px;
            margin-bottom: 18px;
        }

        &__text {
            font-size: 20px;
            line-height: 22px;
        }

        .button {
            width: 100%;
        }
    }
}