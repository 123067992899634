.footer-options {

	.list-horizontal {
		display: inline-block;
	}
	
	.footer-nav{
		text-transform: uppercase;
	}

	.footer-nav__link,
	.social-icon__link {
		color: $color-brand-white;

		&:hover {
			color: $color-brand-white-abs;			
		}
	}

	.footer-nav {
		.list__item {
			margin-left: 2.5vw;
			&:first-child {
				margin-left: 0;
			}
		}
	}

	.social-icon__link {
		font-size: px-to-rem(14px);
		margin-left: 1rem;
	}
}