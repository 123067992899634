.credentials {
	columns: 2 auto;

	@include bp-max($bp-tablet-sm) {
		columns: 1;
	}
}

.credentials__category {
	-webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.credentials__category-label {
	@include font-secondary-medium;

	font-size: px-to-rem(25px);
	line-height: 1.4;

}

.credentials__category-content {
	position: relative;

	&.rich-text{
		margin-top: 0px;
	}
}
