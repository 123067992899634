///////////////////////////////////////////////////////////////////////////////
//
//  Grid Content Variables
//
///////////////////////////////////////////////////////////////////////////////

$grid-content-width:   66%;
$grid-sidebar-width:   28%;  


//Solving the Whitespace issuewith inlineblock positioning
//http://www.vanseodesign.com/css/inline-blocks/
.grid-row-inline-block  {
    overflow: hidden;
    font-size: 0;
    line-height: 0;

     > [class*="grid-col"]  {
        font-size: 1rem;
        line-height: 1.5;
        display: inline-block;
        vertical-align: top;
    }
}

.grid-row {
    @extend %clearfix;
}

.grid-row-reversed {

    > [class*="grid-col"] {
        float: right;
    }
}

[class*="grid-col"] {
    float: left;

    // @include respond-max($mobile-max) {
    //  float: none;
    // }
}

.grid-col-33{
    width: 33.33333%;
}

.grid-col-50{
    width: 50%;
}



//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-20 {
    width: 20%;
}

.grid-col-25 {
    width: 25%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-40 {
    width: 40%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-35 {
    width: 35%;
}

//specify breakpoint inside components, breakpoints will differ based on layout
.grid-col-60 {
    width: 60%;
}

.grid-col-sidebar {
    width: 28%;
    line-height: 1.4;
    float: right;

    @include bp-max($bp-tablet) {
        width: 30%;
    }
}


.grid-col-content {
    width: 66%;
}
