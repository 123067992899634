//balch buttons
button,
.button {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    padding: .4rem 1rem;
    border: 1px solid transparent;
    font-size: .7rem;
    font-family: $font-family-accent;
    font-weight: $font-weight-light;
    transition: $base-animation;
    border-radius: $border-radius;
    outline: 0;

    &+& {
        margin-left: 1rem;
    }
}

.button--ghost {
    @include font-secondary-medium;

    border: 2px solid $color-brand-blue;
    color: $color-brand-blue;
    font-size: px-to-rem(18px);
    line-height: 1.1;
    padding: px-to-rem(10px) px-to-rem(40px);
    text-transform: uppercase;

    &:hover {
        color: $color-brand-white-abs;
        background-color: $color-brand-blue;
    }

    @include bp-max($bp-tablet-sm) {
        padding-top: px-to-rem(14px);
        padding-bottom: px-to-rem(14px);
    }
}

.button--ghost-reverse {
    @include font-secondary-medium;

    font-size: px-to-rem(18px);
    line-height: 1.1;
    padding: px-to-rem(10px) px-to-rem(40px);
    text-transform: uppercase;
    color: $color-brand-white-abs;
    background-color: $color-brand-blue;
    border: 2px solid $color-brand-blue;

    &:hover {
        background-color: $color-brand-white-abs;
        color: $color-brand-blue;
    }

    &.emailDisclaimer {
        color: $color-brand-black
    }

    &.emailDisclaimerCancel {
        background-color: $color-brand-blue;
        color: map-get($colors, white);
    }
}

.button--regent {
    @extend .button--ghost;

    border: 2px solid #FFF;
    color: #FFF;

    &:hover {
        border-color: $color-brand-blue;
    }
}

.button--white {
    @include font-accent-bold;

    border: 2px solid $color-brand-white-abs;
    color: $color-brand-white-abs;
    font-size: px-to-rem(18px);
    line-height: 1.1;
    padding: px-to-rem(10px) px-to-rem(40px);
    text-transform: uppercase;

    &:hover {
        color: $color-text-base;
        background-color: $color-brand-white-abs;
    }

    @include bp-max($bp-tablet-sm) {
        padding-top: px-to-rem(14px);
        padding-bottom: px-to-rem(14px);
    }
}

.button--black {
    color: #000000;
    border: 1px solid black;

    &:hover {
        color: $color-brand-white-abs;
        background-color: #000000;
    }
}

.button--card-grid {
    font-family: $font-family-secondary;
    padding: 12px 32px;
    margin: 0 auto;
    display: table;
    text-align: center;
    // Icon
    .bi {
        padding-left: 0.5em;
    }
}

.button--icon {
    border: 0 none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
}

.button--full {
    width: 100%;
}

.button--large {
    width: 100%;
    font-size: 1rem;
    text-align: center;
}

.button--green {
    background-color: $color-opaque-green-btn;
    color: $color-brand-white-abs;

    &:hover {
        background-color: $color-brand-green;
        color: $color-brand-white-abs;
    }
}

.button--load-more {
    display: block;
    margin: 0 auto;

    &+.rich-text {
        margin-top: $spacing-unit-block;
    }
}

.button--accordion-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background-color: $color-brand-white-abs;
    color: $color-brand-green;
    text-align: center;
    padding: 0;
    z-index: 10;
    @include size(35px);

    @include bi-icon-after($bi-interface-plus) {
        transition: transform .4s;
        transform-origin: 50% 50%;
        font-size: 0.85rem;
        font-weight: $font-weight-bold;
    }

    &.expanded {
        @include bi-icon-after($bi-interface-minus)
    }
}

//sevie landing overrides for expand all buttons
.services-accordion .button-expand-all {
    color: $color-opaque-gray;
    font-weight: $font-weight-bold;
    float: right;
    text-transform: uppercase;
    font-size: 0.9rem;

    @include bp-max($bp-tablet-sm) {
        display: none;
    }

    &:after {
        margin-left: px-to-rem(15px);
    }

    &:hover {
        color: $color-blue-dodger-blue;
    }

    &.expand {
        @include bi-icon-after($bi-interface-plus) {
            color: inherit;
        }
    }

    &.collapse {
        margin-left: 0;

        @include bi-icon-after($bi-interface-minus) {
            color: inherit;
        }
    }
}