///////////////////////////////////////////////////////////////////////////////
//
//  Variables
//
///////////////////////////////////////////////////////////////////////////////

/**

  TODO:
  - separate mixing
  - Separate icon classses

 */



$font-file-path-base: $bt-font-path;
$font-version: "";

@mixin font-face-icon-family($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
      src: url('#{$font-file-path-base}/#{$file-path}.eot');
      src: url('#{$font-file-path-base}/#{$file-path}.eot?#iefix') format('embedded-opentype'),
           url('#{$font-file-path-base}/#{$file-path}.woff') format('woff'),
           url('#{$font-file-path-base}/#{$file-path}.ttf') format('truetype'),
           url('#{$font-file-path-base}/#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}


$icon-font-family: "budicon";
$icon-font-fileName: "budicon";

@include font-face-icon-family($icon-font-family, $icon-font-fileName, normal, normal);



$icon-css-prefix:       bi !default;

//This font has some issue with vertical alignment
.#{$icon-css-prefix} {
    display: inline-block;
    font-family: $icon-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    vertical-align: (-100%*1/14);
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox
    width: 1em;
    font-size: 110%;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

  &:before {
    display: inline-block;
  }
}


@mixin bi-icon() {
  display: inline-block;
  font-family: $icon-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  vertical-align: (-100%*1/14);
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
  width: 1em;
  font-size: 110%;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
}

@mixin bi-icon-before($icon-type) {

  &:before {
    content: $icon-type;
    display: inline-block;
    font-family: $icon-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    vertical-align: (-100%*1/14);
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox
    width: 1em;
    font-size: 110%;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    @content;
  }
}

@mixin bi-icon-after($icon-type) {

  &:after {
    content: $icon-type;
    display: inline-block;
    font-family: $icon-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    vertical-align: (-100%*1/14);
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0); // ensures no half-pixel rendering in firefox
    width: 1em;
    font-size: 110%;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    @content;
  }
}


$bi-expand: "\eaec";
$bi-enlarge: "\eae9";
$bi-arrow-right: "\eb14";
$bi-bow-arrow-bottom: "\eac8";
$bi-arrow-bottom: "\eac5";
$bi-interface-direction: "\eae7";
$bi-interface-box-bottom: "\eac8";

$bi-com-phone:  "\e82f";
$bi-com-email: "\e81a";

$bi-doc-paper-line: "\e886";

$bi-user-single-a: "\ea03";

$bi-interface-plus: "\eb0a";
$bi-interface-minus: "\eb03";
$bi-location-pin-reg: '\e9b9';


.#{$icon-css-prefix}-animal-fish:before { content: '\e800'; } /* '' */
.#{$icon-css-prefix}-animal-fox:before { content: '\e801'; } /* '' */
.#{$icon-css-prefix}-animal-frog:before { content: '\e802'; } /* '' */
.#{$icon-css-prefix}-animal-goose:before { content: '\e803'; } /* '' */
.#{$icon-css-prefix}-animal-husky:before { content: '\e804'; } /* '' */
.#{$icon-css-prefix}-animal-lion:before { content: '\e805'; } /* '' */
.#{$icon-css-prefix}-animal-monkey:before { content: '\e806'; } /* '' */
.#{$icon-css-prefix}-animal-monkey-a:before { content: '\e807'; } /* '' */
.#{$icon-css-prefix}-animal-mouse:before { content: '\e808'; } /* '' */
.#{$icon-css-prefix}-animal-pig:before { content: '\e809'; } /* '' */
.#{$icon-css-prefix}-animal-pig-a:before { content: '\e80a'; } /* '' */
.#{$icon-css-prefix}-animal-sheep:before { content: '\e80b'; } /* '' */
.#{$icon-css-prefix}-animal-shrimp:before { content: '\e80c'; } /* '' */
.#{$icon-css-prefix}-animal-wolf:before { content: '\e80d'; } /* '' */
.#{$icon-css-prefix}-animal-bull:before { content: '\e80e'; } /* '' */
.#{$icon-css-prefix}-animal-cat:before { content: '\e80f'; } /* '' */
.#{$icon-css-prefix}-animal-chicken:before { content: '\e810'; } /* '' */
.#{$icon-css-prefix}-animal-cow:before { content: '\e811'; } /* '' */
.#{$icon-css-prefix}-animal-cow-a:before { content: '\e812'; } /* '' */
.#{$icon-css-prefix}-animal-dog:before { content: '\e813'; } /* '' */
.#{$icon-css-prefix}-com-bubble-dot-b:before { content: '\e814'; } /* '' */
.#{$icon-css-prefix}-com-bubble-line:before { content: '\e815'; } /* '' */
.#{$icon-css-prefix}-com-bubble-line-a:before { content: '\e816'; } /* '' */
.#{$icon-css-prefix}-com-bubble-line-b:before { content: '\e817'; } /* '' */
.#{$icon-css-prefix}-com-chat:before { content: '\e818'; } /* '' */
.#{$icon-css-prefix}-com-check-box:before { content: '\e819'; } /* '' */
.#{$icon-css-prefix}-com-email:before { content: '\e81a'; } /* '' */
.#{$icon-css-prefix}-com-email-cross:before { content: '\e81b'; } /* '' */
.#{$icon-css-prefix}-com-email-minus:before { content: '\e81c'; } /* '' */
.#{$icon-css-prefix}-com-email-o:before { content: '\e81d'; } /* '' */
.#{$icon-css-prefix}-com-email-plus:before { content: '\e81e'; } /* '' */
.#{$icon-css-prefix}-com-email-tick:before { content: '\e81f'; } /* '' */
.#{$icon-css-prefix}-com-envelope:before { content: '\e820'; } /* '' */
.#{$icon-css-prefix}-com-feather-pen:before { content: '\e821'; } /* '' */
.#{$icon-css-prefix}-com-fountain-pen:before { content: '\e822'; } /* '' */
.#{$icon-css-prefix}-com-group-bubble:before { content: '\e823'; } /* '' */
.#{$icon-css-prefix}-com-group-bubble-a:before { content: '\e824'; } /* '' */
.#{$icon-css-prefix}-com-group-bubble-b:before { content: '\e825'; } /* '' */
.#{$icon-css-prefix}-com-group-bubble-c:before { content: '\e826'; } /* '' */
.#{$icon-css-prefix}-com-group-bubble-d:before { content: '\e827'; } /* '' */
.#{$icon-css-prefix}-com-help:before { content: '\e828'; } /* '' */
.#{$icon-css-prefix}-com-help-a:before { content: '\e829'; } /* '' */
.#{$icon-css-prefix}-com-megaphone:before { content: '\e82a'; } /* '' */
.#{$icon-css-prefix}-com-megaphone-a:before { content: '\e82b'; } /* '' */
.#{$icon-css-prefix}-com-mic:before { content: '\e82c'; } /* '' */
.#{$icon-css-prefix}-com-mic-mute:before { content: '\e82d'; } /* '' */
.#{$icon-css-prefix}-com-paperplane:before { content: '\e82e'; } /* '' */
.#{$icon-css-prefix}-com-phone:before { content: '\e82f'; } /* '' */
.#{$icon-css-prefix}-com-phone-a:before { content: '\e830'; } /* '' */
.#{$icon-css-prefix}-com-pictures:before { content: '\e831'; } /* '' */
.#{$icon-css-prefix}-com-voicemail:before { content: '\e832'; } /* '' */
.#{$icon-css-prefix}-com-bell:before { content: '\e833'; } /* '' */
.#{$icon-css-prefix}-com-bell-a:before { content: '\e834'; } /* '' */
.#{$icon-css-prefix}-com-broadcast:before { content: '\e835'; } /* '' */
.#{$icon-css-prefix}-com-broadcast-a:before { content: '\e836'; } /* '' */
.#{$icon-css-prefix}-com-bubble:before { content: '\e837'; } /* '' */
.#{$icon-css-prefix}-com-bubble-a:before { content: '\e838'; } /* '' */
.#{$icon-css-prefix}-com-bubble-b:before { content: '\e839'; } /* '' */
.#{$icon-css-prefix}-com-bubble-dot:before { content: '\e83a'; } /* '' */
.#{$icon-css-prefix}-com-bubble-dot-a:before { content: '\e83b'; } /* '' */
.#{$icon-css-prefix}-doc-analytic-blank:before { content: '\e83c'; } /* '' */
.#{$icon-css-prefix}-doc-analytic-line:before { content: '\e83d'; } /* '' */
.#{$icon-css-prefix}-doc-analytic-line-a:before { content: '\e83e'; } /* '' */
.#{$icon-css-prefix}-doc-article:before { content: '\e83f'; } /* '' */
.#{$icon-css-prefix}-doc-article-a:before { content: '\e840'; } /* '' */
.#{$icon-css-prefix}-doc-article-b:before { content: '\e841'; } /* '' */
.#{$icon-css-prefix}-doc-attachment:before { content: '\e842'; } /* '' */
.#{$icon-css-prefix}-doc-attachment-a:before { content: '\e843'; } /* '' */
.#{$icon-css-prefix}-doc-binder-blank:before { content: '\e844'; } /* '' */
.#{$icon-css-prefix}-doc-binder-four:before { content: '\e845'; } /* '' */
.#{$icon-css-prefix}-doc-binder-one:before { content: '\e846'; } /* '' */
.#{$icon-css-prefix}-doc-binder-three:before { content: '\e847'; } /* '' */
.#{$icon-css-prefix}-doc-binder-two:before { content: '\e848'; } /* '' */
.#{$icon-css-prefix}-doc-binder-zero:before { content: '\e849'; } /* '' */
.#{$icon-css-prefix}-doc-book:before { content: '\e84a'; } /* '' */
.#{$icon-css-prefix}-doc-book-a:before { content: '\e84b'; } /* '' */
.#{$icon-css-prefix}-doc-book-b:before { content: '\e84c'; } /* '' */
.#{$icon-css-prefix}-doc-book-c:before { content: '\e84d'; } /* '' */
.#{$icon-css-prefix}-doc-book-d:before { content: '\e84e'; } /* '' */
.#{$icon-css-prefix}-doc-bookmark:before { content: '\e84f'; } /* '' */
.#{$icon-css-prefix}-doc-book-mark:before { content: '\e850'; } /* '' */
.#{$icon-css-prefix}-doc-box:before { content: '\e851'; } /* '' */
.#{$icon-css-prefix}-doc-briefcase:before { content: '\e852'; } /* '' */
.#{$icon-css-prefix}-doc-briefcase-a:before { content: '\e853'; } /* '' */
.#{$icon-css-prefix}-doc-briefcase-b:before { content: '\e854'; } /* '' */
.#{$icon-css-prefix}-doc-briefcase-c:before { content: '\e855'; } /* '' */
.#{$icon-css-prefix}-doc-briefcase-d:before { content: '\e856'; } /* '' */
.#{$icon-css-prefix}-doc-compose:before { content: '\e857'; } /* '' */
.#{$icon-css-prefix}-doc-compose-a:before { content: '\e858'; } /* '' */
.#{$icon-css-prefix}-doc-documents:before { content: '\e859'; } /* '' */
.#{$icon-css-prefix}-doc-documents-minus:before { content: '\e85a'; } /* '' */
.#{$icon-css-prefix}-doc-documents-plus:before { content: '\e85b'; } /* '' */
.#{$icon-css-prefix}-doc-download:before { content: '\e85c'; } /* '' */
.#{$icon-css-prefix}-doc-drawer:before { content: '\e85d'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-a:before { content: '\e85e'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-b:before { content: '\e85f'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-blank:before { content: '\e860'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-download:before { content: '\e861'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-line:before { content: '\e862'; } /* '' */
.#{$icon-css-prefix}-doc-drawer-upload:before { content: '\e863'; } /* '' */
.#{$icon-css-prefix}-doc-envelope:before { content: '\e864'; } /* '' */
.#{$icon-css-prefix}-doc-file-blank:before { content: '\e865'; } /* '' */
.#{$icon-css-prefix}-doc-file-blank-a:before { content: '\e866'; } /* '' */
.#{$icon-css-prefix}-doc-file-cross:before { content: '\e867'; } /* '' */
.#{$icon-css-prefix}-doc-file-cross-a:before { content: '\e868'; } /* '' */
.#{$icon-css-prefix}-doc-file-line:before { content: '\e869'; } /* '' */
.#{$icon-css-prefix}-doc-file-line-a:before { content: '\e86a'; } /* '' */
.#{$icon-css-prefix}-doc-file-minus:before { content: '\e86b'; } /* '' */
.#{$icon-css-prefix}-doc-file-minus-a:before { content: '\e86c'; } /* '' */
.#{$icon-css-prefix}-doc-file-plus:before { content: '\e86d'; } /* '' */
.#{$icon-css-prefix}-doc-file-plus-a:before { content: '\e86e'; } /* '' */
.#{$icon-css-prefix}-doc-file-stack:before { content: '\e86f'; } /* '' */
.#{$icon-css-prefix}-doc-file-tick:before { content: '\e870'; } /* '' */
.#{$icon-css-prefix}-doc-file-tick-a:before { content: '\e871'; } /* '' */
.#{$icon-css-prefix}-doc-folder:before { content: '\e872'; } /* '' */
.#{$icon-css-prefix}-doc-folder-a:before { content: '\e873'; } /* '' */
.#{$icon-css-prefix}-doc-folder-check-a:before { content: '\e874'; } /* '' */
.#{$icon-css-prefix}-doc-folder-cross:before { content: '\e875'; } /* '' */
.#{$icon-css-prefix}-doc-folder-cross-a:before { content: '\e876'; } /* '' */
.#{$icon-css-prefix}-doc-folder-minus:before { content: '\e877'; } /* '' */
.#{$icon-css-prefix}-doc-folder-minus-a:before { content: '\e878'; } /* '' */
.#{$icon-css-prefix}-doc-folder-plus:before { content: '\e879'; } /* '' */
.#{$icon-css-prefix}-doc-folder-plus-a:before { content: '\e87a'; } /* '' */
.#{$icon-css-prefix}-doc-folder-s:before { content: '\e87b'; } /* '' */
.#{$icon-css-prefix}-doc-folder-sync:before { content: '\e87c'; } /* '' */
.#{$icon-css-prefix}-doc-folder-tick:before { content: '\e87d'; } /* '' */
.#{$icon-css-prefix}-doc-list:before { content: '\e87e'; } /* '' */
.#{$icon-css-prefix}-doc-news:before { content: '\e87f'; } /* '' */
.#{$icon-css-prefix}-doc-newspaper:before { content: '\e880'; } /* '' */
.#{$icon-css-prefix}-doc-newspaper-a:before { content: '\e881'; } /* '' */
.#{$icon-css-prefix}-doc-newspaper-b:before { content: '\e882'; } /* '' */
.#{$icon-css-prefix}-doc-notebook:before { content: '\e883'; } /* '' */
.#{$icon-css-prefix}-doc-notebook-a:before { content: '\e884'; } /* '' */
.#{$icon-css-prefix}-doc-note-pen:before { content: '\e885'; } /* '' */
.#{$icon-css-prefix}-doc-paper-line:before { content: '\e886'; } /* '' */
.#{$icon-css-prefix}-doc-papers:before { content: '\e887'; } /* '' */
.#{$icon-css-prefix}-doc-paper-stack:before { content: '\e888'; } /* '' */
.#{$icon-css-prefix}-doc-photo-stack:before { content: '\e889'; } /* '' */
.#{$icon-css-prefix}-doc-pie:before { content: '\e88a'; } /* '' */
.#{$icon-css-prefix}-doc-pie-a:before { content: '\e88b'; } /* '' */
.#{$icon-css-prefix}-doc-pie-b:before { content: '\e88c'; } /* '' */
.#{$icon-css-prefix}-doc-pin:before { content: '\e88d'; } /* '' */
.#{$icon-css-prefix}-doc-pin-a:before { content: '\e88e'; } /* '' */
.#{$icon-css-prefix}-doc-print:before { content: '\e88f'; } /* '' */
.#{$icon-css-prefix}-doc-print-a:before { content: '\e890'; } /* '' */
.#{$icon-css-prefix}-doc-profile:before { content: '\e891'; } /* '' */
.#{$icon-css-prefix}-doc-profile-a:before { content: '\e892'; } /* '' */
.#{$icon-css-prefix}-doc-upload:before { content: '\e893'; } /* '' */
.#{$icon-css-prefix}-doc-wboard-line:before { content: '\e894'; } /* '' */
.#{$icon-css-prefix}-doc-wboard-tick:before { content: '\e895'; } /* '' */
.#{$icon-css-prefix}-media-glass:before { content: '\e896'; } /* '' */
.#{$icon-css-prefix}-media-glass-a:before { content: '\e897'; } /* '' */
.#{$icon-css-prefix}-media-image:before { content: '\e898'; } /* '' */
.#{$icon-css-prefix}-media-image-a:before { content: '\e899'; } /* '' */
.#{$icon-css-prefix}-media-image-b:before { content: '\e89a'; } /* '' */
.#{$icon-css-prefix}-media-image-c:before { content: '\e89b'; } /* '' */
.#{$icon-css-prefix}-media-image-d:before { content: '\e89c'; } /* '' */
.#{$icon-css-prefix}-media-negative:before { content: '\e89d'; } /* '' */
.#{$icon-css-prefix}-media-negative-a:before { content: '\e89e'; } /* '' */
.#{$icon-css-prefix}-media-video:before { content: '\e89f'; } /* '' */
.#{$icon-css-prefix}-media-video-a:before { content: '\e8a0'; } /* '' */
.#{$icon-css-prefix}-media-video-clip:before { content: '\e8a1'; } /* '' */
.#{$icon-css-prefix}-media-video-r:before { content: '\e8a2'; } /* '' */
.#{$icon-css-prefix}-business-tie:before { content: '\e8a3'; } /* '' */
.#{$icon-css-prefix}-business-tie-a:before { content: '\e8a4'; } /* '' */
.#{$icon-css-prefix}-business-tie-b:before { content: '\e8a5'; } /* '' */
.#{$icon-css-prefix}-doc-analytic-bar:before { content: '\e8a6'; } /* '' */
.#{$icon-css-prefix}-ecommerce-basket:before { content: '\e8a7'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bill:before { content: '\e8a8'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bill-a:before { content: '\e8a9'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bill-b:before { content: '\e8aa'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bowtie:before { content: '\e8ab'; } /* '' */
.#{$icon-css-prefix}-ecommerce-cash:before { content: '\e8ac'; } /* '' */
.#{$icon-css-prefix}-ecommerce-cash-a:before { content: '\e8ad'; } /* '' */
.#{$icon-css-prefix}-ecommerce-coins:before { content: '\e8ae'; } /* '' */
.#{$icon-css-prefix}-ecommerce-coins-a:before { content: '\e8af'; } /* '' */
.#{$icon-css-prefix}-ecommerce-creditcard:before { content: '\e8b0'; } /* '' */
.#{$icon-css-prefix}-ecommerce-creditcard-b:before { content: '\e8b1'; } /* '' */
.#{$icon-css-prefix}-ecommerce-creditcard-c:before { content: '\e8b2'; } /* '' */
.#{$icon-css-prefix}-ecommerce-creditcard-d:before { content: '\e8b3'; } /* '' */
.#{$icon-css-prefix}-ecommerce-diamon:before { content: '\e8b4'; } /* '' */
.#{$icon-css-prefix}-ecommerce-digital-download:before { content: '\e8b5'; } /* '' */
.#{$icon-css-prefix}-ecommerce-digital-upload:before { content: '\e8b6'; } /* '' */
.#{$icon-css-prefix}-ecommerce-dollar:before { content: '\e8b7'; } /* '' */
.#{$icon-css-prefix}-ecommerce-euro:before { content: '\e8b8'; } /* '' */
.#{$icon-css-prefix}-ecommerce-gift:before { content: '\e8b9'; } /* '' */
.#{$icon-css-prefix}-ecommerce-hanger:before { content: '\e8ba'; } /* '' */
.#{$icon-css-prefix}-ecommerce-jeans:before { content: '\e8bb'; } /* '' */
.#{$icon-css-prefix}-ecommerce-lipstick:before { content: '\e8bc'; } /* '' */
.#{$icon-css-prefix}-ecommerce-market:before { content: '\e8bd'; } /* '' */
.#{$icon-css-prefix}-ecommerce-mastercard:before { content: '\e8be'; } /* '' */
.#{$icon-css-prefix}-ecommerce-pack:before { content: '\e8bf'; } /* '' */
.#{$icon-css-prefix}-ecommerce-pack-a:before { content: '\e8c0'; } /* '' */
.#{$icon-css-prefix}-ecommerce-pounds:before { content: '\e8c1'; } /* '' */
.#{$icon-css-prefix}-ecommerce-ring:before { content: '\e8c2'; } /* '' */
.#{$icon-css-prefix}-ecommerce-safebox:before { content: '\e8c3'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shirt:before { content: '\e8c4'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shirt-a:before { content: '\e8c5'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shoes:before { content: '\e8c6'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shop:before { content: '\e8c7'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart:before { content: '\e8c8'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-a:before { content: '\e8c9'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-a-download:before { content: '\e8ca'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-a-fill:before { content: '\e8cb'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-c:before { content: '\e8cc'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-d:before { content: '\e8cd'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-download:before { content: '\e8ce'; } /* '' */
.#{$icon-css-prefix}-ecommerce-shopcart-fill:before { content: '\e8cf'; } /* '' */
.#{$icon-css-prefix}-ecommerce-short:before { content: '\e8d0'; } /* '' */
.#{$icon-css-prefix}-ecommerce-suitcase:before { content: '\e8d1'; } /* '' */
.#{$icon-css-prefix}-ecommerce-suitcase-a:before { content: '\e8d2'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag:before { content: '\e8d3'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-a:before { content: '\e8d4'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-a-s:before { content: '\e8d5'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-b:before { content: '\e8d6'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-b-s:before { content: '\e8d7'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-c:before { content: '\e8d8'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-cross:before { content: '\e8d9'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-c-s:before { content: '\e8da'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-minus:before { content: '\e8db'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-plus:before { content: '\e8dc'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tag-tick:before { content: '\e8dd'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tracking:before { content: '\e8de'; } /* '' */
.#{$icon-css-prefix}-ecommerce-tshirt:before { content: '\e8df'; } /* '' */
.#{$icon-css-prefix}-ecommerce-wallet:before { content: '\e8e0'; } /* '' */
.#{$icon-css-prefix}-ecommerce-wallet-a:before { content: '\e8e1'; } /* '' */
.#{$icon-css-prefix}-ecommerce-woman-bag:before { content: '\e8e2'; } /* '' */
.#{$icon-css-prefix}-ecommerce-yen:before { content: '\e8e3'; } /* '' */
.#{$icon-css-prefix}-ecommerce-backpack:before { content: '\e8e4'; } /* '' */
.#{$icon-css-prefix}-ecommerce-backpack-a:before { content: '\e8e5'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bag:before { content: '\e8e6'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bag-a:before { content: '\e8e7'; } /* '' */
.#{$icon-css-prefix}-ecommerce-bag-b:before { content: '\e8e8'; } /* '' */
.#{$icon-css-prefix}-editorial-pencil-s:before { content: '\e8e9'; } /* '' */
.#{$icon-css-prefix}-editorial-pen-s:before { content: '\e8ea'; } /* '' */
.#{$icon-css-prefix}-editorial-redo:before { content: '\e8eb'; } /* '' */
.#{$icon-css-prefix}-editorial-right-align:before { content: '\e8ec'; } /* '' */
.#{$icon-css-prefix}-editorial-trash:before { content: '\e8ed'; } /* '' */
.#{$icon-css-prefix}-editorial-trash-a:before { content: '\e8ee'; } /* '' */
.#{$icon-css-prefix}-editorial-trash-a-l:before { content: '\e8ef'; } /* '' */
.#{$icon-css-prefix}-editorial-trash-l:before { content: '\e8f0'; } /* '' */
.#{$icon-css-prefix}-editorial-undo:before { content: '\e8f1'; } /* '' */
.#{$icon-css-prefix}-editorial-write:before { content: '\e8f2'; } /* '' */
.#{$icon-css-prefix}-editorial-write-s:before { content: '\e8f3'; } /* '' */
.#{$icon-css-prefix}-editorial-ascending:before { content: '\e8f4'; } /* '' */
.#{$icon-css-prefix}-editorial-bookmark:before { content: '\e8f5'; } /* '' */
.#{$icon-css-prefix}-editorial-bookmark-a:before { content: '\e8f6'; } /* '' */
.#{$icon-css-prefix}-editorial-brush:before { content: '\e8f7'; } /* '' */
.#{$icon-css-prefix}-editorial-center-align:before { content: '\e8f8'; } /* '' */
.#{$icon-css-prefix}-editorial-compose:before { content: '\e8f9'; } /* '' */
.#{$icon-css-prefix}-editorial-descending:before { content: '\e8fa'; } /* '' */
.#{$icon-css-prefix}-editorial-left-align:before { content: '\e8fb'; } /* '' */
.#{$icon-css-prefix}-editorial-pen:before { content: '\e8fc'; } /* '' */
.#{$icon-css-prefix}-editorial-pencil:before { content: '\e8fd'; } /* '' */
.#{$icon-css-prefix}-editorial-pencil-a:before { content: '\e8fe'; } /* '' */
.#{$icon-css-prefix}-editorial-pencil-a-s:before { content: '\e8ff'; } /* '' */
.#{$icon-css-prefix}-building-drawer-a:before { content: '\e900'; } /* '' */
.#{$icon-css-prefix}-building-house:before { content: '\e901'; } /* '' */
.#{$icon-css-prefix}-building-house-a:before { content: '\e902'; } /* '' */
.#{$icon-css-prefix}-building-museum:before { content: '\e903'; } /* '' */
.#{$icon-css-prefix}-building-office:before { content: '\e904'; } /* '' */
.#{$icon-css-prefix}-building-office-a:before { content: '\e905'; } /* '' */
.#{$icon-css-prefix}-building-office-b:before { content: '\e906'; } /* '' */
.#{$icon-css-prefix}-building-sofa:before { content: '\e907'; } /* '' */
.#{$icon-css-prefix}-building-sofa-a:before { content: '\e908'; } /* '' */
.#{$icon-css-prefix}-building-sofa-b:before { content: '\e909'; } /* '' */
.#{$icon-css-prefix}-building-sofa-twin:before { content: '\e90a'; } /* '' */
.#{$icon-css-prefix}-building-sofa-twin-a:before { content: '\e90b'; } /* '' */
.#{$icon-css-prefix}-building-table-lamp:before { content: '\e90c'; } /* '' */
.#{$icon-css-prefix}-building-table-lamp-a:before { content: '\e90d'; } /* '' */
.#{$icon-css-prefix}-building-tower:before { content: '\e90e'; } /* '' */
.#{$icon-css-prefix}-building-tree:before { content: '\e90f'; } /* '' */
.#{$icon-css-prefix}-environment-flower:before { content: '\e910'; } /* '' */
.#{$icon-css-prefix}-environment-flower-a:before { content: '\e911'; } /* '' */
.#{$icon-css-prefix}-environment-flower-b:before { content: '\e912'; } /* '' */
.#{$icon-css-prefix}-environment-leaf:before { content: '\e913'; } /* '' */
.#{$icon-css-prefix}-environment-leaf-a:before { content: '\e914'; } /* '' */
.#{$icon-css-prefix}-environment-mountain:before { content: '\e915'; } /* '' */
.#{$icon-css-prefix}-environment-mountain-a:before { content: '\e916'; } /* '' */
.#{$icon-css-prefix}-environment-no-smoke:before { content: '\e917'; } /* '' */
.#{$icon-css-prefix}-environment-plant:before { content: '\e918'; } /* '' */
.#{$icon-css-prefix}-environment-sign:before { content: '\e919'; } /* '' */
.#{$icon-css-prefix}-environment-smoke:before { content: '\e91a'; } /* '' */
.#{$icon-css-prefix}-environment-tree:before { content: '\e91b'; } /* '' */
.#{$icon-css-prefix}-building-apartment:before { content: '\e91c'; } /* '' */
.#{$icon-css-prefix}-building-bed:before { content: '\e91d'; } /* '' */
.#{$icon-css-prefix}-building-bulb:before { content: '\e91e'; } /* '' */
.#{$icon-css-prefix}-building-cabinet:before { content: '\e91f'; } /* '' */
.#{$icon-css-prefix}-building-desk:before { content: '\e920'; } /* '' */
.#{$icon-css-prefix}-building-desk-a:before { content: '\e921'; } /* '' */
.#{$icon-css-prefix}-building-desk-b:before { content: '\e922'; } /* '' */
.#{$icon-css-prefix}-building-desk-c:before { content: '\e923'; } /* '' */
.#{$icon-css-prefix}-building-door:before { content: '\e924'; } /* '' */
.#{$icon-css-prefix}-building-drawer:before { content: '\e925'; } /* '' */
.#{$icon-css-prefix}-beverage-cocktail-a:before { content: '\e926'; } /* '' */
.#{$icon-css-prefix}-beverage-coffee:before { content: '\e927'; } /* '' */
.#{$icon-css-prefix}-beverage-coffee-a:before { content: '\e928'; } /* '' */
.#{$icon-css-prefix}-beverage-coffee-b:before { content: '\e929'; } /* '' */
.#{$icon-css-prefix}-beverage-coffee-cup:before { content: '\e92a'; } /* '' */
.#{$icon-css-prefix}-beverage-coffee-cup-a:before { content: '\e92b'; } /* '' */
.#{$icon-css-prefix}-beverage-cokctail:before { content: '\e92c'; } /* '' */
.#{$icon-css-prefix}-beverage-cup-straw:before { content: '\e92d'; } /* '' */
.#{$icon-css-prefix}-beverage-empty-glass:before { content: '\e92e'; } /* '' */
.#{$icon-css-prefix}-beverage-milk:before { content: '\e92f'; } /* '' */
.#{$icon-css-prefix}-beverage-milk-a:before { content: '\e930'; } /* '' */
.#{$icon-css-prefix}-beverage-tea:before { content: '\e931'; } /* '' */
.#{$icon-css-prefix}-beverage-tea-a:before { content: '\e932'; } /* '' */
.#{$icon-css-prefix}-beverage-tea-cup:before { content: '\e933'; } /* '' */
.#{$icon-css-prefix}-beverage-tea-cup-a:before { content: '\e934'; } /* '' */
.#{$icon-css-prefix}-beverage-water:before { content: '\e935'; } /* '' */
.#{$icon-css-prefix}-beverage-water-a:before { content: '\e936'; } /* '' */
.#{$icon-css-prefix}-beverage-water-glass:before { content: '\e937'; } /* '' */
.#{$icon-css-prefix}-beverage-wine:before { content: '\e938'; } /* '' */
.#{$icon-css-prefix}-food-apple:before { content: '\e939'; } /* '' */
.#{$icon-css-prefix}-food-bowl:before { content: '\e93a'; } /* '' */
.#{$icon-css-prefix}-food-cherry:before { content: '\e93b'; } /* '' */
.#{$icon-css-prefix}-food-cherry-a:before { content: '\e93c'; } /* '' */
.#{$icon-css-prefix}-food-chinese-food:before { content: '\e93d'; } /* '' */
.#{$icon-css-prefix}-food-chinese-food-a:before { content: '\e93e'; } /* '' */
.#{$icon-css-prefix}-food-chinese-food-b:before { content: '\e93f'; } /* '' */
.#{$icon-css-prefix}-food-drumstick:before { content: '\e940'; } /* '' */
.#{$icon-css-prefix}-food-egg:before { content: '\e941'; } /* '' */
.#{$icon-css-prefix}-food-grape:before { content: '\e942'; } /* '' */
.#{$icon-css-prefix}-food-hamburger:before { content: '\e943'; } /* '' */
.#{$icon-css-prefix}-food-hamburger-a:before { content: '\e944'; } /* '' */
.#{$icon-css-prefix}-food-ice-cream:before { content: '\e945'; } /* '' */
.#{$icon-css-prefix}-food-ice-cream-a:before { content: '\e946'; } /* '' */
.#{$icon-css-prefix}-food-ice-cream-b:before { content: '\e947'; } /* '' */
.#{$icon-css-prefix}-food-ice-cream-c:before { content: '\e948'; } /* '' */
.#{$icon-css-prefix}-food-melon:before { content: '\e949'; } /* '' */
.#{$icon-css-prefix}-food-noodle:before { content: '\e94a'; } /* '' */
.#{$icon-css-prefix}-food-noodle-a:before { content: '\e94b'; } /* '' */
.#{$icon-css-prefix}-food-onigiri:before { content: '\e94c'; } /* '' */
.#{$icon-css-prefix}-food-onigiri-a:before { content: '\e94d'; } /* '' */
.#{$icon-css-prefix}-food-white-bread:before { content: '\e94e'; } /* '' */
.#{$icon-css-prefix}-kitchen-fork-knife:before { content: '\e94f'; } /* '' */
.#{$icon-css-prefix}-kitchen-fork-knife-a:before { content: '\e950'; } /* '' */
.#{$icon-css-prefix}-kitchen-fridge:before { content: '\e951'; } /* '' */
.#{$icon-css-prefix}-kitchen-grill:before { content: '\e952'; } /* '' */
.#{$icon-css-prefix}-kitchen-heat:before { content: '\e953'; } /* '' */
.#{$icon-css-prefix}-kitchen-heat-a:before { content: '\e954'; } /* '' */
.#{$icon-css-prefix}-kitchen-hood:before { content: '\e955'; } /* '' */
.#{$icon-css-prefix}-kitchen-hood-a:before { content: '\e956'; } /* '' */
.#{$icon-css-prefix}-kitchen-jam:before { content: '\e957'; } /* '' */
.#{$icon-css-prefix}-kitchen-ketchup:before { content: '\e958'; } /* '' */
.#{$icon-css-prefix}-kitchen-leaf:before { content: '\e959'; } /* '' */
.#{$icon-css-prefix}-kitchen-microwave:before { content: '\e95a'; } /* '' */
.#{$icon-css-prefix}-kitchen-pepper:before { content: '\e95b'; } /* '' */
.#{$icon-css-prefix}-kitchen-plate:before { content: '\e95c'; } /* '' */
.#{$icon-css-prefix}-kitchen-recipe-book:before { content: '\e95d'; } /* '' */
.#{$icon-css-prefix}-kitchen-salt:before { content: '\e95e'; } /* '' */
.#{$icon-css-prefix}-kitchen-spatula:before { content: '\e95f'; } /* '' */
.#{$icon-css-prefix}-kitchen-spoon-fork:before { content: '\e960'; } /* '' */
.#{$icon-css-prefix}-kitchen-spoon-fork-a:before { content: '\e961'; } /* '' */
.#{$icon-css-prefix}-kitchen-tissue:before { content: '\e962'; } /* '' */
.#{$icon-css-prefix}-kitchen-water:before { content: '\e963'; } /* '' */
.#{$icon-css-prefix}-beverage-alt-glass:before { content: '\e964'; } /* '' */
.#{$icon-css-prefix}-beverage-alt-glass-a:before { content: '\e965'; } /* '' */
.#{$icon-css-prefix}-beverage-alt-glass-b:before { content: '\e966'; } /* '' */
.#{$icon-css-prefix}-beverage-alt-glass-c:before { content: '\e967'; } /* '' */
.#{$icon-css-prefix}-beverage-beer:before { content: '\e968'; } /* '' */
.#{$icon-css-prefix}-beverage-chinese-tea:before { content: '\e969'; } /* '' */
.#{$icon-css-prefix}-beverage-chinese-tea-a:before { content: '\e96a'; } /* '' */
.#{$icon-css-prefix}-beverage-chinese-tea-a-s:before { content: '\e96b'; } /* '' */
.#{$icon-css-prefix}-beverage-chinese-tea-s:before { content: '\e96c'; } /* '' */
.#{$icon-css-prefix}-setting-switch:before { content: '\e96d'; } /* '' */
.#{$icon-css-prefix}-setting-switch-a:before { content: '\e96e'; } /* '' */
.#{$icon-css-prefix}-setting-wrench:before { content: '\e96f'; } /* '' */
.#{$icon-css-prefix}-setting-wrench-a:before { content: '\e970'; } /* '' */
.#{$icon-css-prefix}-setting-airplane:before { content: '\e971'; } /* '' */
.#{$icon-css-prefix}-setting-component:before { content: '\e972'; } /* '' */
.#{$icon-css-prefix}-setting-eq:before { content: '\e973'; } /* '' */
.#{$icon-css-prefix}-setting-eq-a:before { content: '\e974'; } /* '' */
.#{$icon-css-prefix}-setting-gear:before { content: '\e975'; } /* '' */
.#{$icon-css-prefix}-setting-gear-a:before { content: '\e976'; } /* '' */
.#{$icon-css-prefix}-setting-gear-b:before { content: '\e977'; } /* '' */
.#{$icon-css-prefix}-setting-hotspot:before { content: '\e978'; } /* '' */
.#{$icon-css-prefix}-setting-notification:before { content: '\e979'; } /* '' */
.#{$icon-css-prefix}-sport-tape:before { content: '\e97a'; } /* '' */
.#{$icon-css-prefix}-sport-tennisball:before { content: '\e97b'; } /* '' */
.#{$icon-css-prefix}-sport-time:before { content: '\e97c'; } /* '' */
.#{$icon-css-prefix}-sport-trophy:before { content: '\e97d'; } /* '' */
.#{$icon-css-prefix}-sport-trophy-a:before { content: '\e97e'; } /* '' */
.#{$icon-css-prefix}-sport-tv:before { content: '\e97f'; } /* '' */
.#{$icon-css-prefix}-sport-tv-a:before { content: '\e980'; } /* '' */
.#{$icon-css-prefix}-medicine-heart:before { content: '\e981'; } /* '' */
.#{$icon-css-prefix}-medicine-lab:before { content: '\e982'; } /* '' */
.#{$icon-css-prefix}-medicine-lab-a:before { content: '\e983'; } /* '' */
.#{$icon-css-prefix}-sport-alt-badge:before { content: '\e984'; } /* '' */
.#{$icon-css-prefix}-sport-alt-badge-a:before { content: '\e985'; } /* '' */
.#{$icon-css-prefix}-sport-badge:before { content: '\e986'; } /* '' */
.#{$icon-css-prefix}-sport-badge-a:before { content: '\e987'; } /* '' */
.#{$icon-css-prefix}-sport-badge-b:before { content: '\e988'; } /* '' */
.#{$icon-css-prefix}-sport-badge-c:before { content: '\e989'; } /* '' */
.#{$icon-css-prefix}-sport-badge-one:before { content: '\e98a'; } /* '' */
.#{$icon-css-prefix}-sport-badge-one-a:before { content: '\e98b'; } /* '' */
.#{$icon-css-prefix}-sport-baseball:before { content: '\e98c'; } /* '' */
.#{$icon-css-prefix}-sport-basketball:before { content: '\e98d'; } /* '' */
.#{$icon-css-prefix}-sport-basketball-a:before { content: '\e98e'; } /* '' */
.#{$icon-css-prefix}-sport-bottle:before { content: '\e98f'; } /* '' */
.#{$icon-css-prefix}-sport-cards:before { content: '\e990'; } /* '' */
.#{$icon-css-prefix}-sport-dumbell:before { content: '\e991'; } /* '' */
.#{$icon-css-prefix}-sport-dumbell-a:before { content: '\e992'; } /* '' */
.#{$icon-css-prefix}-sport-flag:before { content: '\e993'; } /* '' */
.#{$icon-css-prefix}-sport-flag-a:before { content: '\e994'; } /* '' */
.#{$icon-css-prefix}-sport-football:before { content: '\e995'; } /* '' */
.#{$icon-css-prefix}-sport-medic:before { content: '\e996'; } /* '' */
.#{$icon-css-prefix}-sport-mic:before { content: '\e997'; } /* '' */
.#{$icon-css-prefix}-sport-puzzle:before { content: '\e998'; } /* '' */
.#{$icon-css-prefix}-sport-stadium:before { content: '\e999'; } /* '' */
.#{$icon-css-prefix}-sport-stadium-a:before { content: '\e99a'; } /* '' */
.#{$icon-css-prefix}-time-alarm:before { content: '\e99b'; } /* '' */
.#{$icon-css-prefix}-time-alt-wall-clock:before { content: '\e99c'; } /* '' */
.#{$icon-css-prefix}-time-calendar:before { content: '\e99d'; } /* '' */
.#{$icon-css-prefix}-time-calendar-a:before { content: '\e99e'; } /* '' */
.#{$icon-css-prefix}-time-calendar-b:before { content: '\e99f'; } /* '' */
.#{$icon-css-prefix}-time-clock:before { content: '\e9a0'; } /* '' */
.#{$icon-css-prefix}-time-clock-a:before { content: '\e9a1'; } /* '' */
.#{$icon-css-prefix}-time-clock-b:before { content: '\e9a2'; } /* '' */
.#{$icon-css-prefix}-time-clock-c:before { content: '\e9a3'; } /* '' */
.#{$icon-css-prefix}-time-hour-glass:before { content: '\e9a4'; } /* '' */
.#{$icon-css-prefix}-time-speed-meter:before { content: '\e9a5'; } /* '' */
.#{$icon-css-prefix}-time-stopwatch:before { content: '\e9a6'; } /* '' */
.#{$icon-css-prefix}-time-stopwatch-a:before { content: '\e9a7'; } /* '' */
.#{$icon-css-prefix}-time-stopwatch-b:before { content: '\e9a8'; } /* '' */
.#{$icon-css-prefix}-time-timer:before { content: '\e9a9'; } /* '' */
.#{$icon-css-prefix}-time-wall-clock:before { content: '\e9aa'; } /* '' */
.#{$icon-css-prefix}-time-watches:before { content: '\e9ab'; } /* '' */
.#{$icon-css-prefix}-time-watches-a:before { content: '\e9ac'; } /* '' */
.#{$icon-css-prefix}-time-watches-b:before { content: '\e9ad'; } /* '' */
.#{$icon-css-prefix}-location-alt-pin:before { content: '\e9ae'; } /* '' */
.#{$icon-css-prefix}-location-avenue:before { content: '\e9af'; } /* '' */
.#{$icon-css-prefix}-location-compass:before { content: '\e9b0'; } /* '' */
.#{$icon-css-prefix}-location-map:before { content: '\e9b1'; } /* '' */
.#{$icon-css-prefix}-location-pin:before { content: '\e9b2'; } /* '' */
.#{$icon-css-prefix}-location-pin-blank:before { content: '\e9b3'; } /* '' */
.#{$icon-css-prefix}-location-pin-check:before { content: '\e9b4'; } /* '' */
.#{$icon-css-prefix}-location-pin-map:before { content: '\e9b5'; } /* '' */
.#{$icon-css-prefix}-location-pin-map-a:before { content: '\e9b6'; } /* '' */
.#{$icon-css-prefix}-location-pin-minus:before { content: '\e9b7'; } /* '' */
.#{$icon-css-prefix}-location-pin-plus:before { content: '\e9b8'; } /* '' */
.#{$icon-css-prefix}-location-pin-regular:before { content: '\e9b9'; } /* '' */
.#{$icon-css-prefix}-location-sign-street:before { content: '\e9ba'; } /* '' */
.#{$icon-css-prefix}-tool-console:before { content: '\e9bb'; } /* '' */
.#{$icon-css-prefix}-tool-console-a:before { content: '\e9bc'; } /* '' */
.#{$icon-css-prefix}-tool-console-old:before { content: '\e9bd'; } /* '' */
.#{$icon-css-prefix}-tool-disk:before { content: '\e9be'; } /* '' */
.#{$icon-css-prefix}-tool-disk-a:before { content: '\e9bf'; } /* '' */
.#{$icon-css-prefix}-tool-flashlight:before { content: '\e9c0'; } /* '' */
.#{$icon-css-prefix}-tool-flashlight-a:before { content: '\e9c1'; } /* '' */
.#{$icon-css-prefix}-tool-flashlight-a-s:before { content: '\e9c2'; } /* '' */
.#{$icon-css-prefix}-tool-flashlight-s:before { content: '\e9c3'; } /* '' */
.#{$icon-css-prefix}-tool-gameboy:before { content: '\e9c4'; } /* '' */
.#{$icon-css-prefix}-tool-hammer:before { content: '\e9c5'; } /* '' */
.#{$icon-css-prefix}-tool-headphone:before { content: '\e9c6'; } /* '' */
.#{$icon-css-prefix}-tool-headphone-a:before { content: '\e9c7'; } /* '' */
.#{$icon-css-prefix}-tool-laptop:before { content: '\e9c8'; } /* '' */
.#{$icon-css-prefix}-tool-magic-wand:before { content: '\e9c9'; } /* '' */
.#{$icon-css-prefix}-tool-magic-wand-a:before { content: '\e9ca'; } /* '' */
.#{$icon-css-prefix}-tool-magnet:before { content: '\e9cb'; } /* '' */
.#{$icon-css-prefix}-tool-magnifier:before { content: '\e9cc'; } /* '' */
.#{$icon-css-prefix}-tool-medical-tape:before { content: '\e9cd'; } /* '' */
.#{$icon-css-prefix}-tool-mobile:before { content: '\e9ce'; } /* '' */
.#{$icon-css-prefix}-tool-mobile-l:before { content: '\e9cf'; } /* '' */
.#{$icon-css-prefix}-tool-monitor:before { content: '\e9d0'; } /* '' */
.#{$icon-css-prefix}-tool-monitor-a:before { content: '\e9d1'; } /* '' */
.#{$icon-css-prefix}-tool-mouse:before { content: '\e9d2'; } /* '' */
.#{$icon-css-prefix}-tool-net:before { content: '\e9d3'; } /* '' */
.#{$icon-css-prefix}-tool-paint-roler:before { content: '\e9d4'; } /* '' */
.#{$icon-css-prefix}-tool-projector:before { content: '\e9d5'; } /* '' */
.#{$icon-css-prefix}-tool-radio:before { content: '\e9d6'; } /* '' */
.#{$icon-css-prefix}-tool-ruler:before { content: '\e9d7'; } /* '' */
.#{$icon-css-prefix}-tool-scissor:before { content: '\e9d8'; } /* '' */
.#{$icon-css-prefix}-tool-tablet:before { content: '\e9d9'; } /* '' */
.#{$icon-css-prefix}-tool-tablet-l:before { content: '\e9da'; } /* '' */
.#{$icon-css-prefix}-tool-television:before { content: '\e9db'; } /* '' */
.#{$icon-css-prefix}-tool-toothbrush:before { content: '\e9dc'; } /* '' */
.#{$icon-css-prefix}-tool-umbrella:before { content: '\e9dd'; } /* '' */
.#{$icon-css-prefix}-tool-video:before { content: '\e9de'; } /* '' */
.#{$icon-css-prefix}-tool-alt-camera:before { content: '\e9df'; } /* '' */
.#{$icon-css-prefix}-tool-alt-camera-a:before { content: '\e9e0'; } /* '' */
.#{$icon-css-prefix}-tool-android:before { content: '\e9e1'; } /* '' */
.#{$icon-css-prefix}-tool-android-l:before { content: '\e9e2'; } /* '' */
.#{$icon-css-prefix}-tool-binoculars:before { content: '\e9e3'; } /* '' */
.#{$icon-css-prefix}-tool-brush:before { content: '\e9e4'; } /* '' */
.#{$icon-css-prefix}-tool-brush-a:before { content: '\e9e5'; } /* '' */
.#{$icon-css-prefix}-tool-calculator:before { content: '\e9e6'; } /* '' */
.#{$icon-css-prefix}-tool-camera:before { content: '\e9e7'; } /* '' */
.#{$icon-css-prefix}-tool-camera-a:before { content: '\e9e8'; } /* '' */
.#{$icon-css-prefix}-tool-camera-b:before { content: '\e9e9'; } /* '' */
.#{$icon-css-prefix}-tool-compass:before { content: '\e9ea'; } /* '' */
.#{$icon-css-prefix}-transport-car-b:before { content: '\e9eb'; } /* '' */
.#{$icon-css-prefix}-transport-car-c:before { content: '\e9ec'; } /* '' */
.#{$icon-css-prefix}-transport-ship:before { content: '\e9ed'; } /* '' */
.#{$icon-css-prefix}-transport-train:before { content: '\e9ee'; } /* '' */
.#{$icon-css-prefix}-transport-truck:before { content: '\e9ef'; } /* '' */
.#{$icon-css-prefix}-transport-truck-a:before { content: '\e9f0'; } /* '' */
.#{$icon-css-prefix}-transport-alt-bus:before { content: '\e9f1'; } /* '' */
.#{$icon-css-prefix}-transport-bicycle:before { content: '\e9f2'; } /* '' */
.#{$icon-css-prefix}-transport-bicycle-a:before { content: '\e9f3'; } /* '' */
.#{$icon-css-prefix}-transport-boat:before { content: '\e9f4'; } /* '' */
.#{$icon-css-prefix}-transport-bus:before { content: '\e9f5'; } /* '' */
.#{$icon-css-prefix}-transport-bus-a:before { content: '\e9f6'; } /* '' */
.#{$icon-css-prefix}-transport-car:before { content: '\e9f7'; } /* '' */
.#{$icon-css-prefix}-transport-car-a:before { content: '\e9f8'; } /* '' */
.#{$icon-css-prefix}-user-male:before { content: '\e9f9'; } /* '' */
.#{$icon-css-prefix}-user-male-check:before { content: '\e9fa'; } /* '' */
.#{$icon-css-prefix}-user-male-cross:before { content: '\e9fb'; } /* '' */
.#{$icon-css-prefix}-user-male-minus:before { content: '\e9fc'; } /* '' */
.#{$icon-css-prefix}-user-male-plus:before { content: '\e9fd'; } /* '' */
.#{$icon-css-prefix}-user-male-sign:before { content: '\e9fe'; } /* '' */
.#{$icon-css-prefix}-user-password:before { content: '\e9ff'; } /* '' */
.#{$icon-css-prefix}-user-password-u:before { content: '\ea00'; } /* '' */
.#{$icon-css-prefix}-user-password-u-a:before { content: '\ea01'; } /* '' */
.#{$icon-css-prefix}-user-single:before { content: '\ea02'; } /* '' */
.#{$icon-css-prefix}-user-single-a:before { content: '\ea03'; } /* '' */
.#{$icon-css-prefix}-user-single-a-check:before { content: '\ea04'; } /* '' */
.#{$icon-css-prefix}-user-single-a-cross:before { content: '\ea05'; } /* '' */
.#{$icon-css-prefix}-user-single-a-group:before { content: '\ea06'; } /* '' */
.#{$icon-css-prefix}-user-single-a-list:before { content: '\ea07'; } /* '' */
.#{$icon-css-prefix}-user-single-a-minus:before { content: '\ea08'; } /* '' */
.#{$icon-css-prefix}-user-single-a-plus:before { content: '\ea09'; } /* '' */
.#{$icon-css-prefix}-user-single-check:before { content: '\ea0a'; } /* '' */
.#{$icon-css-prefix}-user-single-cross:before { content: '\ea0b'; } /* '' */
.#{$icon-css-prefix}-user-single-minus:before { content: '\ea0c'; } /* '' */
.#{$icon-css-prefix}-user-single-plus:before { content: '\ea0d'; } /* '' */
.#{$icon-css-prefix}-user-single-round:before { content: '\ea0e'; } /* '' */
.#{$icon-css-prefix}-user-alt:before { content: '\ea0f'; } /* '' */
.#{$icon-css-prefix}-user-alt-check:before { content: '\ea10'; } /* '' */
.#{$icon-css-prefix}-user-alt-cross:before { content: '\ea11'; } /* '' */
.#{$icon-css-prefix}-user-alt-group:before { content: '\ea12'; } /* '' */
.#{$icon-css-prefix}-user-alt-list:before { content: '\ea13'; } /* '' */
.#{$icon-css-prefix}-user-alt-minus:before { content: '\ea14'; } /* '' */
.#{$icon-css-prefix}-user-alt-plus:before { content: '\ea15'; } /* '' */
.#{$icon-css-prefix}-user-contact-book:before { content: '\ea16'; } /* '' */
.#{$icon-css-prefix}-user-female:before { content: '\ea17'; } /* '' */
.#{$icon-css-prefix}-user-female-cross:before { content: '\ea18'; } /* '' */
.#{$icon-css-prefix}-user-female-group:before { content: '\ea19'; } /* '' */
.#{$icon-css-prefix}-user-female-list:before { content: '\ea1a'; } /* '' */
.#{$icon-css-prefix}-user-female-minus:before { content: '\ea1b'; } /* '' */
.#{$icon-css-prefix}-user-female-plus:before { content: '\ea1c'; } /* '' */
.#{$icon-css-prefix}-user-female-sign:before { content: '\ea1d'; } /* '' */
.#{$icon-css-prefix}-user-female-tick:before { content: '\ea1e'; } /* '' */
.#{$icon-css-prefix}-user-key:before { content: '\ea1f'; } /* '' */
.#{$icon-css-prefix}-user-lock:before { content: '\ea20'; } /* '' */
.#{$icon-css-prefix}-user-lock-u:before { content: '\ea21'; } /* '' */
.#{$icon-css-prefix}-user-lock-u-a:before { content: '\ea22'; } /* '' */
.#{$icon-css-prefix}-weather-sunset-a:before { content: '\ea23'; } /* '' */
.#{$icon-css-prefix}-weather-wind:before { content: '\ea24'; } /* '' */
.#{$icon-css-prefix}-weather-wind-pressure:before { content: '\ea25'; } /* '' */
.#{$icon-css-prefix}-weather-wind-pressure-a:before { content: '\ea26'; } /* '' */
.#{$icon-css-prefix}-weather-cloud:before { content: '\ea27'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-a:before { content: '\ea28'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-rainy:before { content: '\ea29'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-rainy-a:before { content: '\ea2a'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-snow:before { content: '\ea2b'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-snow-a:before { content: '\ea2c'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-stormy:before { content: '\ea2d'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-stormy-a:before { content: '\ea2e'; } /* '' */
.#{$icon-css-prefix}-weather-cloud-sun:before { content: '\ea2f'; } /* '' */
.#{$icon-css-prefix}-weather-humid:before { content: '\ea30'; } /* '' */
.#{$icon-css-prefix}-weather-moon:before { content: '\ea31'; } /* '' */
.#{$icon-css-prefix}-weather-moon-a:before { content: '\ea32'; } /* '' */
.#{$icon-css-prefix}-weather-moon-b:before { content: '\ea33'; } /* '' */
.#{$icon-css-prefix}-weather-moon-r:before { content: '\ea34'; } /* '' */
.#{$icon-css-prefix}-weather-sun-a:before { content: '\ea35'; } /* '' */
.#{$icon-css-prefix}-weather-sun-b:before { content: '\ea36'; } /* '' */
.#{$icon-css-prefix}-weather-sun-r:before { content: '\ea37'; } /* '' */
.#{$icon-css-prefix}-weather-sunrise:before { content: '\ea38'; } /* '' */
.#{$icon-css-prefix}-web-bug:before { content: '\ea39'; } /* '' */
.#{$icon-css-prefix}-web-code:before { content: '\ea3a'; } /* '' */
.#{$icon-css-prefix}-web-database:before { content: '\ea3b'; } /* '' */
.#{$icon-css-prefix}-web-download:before { content: '\ea3c'; } /* '' */
.#{$icon-css-prefix}-web-graph:before { content: '\ea3d'; } /* '' */
.#{$icon-css-prefix}-web-inspect:before { content: '\ea3e'; } /* '' */
.#{$icon-css-prefix}-web-internet:before { content: '\ea3f'; } /* '' */
.#{$icon-css-prefix}-web-internet-a:before { content: '\ea40'; } /* '' */
.#{$icon-css-prefix}-web-logout:before { content: '\ea41'; } /* '' */
.#{$icon-css-prefix}-web-menu-collapse-down:before { content: '\ea42'; } /* '' */
.#{$icon-css-prefix}-web-menu-collapse-left:before { content: '\ea43'; } /* '' */
.#{$icon-css-prefix}-web-menu-collapse-right:before { content: '\ea44'; } /* '' */
.#{$icon-css-prefix}-web-menu-collapse-up:before { content: '\ea45'; } /* '' */
.#{$icon-css-prefix}-web-reading-list:before { content: '\ea46'; } /* '' */
.#{$icon-css-prefix}-web-report:before { content: '\ea47'; } /* '' */
.#{$icon-css-prefix}-web-report-a:before { content: '\ea48'; } /* '' */
.#{$icon-css-prefix}-web-rss:before { content: '\ea49'; } /* '' */
.#{$icon-css-prefix}-web-share:before { content: '\ea4a'; } /* '' */
.#{$icon-css-prefix}-web-share-a:before { content: '\ea4b'; } /* '' */
.#{$icon-css-prefix}-web-share-b:before { content: '\ea4c'; } /* '' */
.#{$icon-css-prefix}-web-statistic:before { content: '\ea4d'; } /* '' */
.#{$icon-css-prefix}-web-traffic:before { content: '\ea4e'; } /* '' */
.#{$icon-css-prefix}-web-traffic-a:before { content: '\ea4f'; } /* '' */
.#{$icon-css-prefix}-web-traffic-b:before { content: '\ea50'; } /* '' */
.#{$icon-css-prefix}-web-traffic-c:before { content: '\ea51'; } /* '' */
.#{$icon-css-prefix}-web-upload:before { content: '\ea52'; } /* '' */
.#{$icon-css-prefix}-web-url:before { content: '\ea53'; } /* '' */
.#{$icon-css-prefix}-web-url-a:before { content: '\ea54'; } /* '' */
.#{$icon-css-prefix}-web-url-b:before { content: '\ea55'; } /* '' */
.#{$icon-css-prefix}-web-warning:before { content: '\ea56'; } /* '' */
.#{$icon-css-prefix}-web-webcam:before { content: '\ea57'; } /* '' */
.#{$icon-css-prefix}-web-broken-link:before { content: '\ea58'; } /* '' */
.#{$icon-css-prefix}-web-browser:before { content: '\ea59'; } /* '' */
.#{$icon-css-prefix}-web-browser-a:before { content: '\ea5a'; } /* '' */
.#{$icon-css-prefix}-web-browser-b:before { content: '\ea5b'; } /* '' */
.#{$icon-css-prefix}-web-browser-cross:before { content: '\ea5c'; } /* '' */
.#{$icon-css-prefix}-web-browser-minus:before { content: '\ea5d'; } /* '' */
.#{$icon-css-prefix}-web-browser-plus:before { content: '\ea5e'; } /* '' */
.#{$icon-css-prefix}-web-browser-tab:before { content: '\ea5f'; } /* '' */
.#{$icon-css-prefix}-web-browser-tab-cross:before { content: '\ea60'; } /* '' */
.#{$icon-css-prefix}-web-browser-tab-minus:before { content: '\ea61'; } /* '' */
.#{$icon-css-prefix}-web-browser-tab-plus:before { content: '\ea62'; } /* '' */
.#{$icon-css-prefix}-web-browser-tab-tick:before { content: '\ea63'; } /* '' */
.#{$icon-css-prefix}-web-browser-tick:before { content: '\ea64'; } /* '' */
.#{$icon-css-prefix}-web-browser-window:before { content: '\ea65'; } /* '' */
.#{$icon-css-prefix}-logo-vimeo:before { content: '\ea66'; } /* '' */
.#{$icon-css-prefix}-logo-windows:before { content: '\ea67'; } /* '' */
.#{$icon-css-prefix}-logo-zerply:before { content: '\ea68'; } /* '' */
.#{$icon-css-prefix}-logo-amazon:before { content: '\ea69'; } /* '' */
.#{$icon-css-prefix}-logo-dribbble:before { content: '\ea6a'; } /* '' */
.#{$icon-css-prefix}-logo-dropbox:before { content: '\ea6b'; } /* '' */
.#{$icon-css-prefix}-logo-evernote:before { content: '\ea6c'; } /* '' */
.#{$icon-css-prefix}-logo-facebook:before { content: '\ea6d'; } /* '' */
.#{$icon-css-prefix}-logo-grooveshark:before { content: '\ea6e'; } /* '' */
.#{$icon-css-prefix}-logo-instagram:before { content: '\ea6f'; } /* '' */
.#{$icon-css-prefix}-logo-linkedin:before { content: '\ea70'; } /* '' */
.#{$icon-css-prefix}-logo-musio:before { content: '\ea71'; } /* '' */
.#{$icon-css-prefix}-logo-path:before { content: '\ea72'; } /* '' */
.#{$icon-css-prefix}-logo-paypal:before { content: '\ea73'; } /* '' */
.#{$icon-css-prefix}-logo-picasa:before { content: '\ea74'; } /* '' */
.#{$icon-css-prefix}-logo-pinterest:before { content: '\ea75'; } /* '' */
.#{$icon-css-prefix}-logo-rdio:before { content: '\ea76'; } /* '' */
.#{$icon-css-prefix}-logo-squarespace:before { content: '\ea77'; } /* '' */
.#{$icon-css-prefix}-logo-squareup:before { content: '\ea78'; } /* '' */
.#{$icon-css-prefix}-logo-twitter:before { content: '\ea79'; } /* '' */
.#{$icon-css-prefix}-misc-one-click:before { content: '\ea7a'; } /* '' */
.#{$icon-css-prefix}-misc-pointer:before { content: '\ea7b'; } /* '' */
.#{$icon-css-prefix}-misc-pointer-swipe-l:before { content: '\ea7c'; } /* '' */
.#{$icon-css-prefix}-misc-pointer-swipe-r:before { content: '\ea7d'; } /* '' */
.#{$icon-css-prefix}-misc-puzzle:before { content: '\ea7e'; } /* '' */
.#{$icon-css-prefix}-misc-wifi:before { content: '\ea7f'; } /* '' */
.#{$icon-css-prefix}-misc-crown:before { content: '\ea80'; } /* '' */
.#{$icon-css-prefix}-misc-cube:before { content: '\ea81'; } /* '' */
.#{$icon-css-prefix}-misc-dice-five:before { content: '\ea82'; } /* '' */
.#{$icon-css-prefix}-misc-dice-four:before { content: '\ea83'; } /* '' */
.#{$icon-css-prefix}-misc-dice-one:before { content: '\ea84'; } /* '' */
.#{$icon-css-prefix}-misc-dice-six:before { content: '\ea85'; } /* '' */
.#{$icon-css-prefix}-misc-dice-three:before { content: '\ea86'; } /* '' */
.#{$icon-css-prefix}-misc-dice-two:before { content: '\ea87'; } /* '' */
.#{$icon-css-prefix}-misc-double-click:before { content: '\ea88'; } /* '' */
.#{$icon-css-prefix}-misc-female-gender:before { content: '\ea89'; } /* '' */
.#{$icon-css-prefix}-misc-globe:before { content: '\ea8a'; } /* '' */
.#{$icon-css-prefix}-misc-male-gender:before { content: '\ea8b'; } /* '' */
.#{$icon-css-prefix}-misc-mood-happy:before { content: '\ea8c'; } /* '' */
.#{$icon-css-prefix}-misc-mood-sad:before { content: '\ea8d'; } /* '' */
.#{$icon-css-prefix}-music-mic:before { content: '\ea8e'; } /* '' */
.#{$icon-css-prefix}-music-mic-a:before { content: '\ea8f'; } /* '' */
.#{$icon-css-prefix}-music-next:before { content: '\ea90'; } /* '' */
.#{$icon-css-prefix}-music-next-l:before { content: '\ea91'; } /* '' */
.#{$icon-css-prefix}-music-next-l-a:before { content: '\ea92'; } /* '' */
.#{$icon-css-prefix}-music-pause:before { content: '\ea93'; } /* '' */
.#{$icon-css-prefix}-music-pause-a:before { content: '\ea94'; } /* '' */
.#{$icon-css-prefix}-music-pause-b:before { content: '\ea95'; } /* '' */
.#{$icon-css-prefix}-music-play:before { content: '\ea96'; } /* '' */
.#{$icon-css-prefix}-music-playlist:before { content: '\ea97'; } /* '' */
.#{$icon-css-prefix}-music-previous:before { content: '\ea98'; } /* '' */
.#{$icon-css-prefix}-music-previous-l:before { content: '\ea99'; } /* '' */
.#{$icon-css-prefix}-music-previous-l-a:before { content: '\ea9a'; } /* '' */
.#{$icon-css-prefix}-music-radio:before { content: '\ea9b'; } /* '' */
.#{$icon-css-prefix}-music-record:before { content: '\ea9c'; } /* '' */
.#{$icon-css-prefix}-music-record-a:before { content: '\ea9d'; } /* '' */
.#{$icon-css-prefix}-music-repeat:before { content: '\ea9e'; } /* '' */
.#{$icon-css-prefix}-music-repeat-a:before { content: '\ea9f'; } /* '' */
.#{$icon-css-prefix}-music-repeat-one:before { content: '\eaa0'; } /* '' */
.#{$icon-css-prefix}-music-repeat-one-a:before { content: '\eaa1'; } /* '' */
.#{$icon-css-prefix}-music-shuffle:before { content: '\eaa2'; } /* '' */
.#{$icon-css-prefix}-music-shuffle-a:before { content: '\eaa3'; } /* '' */
.#{$icon-css-prefix}-music-song-note:before { content: '\eaa4'; } /* '' */
.#{$icon-css-prefix}-music-song-note-a:before { content: '\eaa5'; } /* '' */
.#{$icon-css-prefix}-music-speaker:before { content: '\eaa6'; } /* '' */
.#{$icon-css-prefix}-music-speaker-a:before { content: '\eaa7'; } /* '' */
.#{$icon-css-prefix}-music-stop:before { content: '\eaa8'; } /* '' */
.#{$icon-css-prefix}-music-stop-a:before { content: '\eaa9'; } /* '' */
.#{$icon-css-prefix}-volume-high:before { content: '\eaaa'; } /* '' */
.#{$icon-css-prefix}-volume-high-a:before { content: '\eaab'; } /* '' */
.#{$icon-css-prefix}-volume-low:before { content: '\eaac'; } /* '' */
.#{$icon-css-prefix}-volume-low-a:before { content: '\eaad'; } /* '' */
.#{$icon-css-prefix}-volume-medium:before { content: '\eaae'; } /* '' */
.#{$icon-css-prefix}-volume-medium-a:before { content: '\eaaf'; } /* '' */
.#{$icon-css-prefix}-volume-mute:before { content: '\eab0'; } /* '' */
.#{$icon-css-prefix}-volume-mute-a:before { content: '\eab1'; } /* '' */
.#{$icon-css-prefix}-music-album:before { content: '\eab2'; } /* '' */
.#{$icon-css-prefix}-music-album-a:before { content: '\eab3'; } /* '' */
.#{$icon-css-prefix}-music-album-b:before { content: '\eab4'; } /* '' */
.#{$icon-css-prefix}-music-album-c:before { content: '\eab5'; } /* '' */
.#{$icon-css-prefix}-music-album-cd:before { content: '\eab6'; } /* '' */
.#{$icon-css-prefix}-music-cd:before { content: '\eab7'; } /* '' */
.#{$icon-css-prefix}-music-eject:before { content: '\eab8'; } /* '' */
.#{$icon-css-prefix}-music-eq:before { content: '\eab9'; } /* '' */
.#{$icon-css-prefix}-music-eq-a:before { content: '\eaba'; } /* '' */
.#{$icon-css-prefix}-music-group-note:before { content: '\eabb'; } /* '' */
.#{$icon-css-prefix}-music-group-note-a:before { content: '\eabc'; } /* '' */
.#{$icon-css-prefix}-music-headphone:before { content: '\eabd'; } /* '' */
.#{$icon-css-prefix}-music-headphone-a:before { content: '\eabe'; } /* '' */
.#{$icon-css-prefix}-music-list:before { content: '\eabf'; } /* '' */
.#{$icon-css-prefix}-interface-backspace:before { content: '\eac0'; } /* '' */
.#{$icon-css-prefix}-interface-backspace-a:before { content: '\eac1'; } /* '' */
.#{$icon-css-prefix}-interface-bell:before { content: '\eac2'; } /* '' */
.#{$icon-css-prefix}-interface-bell-a:before { content: '\eac3'; } /* '' */
.#{$icon-css-prefix}-interface-block:before { content: '\eac4'; } /* '' */
.#{$icon-css-prefix}-interface-bottom:before { content: '\eac5'; } /* '' */
.#{$icon-css-prefix}-interface-bottom-k:before { content: '\eac6'; } /* '' */
.#{$icon-css-prefix}-interface-bottom-r:before { content: '\eac7'; } /* '' */
.#{$icon-css-prefix}-interface-box-bottom:before { content: '\eac8'; } /* '' */
.#{$icon-css-prefix}-interface-box-bottom-a:before { content: '\eac9'; } /* '' */
.#{$icon-css-prefix}-interface-box-cross:before { content: '\eaca'; } /* '' */
.#{$icon-css-prefix}-interface-box-left:before { content: '\eacb'; } /* '' */
.#{$icon-css-prefix}-interface-box-left-a:before { content: '\eacc'; } /* '' */
.#{$icon-css-prefix}-interface-box-minus:before { content: '\eacd'; } /* '' */
.#{$icon-css-prefix}-interface-box-plus:before { content: '\eace'; } /* '' */
.#{$icon-css-prefix}-interface-box-right:before { content: '\eacf'; } /* '' */
.#{$icon-css-prefix}-interface-box-right-a:before { content: '\ead0'; } /* '' */
.#{$icon-css-prefix}-interface-box-tick:before { content: '\ead1'; } /* '' */
.#{$icon-css-prefix}-interface-box-top:before { content: '\ead2'; } /* '' */
.#{$icon-css-prefix}-interface-box-top-a:before { content: '\ead3'; } /* '' */
.#{$icon-css-prefix}-interface-bulleye:before { content: '\ead4'; } /* '' */
.#{$icon-css-prefix}-interface-calendar:before { content: '\ead5'; } /* '' */
.#{$icon-css-prefix}-interface-circle:before { content: '\ead6'; } /* '' */
.#{$icon-css-prefix}-interface-circle-cross:before { content: '\ead7'; } /* '' */
.#{$icon-css-prefix}-interface-circle-minus:before { content: '\ead8'; } /* '' */
.#{$icon-css-prefix}-interface-circle-plus:before { content: '\ead9'; } /* '' */
.#{$icon-css-prefix}-interface-circle-tick:before { content: '\eada'; } /* '' */
.#{$icon-css-prefix}-interface-circle-tick-a:before { content: '\eadb'; } /* '' */
.#{$icon-css-prefix}-interface-clock:before { content: '\eadc'; } /* '' */
.#{$icon-css-prefix}-interface-clock-a:before { content: '\eadd'; } /* '' */
.#{$icon-css-prefix}-interface-clock-b:before { content: '\eade'; } /* '' */
.#{$icon-css-prefix}-interface-cloud-download:before { content: '\eadf'; } /* '' */
.#{$icon-css-prefix}-interface-cloud-upload:before { content: '\eae0'; } /* '' */
.#{$icon-css-prefix}-interface-cmd:before { content: '\eae1'; } /* '' */
.#{$icon-css-prefix}-interface-crop:before { content: '\eae2'; } /* '' */
.#{$icon-css-prefix}-interface-cross:before { content: '\eae3'; } /* '' */
.#{$icon-css-prefix}-interface-dashboard:before { content: '\eae4'; } /* '' */
.#{$icon-css-prefix}-interface-direction:before { content: '\eae7'; } /* '' */
.#{$icon-css-prefix}-interface-downloading:before { content: '\eae8'; } /* '' */
.#{$icon-css-prefix}-interface-enlarge:before { content: '\eae9'; } /* '' */
.#{$icon-css-prefix}-interface-enlarge-a:before { content: '\eaea'; } /* '' */
.#{$icon-css-prefix}-interface-enlarge-c:before { content: '\eaeb'; } /* '' */
.#{$icon-css-prefix}-interface-expand:before { content: '\eaec'; } /* '' */
.#{$icon-css-prefix}-interface-forward:before { content: '\eaed'; } /* '' */
.#{$icon-css-prefix}-interface-fullscreen:before { content: '\eaee'; } /* '' */
.#{$icon-css-prefix}-interface-fullscreen-a:before { content: '\eaef'; } /* '' */
.#{$icon-css-prefix}-interface-fullscreen-wide:before { content: '\eaf0'; } /* '' */
.#{$icon-css-prefix}-interface-hamburger:before { content: '\eaf1'; } /* '' */
.#{$icon-css-prefix}-interface-heart:before { content: '\eaf2'; } /* '' */
.#{$icon-css-prefix}-interface-help:before { content: '\eaf3'; } /* '' */
.#{$icon-css-prefix}-interface-help-a:before { content: '\eaf4'; } /* '' */
.#{$icon-css-prefix}-interface-home:before { content: '\eaf5'; } /* '' */
.#{$icon-css-prefix}-interface-home-a:before { content: '\eaf6'; } /* '' */
.#{$icon-css-prefix}-interface-horizontal:before { content: '\eaf7'; } /* '' */
.#{$icon-css-prefix}-interface-horizontal-a:before { content: '\eaf8'; } /* '' */
.#{$icon-css-prefix}-interface-in-link:before { content: '\eaf9'; } /* '' */
.#{$icon-css-prefix}-interface-left:before { content: '\eafa'; } /* '' */
.#{$icon-css-prefix}-interface-left-b-k:before { content: '\eafb'; } /* '' */
.#{$icon-css-prefix}-interface-left-k:before { content: '\eafc'; } /* '' */
.#{$icon-css-prefix}-interface-left-r:before { content: '\eafd'; } /* '' */
.#{$icon-css-prefix}-interface-left-t-k:before { content: '\eafe'; } /* '' */
.#{$icon-css-prefix}-interface-list-view:before { content: '\eaff'; } /* '' */
.#{$icon-css-prefix}-interface-login:before { content: '\eb00'; } /* '' */
.#{$icon-css-prefix}-interface-logout-a:before { content: '\eb01'; } /* '' */
.#{$icon-css-prefix}-interface-minimize:before { content: '\eb02'; } /* '' */
.#{$icon-css-prefix}-interface-minus:before { content: '\eb03'; } /* '' */
.#{$icon-css-prefix}-interface-more:before { content: '\eb04'; } /* '' */
.#{$icon-css-prefix}-interface-number:before { content: '\eb05'; } /* '' */
.#{$icon-css-prefix}-interface-out-link:before { content: '\eb06'; } /* '' */
.#{$icon-css-prefix}-interface-pixel:before { content: '\eb07'; } /* '' */
.#{$icon-css-prefix}-interface-places:before { content: '\eb08'; } /* '' */
.#{$icon-css-prefix}-interface-places-a:before { content: '\eb09'; } /* '' */
.#{$icon-css-prefix}-interface-plus:before { content: '\eb0a'; } /* '' */
.#{$icon-css-prefix}-interface-popup:before { content: '\eb0b'; } /* '' */
.#{$icon-css-prefix}-interface-power:before { content: '\eb0c'; } /* '' */
.#{$icon-css-prefix}-interface-refresh:before { content: '\eb0d'; } /* '' */
.#{$icon-css-prefix}-interface-repeating:before { content: '\eb0e'; } /* '' */
.#{$icon-css-prefix}-interface-reply:before { content: '\eb0f'; } /* '' */
.#{$icon-css-prefix}-interface-resize-full:before { content: '\eb10'; } /* '' */
.#{$icon-css-prefix}-interface-resize-full-a:before { content: '\eb11'; } /* '' */
.#{$icon-css-prefix}-interface-resize-normal:before { content: '\eb12'; } /* '' */
.#{$icon-css-prefix}-interface-reverse:before { content: '\eb13'; } /* '' */
.#{$icon-css-prefix}-interface-right:before { content: '\eb14'; } /* '' */
.#{$icon-css-prefix}-interface-right-k:before { content: '\eb15'; } /* '' */
.#{$icon-css-prefix}-interface-right-r:before { content: '\eb16'; } /* '' */
.#{$icon-css-prefix}-interface-rigth-b-k:before { content: '\eb17'; } /* '' */
.#{$icon-css-prefix}-interface-rigth-t-k:before { content: '\eb18'; } /* '' */
.#{$icon-css-prefix}-interface-search:before { content: '\eb19'; } /* '' */
.#{$icon-css-prefix}-interface-search-cross:before { content: '\eb1a'; } /* '' */
.#{$icon-css-prefix}-interface-search-minus:before { content: '\eb1b'; } /* '' */
.#{$icon-css-prefix}-interface-search-plus:before { content: '\eb1c'; } /* '' */
.#{$icon-css-prefix}-interface-search-tick:before { content: '\eb1d'; } /* '' */
.#{$icon-css-prefix}-interface-sidebar-hamburger:before { content: '\eb1e'; } /* '' */
.#{$icon-css-prefix}-interface-star:before { content: '\eb1f'; } /* '' */
.#{$icon-css-prefix}-interface-star-a:before { content: '\eb20'; } /* '' */
.#{$icon-css-prefix}-interface-target:before { content: '\eb21'; } /* '' */
.#{$icon-css-prefix}-interface-thumbnail:before { content: '\eb22'; } /* '' */
.#{$icon-css-prefix}-interface-tick:before { content: '\eb23'; } /* '' */
.#{$icon-css-prefix}-interface-top:before { content: '\eb24'; } /* '' */
.#{$icon-css-prefix}-interface-top-k:before { content: '\eb25'; } /* '' */
.#{$icon-css-prefix}-interface-top-r:before { content: '\eb26'; } /* '' */
.#{$icon-css-prefix}-interface-undo:before { content: '\eb27'; } /* '' */
.#{$icon-css-prefix}-interface-uploading:before { content: '\eb28'; } /* '' */
.#{$icon-css-prefix}-interface-vertical:before { content: '\eb29'; } /* '' */
.#{$icon-css-prefix}-interface-vertical-a:before { content: '\eb2a'; } /* '' */
.#{$icon-css-prefix}-interface-view:before { content: '\eb2b'; } /* '' */
.#{$icon-css-prefix}-interface-warning:before { content: '\eb2c'; } /* '' */
.#{$icon-css-prefix}-interface-window:before { content: '\eb2d'; } /* '' */
.#{$icon-css-prefix}-layout-footer:before { content: '\eb2e'; } /* '' */
.#{$icon-css-prefix}-layout-grid:before { content: '\eb2f'; } /* '' */
.#{$icon-css-prefix}-layout-half:before { content: '\eb30'; } /* '' */
.#{$icon-css-prefix}-layout-header:before { content: '\eb31'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-l:before { content: '\eb32'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-l-a:before { content: '\eb33'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-l-half:before { content: '\eb34'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-l-half-a:before { content: '\eb35'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-r:before { content: '\eb36'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-r-a:before { content: '\eb37'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-r-half:before { content: '\eb38'; } /* '' */
.#{$icon-css-prefix}-layout-sidebar-r-half-a:before { content: '\eb39'; } /* '' */
.#{$icon-css-prefix}-layout-third-h:before { content: '\eb3a'; } /* '' */
.#{$icon-css-prefix}-layout-third-v:before { content: '\eb3b'; } /* '' */
.#{$icon-css-prefix}-layout-wireframe:before { content: '\eb3c'; } /* '' */
.#{$icon-css-prefix}-layout-wireframe-a:before { content: '\eb3d'; } /* '' */
.#{$icon-css-prefix}-interface-alt-cross:before { content: '\eb3e'; } /* '' */
.#{$icon-css-prefix}-interface-alt-fullscreen:before { content: '\eb3f'; } /* '' */
.#{$icon-css-prefix}-interface-alt-minus:before { content: '\eb40'; } /* '' */
.#{$icon-css-prefix}-interface-alt-plus:before { content: '\eb41'; } /* '' */
.#{$icon-css-prefix}-interface-alt-tick:before { content: '\eb42'; } /* '' */
.#{$icon-css-prefix}-interface-alt-widescreen:before { content: '\eb43'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-all:before { content: '\eb44'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-bottom:before { content: '\eb45'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-bottom-circle:before { content: '\eb46'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-left:before { content: '\eb47'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-left-b:before { content: '\eb48'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-left-circle:before { content: '\eb49'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-left-t:before { content: '\eb4a'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-right:before { content: '\eb4b'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-right-b:before { content: '\eb4c'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-right-circle:before { content: '\eb4d'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-right-t:before { content: '\eb4e'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-top:before { content: '\eb4f'; } /* '' */
.#{$icon-css-prefix}-interface-arrow-top-circle:before { content: '\eb50'; } /* '' */
.#{$icon-css-prefix}-animal-dog-a:before { content: '\eb51'; } /* '' */
.#{$icon-css-prefix}-interface-horizontal-a-1:before { content: '\eb52'; } /* '' */
.#{$icon-css-prefix}-interface-vertical-a-1:before { content: '\eb53'; } /* '' */
.#{$icon-css-prefix}-interface-diag:before { content: '\eb54'; } /* '' */
.#{$icon-css-prefix}-interface-diag-a:before { content: '\eb55'; } /* '' */