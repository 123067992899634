.page-dei {
    .breadcrumb-nav {
        padding-bottom: 3em;
    }
}

#app {
    &.app-dei {
        background-image: none;
    }
}
