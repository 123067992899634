.tabs-panel__body {
  overflow: hidden;
  position: relative;

  &.is-animating-right {
    .is-selected {
      left: -100%;
    }
  }

  &.is-animating-out {

    &.is-animating-left {

      .is-active-panel {
        position: absolute;
        left: -100%;
      }
    }

    &.is-animating-right {

      .is-active-panel {
        position: absolute;
        left: 100%;
      }
    }

    .is-animating-in {
      z-index: 2;
      left: 0;
      transition: transform .6s $animationEase,
                  left .6s $animationEase;
    }
  }
}

.tabs-panel {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
  width: 100%;
  background-color: $color-brand-light;



  &.is-active-panel {
    position: relative;
    z-index: 5;
    left: 0;
    transform: translateZ(0);
    transition: transform .6s $animationEase,
                left .6s $animationEase;
  }

}
