@page{
    margin: 25mm;
}

.pdf{
  /*Header*/
  font-family:sans-serif;
  width: 700px; 
  li {
    list-style-type:disc;
  }
  h1,h2,h3,h4,h5,h6,
  .h1-size,
  .h2-size,
  .h3-size,
  .h4-size,
  .h5-size,
  .section-header  {
      letter-spacing: .02rem;
  }
  .site-full-nav{
    display: none;
  }

  h2 { 
      color:#59819d; 
  }
  .site-brand__logo-link img.site-brand__logo{
      display: none,
  }

  .site-brand__logo-link img.site-brand__logo--interior{
      margin-bottom: 20px;
      height: 50px;
      display: block;
  }

  .site-layout-wrapper{
    width: 100%;
    max-width: none;
    padding: 0;
    margin: 0px;
  }
  .question-text {
    font-weight: bold !important;
  }
  .question {
      padding-bottom: 12px;
  }
  .questions-list {
    margin-left: -1.1rem;
  }
  .select-matters-tab {
      padding-top:9px;
      p {
          margin-bottom: px-to-rem(9px);
      }
      ul {
          margin-left:-1.1rem;
      }
  }
     blockquote,
    .blockquote {
        border-left: px-to-rem(3px) solid lightgray;
        margin: 0;
        padding-left: px-to-rem(28px);
        font-size: px-to-rem(20px);
        font-style: italic;
        line-height: 1.2;
        position: relative;
        color: $pdf-print-blue;
        line-height: 1.6em;


        @include font-accent;

        &:before {
            content:'\201C';
            position: absolute;
            left: 0.3em;
        }

        &:after {
             content: '\201D';
             padding-left: px-to-rem(5px);
        }

        > p {
            margin-bottom: 0;
        }
        
        cite {
            font-size: px-to-rem(24px);
            font-style: normal;
            display: block;
            margin-top: $spacing-unit/2;
        }
    }
    .pdf-list{
        li {
            padding-bottom:15px;
        }
    }

    .people-quote-section {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .quote-source {
        border-left: px-to-rem(3px) solid lightgray;
        padding-left: px-to-rem(28px);
        color: $pdf-print-blue;
        padding-top: 0.5rem;
    }
}