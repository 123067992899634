.people-landing{
  .dropdown-label-icon{
    display: inline;
  }

  .people-sort-by-container{
    .is-active{
      font-weight: bold;
    }
  }

  .people-letters-container{
    .filters{
      width: 55%;
      display: inline-block;
    }
    .sort{
      display: inline-block;
      float: right; 
    }
  }

}
