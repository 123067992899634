.priorities {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-brand-dark-gray;
    color: $color-brand-white;
    position: relative;

    &__wrapper {
        width: 100%;
        max-width: calc(1160px + #{($site-padding-tablet * 2)});
        margin: auto;
        position: relative;
        z-index: 1;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: $color-brand-black;
        opacity: .6;
        height: 100%;
        width: 100%;
    }
    
    &__content {
        padding: 4em 2em 250px 2em; 
    }

    &__title {
        font-family: $font-family-serif-new;
        font-size: 64px;
        font-style: italic;
        color: $color-brand-white;
        text-transform: none;
    }

    &__grid {
        margin-top: 80px;
    }

    &__item {
        min-width: 500px;
        width: 100%;
        transition: .3s ease all;
        position: relative;
        height: 140px;

        &:hover {
            z-index: 1;
            .priorities__item-inner {
                opacity: 1;
            }

            .priorities__item-title,
            .priorities__item-inner {
                background-color: $color-opaque-black;
            }
        }


        &:first-child {
            right: 0%;
            margin-left: auto;
        }

        &:nth-child(2) {
            left: 5%;
        }

        &:nth-child(3) {
            right: 10%;
            margin-left: auto;
        }

        &:nth-child(4) {
            left: 0;
        }

        &:nth-child(5) {
            right: 0; 
            margin-left: auto;
        }
    }

    &__item-title {
        font-family: $font-family-serif-new;
        font-size: 86px;
        line-height: 1;
        padding: 50px 50px 0 50px;
        transition: .3s ease all;
        span {
            display: inline-block;
        }
    }

    &__item-content {
        // height: 150px;
        overflow: visible;
    }

    &__item-inner {
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: .3s ease all;
        width: 100%;
    }
    
    &__item-wrapper {
        padding: 0 50px 50px 50px;
    }

    &__item-subtitle {
        font-family: $font-family-secondary;
        font-size: 24px;
        font-weight: $font-weight-medium;
        padding-bottom: .5em;
        border-bottom: 5px solid $color__green;
    }

    &__item-details {
        font-family: $font-family-primary;
        font-size: 20px;
        padding: 1em;
    }

    @include bp-max($bp-tablet-sm) {
        &__title {
            font-size: px-to-rem(60px);
        }

        &__item-title {
            font-size: px-to-rem(60px);
        }

        &__item {
            max-width: none!important;
            min-width: 0!important;
            left: 0;
            right: 0;
            margin-left: 0!important;
            margin-right: 0!important;
            margin-top: 2em;
            height: auto;

            &:nth-child(2),
            &:nth-child(3)
            &:nth-child(4)
            &:nth-child(5) {
                left: 0;
                right: 0;
            }
        }
    }

	@include bp-max($bp-mobile) {
        &__title {
            font-size: px-to-rem(60px);
        }

        &__item-title {
            font-size: px-to-rem(45px);
        }

        
    }
}