.probono-landing {

    .InputNotValid {
        border-color: red;
    }

    .email-send-error {
        margin-top: 24px;
        text-align: center;
    }

    .ErrorMessage {
        color: red;
    }

    .section-header {
        margin-top: px-to-rem(30px);
    }

    .searchbox__input__full {
        background-color: $color-opaque-white;
        width: 100%;
        float: left;
        margin-top: 0.5rem;

        @include placeholder() {
            color: $color-text-input;
        }

        @include bp-max($bp-mobile) {
            width: 100%;
            padding-left: 3rem;
            font-size: 1rem;
        }
    }

    .checkbox-label {
        @include clearfix;
        display: inline-block;
        margin-left: 1rem;
        margin-top: 1rem;
    }

    .searchbox__input {
        width: 49%;
        float: left;
        margin-top: 0.5rem;
        
        &--full{
            width: 99.5%;
        }
        @include bp-max($bp-mobile) {
            width: 100%;
        }
    }

    .searchbox__input:nth-child(even) {
        margin-left: 10px;

        @include bp-max($bp-mobile) {
            margin-left: 0px;
        }
    }
}
