.site-footer {
	@include font-secondary;
	color: $color-brand-white-abs;
	// font-size: 1em;

	&__columns {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	&__logo {
		width: 138px;
		margin-right: 5%;
		display: flex;
		align-items: center;

		> img {
			display: block;
			width: 100%;
		}
	}

	&__message {
		max-width: 390px;
		width: 29%;
		margin-right: 5%;
	}
	&__nav {
		flex-grow: 1;
		columns: 3 auto;

		.list__item {
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
		}
	}

	&__nav-link {
		color: $color-brand-white-abs;
		font-size: px-to-rem(16px);
		text-decoration: underline;
		padding-bottom: 12px;
		display: inline-block;
	}

	.social-icon__link {
		color: $color-brand-white-abs;
		font-size: px-to-rem(20px);
	}

	&__nav-link,
	.social-icon__link {
		&:hover {
			color: $color-brand-green;
		}
	}



	.social-icons {
		float: right;
		.list__item + .list__item {
			margin-left: 20px;
		}
	}

	&__closing {
		padding-top: px-to-rem(60px);
	}

	&__copyright {
		font-size: px-to-rem(14px);
		text-transform: uppercase;
	}

	@include bp-max($bp-tablet) {
		&__columns {
			flex-wrap: wrap;
		}

		&__message {
			flex-grow: 1;
		}

		&__nav {
			width: 100%;
			margin-top: px-to-rem(60px);
		}
	}

	@include bp-max($bp-mobile) {
		text-align: center;

		&__message {
			flex-grow: 1;
			width: auto;
			max-width: none;
		}
		&__nav-link {
			&.hide-mobile {
				display: none;
			}
		}
	
		&__columns {
			display: block;
		}

		&__logo {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: px-to-rem(32px);
		}
		&__logo {
			max-width: none;
		}

		&__nav {
			columns: 1;
		}

		.social-icon__link {
			font-size: px-to-rem(30px);
		}

		.social-icons {
			float: none;
			display: flex;
			justify-content: space-around;
			.list__item + .list__item {
				margin-left: 0;
			}
		}

		&__copyright {
			margin-top: px-to-rem(30px);
		}
	}
}
