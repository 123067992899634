.sitesearch{

    .results--list {
        border-top: 1px solid #E9E9E9;
    }

    .results--item {
        display: flex;
        flex-direction: row;
        padding: 0.5em 0;
        border-bottom: 1px solid #E9E9E9;
    }

    .results--item--image {
        flex: 0 1 auto;
        margin-right: 10px;
    }

    .results--item--text {
        flex: 1 1 auto;
    }

    .results--item--text--title {
        margin-bottom: 7px;
        color: #5b7e99;
    }

    .results--item--text--file-format {
        font-size: 13px;
    }

    .results--item--text--url {
        color: #5b7e99;
        font-size: 13px;
    }

  .google-search-filters{
    ul {
      padding-left: 0;
      li{
        display: inline-block;               
        margin-bottom: 0.2rem;
        height: 2.5rem;
        width: calc(25% - 3px);
        button{
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }

    @include bp-max($bp-tablet-sm) {
        ul {        
          li{        
            height: 3.5rem;
            width: calc(50% - 3px);        
        }
      }    
    }
  }

    .google-filter{
      width: 195px;
    }

    #cse{

      margin-top:50px;

      a{
          @include font-accent;
          font-size: px-to-rem(24px);
          line-height: 1;
          display: inline-block;
          text-decoration: none;

          :hover{
            color: #578fdb;
          }
      }

      .gs-title{
        color: #5b7e99;
        margin-bottom: 7px;

        b{
          color: #5b7e99;
          text-decoration: none;
        }
      }

      .gs-fileFormat{
        font-family: "Quattrocento",serif;
      }

      .gs-snippet{
        font-size: px-to-rem(18px);
        line-height: 1.3;
        font-family: "Quattrocento",serif;
        margin-bottom: 7px;
      }

      .gs-visibleUrl{
        @include font-accent;
        color: #5b7e99;
      }

      .gsc-webResult{
        &.gsc-result:first-of-type{

          border-bottom: none;

          .gs-spelling{
            display: none;
            border: none;
          }
        }
      }

    }

}
