.site-nav{
	@include font-secondary;

	margin-right: 50px;
	padding-left:px-to-rem($site-header-width);
	white-space: nowrap;
	text-transform: uppercase;
	transform: translateZ(0);
	
	@include bp-max($bp-desktop) {
		font-size: 	px-to-rem(16px);
	}

	@include bp-max($bp-hamburger) {
		@include vertical-align;

		margin-right: 0;
		white-space: normal;
		padding-left: 0;
	}

	@include bp-max($bp-mobile) {
		transform: translateY(-40%);
        
	}

	@include bp-max-orientation() {
		top: auto;
		transform: translateY(0);
		margin-top: px-to-rem($site-header-height-mobile);
	}
    @include bp-max($bp-mobile-portrait){
        top:40%;
    }
}

.site-nav__list {
	position: relative;
	display: inline;
	

	@include bp-max($bp-hamburger) {
		display: block;

		& > .list__item {
			display: block;
			position: relative;
		}
	}

	.list__item + .list__item {
		margin-left: 3%;

		@include bp-max($bp-hamburger) {
			margin-left: 0;
		}
	}
    .list__item {
        @include bp-min($bp-mobile-portrait) {
            &:last-child {
                .subnav {
                    text-align:right;
                }
            }
            &:nth-last-of-type(2){
                .subnav {
                    text-align:right;
                }
            }
        }
        @include bp-max($bp-mobile-portrait) {
            .subnav {
                text-align:center;
            }
            
        }
    }
}
@include bp-max($bp-mobile-portrait) {
    .is-visible-subnav {
        margin-bottom:0 !important;
    }
}

.subnav {
    position:absolute;
    left:0;
    right:0;
    top:100%;
    text-align:left;
	opacity: 0;
	visibility: hidden;
	transition: opacity .4s ease-in,
				visibility .2s ease-in .4s;
    @include bp-max($bp-mobile-portrait) {
        position:relative;
        display:none;
        margin-left:-6%;
        .is-visible-subnav + .subnav {
            display:none;
            
        }
        .list__item {
            display:block;
            padding:1% 0;
            font-size:1.1em;
        }
    }
	.is-visible-subnav + &{
		transition: opacity .4s ease-in,
					visibility .4s ease-in;
		opacity: 1;
        display:block;
		visibility: visible;
        margin-bottom:4%;
		@include bp-max-orientation() {
			position: relative;
		}
	}
    
	.list__item + .list__item {
		margin-left: 4%;

		@include bp-max($bp-hamburger) {
			
		}
	}

	@include bp-max($bp-hamburger) {
		margin-left: 0;
		padding: $spacing-unit $spacing-unit 0;
	}

	@include bp-max($bp-hamburger) {
		padding-top: px-to-rem(5px);
	}

	@include bp-max-orientation() {
		padding-bottom: px-to-rem(15px);
	}


}

.subnav__link {
	@extend %small-text;
}


.site-nav__link,
.subnav__link {
	line-height: 1;
	display: inline-block;
	color: $color-brand-white;


	&:hover {
		color: $color-brand-black;
	}

	@include bp-max($bp-hamburger) {
		color: $color-brand-white;
	}
}

.site-nav__link {
	position: relative;

    &.active-nav {
        font-weight: 500;

        &:after {
            font-weight: 600 !important;
        }
    }

    .subnav-active &  {
    	opacity: .5;
    }

	&.subnav__dropdown {
		@include bi-icon-after($bi-arrow-bottom) {
			margin-left: $spacing-unit-small;
			font-size: px-to-rem(15px);
			transition: transform .4s;
		}

		&.is-visible-subnav {
			opacity: 1;
			
			&:after {
				transform: rotateX(180deg);
			}
		}
	}

	@include bp-max($bp-hamburger) {
		font-size: px-to-rem(20px);
		display: block;
		margin-bottom: 10%;
		margin-left: 0;

		
	}

	@include bp-max($bp-mobile) {
		font-size: 1.3rem;
		margin-bottom: 5%;
		display: block;

	}

	@include bp-max($bp-mobile-portrait) {
		font-size: 1.2rem;
		margin-bottom:5%;
	}

	@include bp-max-orientation() {
		margin-bottom: 15px;
	}
}