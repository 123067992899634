.loading-bar {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background-color: $color-brand-darkblue;
	transition: bottom .5s $animationEase,
				opacity .5s $animationEase,
				visibility .5s ease .5s;

	bottom: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: $zindex-cube-arrows;

	.is-loading-data &{
		bottom: 0;
		opacity: 1;
		visibility: visible;
		transition: bottom 1s $animationEase,
					opacity 1s $animationEase;
	}
}


.loading-bar__progress {
	@include absolute-center-align;

	width: 50%;
	height: 3px;
	padding: 1px 0;
	background-color: darken($color-brand-darkblue, 10);
}

.loading-bar__percentage {
	height: 1px;
	width: 5%;
	display: block;
	background-color: $color-brand-lightgreen;
	transition: width 1s linear 1.2s;

	.is-loading-data &{
		width: 100%;	
	}
}