.filter-tabs {
    @include font-secondary-medium();

    &__title {
        line-height: 1;
        margin-bottom: 1em;
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        border-radius: 4px;
        overflow: hidden;
    }

    &__option {
        flex-grow: 1;
        display: block;
        position: relative;
    }

    &__radio {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &__radio:checked + &__radio-label {
        background-color: $color-brand-green;
        color: $color-brand-white-abs;
    }

    &__radio-label {
        font-size: px-to-rem(16px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color__gray--light;
        height: px-to-rem(62px);
        padding: px-to-rem(10px);
        @include transition(background-color);
    }


    @include bp-min($bp-tablet-sm) {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        &__title {
            flex-shrink: 0;
            font-size: 1rem;
            margin-right: 1rem;
            margin-bottom: 0;
        }

        &__list {
            flex-grow: 1;
        }

    }

    @include bp-min($bp-tablet) {
        &__title {
            margin-right: px-to-rem(26px);
        }
        &__radio-label {
            font-size: 1rem;
        }
    }
}