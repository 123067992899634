.search-module {
    max-width: 770px;
    margin: 0 auto;
    padding: 108px 0;

    &__text {
        margin: 0 auto;
        max-width: 510px;
    }

    &__line1,
    &__line2 {
        color: $color__green;
        font-family: $font-family-serif-new;
    }

    &__line1 {
        font-size: 64px;
        line-height: 52px;
    }

    &__line2 {
        text-align: right;
        font-style: italic;
        font-size: 96px;
        line-height: 1;
    }

    &__input-wrapper {
        display: flex;
        position: relative;
        width: 100%;
    }

    &__input {
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid $color__gray--medium;
        font-family: $font-family-secondary;
        font-size: 20px;
        color: $color__gray--dark;
        font-weight: $font-weight-medium;

        &::placeholder {
            font-style: italic;
            color: $color__gray--dark;
            font-weight: $font-weight-medium;
        }
    }

    &__btn {
        color: $color-brand-white-abs;
        background-color: $color__green;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        padding: 0;

        .bi.bi-interface-search {
            font-size: 21px;
        }
    }

    &__close {
        padding: 0;
        position: absolute;
        right: 70px;
        bottom: 12px;
        display: none;

        &--visible {
            display: block;
        }
    }

    @include bp-max($bp-tablet-sm) {
        padding: 0;

        &__input {
            font-size: 16px;
            width: 89%;
        }

        &__text {
            margin-bottom: 24px;
        }

        &__line1 {
            font-size: 32px;
            line-height: 34px;
            text-align: center;
        }

        &__line2 {
            font-size: 48px;
            text-align: center;
        }

        &__btn {
            height: 40px;
            width: 40px;

            .bi.bi-interface-search {
                font-size: 16px;
            }
        }
    }
}