.article {
	//font-family: $font-family-accent;
	font-weight: $font-weight-light;
}

.article__header {
  margin-bottom: $spacing-unit /2;
}

.article__title {
	line-height: 1.1;
	margin-bottom: 0;
}

.article__lead {
  font-size: 120%;
}

.article__body {
	margin-bottom: $spacing-unit;
}


.article--modal {
	time {
		color: $color-text-gray;
		font-weight: $font-weight-regular;
		margin-bottom: $spacing-unit /2;
	}
}
