.trending-topics {
    .trending-topics__header {
        display: inline-block;
    }

    .trending-topics__content {
        margin-top: $spacing-unit/2;
        margin-bottom: $spacing-unit;
    }

    .list-topics__btn {
        float: right;
        display: inline-block;

        @include bp-max($bp-mobile) {
            float: none;
            display: block;
            margin-bottom: $spacing-unit;
        }
    }

    .list__item {
        
        font-family: $font-family-accent;
        color: #FFF;
        margin-bottom: 1px;
        position: relative;
        overflow: hidden;



        .list__header {
            font-size: px-to-rem(24px);
            margin-bottom: px-to-rem(30px);
            text-transform: uppercase;
            font-weight: $font-weight-bold;
        }
    }

     .trending-topics__bg {
        @extend %bg-cover;

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: scale(1);
        transition: transform 1s ease-out;
     }

    .trending-topics__link {
        display: block;
        color: $color-brand-white;
        position: relative;
        z-index: 5;
        padding: px-to-rem(50px) px-to-rem(40px);
        min-height: 18vh;

        &:hover {
            & + .trending-topics__bg {
                transform: scale(1.05);
            }
        }
    }

    .seemore-topics__btn {
        display: block;
        margin: auto;
    }
}