.quote-carousel {
    background-color: $color__gray--medium;
    color: $color-brand-white;
    $box-shadow: -5px 25px 25px 0 rgba(0, 0, 0, .15);

    &__wrapper {
        width: 100%;
        margin: auto;
    }

    &__slide {

    }

    &__slide-content {
        display: flex;
        align-items: stretch;
    }

    &__image {
        flex: 1 1 55%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &__text {
        flex: 1 1 45%;
        background-color: $color__gray--dark;
        padding: 4em;
        min-height: 700px;
        display: flex;
        align-items: center;

        &--blue {
            background-color: $color-content-blue;
        }

        &--citrus {
            background-color: $color-content-citrus;
        }

        &--royal {
            background-color: $color-content-royal;
        }

        &--royal-light {
            background-color: $color-content-royal-hl;
        }

        &--green {
            background-color: $color__green;
        }
    }

    &__text-content {

    }

    &__heading {
        font-family: $font-family-serif-new;
        font-size: px-to-rem(36px);
    }

    &__quote {
        position: relative;
        z-index: 0;
        font-family: $font-family-primary;
        font-size: px-to-rem(20px);

        &::before {
            content: url('/assets/site/Main/public/images/icons/quote-mark.svg');
            position: absolute;
            top: px-to-rem(-20px);
            left: px-to-rem(-20px);
            z-index: -1;
            opacity: .15;
        }

        &::after {
            content: url('/assets/site/Main/public/images/icons/quote-mark.svg');
            transform: rotate(180deg);
            position: absolute;
            bottom: px-to-rem(-20px);
            right: px-to-rem(-8px);
            z-index: -1;
            opacity: .15;
        }
    }

    &__details {
        margin-top: 2em;
    }

    &__name {
        font-family: $font-family-secondary;
        font-size: px-to-rem(20px);
        text-transform: uppercase;    
        font-weight: bold;
    }

    &__title {
        font-family: $font-family-secondary;
        font-size: px-to-rem(20px);
        text-transform: uppercase;
        font-weight: bold;
    }

    &__supporting {
        font-family: $font-family-serif-new;
        font-size: px-to-rem(24px);
        line-height: 1.2;
    }

    &__cta {
        background-color: $color-brand-white;
        padding: px-to-rem(10px) px-to-rem(16px);
        font-family: $font-family-titles;
        font-weight: $font-weight-bold;
        color: $color-brand-black !important;
        text-transform: uppercase;
        display: inline-block;
        font-size: px-to-rem(16px);
        margin-top: px-to-rem(35px);
        transform: translate(0, 0);
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

        &:hover {
            color: $color-brand-black;
            transform: translate(2px, -2px);
            box-shadow: $box-shadow;
        }
    }

    .swiper-nav {
        text-align: center;
        margin-top: 2em;
    }

    .swiper-play-pause {

    }

    .swiper-button-prev {
        color: $color-brand-white;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color-brand-white;
            font-size: px-to-rem(60px);
        }

        transform: rotate(180deg);
        background-image: none;
        left: 2em;
    }

    .swiper-button-next {
        color: $color-brand-white;
        background-image: none;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color-brand-white;
            font-size: px-to-rem(60px);
        }
        right: 2em;
    }

    .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        display: inline-block;
        margin: 8px;
        border-radius: 100%;
        border: 3px solid $color-brand-white;
        background-color: transparent;
    }
    
    .swiper-pagination-bullet-active {
        background-color: $color-brand-white;
    }


    @include bp-max($bp-tablet-sm) {
        &__slide-content {
            flex-wrap: wrap;
        }

        &__image {
            flex: 1 1 100%;
            height: 200px;
        }

        &__text {
            flex: 1 1 100%;
            min-height: 0;
        }

        .swiper-pagination {
            top: 170px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 100px;
        }
    }

	@include bp-max($bp-mobile) {
       
    }
}