.logo-carousel {
    background-color: $color__gray--dark;
    color: $color-brand-white;

    &__title {
        font-size: px-to-rem(64px);
        margin-bottom: px-to-rem(34px); 
    }

    &__wrapper {
        max-width: calc(1160px + #{($site-padding-tablet * 2)});
        width: 100%;
        margin: auto;
        padding: 4em 2em 2em 2em; 
    }

    &__slide {

    }

    .swiper-container {
        padding: 0 2em;
    }

    .swiper-wrapper {
        align-items: center;
    }

    .swiper-nav {
        text-align: center;
        margin-top: 2em;
        opacity: .5;
        transition: .3s ease all;

        &--playing {
            opacity: 1;
        }
    }

    .swiper-play-pause {

    }

    .swiper-button-prev {
        $autoplayHeight: 1.5rem;

        color: $color-brand-white;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color-brand-white;
            font-size: px-to-rem(60px);
        }
        height: calc(100% - #{$autoplayHeight});
        top: 0;
        left: 0;
        transform: rotate(180deg);
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: .3s ease all;

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -10px;
            width: 100px;
            height: 100%;
            background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, $color__gray--dark 70%);
            z-index: 0;
        }
    }

    .swiper-button-next {
        $autoplayHeight: 1.5rem;

        color: $color-brand-white;
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - #{$autoplayHeight});
        top: 0;
        right: 0;
        opacity: 1;
        transition: .3s ease all;
        @include bi-icon-after($bi-arrow-right) {
            display: block;
            margin: 0 auto;
            color: $color-brand-white;
            font-size: px-to-rem(60px);
        }

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: -10px;
            width: 100px;
            height: 100%;
            background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, $color__gray--dark 70%);
            z-index: 0;
        }
    }

    .swiper-button-disabled,
    .swiper-button-disabled.swiper-button-prev,
    .swiper-button-disabled.swiper-button-next {
        opacity: 0;
        pointer-events: none;
    }

    .swiper-slide {
        width: auto;
    }

    &__slide-content {
        padding: 0;
        display: block;
        img {
            max-width: 195px;
            width: auto;
            display: block;
        }
    }
   

    @include bp-max($bp-tablet-sm) {

        &__title {
            font-size: px-to-rem(32px);
            margin-bottom: 1.5rem;
        }

        .swiper-container {
            margin-left: -2em;
            margin-right: -2em;
        }

        &__slide-content {

            img {
                max-width: 100%;
            }
        }
       
    }

	@include bp-max($bp-mobile) {
       
    }
}