.latest-pubs {

  margin-top:-70px;
  
}

.comma-separated {
  display: flex;
}

.comma-separated > span + span:before {
  content: ", ";
}