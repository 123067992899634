.in-page-article-search {

    .filters {
        font-family: "brandon-grotesque", sans-serif;
        text-transform: uppercase;
        margin-bottom: $spacing-unit-block;

        .filter-label {
            display: inline-block;
            color: $color-gray-thunder;
            font-weight: $font-weight-bold;
        }

        .filter-list {
            display: inline-block;
            position: relative;
            padding-left: 1rem;
            margin-bottom:0;

            @include bp-max($bp-mobile) {
                margin: 0;
                padding: 0;
                display: block;
            }

            li {
                display: inline-block;
                padding: 0 .25rem;
                color: $color-gray-dim-gray;
                cursor: pointer;

                @include bp-max($bp-mobile) {
                    display: block;
                    margin: .5rem 0;
                    padding: 0;
                }

                @include bp-min($bp-mobile+1) {
                    + li:before {
                        padding-right: .75rem;
                        content: '|';
                    }
                }

                &.active-selection {
                    color: $color-blue-waikawa-gray;
                }
            }
        }

        .filter {
            .site-nav__link {
                font-size: inherit;
                margin-bottom: inherit;
                color: inherit;
            }

            .subnav {
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                text-align: left;
                opacity: 0;
                visibility: hidden;

                transition: opacity .4s ease-in, visibility .2s ease-in .4s;

                .filter {
                    font-size: px-to-rem(14px);
                }
            }

            & .is-visible-subnav {
                + .subnav {
                    transition: opacity .4s ease-in, visibility .4s ease-in;
                    opacity: 1 !important;
                    visibility: visible !important;

                    @include bp-max($bp-mobile) {
                        position: relative;
                    }
                }
            }
        }
    }
}