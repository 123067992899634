.site-searchbox__wrapper {
	@include vertical-align;

	position: absolute;
	height: 80%;
	width:100%;
	z-index: $zindex-modal-body;

	visibility: hidden;
	opacity: 0;

	.is-site-search-active &{
		position: fixed;
		opacity: 1;
		visibility: visible;
		transition: visibility 1s $animationEase ,
					opacity 1s $animationEase ;
	}

	.is-overlay-active-fadeout &{
		transition: visibility 1s $animationEase .3s,
					opacity 1s $animationEase;
	}
}

.site-searchbox {
	@include vertical-align;

	width: 60%;
	margin: 0 auto;


	@include bp-max($bp-mobile) {
    	width: 95%;
  	}

	.searchbox__input {
		background-color: $color-opaque-white;
		padding: .9rem 1.5rem .9rem 5rem;
		text-align: center;
		box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.4);
		text-align: left;
		line-height: 2rem;

		@include bp-max($bp-mobile) {
			padding-left: 3rem;
			font-size: 1rem;
		}
	}

	.searchbox__btn {
		color: $color-brand-green;
		right: auto;
		left: 0;
		font-size: 1.3rem;

		@include bp-max($bp-mobile) {
			font-size: 1.1rem;
			padding: 0 1.2rem;
		}
	}
}
