.list-authors {

  .media__img {
    max-width: 45%;
    padding-bottom:20px;
    @include bp-max($bp-mobile) {
      max-width: none;
      float: none;
      margin-bottom: $spacing-unit-small;
      padding-bottom:0px;
    }
  }

  &__name {
    @include font-accent-bold;

    font-size: px-to-rem(24px);
  }

  &__level {
    @extend %uc;
    @include font-secondary-medium;

    color: $color-brand-dark-gray;
    margin-bottom: $spacing-unit-small;
  }
  .media__bodyspace
      {
      overflow:hidden;
      margin-bottom: 1.3rem;
  }

.list-authors-result-details {
    font-size: 1rem;
    @media (min-width: px-to-rem(450px)) {
        font-size: px-to-rem(22px);
    }
}

.list-authors-result-tel {
    &::before {
        content: "\f095";
    }
    a {
        color: #000;
    }
}

.list-authors-result-email,
.list-authors-result-tel,
.list-authors-result-location {
    margin-bottom: 0.6rem;
}

.list-authors-result-email::before,
.list-authors-result-tel::before,
.list-authors-result-location::before {
    color: #555;
    display: inline-block;
    font-family: "Font Awesome";
    margin-right: 2rem;
    @media (min-width: px-to-rem(450px)) {
        margin-right: 1.3rem;
    }
}

.list-authors-result-email {
    margin-bottom: 0;
    &::before {
        content: "\f003";
    }
}

.list-authors-result-title {
    font-size: 1.1rem;
    margin-bottom: 1.75rem;
    @media (min-width: px-to-rem(850px)) {
        font-size: px-to-rem(26px);
        margin-bottom: .25rem;
    }
    }
}

