.field-group {
	@include clearfix;
}

.field--1of2 {
	float: left;
	width: 50%;
	padding: 0 1rem 1rem 0;

	&:last-child {
		padding-right: 0;
	}

	.textbox {
		width: 100%;
	}
}