.callout-listing {

    &__title {
        margin-bottom: 1rem;
    }

    &__intro {
        @include font-secondary-medium;
        color: $color__gray--dark;
    }

    &__item {
        margin-top: px-to-rem(32px)
    }


    &__link {
        @include font-secondary-medium;
        font-size: px-to-rem(22px);
        color: $color-brand-green;
    }

    @include bp-min($bp-tablet-sm) {
        &__items {
            margin-top: px-to-rem(48px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &:after {
                content: '';
                display: block;
                width: 32.3333%
            }
        }

        &__item {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            margin-top: 0;
            padding-bottom: px-to-rem(24px);
            width: 32.3333%
        }

        &__title {
            margin-bottom: px-to-rem(24px);
        }

        &__intro {
            font-size: px-to-rem(22px);
        }
    }

    @include bp-min($bp-desktop) {
        &__items {
            justify-content: flex-start;
            &:after {
                display: none;
            }
        }
        &__item {
            width: 25%;
            padding-right: 1rem;
        }
    }
}