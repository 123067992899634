//Brand colors
$color-brand-green: rgb(0, 154, 69);
$color-brand-lightgreen: rgb(173, 204, 191);
$color-brand-light: rgb(246, 246, 246);
$color-brand-black: rgb(0, 0, 0);
$color-brand-white: rgba(225, 225, 225, 1);
$color-brand-white-abs: #fff;
$color-brand-blue: rgb(90, 125, 153);
$color-brand-darkblue: rgb(69, 83, 99);
$color-brand-dark-gray: rgb(70, 70, 70);
$color-opaque-gray: rgb(98, 114, 120);
$color-forest-green: #1AA55A;


//opaque
$color-transparent: rgba(0, 0, 0, 0);
$color-opaque-blue: rgba(90, 125, 153, .85);
$color-opaque-green: rgba(10, 154, 69, .85);
$color-opaque-green-btn: rgba(10, 154, 69, .75);
$color-opaque-overlay: rgba(69, 83, 99, .9);
$color-opaque-black: rgba(0, 0, 0, .8);
$color-opaque-white: rgba(255, 255, 255, .9);
$color-opaque-white-modal: rgba(255, 255, 255, .96);
$color-opaque-dark-gray: rgba(70, 70, 70, .85);
$color-opaque-regent-blue: rgba(147, 190, 211, .5);
$color-opaque-teal: rgba(0, 128, 128, 0.25);
$color-opaque-gray-overlay: rgba(98, 114, 120, 0.80);


//Other Colors - names from http://www.color-blindness.com/color-name-hue/
$color-gray-light-gray: #D2D2D2;
$color-gray-dim-gray: #666666;
$color-gray-gainsboro: #e1e1e1;
$color-gray-thunder: #464645;

$color-blue-regent-blue: #93bed3;
$color-blue-dodger-blue: #578fdb;
$color-blue-waikawa-gray: #5b7e99;
$color-gray-lynch: #71848b;


//text colors
$color-text-base: $color-gray-thunder;
$color-text-rich-text: $color-gray-dim-gray;
$color-text-gray: rgb(112, 112, 112);
$color-text-gray-light: rgb(125, 125, 125);
$color-text-dark: $color-gray-thunder;
$color-text-input: #898989;
$color-text-green: #009a45;

//links
$color-links: $color-blue-waikawa-gray;
$color-links-hover: $color-blue-dodger-blue;
$color-list-arrow: $color-blue-regent-blue;

//misc colors
$color-text-bullets: $color-blue-waikawa-gray;


//border-colors
$color-border: $color-gray-gainsboro;
$color-border-alt: #d7d7d7;

// Careers
$color__green: #009B48;
$color__green--light: #9DFF78;
$color__gray--dark: #585858;
$color__gray--medium: #BCBCBC;
$color__gray--light: #F1F1F1;
$color__greige: #E9E6DE;

// Content Cards
$color-content-green: $color__green;
$color-content-green-hl: $color__green--light;
$color-content-blue: #3D7BCF;
$color-content-blue-hl: #6DC9FC;
$color-content-citrus: #EC572F;
$color-content-citrus-hl: #FFC144;
$color-content-royal: #2C4497;
$color-content-royal-hl: #4F72ED;