.people-letters-container {
    display: none;
    list-style-type: none;
    @media (min-width: px-to-rem(860px)) {
        display: block;
        button {
            border-width: 0;
            padding: 0;
        }
        ol {
            display: flex;
            justify-content: space-between;
            padding-left: 0;
            button {
                color: $color-blue-waikawa-gray;
                font-family: $font-family-secondary;
                font-size: px-to-rem(29px);
                font-weight: $font-weight-medium;
                padding: 0;
                text-transform: uppercase;
                &:hover {
                    color: mix(map-get($colors, lightblue), map-get($colors, dark), 95%);
                }
            }
            li {
                display: inline-block;
                padding: 0;
            }
        }
    }

    @include bp-max($bp-mobile) {
        display: block;
        ol{
            padding-left: 0;
            text-align: center;
            li{
                display: inline-block;
                width: 8vw;
                button{
                    font-size: 6vw;
                    color: $color-blue-waikawa-gray;
                }
            }
        }
    }
}
