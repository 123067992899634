.related-info {
	font-family: $font-family-accent;
	font-weight: $font-weight-light;

	& + &  {
		margin-top: $spacing-unit;
	}
}

.related-info__title {
	margin-bottom: $spacing-unit-small;
	text-transform: uppercase;

	&:after {
		content: ':';
	}
}

