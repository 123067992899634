.hero-banner {
    background-color: $color-brand-black;
    color: $color-brand-white-abs;
    padding: px-to-rem(45px) 0;
    margin-bottom: px-to-rem(32px);
    &__tag {
        @include font-secondary-medium;
        font-size: px-to-rem(16px);
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color-content-citrus;

        &:not(:last-child) {
            &::after {
                content: '|';
                color: $color-brand-white-abs;
                margin-left: 0.5em;
                margin-right: 0.5em;
            }
        }
    }

    &__title {
        font-family: $font-family-serif-new;
        font-size: px-to-rem(50px);
        line-height: 1;
        position: relative;
        z-index: 2;
        padding-right: 20%;
    }
    &__tags {
        margin-bottom: px-to-rem(12px);
        position: relative;
        z-index: 2;
    }

    &__main {
        position: relative;
        min-height: px-to-rem(250px)
    }

    &__image {
        width: 54%;
        right: 16px;
        top: 0;
        bottom: px-to-rem(-70px);
        position: absolute;
        background: no-repeat center;
        background-size: cover;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.4);
        }
    }

    @include bp-min($bp-tablet-sm) {
        padding: 0;

        &__wrapper {
            padding-top: px-to-rem(70px);
            padding-bottom: px-to-rem(70px);
            position: relative;
        }

        &__main {
            position: static;
        }

        &__title {
            font-size: px-to-rem(70px);
            line-height: 1;
        }

        $image-width: px-to-rem(460px);
        &__image {
            width: $image-width;
        }

        & + .breadcrumb-nav {
            padding-right: $image-width + 3rem;
        }
        & ~ .page-body {
            .article-detail-body {
                margin-top: 100px;
            }
        }
    }


    @include bp-min($bp-desktop) {
        &__wrapper {
            padding-top: px-to-rem(90px);
            padding-bottom: px-to-rem(90px);
        }
        &__title {
            font-size: px-to-rem(90px);
            line-height: 1;
        }

        &__image {
            bottom: auto;
            height: px-to-rem(620px);
        }

        & ~ .page-body {
            margin-top: px-to-rem(120px);

            .article-detail-body {
                margin-top: 0;
            }

            .article-detail-people,
            .article-detail-authors  {
                padding-right: px-to-rem(360px);
                min-height: 20px;
            }
        }
    }
}