fieldset {
	border: 0 none;
	padding: 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
	display: inline-block;
	background-color: $color-brand-white;
	border: 1px solid $color-brand-white;
	border-radius: 4px;
	padding: .5rem 1rem;
	font-family: $font-family-accent;
	font-weight: $font-weight-light;
	font-size: 1.1rem;
	line-height: 1.2rem;
	color: $color-text-input;

	&:active,
	&:focus {
		//outline: 1px solid $color-opaque-blue;
		outline: 0; 
	}

	@include placeholder() {
		color: $color-text-input;
	}
}
