.accordion-list {
	$question-spacing: 1em;
	width: 100%;
	.header {
		display: table;

		@include font-secondary-medium;
	}
	.header-text {
		display: table-cell;
		width: 100%;
		font-family: $font-family-serif-new;
        font-size: 64px;
		color: $color-brand-green;
		line-height: 1.1;
	}

	.button-expand-all {
		display: table-cell;
		font-size: px-to-rem(20px);
		white-space: nowrap;
		padding: 0;
		color: $color-brand-green; 
		transition: color .3s;
		text-transform: uppercase;

		&:hover {
			color: $color-links-hover;
		}
	}

	.questions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.question {
		margin-top: .5em;
	}

	.question-text {
		font-size: px-to-rem(26px);
		color: $color-brand-green;
		padding-top: $question-spacing;
		padding-bottom: $question-spacing;
		border-bottom: 2px solid $color-gray-light-gray;
		cursor: pointer;
		position: relative;
		padding-right: 1em;

		@include font-accent;

		@include bi-icon-after($bi-arrow-bottom) {

			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			transition: transform .4s;
			transform-origin: 50% 50%;
		}

		&.show-answer {
			&:after {
				transform: translateY(-50%) rotateX(180deg);
			}
		}
	}

	.question-answer {
		font-size: px-to-rem(24px);
		line-height: px-to-rem(32px);
		color: $color-gray-dim-gray;
		padding-top: $question-spacing;
		display: none;
		a:not(.button) {
			color: $color-brand-green;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include bp-max($bp-tablet-sm) {
		.header-text { 
			font-size: 32px;
		}
    }
}